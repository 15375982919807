import React from "react";

export function IconGraphic() {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 256">
            <path
                fill="currentColor"
                d="M245.83 121.63a15.53 15.53 0 00-9.52-7.33 73.55 73.55 0 00-22.17-2.22c4-19.85 1-35.55-2-44.86a16.17 16.17 0 00-18.8-10.88 85.53 85.53 0 00-28.55 12.12 94.58 94.58 0 00-27.11-33.25 16.05 16.05 0 00-19.26 0 94.58 94.58 0 00-27.16 33.25 85.53 85.53 0 00-28.55-12.12 16.14 16.14 0 00-18.79 10.88c-3 9.31-6 25-2.06 44.86a73.55 73.55 0 00-22.17 2.22 15.53 15.53 0 00-9.52 7.33 16 16 0 00-1.6 12.26c3.39 12.58 13.8 36.49 45.33 55.33S113.13 208 128.05 208s42.67 0 74-18.78c31.53-18.84 41.94-42.75 45.33-55.33a16 16 0 00-1.55-12.26zM62.1 175.49C35.47 159.57 26.82 140.05 24 129.7a59.61 59.61 0 0122.5-1.17 129.08 129.08 0 009.15 19.41 142.28 142.28 0 0034 39.56 114.92 114.92 0 01-27.55-12.01zM128 190.4c-9.33-6.94-32-28.23-32-71.23C96 76.7 118.38 55.24 128 48c9.62 7.26 32 28.72 32 71.19 0 42.98-22.67 64.27-32 71.21zm104-60.68c-2.77 10.24-11.4 29.81-38.09 45.77a114.92 114.92 0 01-27.55 12 142.28 142.28 0 0034-39.56 129.08 129.08 0 009.15-19.41 59.69 59.69 0 0122.49 1.19z"
            ></path>
        </svg>
    );
}
