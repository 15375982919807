import { useState } from "react";
import styles from "./VideoThumbnail.module.scss";

interface IProps {
    videoIdentifier: string;
}

export function VideoThumbnail(props: IProps) {
    const [maxResNotAvailable, setMaxResNotAvailable] = useState("");

    const size = maxResNotAvailable === props.videoIdentifier ? "sddefault" : "maxresdefault"; // maxresdefault is not always available, onError wont work because it returns an image but a small one

    return (
        <div className={styles.thumbnailWrapper}>
            <img
                className={styles.thumbnail}
                src={"https://img.youtube.com/vi/" + props.videoIdentifier + "/" + size + ".jpg"}
                alt=""
                onLoad={(event: any) => {
                    if (event.target.naturalWidth < 400) setMaxResNotAvailable(props.videoIdentifier);
                }}
            />
        </div>
    );
}
