import escapeHtml from "escape-html";
import { Descendant, Text } from "slate";

export const serializeSlateToHtml = (node: Descendant | { children: Descendant[] }): string => {
    if (Text.isText(node)) {
        let string = escapeHtml(node.text);
        if (node.bold) {
            string = `<strong>${string}</strong>`;
        }
        if (node.italic) {
            string = `<em>${string}</em>`;
        }
        if (node.underlined) {
            string = `<u>${string}</u>`;
        }
        return string;
    }
    const childrenNodes = node.children as Descendant[];
    const children = childrenNodes.map((n) => serializeSlateToHtml(n)).join("");

    // Any will be fixed with TS 4.7
    switch ((node as any).type) {
        case "heading-three":
            return `<h3>${children}</h3>`;
        case "paragraph":
            return `<p>${children}</p>`;
        case "link":
            const url = escapeHtml((node as any).url);
            if (url && url.substring(0, 1) === "#") {
                return `<a href="${url}">${children}</a>`;
            } else {
                return `<a href="${url}" rel="noreferrer" target="_blank">${children}</a>`;
            }
        default:
            return children;
    }
};
