import React from "react";
import { IGiftRegistryReservations } from "../../../../../interfaces/Site";
import { IGiftRegistryItem } from "../../../GiftRegistry";
import { giftRegistryReservedQuantity } from "../../utils/GiftRegistryReservedQuantity";

interface IProps {
    className?: any;
    giftRegistryItem: IGiftRegistryItem;
    giftRegistryReservations: IGiftRegistryReservations[] | undefined;
}

export function QuantityAndReservedQuantity(props: IProps) {
    if (props.giftRegistryItem.reservable !== true) {
        return null;
    }

    const reservedQuantity = giftRegistryReservedQuantity(props.giftRegistryItem, props.giftRegistryReservations);

    return (
        <div className={props.className}>
            {props.giftRegistryItem.quantity === 1 ? (
                <>{reservedQuantity > 0 ? "Reserverad" : ""}</>
            ) : (
                <>
                    Antal: {props.giftRegistryItem.quantity}
                    {!!reservedQuantity && <span>, reserverade: {reservedQuantity}</span>}
                </>
            )}
        </div>
    );
}
