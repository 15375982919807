import { logError } from "../generator/utils/LogError";
import { isLocalDev } from "./DomainHelpers";

const getUmami = (fn: (umami: any) => void, tryCount: number = 0) => {
    let umami = (window as any).window.umami;
    if (umami) {
        fn(umami);
    } else {
        if (tryCount < 10) {
            setTimeout(() => {
                getUmami(fn, tryCount + 1);
            }, 500);
        } else {
            throw new Error("Couldn't get umami on window.umami after 10 tries");
        }
    }
};

export const trackEvent = (eventName: string, data?: any) => {
    try {
        if (isLocalDev()) {
            console.log("Umami track event disabled due to local dev", eventName, data);
        } else {
            getUmami((umami) => {
                umami.track(eventName, data);
            });
        }
    } catch (e) {
        logError("umamiHelpers.trackEvent", e, 3);
    }
};
