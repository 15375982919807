import React, { ChangeEvent } from "react";
import { IconDelete } from "../../../icons/delete";
import { IconNewContentModule } from "../../../icons/newContentModule";
import { ISite } from "../../../interfaces/Site";
import Confirmation from "../../../modules/confirmation/Confirmation";
import EditButton, { PointerDirection } from "../../EditButton/EditButton";
import { RadioEdit } from "../Radio/RadioEdit";
import styles from "./RadioGroup.module.scss";

export interface IRadioGroupOption {
    label: string;
    id: string;
}

interface IProps {
    id: string;
    label: string;
    labelOnChange: any;
    removeItem?: any;
    site: ISite;
    options: IRadioGroupOption[];
    handleOptionLabelChange: (option: IRadioGroupOption, value: string) => void;
    handleOptionRemoveItem?: (option: IRadioGroupOption) => void;
    handleOptionAddItem?: () => void;
}

interface IState {
    showEditBar: boolean;
    showDeleteConfirmation: boolean;
}

export class RadioGroupEdit extends React.Component<IProps, IState> {
    state: IState = {
        showEditBar: false,
        showDeleteConfirmation: false,
    };

    showEditBar = () => {
        this.setState({
            showEditBar: true,
        });
    };

    refCancelOnClickOutside: any;

    componentDidMount() {
        document.addEventListener("mousedown", this.handleClickOutside);
    }

    componentWillUnmount() {
        document.removeEventListener("mousedown", this.handleClickOutside);
    }

    handleClickOutside = (event: Event) => {
        if (this.refCancelOnClickOutside && !this.refCancelOnClickOutside.contains(event.target)) {
            this.setState({
                showEditBar: false,
            });
        }
    };

    closeConfirmationCallback = () => {
        this.setState({
            showDeleteConfirmation: false,
        });
    };

    deleteWithConfirmation = () => {
        this.setState({
            showDeleteConfirmation: true,
        });
    };

    setRefForCancelOnClickOutside = (that: any) => {
        this.refCancelOnClickOutside = that;
    };

    labelOnChange = (e: ChangeEvent) => {
        let targetElement: HTMLElement = e.target as HTMLElement;
        let newLabel = targetElement.innerText;

        this.props.labelOnChange(newLabel);
    };

    render() {
        return (
            <div className={styles.wrapper}>
                {this.state.showDeleteConfirmation === true && (
                    <Confirmation
                        confirmationTitle="Ta bort"
                        confirmButtonText="Ta bort"
                        abortButtonText="Avbryt"
                        confirmationText={'Är du säker på att du vill ta bort frågan "' + this.props.label + '"?'}
                        confirmCallback={this.props.removeItem.bind(this)}
                        closeCallback={this.closeConfirmationCallback.bind(this)}
                    ></Confirmation>
                )}
                <div>
                    <div className={styles.markAsEditable}>
                        <div className={`${styles[this.props.site.theme]}`}>
                            <div
                                ref={this.setRefForCancelOnClickOutside}
                                className={styles.label}
                                onClick={this.showEditBar.bind(this)}
                            >
                                <div
                                    placeholder={this.props.labelOnChange ? "Ingen fråga" : undefined}
                                    contentEditable={this.props.labelOnChange ? true : false}
                                    suppressContentEditableWarning={true}
                                    onBlur={this.props.labelOnChange && this.labelOnChange}
                                    className={styles.labelText}
                                >
                                    {this.props.label}
                                </div>
                                <div className={styles.editBarWrapper}>
                                    {this.state.showEditBar === true && this.props.removeItem ? (
                                        <EditButton
                                            callback={this.deleteWithConfirmation.bind(this)}
                                            pointer={PointerDirection.UP}
                                        >
                                            <>
                                                <IconDelete></IconDelete>
                                                Ta bort fråga
                                            </>
                                        </EditButton>
                                    ) : null}
                                </div>
                            </div>
                            <div className={styles.options}>
                                {this.props.options?.map((option) => (
                                    <div className={styles.option} key={option.id}>
                                        <RadioEdit
                                            site={this.props.site}
                                            labelOnChange={(value: string) =>
                                                this.props.handleOptionLabelChange(option, value)
                                            }
                                            label={option.label}
                                            id={option.id}
                                            name={this.props.id}
                                            handleOptionRemoveItem={
                                                this.props.handleOptionRemoveItem
                                                    ? () => this.props.handleOptionRemoveItem!(option)
                                                    : undefined
                                            }
                                        />
                                    </div>
                                ))}
                                {this.props.handleOptionAddItem ? (
                                    <EditButton callback={this.props.handleOptionAddItem}>
                                        <>
                                            <IconNewContentModule />
                                            Lägg till svar
                                        </>
                                    </EditButton>
                                ) : null}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
