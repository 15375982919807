import { IColorScheme, IFonts } from "../../../../../../../../../../../interfaces/Site";
import { colorToCssValue } from "../../../../../../../../../../../utils/colorToCssValue";

export const getStyleVariables = (fonts: IFonts, colorScheme: IColorScheme) => {
    return {
        "--font-heading": fonts.heading,
        "--font-text": fonts.text,
        "--color-primary": colorToCssValue(colorScheme.primary),
        "--color-background": colorToCssValue(colorScheme.background),
        "--color-surface": colorToCssValue(colorScheme.surface),
        "--color-on-primary": colorToCssValue(colorScheme.onPrimary),
        "--color-on-background": colorToCssValue(colorScheme.onBackground),
        "--color-on-surface": colorToCssValue(colorScheme.onSurface),
    } as React.CSSProperties
};