import { IGiftRegistryReservations } from "../../../../interfaces/Site";
import { IGiftRegistryItem } from "../../GiftRegistry";

export const giftRegistryReservedQuantity = (
    giftRegistryItem: IGiftRegistryItem,
    giftRegistryReservations: IGiftRegistryReservations[] | undefined
): number => {
    const id = giftRegistryItem.id;
    if (giftRegistryReservations && giftRegistryReservations.length) {
        const giftRegistryReservation = giftRegistryReservations.find(
            (giftRegistryReservation) => giftRegistryReservation.giftRegistryId === id
        );
        if (giftRegistryReservation && giftRegistryReservation.quantityReserved) {
            return giftRegistryReservation.quantityReserved;
        } else {
            return 0;
        }
    } else {
        return 0;
    }
};
