import React from "react";
import { ChromePicker, ColorResult } from "react-color";
import { IColor, IColorScheme } from "../../../interfaces/Site";
import { colorToCssValue } from "../../../utils/colorToCssValue";
import { ColorV2Type } from "../EditColorV2";
import styles from "./AdvancedColorItem.module.scss";

interface IProps {
    colorPropName: ColorV2Type;
    currentColors: IColorScheme;
    title: string;
    setColors: (colors: IColorScheme) => void;
}

interface IState {
    pickerOpen: boolean;
}

export class AdvancedColorItem extends React.Component<IProps, IState> {
    refCancelOnClickOutside: any;

    state: IState = {
        pickerOpen: false,
    };

    componentDidMount() {
        document.addEventListener("mousedown", this.handleClickOutside);
    }

    componentWillUnmount() {
        document.removeEventListener("mousedown", this.handleClickOutside);
    }

    handleClickOutside = (event: Event) => {
        if (this.refCancelOnClickOutside && !this.refCancelOnClickOutside.contains(event.target)) {
            this.setState({
                pickerOpen: false,
            });
        }
    };

    setRefForCancelOnClickOutside = (that: any) => {
        this.refCancelOnClickOutside = that;
    };

    showPicker = (): void => {
        if (!this.state.pickerOpen) {
            this.setState({
                pickerOpen: true,
            });
        }
    };

    setColors = (color: ColorResult) => {
        const colors = { ...this.props.currentColors };
        const roundedHsl: IColor = {
            h: Math.round(color.hsl.h * 100) / 100,
            s: Math.round(color.hsl.s * 100) / 100,
            l: Math.round(color.hsl.l * 100) / 100,
            a: color.hsl.a === undefined ? 1 : color.hsl.a,
        };
        colors[this.props.colorPropName] = roundedHsl;
        this.props.setColors(colors);
    };

    render() {
        return (
            <div
                className={styles.wrapper}
                style={
                    this.props.currentColors && this.props.currentColors[this.props.colorPropName]
                        ? {
                              backgroundColor: colorToCssValue(this.props.currentColors[this.props.colorPropName]),
                          }
                        : {}
                }
                onClick={this.showPicker}
            >
                <span className={styles.titleWrapper}>{this.props.title}</span>
                <div className={styles.picker} ref={this.setRefForCancelOnClickOutside}>
                    {this.state.pickerOpen && (
                        <ChromePicker
                            onChange={this.setColors}
                            color={this.props.currentColors[this.props.colorPropName]}
                        />
                    )}
                </div>
            </div>
        );
    }
}

export default AdvancedColorItem;
