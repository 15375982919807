import React, { ChangeEvent } from "react";
import { Switch } from "../../../components/form/Switch/Switch";
import { IModule } from "../../../interfaces/Module";
import { ISite } from "../../../interfaces/Site";
import styles from "./MenuItem.module.scss";

interface IProps {
    site: ISite;
    updateHideInMenu: any;
    updateMenuItem: any;
    module: IModule;
}

export class ContentMenuItem extends React.Component<IProps> {
    onMenuTitleChange = (e: ChangeEvent) => {
        let targetElement: HTMLElement = e.target as HTMLElement;
        this.props.updateMenuItem(this.props.module, targetElement.innerText);
    };

    componentWillUnmount = () => {
        let targetElement = this.linkTextRef.current!;
        this.props.updateMenuItem(this.props.module, targetElement.innerText);
    };

    toggleHideInMenu = (value: boolean) => {
        this.props.updateHideInMenu(this.props.module, !value);
    };

    linkTextRef = React.createRef<HTMLDivElement>();

    render() {
        return (
            // eslint-disable-next-line jsx-a11y/anchor-is-valid
            <a className={this.props.module.hideInMenu === true ? styles.editableLinkHidden : styles.editableLink}>
                <div className={styles.editableToggle}>
                    <Switch
                        name="usePassword"
                        handleChange={this.toggleHideInMenu}
                        value={this.props.module.hideInMenu !== true}
                        id="usePassword"
                        dark={true}
                    ></Switch>
                </div>
                <div className={styles.hiddenText}>{this.props.module.hideInMenu ? "Dold" : ""}</div>
                <div
                    className={styles.editableLinkText}
                    ref={this.linkTextRef}
                    contentEditable
                    suppressContentEditableWarning={true}
                    onBlur={this.onMenuTitleChange}
                >
                    {this.props.module.menuTitle}
                </div>
            </a>
        );
    }
}

export default ContentMenuItem;
