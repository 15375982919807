import { IRvspPerson } from "../interfaces/Rvsp";
import {
    deprecatedRsvpValues,
    ICustomValue,
    IDeprecatedValueObject,
} from "../views/GeneratorEdit/views/ViewRvsp/ViewRvsp";

export const getCustomValuesFromRvsps = (rvsps: IRvspPerson[]): ICustomValue[] => {
    let customValues: ICustomValue[] = [];

    const isLabelAlreadyAdded = (label: string, customValues: ICustomValue[]) => {
        const count = customValues.filter((customValue: ICustomValue) => {
            return customValue.label === label;
        }).length;

        return count === 0 ? false : true;
    };

    rvsps.forEach((rvsp) => {
        if (rvsp.customValues && rvsp.customValues.length) {
            rvsp.customValues.forEach((customValue) => {
                if (isLabelAlreadyAdded(customValue.label, customValues) === false) {
                    customValues.push(customValue);
                }
            });
        }
    });

    return customValues;
};

export const getDeprecatedValuesFromRsvps = (rvsps: IRvspPerson[]): IDeprecatedValueObject[] => {
    let values: IDeprecatedValueObject[] = [];

    const isValueAlreadyAdded = (key: string, values: IDeprecatedValueObject[]) => {
        const count = values.filter((value: IDeprecatedValueObject) => {
            return value.key === key;
        }).length;

        return count === 0 ? false : true;
    };

    rvsps.forEach((rvsp) => {
        if (rvsp[deprecatedRsvpValues.FIRSTNAME]) {
            if (isValueAlreadyAdded(deprecatedRsvpValues.FIRSTNAME, values) === false) {
                values.push({
                    key: deprecatedRsvpValues.FIRSTNAME,
                    label: "Förnamn",
                });
            }
        }

        if (rvsp[deprecatedRsvpValues.LASTNAME]) {
            if (isValueAlreadyAdded(deprecatedRsvpValues.LASTNAME, values) === false) {
                values.push({
                    key: deprecatedRsvpValues.LASTNAME,
                    label: "Efternamn",
                });
            }
        }

        if (rvsp[deprecatedRsvpValues.MAIL]) {
            if (isValueAlreadyAdded(deprecatedRsvpValues.MAIL, values) === false) {
                values.push({
                    key: deprecatedRsvpValues.MAIL,
                    label: "E-post",
                });
            }
        }

        if (rvsp[deprecatedRsvpValues.PHONE]) {
            if (isValueAlreadyAdded(deprecatedRsvpValues.PHONE, values) === false) {
                values.push({
                    key: deprecatedRsvpValues.PHONE,
                    label: "Telefon",
                });
            }
        }
    });

    return values;
};
