import Button from "../../../../../../../../components/button/Button";
import { ButtonRow } from "../../../../../../../../components/ButtonRow/ButtonRow";
import { ISite } from "../../../../../../../../interfaces/Site";
import { getBrandedContactMail } from "../../../../../../../../utils/getBrand";
import { getProductFromDomainOption } from "../../../../utils/getProductFromDomainOption";
import { domainOption } from "../../../SelectProduct/SelectProduct";
import styles from "./../../EditDomainName.module.scss";

interface IProps {
    fullDomain: string;
    setShowDomainPayment: (show: boolean) => void;
    setViewSearchDomain: () => void;
    handleRegisterDomain: () => void;
    site: ISite;
}

export function DomainAvailable(props: IProps) {
    const product = getProductFromDomainOption(domainOption.CUSTOM_DOMAIN, props.site);
    return (
        <div className={styles.infoBox}>
            <h2 className={styles.availableTitle}>{props.fullDomain} är ledig!</h2>
            <p>
                När du köper vårt domänpaket för {product.price}:- ingår {props.fullDomain} som vi registrerar hos vår
                domänleverantör GleSYS.
            </p>

            <p>
                Observera att ett domännamn gäller i <strong>12 månader</strong> om du inte förlänger det mot en ny
                kostnad. <strong>Det går inte att ångra hela ditt köp av domännamn</strong> så var noga med att du
                stavat rätt och är nöjd med <strong>{props.fullDomain}</strong>.
            </p>
            {props.site.customDomainActive && !props.site.customDomainRegistrationsAvailable ? (
                <div>
                    <strong>
                        Du har redan köpt en domän. Om du vill köpa en till, vänligen kontakta oss på{" "}
                        <a className={styles.mailLink} href={"mailto:" + getBrandedContactMail()}>
                            {getBrandedContactMail()}
                        </a>{" "}
                        så hjälper vi dig.
                    </strong>
                </div>
            ) : (
                <ButtonRow
                    primary={
                        <Button
                            type="primary"
                            callback={() => {
                                props.site.customDomainRegistrationsAvailable
                                    ? props.handleRegisterDomain()
                                    : props.setShowDomainPayment(true);
                            }}
                            buttonText={props.site.customDomainRegistrationsAvailable ? "Välj domän" : "Köp domän"}
                            data-test-id="buy-domain-button"
                        />
                    }
                    secondary={
                        <Button type="secondary" callback={props.setViewSearchDomain} buttonText={"Byt domän"} />
                    }
                />
            )}
        </div>
    );
}
