import React from "react";
import YesOrNoEdit from "../../../components/form/YesOrNo/YesOrNoEdit";
import { ISite } from "../../../interfaces/Site";
import { IMultipleYesOrNoItem } from "../MultipleYesOrNo";

interface IProps {
    yesOrNoItem: IMultipleYesOrNoItem;
    updateLabel: any;
    updateNoLabel: any;
    updateYesLabel: any;
    removeItem: any;
    id: string;
    site: ISite;
}

export class YesOrNoItem extends React.Component<IProps> {
    labelOnChange = (newLabel: string) => {
        this.props.updateLabel(this.props.yesOrNoItem, newLabel);
    };

    labelYesOnChange = (newLabel: string) => {
        this.props.updateYesLabel(this.props.yesOrNoItem, newLabel);
    };

    labelNoOnChange = (newLabel: string) => {
        this.props.updateNoLabel(this.props.yesOrNoItem, newLabel);
    };

    removeItem = () => {
        this.props.removeItem(this.props.yesOrNoItem);
    };

    render() {
        return (
            <div>
                <YesOrNoEdit
                    label={this.props.yesOrNoItem.label}
                    id={this.props.id}
                    labelOnChange={this.labelOnChange.bind(this)}
                    labelNoOnChange={this.labelNoOnChange.bind(this)}
                    labelYesOnChange={this.labelYesOnChange.bind(this)}
                    removeItem={this.removeItem.bind(this)}
                    labelNo={this.props.yesOrNoItem.labelNo}
                    labelYes={this.props.yesOrNoItem.labelYes}
                    site={this.props.site}
                />
            </div>
        );
    }
}

export default YesOrNoItem;
