import Axios from "axios";
import React from "react";
import Button from "../../components/button/Button";
import Checkbox from "../../components/form/Checkbox/Checkbox";
import FormWrapper from "../../components/form/FormWrapper/FormWrapper";
import PasswordField from "../../components/form/PasswordField/PasswordField";
import TextField from "../../components/form/TextField/TextField";
import Loading from "../../components/Loading/Loading";
import styles from "./SignUp.module.scss";
import ShowError from "../../components/ShowError/ShowError";
import { IError } from "../../../App";

interface IProps {
    callback?: any;
}

interface IState {
    email: string;
    password: string;
    loading: boolean;
    error: IError | null;
}

export class SignUp extends React.Component<IProps> {
    state: IState = {
        email: "",
        password: "",
        loading: false,
        error: null,
    };

    onChangeEmail = (email: string) => {
        this.setState({ email });
    };

    onChangePassword = (password: string) => {
        this.setState({ password });
    };

    submitSignUp = () => {
        this.setState({
            loading: true,
        });
        Axios.post(process.env.REACT_APP_API_URL + "/api/accounts/register/", {
            password: this.state.password,
            mail: this.state.email,
        })
            .then((response) => {
                this.setState({
                    loading: false,
                });

                if (process.env.REACT_APP_BRAND === "party") {
                    try {
                        // @ts-ignore
                        gtag("set", "user_data", {
                            email: this.state.email,
                        });
                        // @ts-ignore
                        gtag("event", "conversion", { send_to: "AW-11043587659/eylaCJiWwoUYEMuM_5Ep" });
                    } catch {}
                }

                if (this.props.callback) {
                    this.props.callback();
                }
            })
            .catch((error) => {
                this.setState({
                    loading: false,
                    error: { text: "Misslyckades att registrera", response: (error as any).response },
                });
            });
    };

    render() {
        return (
            <>
                <ShowError error={this.state.error} />
                <div className={styles.wrapper}>
                    <FormWrapper submit={this.submitSignUp.bind(this)} data-test-id="form-signup">
                        <TextField
                            required={true}
                            email={true}
                            label="E-post"
                            name="email"
                            id="email"
                            onChangeCallback={this.onChangeEmail.bind(this)}
                        ></TextField>
                        <PasswordField
                            required={true}
                            label="Lösenord"
                            name="password"
                            id="password"
                            onChangeCallback={this.onChangePassword.bind(this)}
                        ></PasswordField>
                        <div className={styles.termsWrapper}>
                            <Checkbox
                                name="terms"
                                id="terms"
                                required={true}
                                onChangeCallback={() => {}}
                                label={"Jag godkänner "}
                            >
                                {" "}
                                <a
                                    href={process.env.REACT_APP_LANDING_URL + "/villkor"}
                                    target="_blank"
                                    rel="noreferrer"
                                    className={styles.readTermsLink}
                                >
                                    villkoren
                                </a>
                            </Checkbox>
                        </div>
                        <div className={styles.buttonWrapper}>
                            <Button
                                data-test-id={"button-submit-sign-up"}
                                submit={true}
                                buttonText="Registrera"
                                type="primary"
                            />
                        </div>
                        {this.state.loading === true ? <Loading></Loading> : <></>}
                    </FormWrapper>
                </div>
            </>
        );
    }
}

export default SignUp;
