import React from "react";
import { IRadioGroupOption, RadioGroupEdit } from "../../../components/form/RadioGroup/RadioGroupEdit";
import { ISite } from "../../../interfaces/Site";
import { IMultipleRadioGroupsItem } from "../MultipleRadioGroups";

interface IProps {
    radioGroupItem: IMultipleRadioGroupsItem;
    updateLabel: any;
    removeItem: any;
    id: string;
    site: ISite;
    handleOptionLabelChange: (item: IMultipleRadioGroupsItem, option: IRadioGroupOption, value: string) => void;
    handleOptionRemoveItem?: (item: IMultipleRadioGroupsItem, option: IRadioGroupOption) => void;
    handleOptionAddItem?: (item: IMultipleRadioGroupsItem) => void;
}

export class MultipleRadioGroupItem extends React.Component<IProps> {
    labelOnChange = (newLabel: string) => {
        this.props.updateLabel(this.props.radioGroupItem, newLabel);
    };

    removeItem = () => {
        this.props.removeItem(this.props.radioGroupItem);
    };

    handleOptionRemoveItem = (option: IRadioGroupOption) => {
        if (this.props.handleOptionRemoveItem) {
            this.props.handleOptionRemoveItem(this.props.radioGroupItem, option);
        }
    };

    handleOptionAddItem = () => {
        if (this.props.handleOptionAddItem) {
            this.props.handleOptionAddItem(this.props.radioGroupItem);
        }
    };

    handleOptionLabelChange = (option: IRadioGroupOption, value: string) => {
        this.props.handleOptionLabelChange(this.props.radioGroupItem, option, value);
    };

    render() {
        return (
            <div>
                <RadioGroupEdit
                    label={this.props.radioGroupItem.label}
                    id={this.props.id}
                    labelOnChange={this.labelOnChange.bind(this)}
                    handleOptionLabelChange={this.handleOptionLabelChange.bind(this)}
                    removeItem={this.removeItem.bind(this)}
                    handleOptionRemoveItem={this.handleOptionRemoveItem.bind(this)}
                    handleOptionAddItem={
                        this.props.handleOptionAddItem ? this.handleOptionAddItem.bind(this) : undefined
                    }
                    site={this.props.site}
                    options={this.props.radioGroupItem.options}
                />
            </div>
        );
    }
}
