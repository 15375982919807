import { IRvspPerson, IRvspPersonV2 } from "../../../../../interfaces/Rvsp";
import { randomId } from "../../../../../utils/Random";

export const getDemoRsvps = (): IRvspPerson[] => {
    const rsvps: IRvspPersonV2[] = [
        createDemoRsvp(1, "Elisabeth", "Johansson", true, "Ja t/r", "Vegetarian", "I natt är jag din"),
        createDemoRsvp(2, "Mohammed", "Cazhes", true, "Ja t/r", "", "Disco metal"),
        createDemoRsvp(3, "Lisa", "Cazhes", true, "Ja t/r", "Vegan", "Making Your Mind Up - Bucks Fizz"),
        createDemoRsvp(4, "Christina", "Kande", false, "Nej tack", "", ""),
        createDemoRsvp(5, "Stefan", "Kande", false, "Nej tack", "", ""),
        createDemoRsvp(6, "Elin", "Johanesson", true, "Ja t/r", "", "Simply the best"),
        createDemoRsvp(7, "Kalle", "Johanesson", true, "Ja hem", "", "Det är ju dig jag går och väntar på"),
        createDemoRsvp(8, "Johan", "Davidsson", true, "Ja hem", "", "Abba - Does your mother know"),
        createDemoRsvp(9, "Robert", "Stella", true, "Ja t/r", "Vegetarian", "Linda Bengtzing - hur svårt kan det va"),
        createDemoRsvp(10, "Gustaf", "Stella", true, "Ja t/r", "", "Gasolina med Daddy Yankee!"),
        createDemoRsvp(11, "Lina", "Hedlund", true, "Ja t/r", "Nötter", "Dancing Queen"),
        createDemoRsvp(12, "Cajsa", "Modig", true, "Ja, dit", "", "It's gonna be Me - Nsync"),
        createDemoRsvp(13, "Kent", "Vikander", true, "Ja t/r", "Färsk ananas", "One more time - Daft punk"),
        createDemoRsvp(14, "Lisa", "Vikander", true, "Ja t/r", "", "Stayin Alive"),
        createDemoRsvp(15, "Alice", "Eng", true, "Nej tack", "", "Knaan - Wavin flag"),
        createDemoRsvp(16, "Jesper", "Mejvel", true, "Ja, dit", "", "Papaoutai - med Stromae"),
        createDemoRsvp(17, "Julia", "Mejvel", true, "Nej tack", "", "Eminem - Houdini"),
        createDemoRsvp(18, "Gunde", "Andersson", true, "Nej tack", "", "Euphoria - Loreen"),
        createDemoRsvp(19, "Per-Olof", "Ängbotten", true, "Ja t/r", "Nötter", "I feel love, donna summer"),
        createDemoRsvp(20, "Monica", "Ängbotten", true, "Ja t/r", "", "Levels"),
        createDemoRsvp(21, "Bo", "Davidsson", false, "Nej tack", "", ""),
    ];
    return rsvps as IRvspPerson[]; // Compability with old
};

const createDemoRsvp = (
    number: number,
    firstName: string,
    lastname: string,
    attending: boolean,
    transport: "Ja t/r" | "Ja, dit" | "Ja hem" | "Nej tack",
    food: "" | "Vegetarian" | "Vegan" | "Nötter" | "Färsk ananas",
    song: string
): IRvspPersonV2 => {
    const rsvp = {
        registered: {
            ip: "000.000.000.00",
            date: "2024-06-" + (29 - number).toString().padStart(2, "0") + "T12:27:46.277Z",
        },
        _id: "mock-id-db" + randomId(),
        site: "sitemockid",
        customValues: [
            {
                id: "textFielda6e2dad8-7372-b372-aa4a-73e99abf6033",
                value: firstName,
                type: "TextField",
                label: "Förnamn",
            },
            {
                id: "textField5396fd98-4043-6844-3e71-c4fa3e38acf2",
                value: lastname,
                type: "TextField",
                label: "Efternamn",
            },
            {
                id: "textField0149b58f-a8cf-1f50-ddde-1f7dbe1eb0ba",
                value: firstName + "@exempelmejl.com",
                type: "TextField",
                label: "E-post",
            },
            {
                id: "textField78522bc5-96f5-d555-b659-da2ebafd7e19",
                value: "070212345" + number.toString().padStart(2, "0"),
                type: "TextField",
                label: "Telefonnummer",
            },
            {
                id: "RadioGroupf9fc7aa1-85f6-c0f1-45df-e32b7314811d",
                value: attending ? "Ja!" : "Nej",
                type: "RadioGroup",
                label: "Kommer du?",
            },
            {
                id: "RadioGroupf9fc7aa1-85f6-c0f1-45df-e32b7314811d",
                value: transport,
                type: "RadioGroup",
                label: "Vill du åka buss?",
            },
            {
                id: "textField8f9d2074-3226-1328-4c2d-dffdf186f6a2",
                value: food,
                type: "TextField",
                label: "Eventuella allergier eller annan specialkost",
            },
            {
                id: "textFieldfa0d7c80-8b62-0929-097b-e2e331d72096",
                value: song,
                type: "TextField",
                label: "Om denna låten kommer på festen lovar jag att dansa",
            },
        ],
        source: "mock-rvsps",
    };
    return rsvp;
};
