import React from "react";
import styles from "./TextArea.module.scss";

interface IProps {
    onChangeCallback: any;
    value?: string;
    name: string;
    id: string;
    label?: string;
    disabled?: boolean;
    required?: boolean;
    className?: any;
    placeholder?: string;
}

interface IState {
    value: string;
}

export class TextArea extends React.Component<IProps, IState> {
    state: IState = {
        value: this.props.value || "",
    };

    componentDidUpdate(prevProps: any) {
        if (prevProps.value !== this.props.value && this.props.value !== this.state.value) {
            this.setState({
                value: this.props.value!,
            });
        }
    }

    handleChange(event: React.ChangeEvent<HTMLTextAreaElement>) {
        this.setState({ value: event.target.value });

        this.props.onChangeCallback(event.target.value, this.props.name);
    }

    render() {
        return (
            <div className={this.props.className || styles.textFieldWrapper}>
                <label htmlFor={this.props.id}>{this.props.label}</label>
                <textarea
                    required={this.props.required ? true : false}
                    placeholder={this.props.placeholder}
                    disabled={this.props.disabled ? true : false}
                    id={this.props.id}
                    name={this.props.name}
                    value={this.state.value}
                    onChange={this.handleChange.bind(this)}
                />
                <div className="invalidFeedback" data-invalid-feedback="true"></div>
            </div>
        );
    }
}
