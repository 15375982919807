import React, { useEffect } from "react";
import styles from "./GeneratorSelectList.module.scss";
import { GeneratorSelectListItem } from "./GeneratorSelectListItem/GeneratorSelectListItem";

interface IOption {
    title: string | JSX.Element;
    value: string;
    testId?: string;
}

interface IProps {
    onChangeCallback: any;
    options: IOption[];
    name?: string;
    value: any;
    cols?: number;
    defaultValue?: string;
    dark?: boolean;
}

export function GeneratorSelectList(props: IProps) {
    useEffect(() => {
        if (!props.value && props.defaultValue) {
            props.onChangeCallback(props.defaultValue, props.name);
        }
    }, [props]);

    return (
        <div
            className={`${styles.wrapper} ${props.cols === 2 ? styles.cols2 : ""} ${
                props.cols === 3 ? styles.cols3 : ""
            }`}
        >
            {props.options.map((option: IOption) => (
                <GeneratorSelectListItem
                    title={option.title}
                    selected={props.value === option.value}
                    onClick={() => props.onChangeCallback(option.value, props.name)}
                    key={option.value}
                    dark={props.dark}
                    testId={option.testId}
                />
            ))}
        </div>
    );
}
