import { IColorScheme } from "../../../interfaces/Site";

export interface IColorSchemeCollections {
    [key: string]: IColorSchemeCollection;
}

export interface IColorSchemeCollection {
    name: string;
    colorScheme: IColorScheme;
}

export const colorSchemeCollections: IColorSchemeCollections = {
    BLACK_AND_WHITE: {
        name: "BLACK_AND_WHITE",
        colorScheme: {
            primary: {
                h: 100.62,
                s: 0,
                l: 0.23,
                a: 1,
            },
            background: {
                h: 1,
                s: 0,
                l: 0.96,
                a: 1,
            },
            surface: {
                h: 0,
                s: 0,
                l: 1,
                a: 0.8,
            },
            onPrimary: {
                h: 1,
                s: 0,
                l: 0.96,
                a: 1,
            },
            onBackground: {
                h: 1.83,
                s: 0,
                l: 0.21,
                a: 1,
            },
            onSurface: {
                h: 1,
                s: 0,
                l: 0.21,
                a: 1,
            },
        },
    },
    GOLD: {
        name: "GOLD",
        colorScheme: {
            primary: {
                h: 100.62,
                s: 0,
                l: 0.16,
                a: 1,
            },
            background: {
                h: 1,
                s: 0,
                l: 0.96,
                a: 1,
            },
            surface: {
                h: 0,
                s: 0,
                l: 1,
                a: 1,
            },
            onPrimary: {
                h: 34.15,
                s: 0.66,
                l: 0.64,
                a: 1,
            },
            onBackground: {
                h: 1.83,
                s: 0,
                l: 0.21,
                a: 1,
            },
            onSurface: {
                h: 1,
                s: 0,
                l: 0.21,
                a: 1,
            },
        },
    },
    LIGHT_GREEN: {
        name: "LIGHT_GREEN",
        colorScheme: {
            primary: {
                h: 105.45,
                s: 0.16,
                l: 0.59,
                a: 1,
            },
            background: {
                h: 78,
                s: 0.26,
                l: 0.93,
                a: 1,
            },
            surface: {
                h: 0,
                s: 0,
                l: 1,
                a: 0.8,
            },
            onPrimary: {
                h: 105,
                s: 0.1,
                l: 0.33,
                a: 1,
            },
            onBackground: {
                h: 1.83,
                s: 0,
                l: 0.21,
                a: 1,
            },
            onSurface: {
                h: 1,
                s: 0,
                l: 0.21,
                a: 1,
            },
        },
    },
    VINTAGE_GREEN: {
        name: "VINTAGE_GREEN",
        colorScheme: {
            primary: {
                h: 92,
                s: 0.22,
                l: 0.57,
                a: 1,
            },
            background: {
                h: 56,
                s: 0.63,
                l: 0.97,
                a: 1,
            },
            surface: {
                h: 56,
                s: 0.63,
                l: 0.97,
                a: 1,
            },
            onPrimary: {
                h: 56,
                s: 0.63,
                l: 0.97,
                a: 1,
            },
            onBackground: {
                h: 47,
                s: 0.1,
                l: 0.15,
                a: 1,
            },
            onSurface: {
                h: 47,
                s: 0.1,
                l: 0.15,
                a: 1,
            },
        },
    },
    LIGHT_GREY: {
        name: "LIGHT_GREY",
        colorScheme: {
            primary: {
                h: 92,
                s: 0,
                l: 0.47,
                a: 1,
            },
            background: {
                h: 225.84,
                s: 0.12,
                l: 0.96,
                a: 1,
            },
            surface: {
                h: 240,
                s: 0.07,
                l: 0.97,
                a: 1,
            },
            onPrimary: {
                h: 120,
                s: 0,
                l: 0.94,
                a: 1,
            },
            onBackground: {
                h: 47,
                s: 0.1,
                l: 0.15,
                a: 1,
            },
            onSurface: {
                h: 47,
                s: 0.1,
                l: 0.15,
                a: 1,
            },
        },
    },
    YELLOW_BROWN: {
        name: "YELLOW_BROWN",
        colorScheme: {
            primary: {
                h: 42.11,
                s: 0.36,
                l: 0.69,
                a: 1,
            },
            background: {
                h: 48,
                s: 0.2,
                l: 0.9,
                a: 1,
            },
            surface: {
                h: 0,
                s: 0,
                l: 1,
                a: 0.8,
            },
            onPrimary: {
                h: 31.43,
                s: 0.18,
                l: 0.45,
                a: 1,
            },
            onBackground: {
                h: 42.11,
                s: 0,
                l: 0.21,
                a: 1,
            },
            onSurface: {
                h: 1,
                s: 0,
                l: 0.21,
                a: 1,
            },
        },
    },
    RED: {
        name: "RED",
        colorScheme: {
            primary: {
                h: 8.89,
                s: 0.38,
                l: 0.28,
                a: 1,
            },
            background: {
                h: 351.43,
                s: 1,
                l: 0.99,
                a: 1,
            },
            surface: {
                h: 0,
                s: 0,
                l: 1,
                a: 0.8,
            },
            onPrimary: {
                h: 7.88,
                s: 0.9,
                l: 0.7,
                a: 1,
            },
            onBackground: {
                h: 1.83,
                s: 0,
                l: 0.21,
                a: 1,
            },
            onSurface: {
                h: 1,
                s: 0,
                l: 0.21,
                a: 1,
            },
        },
    },
    PURPLE: {
        name: "PURPLE",
        colorScheme: {
            primary: {
                h: 270,
                s: 0.23,
                l: 0.58,
                a: 1,
            },
            background: {
                h: 0,
                s: 0.26,
                l: 0.93,
                a: 1,
            },
            surface: {
                h: 0,
                s: 0,
                l: 1,
                a: 0.8,
            },
            onPrimary: {
                h: 16.36,
                s: 1,
                l: 0.91,
                a: 1,
            },
            onBackground: {
                h: 1.83,
                s: 0,
                l: 0.21,
                a: 1,
            },
            onSurface: {
                h: 1,
                s: 0,
                l: 0.21,
                a: 1,
            },
        },
    },
    LIGHT_BROWN: {
        name: "LIGHT_BROWN",
        colorScheme: {
            primary: {
                h: 24,
                s: 0.22,
                l: 0.23,
                a: 1,
            },
            background: {
                h: 35.45,
                s: 0.55,
                l: 0.92,
                a: 1,
            },
            surface: {
                h: 8.94,
                s: 0,
                l: 1,
                a: 0.6,
            },
            onPrimary: {
                h: 34.15,
                s: 0.39,
                l: 0.78,
                a: 1,
            },
            onBackground: {
                h: 23.08,
                s: 0.22,
                l: 0.23,
                a: 1,
            },
            onSurface: {
                h: 39,
                s: 0.48,
                l: 0.16,
                a: 1,
            },
        },
    },
    BEIGE_AND_PURPLE: {
        name: "BEIGE_AND_PURPLE",
        colorScheme: {
            primary: {
                h: 341.54,
                s: 0.09,
                l: 0.45,
                a: 1,
            },
            background: {
                h: 52.94,
                s: 0.23,
                l: 0.85,
                a: 1,
            },
            surface: {
                h: 41.54,
                s: 0.39,
                l: 0.92,
                a: 1,
            },
            onPrimary: {
                h: 52.5,
                s: 0,
                l: 1,
                a: 1,
            },
            onBackground: {
                h: 40,
                s: 0.03,
                l: 0.38,
                a: 1,
            },
            onSurface: {
                h: 45,
                s: 0.03,
                l: 0.38,
                a: 1,
            },
        },
    },
    BLUE_GREY: {
        name: "BLUE_GREY",
        colorScheme: {
            primary: {
                h: 196.77,
                s: 0.13,
                l: 0.42,
                a: 1,
            },
            background: {
                h: 201.43,
                s: 0.7,
                l: 0.96,
                a: 1,
            },
            surface: {
                h: 205.16,
                s: 0.49,
                l: 0.88,
                a: 1,
            },
            onPrimary: {
                h: 34.15,
                s: 0,
                l: 1,
                a: 1,
            },
            onBackground: {
                h: 203.33,
                s: 0.07,
                l: 0.21,
                a: 1,
            },
            onSurface: {
                h: 205.71,
                s: 0.07,
                l: 0.21,
                a: 1,
            },
        },
    },
    GREEN_NEON: {
        name: "GREEN_NEON",
        colorScheme: {
            primary: {
                h: 147.5,
                s: 0.15,
                l: 0.31,
                a: 1,
            },
            background: {
                h: 78,
                s: 0.26,
                l: 0.93,
                a: 1,
            },
            surface: {
                h: 0,
                s: 0,
                l: 1,
                a: 0.8,
            },
            onPrimary: {
                h: 64.57,
                s: 0.49,
                l: 0.58,
                a: 1,
            },
            onBackground: {
                h: 1.83,
                s: 0,
                l: 0.21,
                a: 1,
            },
            onSurface: {
                h: 1,
                s: 0,
                l: 0.21,
                a: 1,
            },
        },
    },
    YELLOW_GREEN: {
        name: "YELLOW_GREEN",
        colorScheme: {
            primary: {
                h: 174.4,
                s: 1,
                l: 0.29,
                a: 1,
            },
            background: {
                h: 55,
                s: 1,
                l: 0.95,
                a: 1,
            },
            surface: {
                h: 0,
                s: 0,
                l: 1,
                a: 0.8,
            },
            onPrimary: {
                h: 48.84,
                s: 0.91,
                l: 0.82,
                a: 1,
            },
            onBackground: {
                h: 1.83,
                s: 0,
                l: 0.21,
                a: 1,
            },
            onSurface: {
                h: 1,
                s: 0,
                l: 0.21,
                a: 1,
            },
        },
    },
    BLACK: {
        name: "BLACK",
        colorScheme: {
            primary: {
                h: 174.4,
                s: 0,
                l: 0.97,
                a: 1,
            },
            background: {
                h: 240,
                s: 0.04,
                l: 0.15,
                a: 1,
            },
            surface: {
                h: 240,
                s: 0,
                l: 0,
                a: 0.4,
            },
            onPrimary: {
                h: 240,
                s: 0.04,
                l: 0.15,
                a: 1,
            },
            onBackground: {
                h: 1.83,
                s: 0,
                l: 0.97,
                a: 1,
            },
            onSurface: {
                h: 1,
                s: 0,
                l: 0.97,
                a: 1,
            },
        },
    },
    RICH_BLUE: {
        name: "RICH_BLUE",
        colorScheme: {
            primary: {
                h: 240,
                s: 0.13,
                l: 0.94,
                a: 1,
            },
            background: {
                h: 203.27,
                s: 0.45,
                l: 0.21,
                a: 1,
            },
            surface: {
                h: 240,
                s: 0,
                l: 0,
                a: 0.25,
            },
            onPrimary: {
                h: 203.27,
                s: 0.45,
                l: 0.21,
                a: 1,
            },
            onBackground: {
                h: 1.83,
                s: 0,
                l: 1,
                a: 1,
            },
            onSurface: {
                h: 1,
                s: 0,
                l: 1,
                a: 1,
            },
        },
    },
    SKIN: {
        name: "SKIN",
        colorScheme: {
            primary: {
                h: 358.7,
                s: 0.23,
                l: 0.4,
                a: 1,
            },
            background: {
                h: 4.29,
                s: 0.41,
                l: 0.66,
                a: 1,
            },
            surface: {
                h: 0,
                s: 0,
                l: 1,
                a: 0.2,
            },
            onPrimary: {
                h: 0,
                s: 1,
                l: 0.96,
                a: 1,
            },
            onBackground: {
                h: 1.83,
                s: 0,
                l: 0,
                a: 1,
            },
            onSurface: {
                h: 1,
                s: 0,
                l: 0,
                a: 1,
            },
        },
    },
    BROWN: {
        name: "BROWN",
        colorScheme: {
            primary: {
                h: 21.67,
                s: 0.49,
                l: 0.29,
                a: 1,
            },
            background: {
                h: 33.08,
                s: 0.49,
                l: 0.69,
                a: 1,
            },
            surface: {
                h: 0,
                s: 0,
                l: 1,
                a: 0.8,
            },
            onPrimary: {
                h: 33.4,
                s: 0.43,
                l: 0.56,
                a: 1,
            },
            onBackground: {
                h: 33.16,
                s: 0.73,
                l: 0.2,
                a: 1,
            },
            onSurface: {
                h: 33.16,
                s: 0.73,
                l: 0.2,
                a: 1,
            },
        },
    },
    MILITARY_GREEN: {
        name: "MILITARY_GREEN",
        colorScheme: {
            primary: {
                h: 75,
                s: 0.09,
                l: 0.44,
                a: 1,
            },
            background: {
                h: 0,
                s: 0,
                l: 1,
                a: 1,
            },
            surface: {
                h: 72,
                s: 0.26,
                l: 0.96,
                a: 1,
            },
            onPrimary: {
                h: 0,
                s: 0,
                l: 1,
                a: 1,
            },
            onBackground: {
                h: 0,
                s: 0,
                l: 0.07,
                a: 1,
            },
            onSurface: {
                h: 0,
                s: 0,
                l: 0.07,
                a: 1,
            },
        },
    },
    PINK: {
        name: "PINK",
        colorScheme: {
            primary: {
                h: 334,
                s: 0.85,
                l: 0.63,
                a: 1,
            },
            background: {
                h: 0,
                s: 0,
                l: 1,
                a: 1,
            },
            surface: {
                h: 0,
                s: 0,
                l: 0.96,
                a: 1,
            },
            onPrimary: {
                h: 0,
                s: 0,
                l: 1,
                a: 1,
            },
            onBackground: {
                h: 0,
                s: 0,
                l: 0.07,
                a: 1,
            },
            onSurface: {
                h: 0,
                s: 0,
                l: 0.07,
                a: 1,
            },
        },
    },
    MILITARY_GREEN_2: {
        name: "MILITARY_GREEN_2",
        colorScheme: {
            primary: {
                h: 75,
                s: 0.09,
                l: 0.44,
                a: 1,
            },
            background: {
                h: 72,
                s: 0.24,
                l: 0.96,
                a: 1,
            },
            surface: {
                h: 72,
                s: 0,
                l: 1,
                a: 1,
            },
            onPrimary: {
                h: 0,
                s: 0,
                l: 1,
                a: 1,
            },
            onBackground: {
                h: 0,
                s: 0,
                l: 0.07,
                a: 1,
            },
            onSurface: {
                h: 0,
                s: 0,
                l: 0.07,
                a: 1,
            },
        },
    },
};

export const selectableColorSchemeCollections = Object.keys(colorSchemeCollections).map(
    (key) => colorSchemeCollections[key]
);
