import styles from "./Video.module.scss";

interface IProps {
    videoIdentifier: string;
    autoStart: boolean;
}

export function Video(props: IProps) {
    let url = "https://www.youtube-nocookie.com/embed/" + props.videoIdentifier;

    let autoStartInAllow;
    if (props.autoStart) {
        autoStartInAllow = " autoplay;";
        url = url + "?autoplay=1";
    }

    return (
        <iframe
            src={url}
            title="YouTube video player"
            frameBorder="0"
            allow={
                "accelerometer;" +
                autoStartInAllow +
                " clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            }
            allowFullScreen
            className={styles.iframe}
        />
    );
}
