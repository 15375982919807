import React from "react";
import { LinkButton } from "../../components/button/LinkButton";
import { ISite } from "../../interfaces/Site";
import styles from "./EditHost.module.scss";

interface IProps {
    site?: ISite;
}

export function EditHost(props: IProps) {
    return (
        <div className={styles.wrapper}>
            {props.site?.host ? (
                <>
                    <div>
                        Nuvarande adress:{" "}
                        <a href={"https://" + props.site.host} className={styles.currentLink} target="_blank" rel="noreferrer">
                            {props.site.host}
                        </a>
                    </div>
                    <div>
                        <LinkButton
                            type="primary"
                            to={"/redigera/" + props.site._id + "/valj-adress"}
                            data-test-id="edit-url-button"
                        >
                            Ändra adress
                        </LinkButton>
                    </div>
                </>
            ) : (
                <>
                    {!props.site ? (
                        <div>Du måste registrera dig innan du kan välja adress</div>
                    ) : (
                        <>
                            <div>Du har inte valt adress än.</div>
                            <div>
                                <LinkButton
                                    type="primary"
                                    to={"/redigera/" + props.site._id + "/valj-adress"}
                                    data-test-id="edit-url-button"
                                    data-umami-event="select-host-button"
                                    data-umami-event-position="edit-host"
                                >
                                    Välj adress
                                </LinkButton>
                            </div>
                        </>
                    )}
                </>
            )}
        </div>
    );
}
