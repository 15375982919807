import React from "react";
import styles from "./Select.module.scss";

interface IProps {
    onChangeCallback: any;
    options: {
        title: string;
        value: string;
    }[];
    placeholder?: string;
    disabled?: boolean;
    label?: string;
    defaultValue?: any;
    id: string;
}

interface IState {
    value: string;
}

export class Select extends React.Component<IProps, IState> {
    state: IState = {
        value: this.props.defaultValue || "",
    };

    handleChange(event: React.ChangeEvent<HTMLSelectElement>) {
        this.setState({ value: event.target.value });
        this.props.onChangeCallback(event.target.value);
    }

    render() {
        return (
            <>
                {this.props.label ? (
                    <label className={styles.label} htmlFor={this.props.id}>
                        {this.props.label}
                    </label>
                ) : null}
                <select
                    className={styles.select}
                    id={this.props.id}
                    disabled={this.props.disabled === true ? true : undefined}
                    value={this.state.value}
                    onChange={this.handleChange.bind(this)}
                >
                    {!this.state.value && this.props.placeholder ? (
                        <option value="">{this.props.placeholder}</option>
                    ) : (
                        ""
                    )}
                    {this.props.options.map((option) => (
                        <option key={option.value} value={option.value}>
                            {option.title}
                        </option>
                    ))}
                </select>
            </>
        );
    }
}

export default Select;
