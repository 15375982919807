import React, { useEffect, useRef, useState } from "react";
import { ChromePicker, ColorResult } from "react-color";
import { useDebouncedCallback } from "use-debounce";
import styles from "./ColorPicker.module.scss";

interface IProps {
    onChangeCallback: any;
    value: string;
    name: string;
    id: string;
    label?: string;
    onlyHex?: boolean;
}

export function ColorPicker(props: IProps) {
    const pickerWrapper = useRef<HTMLDivElement>(null);

    const [pickerOpen, setPickerOpen] = useState(false);
    const [currentValue, setCurrentValue] = useState(props.value);

    const debounced = useDebouncedCallback(props.onChangeCallback, 800);

    const handleChange = (color: ColorResult) => {
        setCurrentValue(color.hex);
        debounced(color.hex, props.name);
    };

    const handleClick = (e: any) => {
        if (pickerWrapper.current && !pickerWrapper.current.contains(e.target)) {
            setPickerOpen(false);
        }
    };

    useEffect(() => {
        if (pickerOpen === true) {
            document.addEventListener("click", handleClick);
        } else {
            document.removeEventListener("click", handleClick);
        }

        return () => {
            document.removeEventListener("click", handleClick);
        };
    }, [pickerOpen]);

    return (
        <div className={styles.wrapper}>
            <div className={styles.labelWrapper}>
                <label className={styles.label} htmlFor={props.id}>
                    {props.label}
                </label>
            </div>
            <div className={styles.pickerWrapper}>
                <div
                    className={styles.preview}
                    onClick={() => setPickerOpen(true)}
                    style={{ backgroundColor: props.value }}
                ></div>
                <div className={styles.picker} ref={pickerWrapper}>
                    {pickerOpen && (
                        <ChromePicker onChange={handleChange} color={currentValue} disableAlpha={props.onlyHex} />
                    )}
                </div>
            </div>
        </div>
    );
}
