import React from "react";
import styles from "./EditButton.module.scss";

export enum PointerDirection {
    UP = "pointerUp",
    RIGHT = "pointerRight"
}

interface IProps {
    callback: any;
    pointer?: PointerDirection;
    children?: JSX.Element | JSX.Element[] | string;
}

export class EditButton extends React.Component<IProps> {
    render() {
        return (
            <button type="button" className={styles.button} onClick={this.props.callback}>
                {this.props.children}
                {this.props.pointer && <div className={styles[this.props.pointer]}></div>}
            </button>
        );
    }
}

export default EditButton;
