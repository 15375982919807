import React from "react";
import GeneratorButton from "../../components/GeneratorButton/GeneratorButton";
import { GeneratorButtonEdit } from "../../components/GeneratorButton/GeneratorButtonEdit";
import Content, { IContentProps } from "../Content";

interface IProps extends IContentProps {
    buttonCallback?: any;
    buttonType: "primary" | "secondary";
    buttonDisabled?: boolean;
    buttonSubmit?: boolean;
    buttonText: string;
    buttonSmall?: boolean;
    "data-test-id"?: string;
}

export class ContentGeneratorButton extends Content<IProps> {
    buttonText() {
        return this.propValue() || this.props.buttonText;
    }

    render() {
        return this.props.editMode === true ? (
            <GeneratorButtonEdit
                buttonText={this.buttonText()}
                handleChange={this.onContentChange}
                site={this.props.site}
                type={this.props.buttonType}
                small={this.props.buttonSmall}
            />
        ) : (
            <GeneratorButton
                submit={this.props.buttonSubmit}
                disabled={this.props.buttonDisabled}
                callback={this.props.buttonCallback}
                type={this.props.buttonType}
                buttonText={this.buttonText()}
                site={this.props.site}
                small={this.props.buttonSmall}
                data-test-id={this.props["data-test-id"]}
            />
        );
    }
}

export default ContentGeneratorButton;
