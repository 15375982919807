import WebFont from "webfontloader";
import { IFonts } from "../interfaces/Site";

export const getFontExternalLoadName = (font: string) => {
    // Don't try to load a font that is not on google fonts, for example Georgia or Arial. Then sometimes the entire load will crash.
    switch (font) {
        case "Parisienne":
            return "Parisienne";
        case "Vidaloka":
            return "Vidaloka";
        case "Inspiration":
            return "Inspiration";
        case "Playfair Display":
            return "Playfair Display:400,400i,700,700i";
        case "Pompiere":
            return "Pompiere";
        case "Fjalla One":
            return "Fjalla One";
        case "Amatic SC":
            return "Amatic SC";
        case "Open Sans":
            return "Open Sans";
        case "Zilla Slab":
            return "Zilla Slab";
        case "Kaisei Tokumin":
            return "Kaisei Tokumin";
        case "Raleway":
            return font;
        case "Lobster":
            return font;
        case "Alegreya Sans SC":
            return font;
        case "Josefin Slab":
            return font;
        case "Nixie One":
            return font;
        case "Cinzel Decorative":
            return font;
        case "Sacramento":
            return font;
        case "Gudea":
            return font;
        case "Muli":
            return font;
        case "Dancing Script":
            return font;
        case "Coming Soon":
            return font;
        case "Cutive Mono":
            return font;
        case "Great Vibes":
            return font;
        case "Life Savers":
            return font;
        case "Alex Brush":
            return font;
        case "Lato":
            return font;
        case "Crimson Pro":
            return font;
        case "Lora":
            return font;
        case "Anonymous Pro":
            return font;
        case "Lancelot":
            return font;
        case "Alegreya":
            return font;
        default:
            return "";
    }
};

export function loadSiteFonts(fonts: IFonts) {
    const fontsToLoad: string[] = [];
    if (getFontExternalLoadName(fonts.heading)) {
        fontsToLoad.push(getFontExternalLoadName(fonts.heading));
    }
    if (getFontExternalLoadName(fonts.text)) {
        fontsToLoad.push(getFontExternalLoadName(fonts.text));
    }

    if (fontsToLoad.length) {
        WebFont.load({
            google: {
                families: fontsToLoad,
            },
        });
    }
}
