import React from "react";
import styles from "./MovingDotsBackground.module.scss";
import { TSiteGraphicType } from "../../SiteGraphic";

export interface IProps {
    type: TSiteGraphicType;
}

export function MovingDotsBackground(props: IProps) {
    return (
        <div className={`${styles.outerWrapper} ${styles[props.type]}`}>
            <div className={styles.innerWrapper}>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
            </div>
        </div>
    );
}
