import React from "react";
import { Link } from "react-router-dom";
import "./Button.scss";

interface IProps {
    children: string;
    type: "primary" | "warning" | "secondary";
    fullWidth?: boolean;
    onClick?: () => void;
    "data-test-id"?: string;
    to: string;
    "data-umami-event"?: string;
    "data-umami-event-position"?: string;
}

export function LinkButton(props: IProps) {
    return (
        <Link
            type="button"
            className={`${props.type} ${props.fullWidth ? "full-width" : ""} component-button`}
            data-test-id={props["data-test-id"]}
            onClick={props.onClick}
            to={props.to}
            data-umami-event={props["data-umami-event"]} 
            data-umami-event-position={props["data-umami-event-position"]} 
        >
            {props.children}
        </Link>
    );
}
