import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { IError } from "../../../App";
import Loading from "../../components/Loading/Loading";
import ShowError from "../../components/ShowError/ShowError";
import { IPaymentReceipt } from "../../interfaces/PaymentReceipt";
import { getPaymentReceipt } from "../../services/PaymentService";
import { logError } from "../../utils/LogError";

interface IProps {
    paymentReference: string;
    siteId: string;
}

export const PaymentReceipt = (props: IProps) => {
    const [loading, setLoading] = useState(true);
    const [payment, setPayment] = useState<IPaymentReceipt | null>(null);
    const [error, setError] = useState<IError | null>(null);

    useEffect(() => {
        const getReceipt = async () => {
            setLoading(true);
            try {
                setPayment(await getPaymentReceipt(props.siteId, props.paymentReference));
            } catch (error) {
                setError({ text: "Misslyckades att hämta sidan", response: (error as any).response });
            }
            setLoading(false);
        };
        getReceipt();
    }, [props.paymentReference, props.siteId]);

    useEffect(() => {
        if (process.env.REACT_APP_BRAND === "party") {
            try {
                if (payment && parseInt(payment.amountWithoutVat) > 0) {
                    // @ts-ignore
                    ADDREVENUE.sendEvent("Purchase", {
                        value: payment?.amountWithoutVat.replace(".", ","),
                        currency: "SEK",
                        orderId: props.paymentReference,
                    });
                }
            } catch (e) {
                logError("PaymentReceipt", e, 2, `Couldnt send conversion to addrevenue ` + props.paymentReference);
            }
        }
    }, [payment, props.paymentReference]);

    if (loading) {
        return <Loading />;
    }

    if (error) {
        return <ShowError error={error} />;
    }

    if (!payment) {
        throw Error("Payment not loaded but not error");
    }

    return (
        <div>
            {payment.product} - {payment.amountWithVat} kr
            <br />
            <br />
            Totalt belopp: {payment.amountWithVat} kr
            <br />
            Varav moms: {payment.amountVat} kr
            <br />
            <br />
            Betalningsmetod: {payment.paymentMethod}
            <br />
            Betalningsdatum: {dayjs(payment.date).format("YYYY-MM-DD HH:mm")}
            <br />
            Referens: {payment.reference}
            <br />
            Mottagare: Inity Development AB (org. nr. 559044-3163)
            <br />
            <br />
            Vi har skickat kvittot till din e-postadress.
        </div>
    );
};
