import Axios from "axios";
import React from "react";
import { EditSelectVertical } from "../../components/EditSelectVertical/EditSelectVertical";
import FormWrapper from "../../components/form/FormWrapper/FormWrapper";
import TextField from "../../components/form/TextField/TextField";
import GeneratorModal from "../../components/GeneratorModal/GeneratorModal";
import ContentGeneratorButton from "../../content/GeneratorButton/GeneratorButton";
import ContentH3 from "../../content/h3/H3";
import ContentMultipleTextFields from "../../content/MultipleTextFields/MultipleTextFields";
import textFieldStyles from "../../content/MultipleTextFields/TextFieldItem/TextFieldItem.module.scss";
import ContentRTE from "../../content/RTE/RTE";
import { ISpeech } from "../../interfaces/Speech";
import SetReceiverMail from "../../modules/SetReceiverMail/SetReceiverMail";
import ModuleHeading from "../components/ModuleHeading/ModuleHeading";
import GeneratorModule from "../GeneratorModule";
import styles from "./Speech.module.scss";

interface IState {
    speech: ISpeech;
    postKey?: string;
    editView: View;
    view: View;
}

enum View {
    initate,
    form,
    confirmation,
    spam,
}

export class Speech extends GeneratorModule {
    state: IState = {
        speech: {},
        editView: View.form,
        view: View.initate,
    };

    showForm = (value: string) => {
        Axios.get(process.env.REACT_APP_API_URL + "/api/rsvps/post-key")
            .then((res) => {
                this.setState({
                    postKey: res.data.key,
                    view: View.form,
                });
            })
            .catch((err) => {
                this.props.showError("Misslyckades att ladda tal-formuläret", err.response);
            });
    };

    submit = () => {
        const speechPost = {
            speech: this.state.speech,
            postKey: this.state.postKey,
        };
        Axios.post(process.env.REACT_APP_API_URL + "/api/speeches/" + this.props.site._id + "/", speechPost)
            .then((res) => {
                if (res.data.invertedSuccess && res.data.invertedSuccess === true) {
                    this.setState({
                        view: View.spam,
                    });
                } else {
                    this.setState({
                        view: View.confirmation,
                    });
                }
            })
            .catch((err) => {
                this.props.showError(
                    "Tyvärr misslyckades vi att skicka ditt meddelande om tal. Något gick fel. Försök gärna igen.",
                    err.response
                );
            });
    };

    updateField = (value: string, name: string) => {
        const speech = this.state.speech;

        (speech as any)[name] = value;

        this.setState({
            speech,
        });
    };

    updateCustomField = (value: string, name: string, customValueType: string, label: string) => {
        const speech = this.state.speech;

        if (!speech.customValues) {
            speech.customValues = [];
        }

        let index = speech.customValues.findIndex((customValue) => {
            return customValue.id === name;
        });

        if (index < 0) {
            speech.customValues.push({
                id: name,
                value,
                type: customValueType,
                label,
            });
        } else {
            speech.customValues[index].value = value;
        }

        this.setState({
            speech,
        });
    };

    editViews = [
        {
            title: "Startknapp",
            value: View.initate,
        },
        {
            title: "Formulär",
            value: View.form,
        },
        {
            title: "Bekräftelse",
            value: View.confirmation,
        },
    ];

    changeEditView = (view: View) => {
        this.setState({
            editView: view,
        });
    };

    resetView = () => {
        this.setState({
            view: View.initate,
            editView: View.form,
        });
    };

    viewIs = (view: View) => {
        if (this.props.editMode === true) {
            return this.state.editView === view;
        } else {
            return this.state.view === view;
        }
    };

    render() {
        return (
            <section className={styles[this.props.site.theme]}>
                <ModuleHeading
                    site={this.props.site}
                    propertyParent={this.props.moduleData}
                    showError={this.props.showError}
                    updateProperty={this.props.updateProperty}
                    editMode={this.props.editMode}
                ></ModuleHeading>
                <div className={styles.textAndFormWrapper}>
                    <div className={styles.rteWrapper}>
                        <ContentRTE
                            site={this.props.site}
                            propertyParent={this.props.moduleData}
                            showError={this.props.showError}
                            property="text"
                            updateProperty={this.props.updateProperty}
                            editMode={this.props.editMode}
                        ></ContentRTE>
                    </div>
                    <div className={styles.selectViewWrapper}>
                        {this.props.editMode === true ? (
                            <EditSelectVertical
                                value={this.state.editView}
                                onChangeCallback={this.changeEditView}
                                options={this.editViews}
                            />
                        ) : null}
                    </div>
                    {this.viewIs(View.form) ? (
                        <FormWrapper submit={this.submit.bind(this)} disabled={this.props.editMode === true}>
                            <div className={styles.textFieldGroup}>
                                <div className={styles.textFieldWrapper}>
                                    <TextField
                                        className={textFieldStyles[this.props.site.theme]}
                                        required={true}
                                        disabled={this.props.editMode}
                                        label="Förnamn"
                                        name="firstName"
                                        id={"Speech-FirstName-"}
                                        onChangeCallback={this.updateField}
                                    ></TextField>
                                </div>
                                <div className={styles.textFieldWrapper}>
                                    <TextField
                                        className={textFieldStyles[this.props.site.theme]}
                                        required={true}
                                        disabled={this.props.editMode}
                                        label="Efternamn"
                                        name="lastName"
                                        id={"Speech-LastName-"}
                                        onChangeCallback={this.updateField}
                                    ></TextField>
                                </div>
                            </div>
                            <ContentMultipleTextFields
                                showError={this.props.showError}
                                propertyParent={this.props.moduleData}
                                editMode={this.props.editMode}
                                property="customTextFields"
                                site={this.props.site}
                                updateProperty={this.props.updateProperty}
                                updateCustomField={this.updateCustomField}
                                id={"Speech-MultipleTextFields"}
                            />
                            <div className={styles.buttonAndReceiverWrapper}>
                                <div className={styles.buttonWrapper}>
                                    <ContentGeneratorButton
                                        buttonDisabled={this.props.editAvailable}
                                        buttonText="Skicka"
                                        buttonType="primary"
                                        buttonSubmit={true}
                                        site={this.props.site}
                                        propertyParent={this.props.moduleData}
                                        property="sendButton"
                                        showError={this.props.showError}
                                        updateProperty={this.props.updateProperty}
                                        editMode={this.props.editMode}
                                    ></ContentGeneratorButton>
                                </div>
                                {this.props.editMode === true ? (
                                    <div className={styles.receiverWrapper}>
                                        <SetReceiverMail
                                            site={this.props.site}
                                            updateReceiverMail={this.props.updateReceiverMail}
                                            receiverCategory="speech"
                                        />
                                    </div>
                                ) : (
                                    ""
                                )}
                            </div>
                        </FormWrapper>
                    ) : null}
                    {this.viewIs(View.initate) ? (
                        <div className={styles.showFormButtonWrapper}>
                            <ContentGeneratorButton
                                buttonText="Anmälan av tal"
                                buttonType="primary"
                                buttonCallback={this.showForm}
                                site={this.props.site}
                                propertyParent={this.props.moduleData}
                                property="showFormButton"
                                showError={this.props.showError}
                                updateProperty={this.props.updateProperty}
                                editMode={this.props.editMode}
                            ></ContentGeneratorButton>
                        </div>
                    ) : null}
                    {this.viewIs(View.confirmation) ? (
                        <GeneratorModal
                            site={this.props.site}
                            open={true}
                            close={() => {
                                this.resetView();
                            }}
                        >
                            <div className={styles.confirmation}>
                                <ContentH3
                                    showError={this.props.showError}
                                    updateProperty={this.props.updateProperty}
                                    site={this.props.site}
                                    editMode={this.props.editMode}
                                    property="confirmationTitle"
                                    propertyParent={this.props.moduleData}
                                    fallbackContent="Tack!"
                                ></ContentH3>
                                <div className={styles.confirmationText}>
                                    <ContentRTE
                                        showError={this.props.showError}
                                        updateProperty={this.props.updateProperty}
                                        site={this.props.site}
                                        editMode={this.props.editMode}
                                        property="confirmationText"
                                        propertyParent={this.props.moduleData}
                                        fallbackContent="<p>Tack för att du anmält dig att tala</p>"
                                    ></ContentRTE>
                                </div>
                            </div>
                        </GeneratorModal>
                    ) : null}
                    {this.viewIs(View.spam) ? (
                        <GeneratorModal
                            site={this.props.site}
                            open={true}
                            close={() => {
                                this.resetView();
                            }}
                        >
                            <div className={styles.confirmation}>
                                <h3 className={styles.confirmationTitleError}>Tyvärr gick det fel!</h3>
                                <div className={styles.confirmationText}>
                                    Tyvärr lyckades vi inte skicka din anmälan om tal då vi identifierade dig som en
                                    robot. Vänligen fyll i en gång till och vänta några sekunder innan du trycker på
                                    skicka. Vi ber om ursäkt för besväret!
                                </div>
                            </div>
                        </GeneratorModal>
                    ) : null}
                </div>
            </section>
        );
    }
}

export default Speech;
