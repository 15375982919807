import { useSlate } from "slate-react";
import { Editor, Element as SlateElement } from "slate";
import { isLinkActive, unwrapLink, wrapLink } from "../../utils/link";
import { CustomEditor } from "../../types/SlateTypes";
import { IconBold } from "../../../../../../icons/bold";
import { IconItalic } from "../../../../../../icons/italic";
import { IconUnderlined } from "../../../../../../icons/underlined";
import styles from "./Toolbar.module.scss";
import { isHeadingThreeActive, unwrapHeadingThree, wrapHeadingThree } from "../../utils/headingThree";
import { ToolbarButton } from "./components/ToolbarButton/ToolbarButton";
import { IconRedo } from "../../../../../../icons/redo";
import { IconUndo } from "../../../../../../icons/undo";

interface IProps {
    editor: CustomEditor;
    editorHasFocus: boolean;
}

type Format = "bold" | "italic" | "underlined";

export function Toolbar(props: IProps) {
    const editor = useSlate();

    const handleInsertHeading = () => {
        if (isHeadingThreeActive(editor)) {
            unwrapHeadingThree(editor);
        } else {
            wrapHeadingThree(props.editor, "");
        }
    };

    const handleInsertLink = () => {
        if (isLinkActive(editor)) {
            unwrapLink(editor);
        } else {
            const url = prompt("Url till länk:", "https://");
            if (!url) {
                return;
            }
            wrapLink(props.editor, url);
        }
    };

    return (
        <div className={`${styles.wrapper} ${props.editorHasFocus ? styles.show : ""}`}>
            <MarkButton format="bold">
                <IconBold />
            </MarkButton>
            <MarkButton format="italic">
                <IconItalic />
            </MarkButton>
            <MarkButton format="underlined">
                <div className={styles.iconUnderlined}>
                    <IconUnderlined />
                </div>
            </MarkButton>
            <div className={styles.spacer}></div>
            <BlockButton type="link" handleClick={handleInsertLink}>
                <>Länk</>
            </BlockButton>
            <BlockButton type="heading-three" handleClick={handleInsertHeading}>
                <>Rubrik</>
            </BlockButton>
            <div className={styles.spacer}></div>
            <ToolbarButton handleClick={editor.undo} disabled={!editor.history.undos.length}>
                <IconUndo />
            </ToolbarButton>
            <ToolbarButton handleClick={editor.redo} disabled={!editor.history.redos.length}>
                <IconRedo />
            </ToolbarButton>
        </div>
    );
}

const BlockButton = ({
    type,
    children,
    handleClick,
}: {
    type: "link" | "heading-three";
    children: JSX.Element;
    handleClick: () => void;
}) => {
    const editor = useSlate();
    return (
        <ToolbarButton handleClick={handleClick} active={isBlockActive(editor, type)}>
            {children}
        </ToolbarButton>
    );
};

const MarkButton = ({ format, children }: { format: Format; children: JSX.Element }) => {
    const editor = useSlate();
    return (
        <ToolbarButton handleClick={() => toggleMark(editor, format)} active={isMarkActive(editor, format)}>
            {children}
        </ToolbarButton>
    );
};

const toggleMark = (editor: CustomEditor, format: Format) => {
    const isActive = isMarkActive(editor, format);

    if (isActive) {
        Editor.removeMark(editor, format);
    } else {
        Editor.addMark(editor, format, true);
    }
};

const isMarkActive = (editor: CustomEditor, format: Format) => {
    const marks = Editor.marks(editor);
    return marks ? format in marks === true : false;
};

const isBlockActive = (editor: CustomEditor, type: "link" | "heading-three") => {
    const { selection } = editor;
    if (!selection) return false;

    const [match] = Array.from(
        Editor.nodes(editor, {
            at: Editor.unhangRange(editor, selection),
            match: (n) => !Editor.isEditor(n) && SlateElement.isElement(n) && n.type === type,
        })
    );

    return !!match;
};
