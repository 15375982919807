import React, { Component } from "react";
import { Route, Switch } from "react-router-dom";
import { View404 } from "../views/View404/View404";
import styles from "./Landing.module.scss";
import Footer from "./modules/footer/Footer";
import Header from "./modules/header/Header";
import "./style/landing.scss";
import { ViewLogin } from "./views/ViewLogin/ViewLogin";
import { ViewResetPassword } from "./views/ViewResetPassword/ViewResetPassword";

interface IProps {
    showError: any;
}

export default class Landing extends Component<IProps> {
    render() {
        return (
            <div className={styles.wrapper}>
                <Header></Header>
                <Switch>
                    <Route
                        path="/logga-in"
                        exact
                        render={(props) => <ViewLogin {...props} showError={this.props.showError} />}
                    />
                    <Route
                        path="/aterstall-losenord"
                        exact
                        render={(props) => <ViewResetPassword {...props} showError={this.props.showError} />}
                    />
                    <Route
                        path="/aterstall-losenord/:token"
                        exact
                        render={(props) => <ViewResetPassword {...props} showError={this.props.showError} />}
                    />
                    <Route component={View404} />
                </Switch>
                <Footer />
            </div>
        );
    }
}
