import React from "react";
import EditButton from "../../components/EditButton/EditButton";
import TextField from "../../components/form/TextField/TextField";
import { IconNewContentModule } from "../../icons/newContentModule";
import { randomId } from "../../utils/Random";
import Content, { IContentProps } from "../Content";
import styles from "./MultipleTextFields.module.scss";
import TextFieldItem from "./TextFieldItem/TextFieldItem";
import textFieldStyles from "./TextFieldItem/TextFieldItem.module.scss";

export interface ITextFieldItem {
    id: string;
    label: string;
    mandatory: boolean;
}

interface IProps extends IContentProps {
    updateCustomField: any;
    id: string;
}

export class ContentMultipleTextFields extends Content<IProps> {
    contentType: string = "MultipleTextFields";

    randomId = Math.random().toString(36).substring(7);

    addItem = () => {
        let newPropValue = this.propValue() && this.propValue().length ? this.propValue() : [];

        newPropValue.push({
            id: "textField" + randomId(),
            label: "Text som beskriver fältet",
            mandatory: false,
        });

        this.updateContent(newPropValue);
    };

    updateMandatory = (textFieldItem: TextFieldItem, mandatory: boolean) => {
        const index = this.propValue().indexOf(textFieldItem);
        const collection = this.propValue();
        collection[index].mandatory = mandatory;
        this.updateContent(collection);
    };

    removeItem = (textFieldItem: ITextFieldItem) => {
        const index = this.propValue().indexOf(textFieldItem);
        const collection = this.propValue();
        collection.splice(index, 1);
        this.updateContent(collection);
    };

    updateTextFieldLabel = (textFieldItem: TextFieldItem, newLabel: string) => {
        const index = this.propValue().indexOf(textFieldItem);
        const collection = this.propValue();
        collection[index].label = newLabel;
        this.updateContent(collection);
    };

    onChangeCallback = (value: string, name: string, label: string) => {
        this.props.updateCustomField(value, name, "TextField", label);
    };

    render() {
        return this.props.editMode === true ? (
            <div className={styles[this.props.site.theme]}>
                {this.propValue() && this.propValue().length
                    ? (this.propValue() as ITextFieldItem[]).map((textFieldItem) => (
                          <div className={styles.textFieldWrapper} key={textFieldItem.id}>
                              <TextFieldItem
                                  textFieldItem={textFieldItem}
                                  updateTextFieldLabel={this.updateTextFieldLabel.bind(this)}
                                  removeItem={this.removeItem.bind(this)}
                                  updateMandatory={this.updateMandatory.bind(this)}
                                  className={textFieldStyles[this.props.site.theme]}
                                  id={this.props.id + "-" + textFieldItem.id}
                              />
                          </div>
                      ))
                    : ""}
                <div className={styles.textFieldWrapper}>
                    <div className={styles.addNewTextFieldInactive}>
                        <TextField
                            disabled={true}
                            label="Nytt fält"
                            name="newField"
                            id={this.props.id + "-newField-" + this.randomId}
                            onChangeCallback={() => {}}
                            className={textFieldStyles[this.props.site.theme]}
                        ></TextField>
                    </div>
                    <div className={styles.addNewTextFieldButton}>
                        <EditButton callback={this.addItem}>
                            <>
                                <IconNewContentModule></IconNewContentModule>
                                Lägg till fält
                            </>
                        </EditButton>
                    </div>
                </div>
            </div>
        ) : (
            <div>
                <div className={styles[this.props.site.theme]}>
                    {this.propValue() && this.propValue().length
                        ? (this.propValue() as ITextFieldItem[]).map((textFieldItem) => (
                              <div className={styles.textFieldWrapper} key={textFieldItem.id}>
                                  <TextField
                                      label={textFieldItem.label}
                                      name={textFieldItem.id}
                                      id={this.props.id + "-" + textFieldItem.id}
                                      onChangeCallback={(value: string, name: string) => {
                                          this.onChangeCallback(value, name, textFieldItem.label);
                                      }}
                                      required={textFieldItem.mandatory}
                                      className={textFieldStyles[this.props.site.theme]}
                                  ></TextField>
                              </div>
                          ))
                        : ""}
                </div>
            </div>
        );
    }
}

export default ContentMultipleTextFields;
