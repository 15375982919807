// This has a sibling in back end (products.ts)

export const products = {
    subdomain: {
        name: "Publicering med subdomän",
        price: 49900,
    },
    domain_name: {
        name: "Publicering och domännamn",
        price: 99900,
    },
    domain_name_other_registrar: {
        name: "Publicering med eget domännamn",
        price: 99900,
    },
    domain_name_upgrade_from_subdomain: {
        name: "Nytt domännamn",
        price: 50000,
    },
    domain_name_other_registrar_upgrade_from_subdomain: {
        name: "Eget domännamn",
        price: 50000,
    },
};

export type Product = keyof typeof products;

export const stringIsProduct = (product: Product) => {
    return !!products[product];
};

export const getProductName = (product: Product): string => {
    return products[product].name;
};
export const getProductPriceAsKronor = (product: Product): number => {
    return products[product].price / 100;
};
