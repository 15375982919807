import Axios from "axios";
import React, { useState } from "react";
import { IError } from "../../../../../../App";
import FormWrapper from "../../../../../components/form/FormWrapper/FormWrapper";
import TextField from "../../../../../components/form/TextField/TextField";
import GeneratorButton from "../../../../../components/GeneratorButton/GeneratorButton";
import GeneratorModal from "../../../../../components/GeneratorModal/GeneratorModal";
import Loading from "../../../../../components/Loading/Loading";
import ShowError from "../../../../../components/ShowError/ShowError";
import { IGiftRegistryReservations, ISite } from "../../../../../interfaces/Site";
import { IGiftRegistryItem } from "../../../GiftRegistry";
import { giftRegistryReservedQuantity } from "../../utils/GiftRegistryReservedQuantity";
import { QuantityAndReservedQuantity } from "../QuantityAndReservedQuantity/QuantityAndReservedQuantity";
import styles from "./GiftRegistryCancelReservation.module.scss";

interface IProps {
    close: () => void;
    giftRegistryItem: IGiftRegistryItem;
    site: ISite;
    moduleId: string;
    updateGiftRegistryReservations: (giftRegistryReservations: IGiftRegistryReservations[]) => void;
}

export function GiftRegistryCancelReservation(props: IProps) {
    const [quantity, setQuantity] = useState<number | "">(1);
    const [password, setPassword] = useState<string | "">();
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState<IError | null>(null);

    const handleSubmit = () => {
        setIsLoading(true);

        if (hideQuantityTextField()) {
            setQuantity(1);
        }

        Axios.delete(
            `${process.env.REACT_APP_API_URL}/api/gift-registry/${props.site._id}/${props.moduleId}/${props.giftRegistryItem.id}/`,
            {
                data: {
                    quantity,
                    password,
                },
            }
        )
            .then((response) => {
                props.updateGiftRegistryReservations(response.data.giftRegistryReservations);
                setIsLoading(false);
                props.close();
            })
            .catch((err) => {
                setError({ text: "Misslyckades att ta bort reservation", response: err.response });
                setIsLoading(false);
            });
    };

    const handleQuantityChange = (value: string): void => {
        value ? setQuantity(parseInt(value)) : setQuantity("");
    };

    const handlePasswordChange = (value: string): void => setPassword(value);

    const maxQuantityCancelable = () =>
        giftRegistryReservedQuantity(props.giftRegistryItem, props.site.giftRegistryReservations);

    const hideQuantityTextField = () => maxQuantityCancelable() === 1 && props.giftRegistryItem.quantity === 1;

    return (
        <GeneratorModal site={props.site} open={true} close={props.close}>
            {isLoading && <Loading />}
            <ShowError error={error} />
            <div className={styles.wrapper}>
                <h3>{props.giftRegistryItem.title}</h3>
                <QuantityAndReservedQuantity
                    giftRegistryReservations={props.site.giftRegistryReservations}
                    className={styles.quantity}
                    giftRegistryItem={props.giftRegistryItem}
                />
                <FormWrapper
                    submit={handleSubmit}
                    disabled={false} // Edit mode later
                >
                    <div className={styles.textFieldsWrapper}>
                        <div className={styles.textFieldPassword}>
                            <TextField
                                label="Lösenord"
                                name="password"
                                placeholder=""
                                id="reserveGiftPassword"
                                onChangeCallback={handlePasswordChange}
                                value={password}
                                required={true}
                            />
                        </div>
                        {!hideQuantityTextField() && (
                            <div className={styles.textFieldQuantity}>
                                <TextField
                                    label="Antal du hade reserverat"
                                    number={true}
                                    name="quantity"
                                    id="reserveGiftQuantity"
                                    onChangeCallback={handleQuantityChange}
                                    value={quantity + ""} // + "" converts to string
                                    min={1}
                                    required={true}
                                    max={maxQuantityCancelable()}
                                />
                            </div>
                        )}
                    </div>
                    <GeneratorButton submit={true} buttonText="Ångra reservation" site={props.site} type="primary" />
                </FormWrapper>
            </div>
        </GeneratorModal>
    );
}
