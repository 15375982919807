import {
    IPrintableCard,
    IPrintableContentImage,
    IPrintableContentQr,
    IPrintableContentText,
    TPrintableContent,
} from "../../interfaces/IPrintableCard";
import { Updater } from "use-immer";
import { ContentTypeQR } from "./components/ContentTypeQR/ContentTypeQR";
import { ContentTypeImage } from "./components/ContentTypeImage/ContentTypeImage";
import { ContentTypeText } from "./components/ContentTypeText/ContentTypeText";

interface IProps {
    card: IPrintableCard;
    content: TPrintableContent;
    updateCard?: Updater<IPrintableCard>;
    editMode: boolean;
    aspectRatio?: number;
    thumbnail?: boolean;
}

function isContentTypeText(content: TPrintableContent | undefined): content is IPrintableContentText {
    return (content as IPrintableContentText)?.contentType === "text";
}

function isContentTypeQr(content: TPrintableContent | undefined): content is IPrintableContentQr {
    return (content as IPrintableContentQr)?.contentType === "qr";
}

function isContentTypeImage(content: TPrintableContent | undefined): content is IPrintableContentImage {
    return (content as IPrintableContentImage)?.contentType === "image";
}

// Refactor this later to have one component per type so typing gets easier

function changeContentPropertyInCardText(
    draft: IPrintableCard,
    contentId: string,
    property: keyof IPrintableContentText["properties"],
    propertyContent: string
) {
    const draftContent = draft.content.find((x) => x.id === contentId);
    if (!draftContent) {
        throw Error("Content with id " + contentId + " does not exist");
    }
    if (!isContentTypeText(draftContent)) {
        throw Error("Content is not text");
    }
    draftContent.properties[property] = propertyContent;
}

function changeContentPropertiesInCardImage(
    draft: IPrintableCard,
    contentId: string,
    properties: IPrintableContentImage["properties"]
) {
    const draftContent = draft.content.find((x) => x.id === contentId);
    if (!draftContent) {
        throw Error("Content with id " + contentId + " does not exist");
    }
    if (!isContentTypeImage(draftContent)) {
        throw Error("Content is not image");
    }
    draftContent.properties = properties;
}

function changeContentPropertyInCardQr(
    draft: IPrintableCard,
    contentId: string,
    property: keyof IPrintableContentQr["properties"],
    propertyContent: string
) {
    const draftContent = draft.content.find((x) => x.id === contentId);
    if (!draftContent) {
        throw Error("Content with id " + contentId + " does not exist");
    }
    if (!isContentTypeQr(draftContent)) {
        throw Error("Content is not qr");
    }
    draftContent.properties[property] = propertyContent;
}

export const PrintableContent = (props: IProps) => {
    const updateText = (text: string) => {
        if (!props.updateCard) {
            throw new Error("No update card function in edit mode");
        }
        props.updateCard((draft) => changeContentPropertyInCardText(draft, props.content.id, "text", text));
    };

    const updateQRUrl = (url: string) => {
        if (!props.updateCard) {
            throw new Error("No update card function in edit mode");
        }
        props.updateCard((draft) => changeContentPropertyInCardQr(draft, props.content.id, "url", url));
    };

    const updateImage = (imageProperties: IPrintableContentImage["properties"]) => {
        if (!props.updateCard) {
            throw new Error("No update card function in edit mode");
        }
        props.updateCard((draft) => changeContentPropertiesInCardImage(draft, props.content.id, imageProperties));
    };

    if (isContentTypeText(props.content)) {
        return (
            <ContentTypeText
                card={props.card}
                updateText={updateText}
                editMode={props.editMode}
                content={props.content}
            />
        );
    }

    if (props.content?.contentType === "image") {
        return (
            <ContentTypeImage
                card={props.card}
                content={props.content}
                editMode={props.editMode}
                updateImage={updateImage}
                aspectRatio={props.aspectRatio!}
                thumbnail={props.thumbnail}
            />
        );
    }

    if (props.content?.contentType === "qr") {
        return (
            <ContentTypeQR
                card={props.card}
                updateQRUrl={updateQRUrl}
                content={props.content}
                editMode={props.editMode}
            />
        );
    }

    return <></>;
};
