import React, { useCallback } from "react";
import { useDropzone } from "react-dropzone";
import { IconUpload } from "../../icons/upload";
import styles from "./FileUpload.module.scss";

interface IProps {
    onFileUpload: any;
    buttonText: string;
}

export function FileUpload(props: IProps) {
    const onDrop = useCallback((acceptedFiles: any) => {
        props.onFileUpload(acceptedFiles[0]);
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

    return (
        <div {...getRootProps()} className={`${styles.wrapper} ${isDragActive ? styles.isDragActive : ""}`}>
            <div>
                <IconUpload />
            </div>
            <div>{isDragActive ? <p>Släpp bilden här</p> : <p>{props.buttonText}</p>}</div>
            <input {...getInputProps()} />
        </div>
    );
}
