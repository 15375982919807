import React from "react";
import { NavHashLink } from "react-router-hash-link";
import { IModule } from "../../../interfaces/Module";
import { ISite } from "../../../interfaces/Site";
import styles from "./MenuItem.module.scss";

interface IProps {
    module: IModule;
    closeMenu: any;
    site: ISite;
    measurementOnly?: boolean;
}

export class ContentMenuItem extends React.Component<IProps> {
    render() {

        if (this.props.measurementOnly) {
            return (
                <span
                    className={styles[this.props.site.theme]}
                >
                    {this.props.module.menuTitle}
                </span>
            );
        }
        return (
            <NavHashLink
                to={"#" + this.props.module.slug}
                scroll={(el) => el.scrollIntoView({ behavior: "smooth", block: "start" })}
                onClick={() => {
                    this.props.closeMenu();
                }}
                className={styles[this.props.site.theme]}
            >
                {this.props.module.menuTitle}
            </NavHashLink>
        );
    }
}

export default ContentMenuItem;
