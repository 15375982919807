import React from "react";
import ActionCurtain from "../../components/actionCurtain/ActionCurtain";
import { getBrandedContactMail } from "../../utils/getBrand";
import styles from "./Help.module.scss";

interface IProps {
    close: any;
}

export class Help extends React.Component<IProps> {
    render() {
        return (
            <ActionCurtain title="Hjälp" close={this.props.close}>
                <>
                    <div className={styles.wrapper}>
                        <p>
                            Principen för att redigera är enkel. Du trycker på det du vill ändra, oavsett om
                            det är en bild, en text eller en karta. Sidan är uppbyggd i moduler, överst i mitten av varje
                            modul finner du en svart knapp där det står Modul där du kan flytta moduler upp och ner och
                            lägga till nya.
                        </p>
                        <p>
                            Har du frågor eller önskemål? Skriva till oss på{" "}
                            <a className={styles.mailLink} href={"mailto:" + getBrandedContactMail()}>
                                {getBrandedContactMail()}
                            </a>{" "}
                            så hjälper vi dig. Vi svarar vanligtvis inom en arbetsdag.
                        </p>
                    </div>
                </>
            </ActionCurtain>
        );
    }
}
