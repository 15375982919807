import { useEffect, useState } from "react";
import { IError } from "../../../../../../../App";
import Loading from "../../../../../../components/Loading/Loading";
import ShowError from "../../../../../../components/ShowError/ShowError";
import { ISite } from "../../../../../../interfaces/Site";
import { PrintEditor } from "./components/PrintEditor/PrintEditor";
import { IPrintableCard } from "./components/PrintEditor/interfaces/IPrintableCard";
import { useImmer } from "use-immer";
import { createDemoCard } from "./utils/createDemoCard";
import { getPrint, postPrint, putPrint } from "../../../../../../services/PrintService";
import { trackEvent } from "../../../../../../../utils/UmamiHelpers";
import QuickStartSave from "../../../../../../modules/QuickStartSave/QuickStartSave";
import { postCreateSite } from "../../../../../../services/SiteService";
import { recommendedPrintLayout } from "./utils/recommendedPrintLayout";

interface IProps {
    quickStart: boolean;
    drawerSlotPortalNode: JSX.Element;
    site: ISite;
    printId: string | "new";
    routerHistory: any;
}

export function EditPrint(props: IProps) {
    const [editMode, setEditMode] = useState(true);

    const [loading, setLoading] = useState(false);
    const [loadingOverlay, setLoadingOverlay] = useState(false);
    const [error, setError] = useState<IError | null>(null);
    const [showQuickStartSave, setShowQuickStartSave] = useState(false);

    const [savedCard, setSavedCard] = useState<IPrintableCard>(createDemoCard(recommendedPrintLayout(props.site.theme, props.site.siteGraphic), props.site));

    const [card, setCard] = useImmer<IPrintableCard>(savedCard);

    const isDirty = card !== savedCard;

    const updateCard = setCard;

    const createPrint = async () => {
        setLoadingOverlay(true);
        trackEvent("create-print");
        try {
            const newPrintId = await postPrint({ ...card, site: props.site._id });
            props.routerHistory.push("/redigera/" + props.site._id + "/inbjudningar/" + newPrintId);
        } catch (error) {
            setError({ text: "Misslyckades att spara tryck", response: (error as any).response });
        }
        setLoadingOverlay(false);
    };

    const updatePrint = async () => {
        setLoadingOverlay(true);
        try {
            await putPrint(props.printId, card);
            setSavedCard(card);
        } catch (error) {
            setError({ text: "Misslyckades att uppdatera tryck", response: (error as any).response });
        }
        setLoadingOverlay(false);
    };

    const handleSave = () => {
        if (props.printId === "new") {
            if (props.quickStart === true) {
                setShowQuickStartSave(true);
            } else {
                createPrint();
            }
        } else {
            updatePrint();
        }
    };

    const createSiteAndSavePrint = async () => {
        setLoadingOverlay(true);
        try {
            const newSiteId = await postCreateSite(props.site);

            trackEvent("account-created", {"position": "print"});

            const newPrintId = await postPrint({ ...card, site: newSiteId });

            props.routerHistory.push("/redigera/" + newSiteId + "/inbjudningar/" + newPrintId);
            setShowQuickStartSave(false);
        } catch (error) {
            setError({ text: "Misslyckades att spara sidan", response: (error as any).response });
        }
        setLoadingOverlay(false);
    };

    useEffect(() => {
        const getCard = async () => {
            setLoading(true);
            try {
                const print = await getPrint(props.printId);
                setCard(print);
                setSavedCard(print);
            } catch (error) {
                setError({ text: "Misslyckades att hämta tryck", response: (error as any).response });
            }
            setLoading(false);
        };
        if (props.printId !== "new") {
            getCard();
        }
    }, [props.printId, setCard]);

    if (loading) {
        return <Loading fixed={true} inverted={true} />;
    }

    if (error) {
        return <ShowError error={error} />;
    }

    return (
        <>
            {loadingOverlay ? <Loading fixed={true} /> : null}
            {showQuickStartSave === true ? (
                <QuickStartSave
                    createSite={createSiteAndSavePrint}
                    close={() => setShowQuickStartSave(false)}
                    site={props.site}
                />
            ) : null}
            <PrintEditor
                toggleEditMode={() => setEditMode(!editMode)}
                editMode={editMode}
                card={card}
                site={props.site}
                updateCard={updateCard}
                handleSave={handleSave}
                isDirty={isDirty}
                isNew={props.printId === "new"}
                drawerSlotPortalNode={props.drawerSlotPortalNode}
            />
        </>
    );
}
