import React from "react";
import ExpandableArea from "../../components/ExpandableArea/ExpandableArea";
import { IColorScheme } from "../../interfaces/Site";
import AdvancedColorItem from "./AdvancedColorItem/AdvancedColorItem";
import ColorItem from "./ColorItem/ColorItem";
import styles from "./EditColorV2.module.scss";
import { selectableColorSchemeCollections } from "./utils/ColorSchemeCollections";

interface IProps {
    currentColors: IColorScheme;
    setColors: (colors: IColorScheme) => void;
}

export enum ColorV2Type {
    primary = "primary",
    background = "background",
    surface = "surface",
    onPrimary = "onPrimary",
    onBackground = "onBackground",
    onSurface = "onSurface",
}

export class EditColorV2 extends React.Component<IProps> {

    showColors = () => {
        console.info(this.props.currentColors);
    };

    render() {
        return (
            <>
                <div className={styles.colors}>
                    {selectableColorSchemeCollections.map((collection, index) => (
                        <ColorItem
                            currentColors={this.props.currentColors}
                            key={index}
                            setColors={this.props.setColors}
                            colors={collection.colorScheme}
                        />
                    ))}
                </div>
                <ExpandableArea title="Avancerade inställningar">
                    <div className={styles.advanced}>
                        <AdvancedColorItem
                            title="Primär"
                            colorPropName={ColorV2Type.primary}
                            currentColors={this.props.currentColors}
                            setColors={this.props.setColors}
                        />
                        <AdvancedColorItem
                            title="Bakgrund"
                            colorPropName={ColorV2Type.background}
                            currentColors={this.props.currentColors}
                            setColors={this.props.setColors}
                        />
                        <AdvancedColorItem
                            title="Ytor"
                            colorPropName={ColorV2Type.surface}
                            currentColors={this.props.currentColors}
                            setColors={this.props.setColors}
                        />

                        <AdvancedColorItem
                            title="På primär"
                            colorPropName={ColorV2Type.onPrimary}
                            currentColors={this.props.currentColors}
                            setColors={this.props.setColors}
                        />
                        <AdvancedColorItem
                            title="På bakgrund"
                            colorPropName={ColorV2Type.onBackground}
                            currentColors={this.props.currentColors}
                            setColors={this.props.setColors}
                        />
                        <AdvancedColorItem
                            title="På ytor"
                            colorPropName={ColorV2Type.onSurface}
                            currentColors={this.props.currentColors}
                            setColors={this.props.setColors}
                        />
                    </div>
                </ExpandableArea>
                <button className={styles.displayNoneShowInDevToolsIfNeeded} onClick={this.showColors}>
                    Color as object in console
                </button>
            </>
        );
    }
}
