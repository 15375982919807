import React from "react";
import styles from "./EditSiteGraphic.module.scss";
import { siteGraphics, TSiteGraphic } from "../../components/SiteGraphic/SiteGraphic";
import { SiteGraphicItem } from "./SiteGraphicItem/SiteGraphicItem";

interface IProps {
    siteGraphic?: TSiteGraphic | null;
    setSiteGraphic: (graphic: TSiteGraphic | null) => void;
}

export const EditSiteGraphic = (props: IProps) => {
    return (
        <div className={styles.wrapper}>
            <SiteGraphicItem
                currentSiteGraphic={props.siteGraphic}
                key={"none"}
                setSiteGraphic={props.setSiteGraphic}
                siteGraphic={null}
            />
            {siteGraphics.map((siteGraphic) => (
                <SiteGraphicItem
                    currentSiteGraphic={props.siteGraphic}
                    key={siteGraphic}
                    setSiteGraphic={props.setSiteGraphic}
                    siteGraphic={siteGraphic}
                />
            ))}
        </div>
    );
};
