import axios from "axios";
import React, { useState } from "react";
import { IError } from "../../../../App";
import { QuickStartContext } from "../../../../contexts/QuickStartContext";
import { FileUpload } from "../../../components/fileUpload/FileUpload";
import Loading from "../../../components/Loading/Loading";
import ShowError from "../../../components/ShowError/ShowError";
import { IImage, ISite } from "../../../interfaces/Site";
import { randomId } from "../../../utils/Random";
import { logError } from "../../../utils/LogError";

interface IProps {
    addOrUpdateImageToSite: (image: IImage) => void;
    updateContentImage: any;
    site: ISite;
}

export function UploadImage(props: IProps) {
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState<IError | null>(null);

    const isQuickStart = React.useContext(QuickStartContext);

    const handleFileUpload = (file: any) => {
        setIsLoading(true);
        const data = new FormData();
        data.append("file", file);
        axios
            .post(
                process.env.REACT_APP_API_URL +
                    "/api/sites/" +
                    (isQuickStart ? "quick-start" : props.site._id) +
                    "/upload-image",
                data
            )
            .then((response) => {
                const id = randomId();

                props.addOrUpdateImageToSite({
                    fileName: response.data.fileName,
                    path: response.data.path,
                    cdn: response.data.cdn,
                    sizes: response.data.sizes,
                    originalName: response.data.originalName,
                    temporary: response.data.temporary,
                    id: id,
                });

                props.updateContentImage({
                    type: "image",
                    id: id,
                });
                setIsLoading(false);
            })
            .catch((err) => {
                logError(
                    "generator.uploadImage.handleFileUpload",
                    err,
                    1,
                    {
                        path:
                            process.env.REACT_APP_API_URL +
                            "/api/sites/" +
                            (isQuickStart ? "quick-start" : props.site._id) +
                            "/upload-image",
                    },
                    props.site
                );
                setError({ text: "Misslyckades att ladda upp bilden", response: err.response });
                setIsLoading(false);
            });
    };

    return (
        <>
            {isLoading === true && <Loading />}
            <ShowError error={error} />
            <FileUpload
                onFileUpload={handleFileUpload}
                buttonText="Klicka här eller dra och släpp för att ladda upp bild"
            />
        </>
    );
}
