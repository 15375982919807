import { useState } from "react";
import ActionCurtain from "../../../../components/actionCurtain/ActionCurtain";
import Button from "../../../../components/button/Button";
import EditButton from "../../../../components/EditButton/EditButton";
import Select from "../../../../components/form/Select/Select";
import { SelectVerticalV2 } from "../../../../components/SelectVerticalV2/SelectVerticalV2";
import ContentGeneratorButton from "../../../../content/GeneratorButton/GeneratorButton";
import ContentOneLine from "../../../../content/oneLine/OneLine";
import { ISite } from "../../../../interfaces/Site";
import styles from "./SelectNumberOfPersons.module.scss";

interface IProps {
    updateProperty: (module: any, property: string, content: any) => void;
    maxNumberOfPersons: number;
    selectedNumberOfPersons: number;
    propertyParent: any;
    editMode: boolean;
    site: ISite;
    changeNumberOfPersons: (personsQuantity: number) => void;
    editAvailable?: boolean;
    showError: () => void;
}

export function SelectNumberOfPersons(props: IProps) {
    const [modalOpen, setModalOpen] = useState(false);

    const personsOptions = [...Array(props.maxNumberOfPersons)].map((e, index) => ({
        title: index + 1,
        value: index + 1,
    }));

    const maxNumberOfPersonsOptions = [...Array(5)].map((e, index) => ({
        title: index + 1 + "",
        value: index + 1 + "",
    }));

    const handleMaxNumberOfPersonsUpdate = (value: string) => {
        const valueAsNumber = parseInt(value);
        props.updateProperty(props.propertyParent, "maxNumberOfPersons", valueAsNumber);
    };

    const changeNumberOfPersons = (val: number | string) => {
        if (typeof val === "string") {
            throw new Error("String should be number in changeNumberOfPersons");
        }
        props.changeNumberOfPersons(val);
    };

    return (
        <div className={styles.wrapper}>
            {props.maxNumberOfPersons !== 1 ? (
                <ContentOneLine
                    className={styles.selectNumberOfPersonsText}
                    site={props.site}
                    showError={props.showError}
                    propertyParent={props.propertyParent}
                    property="chooseNumberOfPersonsText"
                    placeholder="Ingen text"
                    updateProperty={props.updateProperty}
                    editMode={props.editMode}
                />
            ) : null}
            {modalOpen ? (
                <ActionCurtain close={() => setModalOpen(false)}>
                    <div className={styles.modal}>
                        <p>
                            Här kan du välja hur många personer gästen kan OSA till på en och samma gång. Om du vet med
                            dig att du tex. inte bjudit mer än max två personer från ett och samma hushåll kan du
                            förslagsvis välja två. Gästen kan alltid OSA flera gånger.
                        </p>
                        <div className={styles.selectWrapper}>
                            <div>
                                <Select
                                    id="selectMaxNumberOfPersons"
                                    label="Välj max antal som kan OSA samtidigt"
                                    options={maxNumberOfPersonsOptions}
                                    defaultValue={props.maxNumberOfPersons}
                                    onChangeCallback={handleMaxNumberOfPersonsUpdate}
                                />
                            </div>
                        </div>
                        <Button
                            type="primary"
                            buttonText="Klar"
                            callback={() => {
                                setModalOpen(false);
                            }}
                        />
                    </div>
                </ActionCurtain>
            ) : null}
            {props.editMode && props.maxNumberOfPersons === 1 ? (
                <div className={styles.editButton}>
                    <EditButton callback={() => setModalOpen(true)}>Ändra valbart antal personer</EditButton>
                </div>
            ) : null}
            <div>
                {props.maxNumberOfPersons !== 1 ? (
                    <SelectVerticalV2
                        value={props.selectedNumberOfPersons}
                        onClick={props.editMode ? () => setModalOpen(true) : undefined}
                        site={props.site}
                        options={personsOptions}
                        handleChange={props.editMode ? undefined : changeNumberOfPersons}
                        data-test-id="select-number-of-persons-rvsp"
                    />
                ) : props.editMode || !props.selectedNumberOfPersons ? (
                    <ContentGeneratorButton
                        buttonDisabled={props.editMode}
                        buttonText="OSA"
                        buttonType="primary"
                        buttonCallback={() => changeNumberOfPersons(1)}
                        site={props.site}
                        propertyParent={props.propertyParent}
                        property="showRSVPFormWhenOnlyOnePerson"
                        showError={props.showError}
                        updateProperty={props.updateProperty}
                        editMode={props.editMode}
                    />
                ) : null}
            </div>
        </div>
    );
}
