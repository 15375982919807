import React from "react";
import { ISite } from "../../interfaces/Site";
import styles from "./SelectVertical.module.scss";

interface IProps {
    onChangeCallback: any;
    options: {
        title: string;
        value: string;
    }[];
    disabled: boolean;
    site: ISite;
    "data-test-id"?: string;
}

interface IState {
    value: string;
}

export class SelectVertical extends React.Component<IProps, IState> {
    state: IState = {
        value: "",
    };

    handleChange(value: string) {
        this.setState({ value: value });

        this.props.onChangeCallback(value);
    }

    render() {
        return (
            <div className={styles[this.props.site.theme]}>
                {this.props.options.map((option, index) => (
                    <button
                        type="button"
                        disabled={this.props.disabled === true ? true : undefined}
                        key={option.value}
                        onClick={() => {
                            this.handleChange(option.value);
                        }}
                        className={`${option.value === this.state.value ? styles.selected : ""} ${
                            this.state.value ? styles.selectedExists : ""
                        }`}
                        data-test-id={this.props["data-test-id"] ? this.props["data-test-id"] + "-" + index : ""}
                    >
                        <span>{option.title}</span>
                    </button>
                ))}
            </div>
        );
    }
}
