import { Editor, Transforms, Element, Range } from "slate";
import { CustomEditor, HeadingThreeElement } from "../types/SlateTypes";

export const isHeadingThreeActive = (editor: CustomEditor) => {
    const [link] = Array.from(
        Editor.nodes(editor, {
            match: (n) => !Editor.isEditor(n) && Element.isElement(n) && n.type === "heading-three",
        })
    );
    return !!link;
};

export const wrapHeadingThree = (editor: CustomEditor, text: string) => {
    if (isHeadingThreeActive(editor)) {
        unwrapHeadingThree(editor);
    }

    const { selection } = editor;
    const isCollapsed = selection && Range.isCollapsed(selection);
    const link: HeadingThreeElement = {
        type: "heading-three",
        children: isCollapsed ? [{ text: text }] : [],
    };

    if (isCollapsed) {
        Transforms.insertNodes(editor, link);
    } else {
        Transforms.wrapNodes(editor, link, { split: true });
        Transforms.collapse(editor, { edge: "end" });
    }
};

export const unwrapHeadingThree = (editor: CustomEditor) => {
    Transforms.unwrapNodes(editor, {
        match: (n) => !Editor.isEditor(n) && Element.isElement(n) && n.type === "heading-three",
    });
};
