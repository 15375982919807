import React from "react";
import { View404 } from "../../../views/View404/View404";
import Loading from "../../components/Loading/Loading";
import { IGiftRegistryReservations, ISite } from "../../interfaces/Site";
import { getPublicSite } from "../../services/SiteService";
import { loadSiteFonts } from "../../utils/LoadSiteFonts";
import { logError } from "../../utils/LogError";
import {
    setGeneratorColorStylesVariables,
    setGeneratorFontsStylesVariables,
} from "../../utils/SetGeneratorStylesVariables";
import { Home } from "../Home/Home";
import { ViewPublicLogin } from "./views/PublicLogin/PublicLogin";

interface IState {
    site?: ISite;
    loading: boolean;
    show404: boolean;
    showPublicLogin: boolean;
}

interface IProps {
    quickStart: boolean;
    showError: any;
    host: string;
    routerHistory: any;
}

export default class GeneratorPublic extends React.Component<IProps, IState> {
    state: IState = {
        site: undefined,
        loading: true,
        show404: false,
        showPublicLogin: false,
    };

    componentDidUpdate(prevProps: IProps) {
        if (this.props.host !== prevProps.host) {
            this.getDataFromDb();
        }
    }

    componentDidCatch(error: any, info: any) {
        logError("GeneratorPublic.componentDidCatch", error, 3, info, this.state.site);
    }

    componentDidMount() {
        this.getDataFromDb();
        /*
        if (isDemoFromHost(this.props.host)) {
            trackEvent("demo", UmamiEventType.funnel, UmamiTrackingId.standard);
        }
        */
    }

    getDataFromDb = async () => {
        this.setState({ loading: true });
        try {
            const site = await getPublicSite(this.props.host);
            this.setSiteInitial(site);
        } catch (error) {
            if ((error as any).response.status === 404) {
                this.setState({ show404: true });
            } else if ((error as any).response.status === 401) {
                this.setState({ showPublicLogin: true });
            } else {
                this.props.showError("Misslyckades att hämta sidan", (error as any).response);
            }
        }
        this.setState({ loading: false });
    };

    setSiteInitial = (site: ISite) => {
        loadSiteFonts(site.fonts);
        setGeneratorFontsStylesVariables(site.fonts);
        setGeneratorColorStylesVariables(site.colorScheme ? site.colorScheme : site.colors!);

        this.setState({
            showPublicLogin: false,
            show404: false,
            site,
        });
    };

    updateGiftRegistryReservations = (giftRegistryReservations: IGiftRegistryReservations[]) => {
        let site = this.state.site;
        site!.giftRegistryReservations = giftRegistryReservations;
        this.setState({ site });
    };

    render() {
        if (this.state.show404 === true) {
            return <View404 />;
        }
        if (this.state.showPublicLogin === true) {
            return <ViewPublicLogin host={this.props.host} setSiteInitial={this.setSiteInitial} />;
        }
        if (!this.state.site) {
            return <Loading fixed={true} inverted={true} />;
        }
        return (
            <>
                {this.state.loading === true && <Loading fixed={true} />}
                <Home
                    routerHistory={this.props.routerHistory}
                    quickStart={this.props.quickStart}
                    showError={this.props.showError}
                    editAvailable={false}
                    editMode={false}
                    site={this.state.site!}
                    updateGiftRegistryReservations={this.updateGiftRegistryReservations.bind(this)}
                />
            </>
        );
    }
}
