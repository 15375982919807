import React from "react";
import { Route, Switch } from "react-router-dom";
import { QuickStartContext } from "../../../contexts/QuickStartContext";
import { trackEvent } from "../../../utils/UmamiHelpers";
import { DrawerSlot } from "../../components/DrawerSlot/DrawerSlot";
import { Help } from "../../modules/Help/Help";
import { logError } from "../../utils/LogError";
import { GeneratorEditMenu } from "./modules/GeneratorEditMenu/GeneratorEditMenu";
import { ViewAccount } from "./views/Account/ViewAccount";
import { EditHome } from "./views/EditHome/EditHome";
import { ViewCheckout } from "./views/ViewCheckout/ViewCheckout";
import { ViewRvsp } from "./views/ViewRvsp/ViewRvsp";
import { ViewCommunication } from "./views/ViewCommunication/ViewCommunication";
import { ViewPrintCheckout } from "./views/ViewPrintCheckout/ViewPrintCheckout";
import { ViewPrintCheckoutConfirmation } from "./views/ViewPrintCheckoutConfirmation/ViewPrintCheckoutConfirmation";

interface IState {
    showHelp: boolean;
    drawerSlotPortalNode: any;
}

interface IProps {
    quickStart: boolean;
    showError: any;
    siteId?: string;
    host?: string;
}

export default class GeneratorEdit extends React.Component<IProps, IState> {
    state: IState = {
        showHelp: false,
        drawerSlotPortalNode: null,
    };

    componentDidCatch(error: any, info: any) {
        logError("GeneratorEdit.componentDidCatch", error, 3, info);
    }

    componentDidMount() {
        if (this.props.quickStart === true) {
            const params = new URLSearchParams(window.location.search);
            if (params.get("register") === "true") {
                trackEvent("url-param-register");
            }
        }
    }

    setShowHelp = (showHelp: boolean) => this.setState({ showHelp });
    showHelp = () => this.setState({ showHelp: true });
    closeHelp = () => this.setState({ showHelp: false });

    setDrawerSlotPortalNode = (drawerSlotPortalNode: any) => {
        this.setState({
            drawerSlotPortalNode,
        });
    };

    render() {
        return (
            <QuickStartContext.Provider value={this.props.quickStart}>
                <DrawerSlot setPortalNode={this.setDrawerSlotPortalNode.bind(this)}>
                    <GeneratorEditMenu
                        quickStart={this.props.quickStart}
                        siteId={this.props.siteId}
                        showHelp={this.state.showHelp}
                        setShowHelp={this.setShowHelp.bind(this)}
                    />
                    {this.state.showHelp === true && <Help close={this.closeHelp} />}
                    <Switch>
                        <Route
                            path="/redigera/:siteId/visa-OSA/"
                            render={(props) => (
                                <ViewRvsp
                                    {...props}
                                    siteId={this.props.siteId}
                                    showError={this.props.showError}
                                    quickStart={this.props.quickStart}
                                />
                            )}
                        />
                        <Route
                            path="/snabbstart/visa-OSA/"
                            render={(props) => (
                                <ViewRvsp
                                    {...props}
                                    showError={this.props.showError}
                                    quickStart={this.props.quickStart}
                                />
                            )}
                        />
                        <Route
                            path="/snabbstart/inbjudningar"
                            render={(props) => (
                                <ViewCommunication
                                    quickStart={true}
                                    drawerSlotPortalNode={this.state.drawerSlotPortalNode}
                                />
                            )}
                        />
                        <Route path="/redigera/:siteId/konto/">
                            {this.props.siteId ? (
                                <ViewAccount siteId={this.props.siteId} />
                            ) : null}
                        </Route>
                        <Route path="/redigera/:siteId/valj-adress">
                            {this.props.siteId ? (
                                <ViewCheckout showError={this.props.showError} siteId={this.props.siteId} />
                            ) : null}
                        </Route>
                        <Route
                            path="/redigera/:siteId/inbjudningar/bekraftelse/:orderId"
                            render={(props) => <ViewPrintCheckoutConfirmation orderId={props.match.params.orderId} />}
                        />
                        <Route
                            path="/redigera/:siteId/inbjudningar/:printId/bestall"
                            render={(routeProps) => (
                                <ViewPrintCheckout
                                    siteId={routeProps.match.params.siteId}
                                    printables={[routeProps.match.params.printId]}
                                    routerHistory={routeProps.history}
                                />
                            )}
                        />
                        <Route
                            path="/redigera/:siteId/inbjudningar"
                            render={(props) => (
                                <ViewCommunication
                                    siteId={this.props.siteId}
                                    quickStart={this.props.quickStart}
                                    drawerSlotPortalNode={this.state.drawerSlotPortalNode}
                                />
                            )}
                        />
                        <Route
                            path="/redigera/:siteId"
                            exact
                            render={(props) => (
                                <EditHome
                                    siteId={this.props.siteId}
                                    routerHistory={props.history}
                                    showHelp={this.state.showHelp}
                                    showHelpFn={this.showHelp}
                                    quickStart={this.props.quickStart}
                                    showError={this.props.showError}
                                    editAvailable={true}
                                    drawerSlotPortalNode={this.state.drawerSlotPortalNode}
                                />
                            )}
                        />
                        <Route
                            path="/snabbstart"
                            exact
                            render={(props) => (
                                <EditHome
                                    host={this.props.host}
                                    routerHistory={props.history}
                                    showHelp={this.state.showHelp}
                                    showHelpFn={this.showHelp}
                                    quickStart={this.props.quickStart}
                                    showError={this.props.showError}
                                    editAvailable={true}
                                    drawerSlotPortalNode={this.state.drawerSlotPortalNode}
                                />
                            )}
                        />
                        <Route
                            path="/sms/snabbstart"
                            exact
                            render={(props) => (
                                <EditHome
                                    host={this.props.host}
                                    routerHistory={props.history}
                                    showHelp={this.state.showHelp}
                                    showHelpFn={this.showHelp}
                                    quickStart={this.props.quickStart}
                                    showError={this.props.showError}
                                    editAvailable={true}
                                    drawerSlotPortalNode={this.state.drawerSlotPortalNode}
                                />
                            )}
                        />
                    </Switch>
                </DrawerSlot>
            </QuickStartContext.Provider>
        );
    }
}
