import React from "react";
import { ISite } from "../../interfaces/Site";
import styles from "./GeneratorButton.module.scss";

interface IProps {
    buttonText: string;
    type: string; // eg. primary, nothing else yet
    site: ISite;
    handleChange: any;
    small?: boolean;
}

export class GeneratorButtonEdit extends React.Component<IProps> {
    render() {
        return (
            <div
                className={`${styles[this.props.site.theme]} ${this.props.small ? styles.small : ""}`}
                contentEditable
                suppressContentEditableWarning={true}
                onBlur={this.props.handleChange}
            >
                {this.props.buttonText}
            </div>
        );
    }
}
