import React from "react";
import ActionCurtain from "../../components/actionCurtain/ActionCurtain";
import { ISite } from "../../interfaces/Site";
import styles from "./EditMenu.module.scss";
import MenuItem from "./MenuItem/MenuItem";

interface IProps {
    site: ISite;
    closeEditMenu: any;
    updateHideInMenu: any;
    updateMenuItem: any;
}

export class EditMenu extends React.Component<IProps> {
    render() {
        return (
            <ActionCurtain title="Ändra meny" close={this.props.closeEditMenu}>
                <ul className={styles.list}>
                    {this.props.site.modules.map((module) => (
                        <li className={styles.listItem} key={module.id}>
                            <MenuItem
                                site={this.props.site}
                                updateHideInMenu={this.props.updateHideInMenu}
                                module={module}
                                updateMenuItem={this.props.updateMenuItem}
                            ></MenuItem>
                        </li>
                    ))}
                </ul>
            </ActionCurtain>
        );
    }
}
