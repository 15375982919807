import React from "react";
import { PercentageWrapper, Percentage } from "../../../../../../components/graphs/Percentage/Percentage";
import { IRvspPerson } from "../../../../../../interfaces/Rvsp";
import { IDeprecatedValueObject } from "../../ViewRvsp";
import styles from "./RsvpChoiceGraphs.module.scss";

interface IProps {
    rsvps: IRvspPerson[];
    getCustomValueWithLabel: (rsvp: IRvspPerson, label: string) => string;
    customValues: any;
    deprecatedValues: IDeprecatedValueObject[];
}

export function RsvpChoiceGraphs(props: IProps) {
    const allRsvpIsDeprecatedWeddingYesOrNo = () => {
        return !props.rsvps.filter((rsvp) => rsvp.weddingYesOrNo === undefined).length;
    };

    const customValuesInfo = props.customValues
        .filter((customValue: any) => customValue.type === "YesOrNo" || customValue.type === "RadioGroup")
        .map((customValue: any) => {
            const answers: { answer: string; count: number }[] = [];

            props.rsvps.forEach((rsvp: IRvspPerson) => {
                const answerCustomValue = props.getCustomValueWithLabel(rsvp, customValue.label) || "Inget svar";
                const alreadyAddedAnswerIndex = answers.findIndex((answer) => answer.answer === answerCustomValue);
                if (alreadyAddedAnswerIndex > -1) {
                    answers[alreadyAddedAnswerIndex].count = answers[alreadyAddedAnswerIndex].count + 1;
                } else {
                    answers.push({ answer: answerCustomValue, count: 1 });
                }
            });

            return {
                question: customValue.label,
                answers: answers.sort((a, b) => b.count - a.count),
            };
        });

    return (
        <div className={styles.wrapper}>
            {allRsvpIsDeprecatedWeddingYesOrNo() ? (
                <PercentageWrapper>
                    <Percentage
                        value={props.rsvps!.filter((rvsp: any) => rvsp.weddingYesOrNo === true).length}
                        totalValue={props.rsvps.length}
                        title="Tackat ja"
                        type="Positive"
                    />
                    <Percentage
                        value={props.rsvps!.filter((rvsp: any) => rvsp.weddingYesOrNo !== true).length}
                        totalValue={props.rsvps.length}
                        title="Tackat nej"
                        type="Negative"
                    />
                </PercentageWrapper>
            ) : null}
            <div className={styles.graphsWrapper}>
                {customValuesInfo.map((customValueInfo: any) => (
                    <div className={styles.graph} key={customValueInfo.question}>
                        <div className={styles.question}>{customValueInfo.question}</div>
                        <div className={styles.answers}>
                            {customValueInfo.answers.map((answer: any) => (
                                <React.Fragment key={answer.answer}>
                                    <div className={styles.count}>{answer.count} st</div>
                                    <div className={styles.text}>{answer.answer}</div>
                                </React.Fragment>
                            ))}
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
}
