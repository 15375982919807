import Axios from "axios";
import React from "react";
import { Link } from "react-router-dom";
import FormWrapper from "../../../generator/components/form/FormWrapper/FormWrapper";
import PasswordField from "../../../generator/components/form/PasswordField/PasswordField";
import styles from "./ResetPassword.module.scss";

interface IProps {
    showError: any;
    token: string;
}

interface IState {
    password: String;
    done: boolean;
}

export class ResetPassword extends React.Component<IProps> {
    state: IState = {
        password: "",
        done: false,
    };

    onChangePassword = (password: String) => {
        this.setState({ password });
    };

    submitResetPassword = () => {
        Axios.put(process.env.REACT_APP_API_URL + "/api/accounts/reset-password/", {
            password: this.state.password,
            token: this.props.token,
        })
            .then((response) => {
                this.setState({
                    done: true,
                });
            })
            .catch((error) => {
                this.props.showError("Misslyckades att återställa lösenord", error.response);
            });
    };

    render() {
        if (this.state.done === true) {
            return (
                <p>
                    Nu har vi ändrat ditt lösenord. <Link to="/logga-in">Logga in</Link>
                </p>
            );
        }
        return (
            <div className={styles.wrapper}>
                <FormWrapper submit={this.submitResetPassword.bind(this)}>
                    <PasswordField
                        required={true}
                        label="Nytt lösenord"
                        name="password"
                        id="password"
                        onChangeCallback={this.onChangePassword.bind(this)}
                    ></PasswordField>
                    <input className={styles.button} type="submit" value="Ändra lösenord" />
                </FormWrapper>
            </div>
        );
    }
}

export default ResetPassword;
