import { getCDNUrl } from "../../utils/DomainHelpers";
import { ISite } from "../interfaces/Site";

export const getImageFromSite = (image: any, site: ISite) => {
    if (image && image.id) {
        if (site.images && site.images.length) {
            return site.images.find((foundImage) => foundImage.id === image.id);
        }
    }
    return null;
};

export const getSrc = (image: any, site: ISite) => {
    const imageObject = getImageFromSite(image, site);
    let src;
    if (imageObject && imageObject.cdn === true) {
        src = getCDNUrl() + imageObject.path + imageObject.fileName;
    } else {
        src = imageObject ? process.env.REACT_APP_API_URL + imageObject.path + imageObject.fileName : "";
    }
    return src;
};

export const getSizePath = (site: ISite, size: number, temporary: boolean | undefined, fileName: string) =>
    process.env.REACT_APP_API_URL +
    "/api/sites/" +
    site._id +
    "/images/size/" +
    size +
    "/" +
    (temporary ? "temporary" : "saved") +
    "/" +
    fileName;

export const getSrcSet = (image: any, site: ISite) => {
    const imageObject = getImageFromSite(image, site);
    if (imageObject && imageObject.cdn && imageObject.sizes) {
        const srcSet = imageObject.sizes.map((sizeItem: any) => {
            return getCDNUrl() + imageObject.path + sizeItem.fileName + " " + sizeItem.size + "w";
        });
        srcSet.push(getSrc(image, site) + " 4000w");
        return srcSet.join(",");
    } else {
        const sizes = [1600, 1200, 800, 600, 400];
        // Dont do responsive images on temporary images due to it's going to get moved anyways when it's saved.
        if (imageObject && !imageObject.temporary) {
            const srcSet = sizes.map((size) => {
                return getSizePath(site, size, imageObject.temporary, imageObject.fileName) + " " + size + "w";
            });
            srcSet.push(getSrc(image, site) + " 2000w");
            return srcSet.join(",");
        } else {
            return "";
        }
    }
};
