import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import dayjs from "dayjs";
import { IconDelete } from "../../../../../../icons/delete";
import { IRvspPerson } from "../../../../../../interfaces/Rvsp";
import { IDeprecatedValueObject } from "../../ViewRvsp";
import styles from "./RsvpAnswers.module.scss";

interface IProps {
    rsvps: IRvspPerson[];
    showDeleteConfirmation: (id: string, name: string) => void;
    getCustomValueWithLabel: (rsvp: IRvspPerson, label: string) => string;
    customValues: any;
    deprecatedValues: IDeprecatedValueObject[];
}

export function RsvpAnswers(props: IProps) {
    function getDeleteConfirmName(rsvp: IRvspPerson) {
        if (rsvp.firstName && rsvp.lastName) {
            return rsvp.firstName + " " + rsvp.lastName;
        } else if (props.getCustomValueWithLabel(rsvp, "Förnamn") && props.getCustomValueWithLabel(rsvp, "Efternamn")) {
            return (
                props.getCustomValueWithLabel(rsvp, "Förnamn") + " " + props.getCustomValueWithLabel(rsvp, "Efternamn")
            );
        } else {
            return "";
        }
    }

    const hasAnyDeprecatedWeddingYesOrNo = () => {
        return !!props.rsvps.filter((rsvp) => rsvp.weddingYesOrNo === false || rsvp.weddingYesOrNo === true).length;
    };

    const getCustomValueLabelClass = (value: string, customValue: any) => {
        if (
            value &&
            typeof value === "string" &&
            (customValue.type === "YesOrNo" || customValue.type === "RadioGroup")
        ) {
            value = value.toUpperCase();
            if (["JA", "JA!", "JA, SJÄLVKLART!", "YES", "YES!"].indexOf(value) > -1) {
                return styles.statusYes;
            } else if (["NEJ", "NEJ TYVÄRR", "NEJ, JAG KAN TYVÄRR INTE KOMMA", "NO"].indexOf(value) > -1) {
                return styles.statusNo;
            }
        }
        return undefined;
    };


    return (
        <TableContainer component={Paper} className={styles.wrapper}>
            <Table>
                <TableHead>
                    <TableRow className={styles.headerRow}>
                        {hasAnyDeprecatedWeddingYesOrNo() ? <TableCell>Kommer</TableCell> : null}
                        {props.deprecatedValues.map((value: IDeprecatedValueObject) => (
                            <TableCell key={value.key}>{value.label}</TableCell>
                        ))}
                        {props.customValues.map((customValue: any) => (
                            <TableCell key={customValue.label}>
                                <div className={styles.truncate}>{customValue.label}</div>
                            </TableCell>
                        ))}
                        <TableCell align="right">Datum</TableCell>
                        <TableCell></TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {props.rsvps.map((rsvp: IRvspPerson) => (
                        <TableRow key={rsvp._id} className={styles.row}>
                            {hasAnyDeprecatedWeddingYesOrNo() ? (
                                <TableCell>
                                    {rsvp.weddingYesOrNo === true || rsvp.weddingYesOrNo === false ? (
                                        <div
                                            className={
                                                rsvp.weddingYesOrNo === true ? styles.statusYes : styles.statusNo
                                            }
                                        >
                                            {rsvp.weddingYesOrNo === true ? "Ja" : "Nej"}
                                        </div>
                                    ) : null}
                                </TableCell>
                            ) : null}
                            {props.deprecatedValues.map((value: IDeprecatedValueObject) => (
                                <TableCell key={value.key}>{rsvp[value.key]}</TableCell>
                            ))}
                            {props.customValues.map((customValue: any) => (
                                <TableCell key={customValue.label}>
                                    <div
                                        className={getCustomValueLabelClass(
                                            props.getCustomValueWithLabel(rsvp, customValue.label),
                                            customValue
                                        )}
                                    >
                                        {props.getCustomValueWithLabel(rsvp, customValue.label)}
                                    </div>
                                </TableCell>
                            ))}
                            <TableCell>{dayjs(rsvp.registered!.date).format("YYYY-MM-DD")}</TableCell>
                            <TableCell align="right">
                                <button
                                    className={styles.deleteButton}
                                    onClick={() => {
                                        props.showDeleteConfirmation(rsvp._id!, getDeleteConfirmName(rsvp));
                                    }}
                                >
                                    <IconDelete />
                                </button>
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
}
