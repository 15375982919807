import { isDemoFromHost } from "../../utils/DomainHelpers";
import { ISite } from "../interfaces/Site";

export const getSiteThemeGeneration = (site: ISite): "V1" | "V2" => {
    if (site.colorScheme?.primary || isDemoFromHost(site.host)) {
        return "V2";
    } else {
        return "V1";
    }
};
