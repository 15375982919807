import { disableBodyScroll, enableBodyScroll } from "body-scroll-lock";
import React from "react";
import ReactDOM from "react-dom";
import Close from "../../components/Close/Close";
import styles from "./ActionCurtain.module.scss";

interface IProps {
    close: any;
    title?: string;
    preventClosingOnClickOutside?: boolean;
    children?: JSX.Element | JSX.Element[];
}

export class ActionCurtain extends React.Component<IProps> {
    refCancelOnClickOutside: any;

    componentDidMount() {
        document.addEventListener("mousedown", this.handleClickOutside);
        disableBodyScroll(this.refCancelOnClickOutside);
    }

    componentWillUnmount() {
        document.removeEventListener("mousedown", this.handleClickOutside);
        enableBodyScroll(this.refCancelOnClickOutside);
    }

    handleClickOutside = (event: Event) => {
        if (
            !this.props.preventClosingOnClickOutside &&
            this.refCancelOnClickOutside &&
            !this.refCancelOnClickOutside.contains(event.target) &&
            document.body.contains(event.target as Node)
        ) {
            this.props.close();
        }
    };

    setRefForCancelOnClickOutside = (that: any) => {
        this.refCancelOnClickOutside = that;
    };

    render() {
        return (
            <>
                {ReactDOM.createPortal(
                    <div className={styles.wrapper}>
                        <div className={styles.innerWrapper} ref={this.setRefForCancelOnClickOutside}>
                            <div className={styles.top}>
                                <div className={styles.title}>{this.props.title}</div>
                                <Close closeEvent={this.props.close}></Close>
                            </div>
                            <div>{this.props.children}</div>
                        </div>
                    </div>,
                    document.querySelector("#actionCurtainPortalSlot")!
                )}
            </>
        );
    }
}

export default ActionCurtain;
