import Axios from "axios";
import { EditSelectVertical } from "../../components/EditSelectVertical/EditSelectVertical";
import FormWrapper from "../../components/form/FormWrapper/FormWrapper";
import GeneratorModal from "../../components/GeneratorModal/GeneratorModal";
import Modal from "../../components/Modal/Modal";
import ContentGeneratorButton from "../../content/GeneratorButton/GeneratorButton";
import ContentH3 from "../../content/h3/H3";
import ContentRTE from "../../content/RTE/RTE";
import { IRvspPersonV2, IRvspV2 } from "../../interfaces/Rvsp";
import SetReceiverMail from "../../modules/SetReceiverMail/SetReceiverMail";
import ModuleHeading from "../components/ModuleHeading/ModuleHeading";
import GeneratorModule from "../GeneratorModule";
import Person from "./components/Person/Person";
import { SelectNumberOfPersons } from "./components/SelectNumberOfPersons/SelectNumberOfPersons";
import styles from "./RSVP3.module.scss";

interface IState {
    personsQuantity: number;
    persons: IRvspPersonV2[];
    postKey?: string;
    editView: View;
    view: View;
    showCantSendRsvpFromEditMode: boolean;
}

enum View {
    form,
    confirmation,
    spam,
}

export class RSVP3 extends GeneratorModule {
    state: IState = {
        personsQuantity: 0,
        persons: [],
        editView: View.form,
        view: View.form,
        showCantSendRsvpFromEditMode: false,
    };

    changeNumberOfPersons = (personsQuantity: number) => {
        let persons = [] as IRvspPersonV2[];

        // eslint-disable-next-line array-callback-return
        [...Array(personsQuantity)].map((e, index) => {
            persons[index] = this.state.persons[index] || {};
        });

        Axios.get(process.env.REACT_APP_API_URL + "/api/rsvps/post-key")
            .then((res) => {
                this.setState({
                    postKey: res.data.key,
                    personsQuantity,
                    persons,
                });
            })
            .catch((err) => {
                this.props.showError("Misslyckades att ladda OSA-formuläret", err.response);
            });
    };

    updateValue = (index: number, name: string, value: string, customValueType: string, label: string) => {
        let persons = this.state.persons;
        let person = persons[index];

        if (person === undefined) {
            // Quick and dirty fix. When running update in componentWillUnmount in for example radiogroup.tsx after changing persons, this not longer exists. 
            // Should not be in RSVP4 but should be ok for now because undefined crashes and this has never crashed before in the logs
            return;
        }

        if (customValueType) {
            if (!person.customValues) {
                person.customValues = [];
            }

            let index = person.customValues.findIndex((customValue) => {
                return customValue.id === name;
            });

            if (index < 0) {
                person.customValues.push({
                    id: name,
                    value,
                    type: customValueType,
                    label,
                });
            } else {
                person.customValues[index].value = value;
            }
        } else {
            (person as any)[name] = value;
        }

        this.setState({
            persons,
        });
    };

    submit = () => {
        const RSVP: IRvspV2 = {
            persons: this.state.persons,
            postKey: this.state.postKey,
            source: "site-form-" + this.props.moduleData.id,
        };
        Axios.post(process.env.REACT_APP_API_URL + "/api/rsvps/" + this.props.site._id + "/v3/", RSVP)
            .then((res) => {
                if (res.data.invertedSuccess && res.data.invertedSuccess === true) {
                    this.setState({
                        view: View.spam,
                    });
                } else {
                    this.setState({
                        view: View.confirmation,
                    });
                }
            })
            .catch((err) => {
                this.props.showError(
                    "Tyvärr misslyckades vi att spara din OSA. Något gick fel. Försök gärna igen.",
                    err.response
                );
            });
    };

    editViews = [
        {
            title: "Formulär",
            value: View.form,
        },
        {
            title: "Bekräftelse",
            value: View.confirmation,
        },
    ];

    changeEditView = (view: View) => {
        this.setState({
            editView: view,
        });
    };

    resetView = () => {
        this.setState({
            view: View.form,
            editView: View.form,
            personsQuantity: 0,
            persons: [],
        });
    };

    viewIs = (view: View) => {
        if (this.props.editMode === true) {
            return this.state.editView === view;
        } else {
            return this.state.view === view;
        }
    };

    render() {
        const maxNumberOfPersons = this.props.moduleData.properties.maxNumberOfPersons || 5;
        return (
            <section className={styles[this.props.site.theme]}>
                <div className={styles.innerWrapper}>
                    <ModuleHeading
                        site={this.props.site}
                        propertyParent={this.props.moduleData}
                        showError={this.props.showError}
                        updateProperty={this.props.updateProperty}
                        editMode={this.props.editMode}
                    ></ModuleHeading>
                    <div className={styles.textAndFormWrapper}>
                        <div className={styles.rteWrapper}>
                            <ContentRTE
                                site={this.props.site}
                                propertyParent={this.props.moduleData}
                                showError={this.props.showError}
                                property="text"
                                updateProperty={this.props.updateProperty}
                                editMode={this.props.editMode}
                            ></ContentRTE>
                        </div>
                        <div className={styles.selectNumberOfPersonsWrapper}>
                            <SelectNumberOfPersons
                                editAvailable={this.props.editAvailable}
                                selectedNumberOfPersons={this.state.personsQuantity}
                                site={this.props.site}
                                updateProperty={this.props.updateProperty}
                                propertyParent={this.props.moduleData}
                                editMode={this.props.editMode}
                                changeNumberOfPersons={this.changeNumberOfPersons}
                                showError={this.props.showError}
                                maxNumberOfPersons={maxNumberOfPersons}
                            />
                        </div>
                        {this.props.editMode || (this.state.personsQuantity && this.state.personsQuantity > 0) ? (
                            <div className={styles.selectViewWrapper}>
                                {this.props.editMode === true ? (
                                    <EditSelectVertical
                                        value={this.state.editView}
                                        onChangeCallback={this.changeEditView}
                                        options={this.editViews}
                                    />
                                ) : null}
                            </div>
                        ) : null}
                        {this.viewIs(View.form) ? (
                            <FormWrapper
                                submit={this.submit.bind(this)}
                                disabled={this.props.editMode === true}
                                data-test-id="rsvp-form"
                            >
                                <>
                                    {[...Array(this.props.editMode ? 1 : this.state.personsQuantity)].map(
                                        (e, index) => (
                                            <Person
                                                showError={this.props.showError}
                                                data={this.props.editMode ? null : this.state.persons[index]}
                                                key={index}
                                                index={index}
                                                updateValue={this.updateValue}
                                                module={this.props.moduleData}
                                                editMode={this.props.editMode}
                                                updateProperty={this.props.updateProperty}
                                                site={this.props.site}
                                                propertyParent={this.props.moduleData}
                                                maxNumberOfPersons={maxNumberOfPersons}
                                            />
                                        )
                                    )}
                                    {this.props.editMode ||
                                    (this.state.personsQuantity && this.state.personsQuantity > 0) ? (
                                        <div className={styles.buttonAndReceiverWrapper}>
                                            <div>
                                                <div className={styles.buttonWrapper}>
                                                    <Modal
                                                        open={this.state.showCantSendRsvpFromEditMode}
                                                        close={() =>
                                                            this.setState({ showCantSendRsvpFromEditMode: false })
                                                        }
                                                    >
                                                        Du kan inte skicka in formuläret från granska-läget. För att
                                                        testa formuläret gå till sidans publika adress (samma som
                                                        gästerna besöker).
                                                    </Modal>
                                                    <ContentGeneratorButton
                                                        buttonCallback={
                                                            this.props.editAvailable
                                                                ? () =>
                                                                      this.setState({
                                                                          showCantSendRsvpFromEditMode: true,
                                                                      })
                                                                : undefined
                                                        }
                                                        buttonText="Skicka"
                                                        buttonType="primary"
                                                        buttonSubmit={!this.props.editAvailable}
                                                        site={this.props.site}
                                                        propertyParent={this.props.moduleData}
                                                        property="sendButton"
                                                        showError={this.props.showError}
                                                        updateProperty={this.props.updateProperty}
                                                        editMode={this.props.editMode}
                                                        data-test-id="rsvp-send-button"
                                                    />
                                                </div>
                                            </div>
                                            {this.props.editMode === true && (
                                                <div className={styles.receiverWrapper}>
                                                    <SetReceiverMail
                                                        site={this.props.site}
                                                        updateReceiverMail={this.props.updateReceiverMail}
                                                        receiverCategory="rsvp"
                                                    />
                                                </div>
                                            )}
                                        </div>
                                    ) : (
                                        ""
                                    )}
                                </>
                            </FormWrapper>
                        ) : null}
                        {this.viewIs(View.confirmation) ? (
                            <GeneratorModal
                                site={this.props.site}
                                open={true}
                                close={() => {
                                    this.resetView();
                                }}
                                data-test-id="rsvp-confirmation-modal"
                            >
                                <div className={styles.confirmation}>
                                    <ContentH3
                                        showError={this.props.showError}
                                        updateProperty={this.props.updateProperty}
                                        site={this.props.site}
                                        editMode={this.props.editMode}
                                        property="confirmationTitle"
                                        propertyParent={this.props.moduleData}
                                        fallbackContent="Tack!"
                                    ></ContentH3>
                                    <div className={styles.confirmationText}>
                                        <ContentRTE
                                            showError={this.props.showError}
                                            updateProperty={this.props.updateProperty}
                                            site={this.props.site}
                                            editMode={this.props.editMode}
                                            property="confirmationText"
                                            propertyParent={this.props.moduleData}
                                            fallbackContent="<p>Tack för att du OSA, vi har tagit emot dina uppgifter.</p>"
                                        ></ContentRTE>
                                    </div>
                                </div>
                            </GeneratorModal>
                        ) : null}
                        {this.viewIs(View.spam) ? (
                            <GeneratorModal
                                site={this.props.site}
                                open={true}
                                close={() => {
                                    this.resetView();
                                }}
                            >
                                <div className={styles.confirmation}>
                                    <h3 className={styles.confirmationTitleError}>Tyvärr gick det fel!</h3>
                                    <div className={styles.confirmationText}>
                                        Tyvärr lyckades vi inte spara din OSA då vi identifierade dig som en robot.
                                        Vänligen fyll i en gång till och vänta några sekunder innan du trycker på
                                        skicka. Vi ber om ursäkt för besväret!
                                    </div>
                                </div>
                            </GeneratorModal>
                        ) : null}
                    </div>
                </div>
            </section>
        );
    }
}
