import React from "react";
import styles from "./GeneratorSelectListItem.module.scss";

interface IProps {
    title: string | JSX.Element;
    onClick: () => void;
    selected: boolean;
    dark?: boolean;
    testId?: string;
}

export function GeneratorSelectListItem(props: IProps) {
    return (
        <button
            className={`${styles.item} ${props.dark ? styles.dark : ""} ${props.selected ? styles.selected : ""}`}
            onClick={props.onClick}
            data-test-id={props.testId}
            type="button"
        >
            {props.title}
        </button>
    );
}
