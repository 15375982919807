import styles from "./CardLayoutTextOnPhoto.module.scss";
import { IPrintableCard, TPrintableContent, TPrintablePositions } from "../../../../interfaces/IPrintableCard";
import { PrintableContent } from "../../../PrintableContent/PrintableContent";
import { Updater } from "use-immer";

interface IProps {
    card: IPrintableCard;
    editMode: boolean;
    page: 1 | 2;
    updateCard?: Updater<IPrintableCard>;
    thumbnail?: boolean;
}

const findContentWithPosition = (content: TPrintableContent[], position: TPrintablePositions) => {
    return content.find((item) => item.position === position);
};

const ContentType = (props: {
    content: TPrintableContent[];
    position: TPrintablePositions;
    updateCard?: Updater<IPrintableCard>;
    card: IPrintableCard;
    editMode: boolean;
    aspectRatio?: number;
    thumbnail?: boolean;
}) => {
    const content = findContentWithPosition(props.content, props.position);
    if (!content) {
        throw new Error("No content with position " + props.position);
    }
    return (
        <div className={styles[props.position]}>
            <PrintableContent
                card={props.card}
                editMode={props.editMode}
                updateCard={props.updateCard}
                content={content}
                aspectRatio={props.aspectRatio}
                thumbnail={props.thumbnail}
            />
        </div>
    );
};

export const CardLayoutTextOnPhoto = (props: IProps) => {
    const card = props.card;

    if (props.page === 1) {
        return (
            <div className={`${styles.wrapper} ${styles.page1}`}>
                <div className={styles.imageWrapper}>
                    <ContentType
                        content={card.content}
                        editMode={props.editMode}
                        card={card}
                        updateCard={props.updateCard}
                        position="primaryImage"
                        aspectRatio={460 / 635}
                        thumbnail={props.thumbnail}
                    />
                </div>
                <ContentType
                    content={card.content}
                    editMode={props.editMode}
                    card={card}
                    updateCard={props.updateCard}
                    position="title"
                />
            </div>
        );
    } else {
        return (
            <div className={`${styles.wrapper} ${styles.page2}`}>
                <div className={styles.innerWrapper}>
                    <ContentType
                        content={card.content}
                        card={card}
                        editMode={props.editMode}
                        updateCard={props.updateCard}
                        position="dateAndLocation"
                    />
                    <ContentType
                        card={card}
                        editMode={props.editMode}
                        updateCard={props.updateCard}
                        content={card.content}
                        position="textAtQR"
                    />
                    <ContentType
                        card={card}
                        editMode={props.editMode}
                        updateCard={props.updateCard}
                        content={card.content}
                        position="qr"
                    />
                </div>
            </div>
        );
    }
};
