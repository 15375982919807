import axios from "axios";
import React, { useState } from "react";
import { IError } from "../../../../../../../../../../../../../../../../../App";
import { IPrintableContentImage } from "../../../../../../../../interfaces/IPrintableCard";
import Loading from "../../../../../../../../../../../../../../../../components/Loading/Loading";
import ShowError from "../../../../../../../../../../../../../../../../components/ShowError/ShowError";
import { FileUpload } from "../../../../../../../../../../../../../../../../components/fileUpload/FileUpload";
import { logError } from "../../../../../../../../../../../../../../../../utils/LogError";

interface IProps {
    printId: string | "new";
    updateImage: (properties: IPrintableContentImage["properties"]) => void;
}

export function UploadImage(props: IProps) {
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState<IError | null>(null);

    const handleFileUpload = (file: any) => {
        setIsLoading(true);
        const data = new FormData();
        data.append("file", file);
        axios
            .post(process.env.REACT_APP_API_URL + "/api/prints/" + props.printId + "/image-upload", data)
            .then((response) => {
                props.updateImage({
                    path: response.data.path,
                    originalName: response.data.originalName,
                    fileName: response.data.fileName,
                    temporary: response.data.temporary,
                    isDemoImage: false,
                    sizes: response.data.sizes,
                });
                setIsLoading(false);
            })
            .catch((err) => {
                logError(
                    "generator.prints.handleFileUpload",
                    err,
                    1,
                    {
                        path: process.env.REACT_APP_API_URL + "/api/prints/" + props.printId + "/upload-image",
                    },
                    props.printId
                );
                setError({ text: "Misslyckades att ladda upp bilden", response: err.response });
                setIsLoading(false);
            });
    };

    return (
        <>
            {isLoading === true && <Loading />}
            <ShowError error={error} />
            <FileUpload
                onFileUpload={handleFileUpload}
                buttonText="Klicka här eller dra och släpp för att ladda upp bild"
            />
        </>
    );
}
