import React from "react";
import styles from "./EditSelectVertical.module.scss";

interface IProps {
    onChangeCallback: any;
    options: {
        title: string;
        value: any;
    }[];
    value: any;
}

export class EditSelectVertical extends React.Component<IProps> {
    handleChange(value: string) {
        this.props.onChangeCallback(value);
    }

    render() {
        return (
            <div className={styles.wrapper}>
                {this.props.options.map((option) => (
                    <button
                        type="button"
                        key={option.value}
                        onClick={() => {
                            this.handleChange(option.value);
                        }}
                        className={option.value === this.props.value ? styles.selected : undefined}
                    >
                        {option.title}
                    </button>
                ))}
            </div>
        );
    }
}
