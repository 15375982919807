import { getCDNUrl } from "../../../../../../../../../../../../../../utils/DomainHelpers";
import { IPrintableContentImage } from "../../../../../interfaces/IPrintableCard";

export const getPrintImageSrc = (imageProperties: IPrintableContentImage["properties"], thumbnail?: boolean) => {
    let fileName = imageProperties.fileName;

    if (thumbnail && imageProperties.sizes.length) {
        fileName = imageProperties.sizes[0].fileName;
    } 

    return `${getCDNUrl()}${imageProperties.path}${fileName}`;
};
