import React from "react";
import ContentH2 from "../../content/h2/H2";
import ContentImage from "../../content/image/Image";
import ContentRTE from "../../content/RTE/RTE";
import GeneratorModule from "../GeneratorModule";
import styles from "./TextAndImage.module.scss";

export class TextAndImage extends GeneratorModule {
    render() {
        return (
            <section className={styles[this.props.site.theme]}>
                <div className={styles.imageWrapper}>
                    <ContentImage
                        site={this.props.site}
                        propertyParent={this.props.moduleData}
                        showError={this.props.showError}
                        property="image"
                        updateProperty={this.props.updateProperty}
                        editMode={this.props.editMode}
                        addOrUpdateImageToSite={this.props.addOrUpdateImageToSite}
                        sizes="(min-width: 968px) 968px, 100vw"
                    ></ContentImage>
                </div>
                <div className={styles.textContentWrapper}>
                    <ContentH2
                        site={this.props.site}
                        propertyParent={this.props.moduleData}
                        showError={this.props.showError}
                        property="title"
                        updateProperty={this.props.updateProperty}
                        editMode={this.props.editMode}
                    ></ContentH2>
                    <ContentRTE
                        site={this.props.site}
                        propertyParent={this.props.moduleData}
                        showError={this.props.showError}
                        property="text"
                        updateProperty={this.props.updateProperty}
                        editMode={this.props.editMode}
                    ></ContentRTE>
                </div>
            </section>
        );
    }
}

export default TextAndImage;
