export function IconRedo() {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" strokeWidth="1.5" viewBox="0 0 24 24">
            <path
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M19 9.5H9c-.162 0-4 0-4 4C5 18 8.702 18 9 18h8"
            ></path>
            <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" d="M15.5 13L19 9.5 15.5 6"></path>
        </svg>
    );
}
