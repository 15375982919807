import { IconDelete } from "../../icons/delete";
import { IconMoveDownContentModule } from "../../icons/moveDownContentModule";
import { IconMoveUpContentModule } from "../../icons/moveUpContentModule";
import { IconNewContentModule } from "../../icons/newContentModule";
import { IconToggleContentModuleActionBar } from "../../icons/toggleContentModuleActionBar";
import styles from "./ContentModuleActionBar.module.scss";
import { IModule } from "../../interfaces/Module";
import { ISite } from "../../interfaces/Site";
import { moduleIsFirst, moduleIsLast } from "../../utils/ModuleCollection";
import { IconShow } from "../../icons/show";
import { IconHide } from "../../icons/hide";
import { IconClose } from "../../icons/close";
import useOnClickOutside from "../../utils/useOnClickOutside";

interface IProps {
    showAddModule: (module: IModule) => void;
    deleteModule: (module: IModule) => void;
    showOrHideModule: (module: IModule, hide: boolean) => void;
    module: IModule;
    moveModule: (module: IModule, indexAdjustment: number) => void;
    site: ISite;
    setActionBarToggled: (toggled: boolean) => void;
    toggled: boolean;
}

export function ContentModuleActionBar({showAddModule, deleteModule, showOrHideModule, module, moveModule, site, setActionBarToggled, toggled}: IProps) {
    const pickerWrapper = useOnClickOutside(() => setActionBarToggled(false), toggled); 

    const toggleShowOrHide = () => showOrHideModule(module, !module.hide);

    return (
        <div className={`${styles.wrapper} ${toggled ? styles.open : ""}`}>
            <button className={styles.toggle} onClick={() => setActionBarToggled(true)}>
                <span className={styles.icon}>{IconToggleContentModuleActionBar()}</span>
                <span className={styles.text}>Modul</span>
            </button>
            <div className={`${styles.actions} ${toggled ? styles.open : ""}`} ref={pickerWrapper}>
                <button onClick={() => setActionBarToggled(false)}>
                    <span className={styles.icon}>{IconClose()}</span>
                    <span className={styles.text}>Stäng</span>
                </button>
                {!moduleIsFirst(site.modules, module) ? (
                    <button
                        onClick={() => {
                            moveModule(module, -1);
                        }}
                    >
                        <span className={styles.icon}>{IconMoveUpContentModule()}</span>
                        <span className={styles.text}>Upp</span>
                    </button>
                ) : null}
                {!moduleIsLast(site.modules, module) ? (
                    <button
                        onClick={() => {
                            moveModule(module, 1);
                        }}
                    >
                        <span className={styles.icon}>{IconMoveDownContentModule()}</span>
                        <span className={styles.text}>Ner</span>
                    </button>
                ) : null}
                <button onClick={toggleShowOrHide}>
                    <span className={styles.icon}>{module.hide ? IconShow() : IconHide()}</span>
                    <span className={styles.text}>{module.hide ? "Visa" : "Dölj"}</span>
                </button>
                <button onClick={() => deleteModule(module)}>
                    <span className={styles.icon}>{IconDelete()}</span>
                    <span className={styles.text}>Ta bort</span>
                </button>
                <button onClick={() => showAddModule(module)}>
                    <span className={styles.icon}>{IconNewContentModule()}</span>
                    <span className={styles.text}>Ny modul</span>
                </button>
            </div>
        </div>
    );
}
