import React from "react";
import { getBrandedContactMail } from "../../../generator/utils/getBrand";
import { Inity } from "../../icons/inity";
import styles from "./Footer.module.scss";

export class Footer extends React.Component {
    render() {
        return (
            <>
                <section className={styles.footer}>
                    <div>
                        Frågor eller synpunkter?{" "}
                        <a className={styles.mailLink} href={"mailto:" + getBrandedContactMail()}>
                            {getBrandedContactMail()}
                        </a>
                    </div>
                    <a href="https://inity.se" className={styles.inity} target="_blank" rel="noopener noreferrer">
                        <Inity />
                    </a>
                </section>
            </>
        );
    }
}

export default Footer;
