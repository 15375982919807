import React from "react";
import EditButton from "../../components/EditButton/EditButton";
import { IconNewContentModule } from "../../icons/newContentModule";
import { randomId } from "../../utils/Random";
import ModuleHeading from "../components/ModuleHeading/ModuleHeading";
import GeneratorModule from "../GeneratorModule";
import styles from "./TextAndImageList.module.scss";
import { TextAndImageListItem } from "./TextAndImageListItem/TextAndImageListItem";

export interface IListItemProperties {
    title: string;
    text: string;
    image: string;
}

export interface IListItem {
    id: string;
    properties: IListItemProperties;
}

export const getEmptyNewListItem = (): IListItem => ({
    id: "listItem" + randomId(),
    properties: {
        title: "", // Must be here, otherwise mongodb/moongoose will save without properties prop.
        text: "",
        image: "",
    },
});

export class TextAndImageList extends GeneratorModule {
    updatePropertyOnListItem = (listItem: IListItem, property: string, value: string) => {
        let updatedListItem = listItem;
        (updatedListItem as any).properties[property] = value;

        const index = this.listValue().indexOf(listItem);
        const updatedListItems = this.listValue();

        updatedListItems[index] = updatedListItem;

        this.props.updateProperty(this.props.moduleData, "list", updatedListItems);
    };

    listValue = () => {
        return this.props.moduleData.properties.list;
    };

    addItem = () => {
        let newPropValue = this.listValue() && this.listValue().length ? this.listValue() : [];
        const newObj = getEmptyNewListItem();
        newPropValue.push(newObj);

        this.props.updateProperty(this.props.moduleData, "list", newPropValue);
    };

    updateList = (listItems: IListItem[]) => {
        this.props.updateProperty(this.props.moduleData, "list", listItems);
    };

    render() {
        return (
            <section className={styles[this.props.site.theme]}>
                <ModuleHeading
                    site={this.props.site}
                    propertyParent={this.props.moduleData}
                    showError={this.props.showError}
                    updateProperty={this.props.updateProperty}
                    editMode={this.props.editMode}
                ></ModuleHeading>
                {this.props.moduleData.properties.list && this.props.moduleData.properties.list.length > 0 && (
                    <div className={styles.itemsWrapper}>
                        {this.props.moduleData.properties.list.map((listItem: IListItem, index: number) => (
                            <TextAndImageListItem
                                showError={this.props.showError}
                                listItem={listItem}
                                key={listItem.id}
                                allListItems={this.listValue()}
                                editMode={this.props.editMode}
                                updatePropertyOnListItem={this.updatePropertyOnListItem.bind(this)}
                                site={this.props.site}
                                updateList={this.updateList.bind(this)}
                                addOrUpdateImageToSite={this.props.addOrUpdateImageToSite}
                            />
                        ))}
                    </div>
                )}
                {this.props.editMode === true && (
                    <div className={styles.editButtonWrapper}>
                        <EditButton callback={this.addItem}>
                            <>
                                <IconNewContentModule></IconNewContentModule>
                                Lägg till ruta
                            </>
                        </EditButton>
                    </div>
                )}
            </section>
        );
    }
}

export default TextAndImageList;
