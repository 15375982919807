import React from "react";
import styles from "./TextField.module.scss";

interface IProps {
    onChangeCallback: any;
    value?: string;
    name: string;
    id: string;
    label?: string;
    disabled?: boolean;
    required?: boolean;
    className?: any;
    email?: boolean;
    placeholder?: string;
    autofocus?: boolean;
    number?: boolean;
    max?: number;
    min?: number;
    pattern?: string;
    minLength?: number;
    maxLength?: number;
    testId?: string;
}

interface IState {
    value: string;
}

export class TextField extends React.Component<IProps, IState> {
    state: IState = {
        value: this.props.value || "",
    };

    componentDidUpdate(prevProps: any) {
        if (prevProps.value !== this.props.value && this.props.value !== this.state.value) {
            this.setState({
                value: this.props.value!,
            });
        }
    }

    componentWillUnmount(): void {
        // If this is removed because it is in a ShowFormContentCondition, set the value to nothing
        this.props.onChangeCallback("", this.props.name);
    }

    handleChange(event: React.ChangeEvent<HTMLInputElement>) {
        this.setState({ value: event.target.value });

        this.props.onChangeCallback(event.target.value, this.props.name);
    }

    type() {
        if (this.props.number === true) {
            return "number";
        } else if (this.props.email === true) {
            return "email";
        } else {
            return "text";
        }
    }

    render() {
        return (
            <div className={this.props.className || styles.textFieldWrapper}>
                <label htmlFor={this.props.id}>{this.props.label}</label>
                <input
                    required={this.props.required ? true : false}
                    placeholder={this.props.placeholder}
                    disabled={this.props.disabled ? true : false}
                    id={this.props.id}
                    type={this.type()}
                    name={this.props.name}
                    value={this.state.value}
                    onChange={this.handleChange.bind(this)}
                    autoFocus={this.props.autofocus}
                    max={this.props.max}
                    min={this.props.min}
                    pattern={this.props.pattern}
                    minLength={this.props.minLength}
                    maxLength={this.props.maxLength}
                    data-test-id={this.props.testId}
                />
                <div className="invalidFeedback" data-invalid-feedback="true"></div>
            </div>
        );
    }
}

export default TextField;
