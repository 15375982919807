import { useState } from "react";
import { colorToCssValue } from "../../../../../../../../../../../../utils/colorToCssValue";
import { IPrintableCard, IPrintableContentQr } from "../../../../interfaces/IPrintableCard";
import styles from "./ContentTypeQR.module.scss";
import { ContentTypeQREdit } from "./components/ContentTypeQREdit/ContentTypeQREdit";

interface IProps {
    card: IPrintableCard;
    content: IPrintableContentQr;
    editMode: boolean;
    updateQRUrl: (url: string) => void;
}

export const ContentTypeQR = (props: IProps) => {
    const darkColor = encodeURIComponent(colorToCssValue(props.card.colorScheme.onBackground));
    const lightColor = encodeURIComponent(colorToCssValue(props.card.colorScheme.background));
    const qrUrl = props.card.site ? props.content.properties.url : "DEMO";
    const qrSrc = `${process.env.REACT_APP_API_URL}/api/qr/${qrUrl}?darkColor=${darkColor}&lightColor=${lightColor}`;

    const [edit, setEdit] = useState(false);

    const handleEdit = () => {
        setEdit(true);
    };

    return (
        <>
            {edit ? (
                <ContentTypeQREdit
                    url={props.content.properties.url}
                    updateQRUrl={props.updateQRUrl}
                    siteId={props.card.site}
                    close={() => setEdit(false)}
                />
            ) : null}
            <img
                alt="QR-kod"
                onClick={props.editMode ? handleEdit : undefined}
                className={props.editMode ? styles.editMode : ""}
                src={qrSrc}
                style={{ borderColor: lightColor }}
            />
        </>
    );
};
