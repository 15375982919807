import styles from "./ToolbarButton.module.scss";

interface IProps {
    children: JSX.Element | string;
    active?: boolean;
    disabled?: boolean;
    handleClick: () => void;
}

export const ToolbarButton = ({ children, active, disabled, handleClick }: IProps) => {
    return (
        <button
            type="button"
            className={`${styles.button} ${active ? styles.active : ""} ${disabled ? styles.disabled : ""}`}
            onMouseDown={(event) => {
                event.preventDefault();
                handleClick();
            }}
        >
            {children}
        </button>
    );
};
