import EditButton from "../../components/EditButton/EditButton";
import { IconNewContentModule } from "../../icons/newContentModule";
import { randomId } from "../../utils/Random";
import Content, { IContentProps } from "../Content";
import styles from "./MultipleRadioGroups.module.scss";
import { MultipleRadioGroupItem } from "./MultipleRadioGroupItem/MultipleRadioGroupItem";
import { IRadioGroupOption } from "../../components/form/RadioGroup/RadioGroupEdit";
import { RadioGroup } from "../../components/form/RadioGroup/RadioGroup";

export interface IMultipleRadioGroupsItem {
    id: string;
    label: string;
    options: IRadioGroupOption[];
}

interface IProps extends IContentProps {
    updateCustomField: any;
    id: string;
}

export class ContentMultipleRadioGroups extends Content<IProps> {
    addItem = () => {
        let newPropValue = this.propValue() && this.propValue().length ? this.propValue() : [];

        newPropValue.push({
            id: "RadioGroup" + randomId(),
            label: "En fråga att svara på?",
            options: [
                { label: "Ja", id: "RadioGroupOption" + randomId() },
                { label: "Nej", id: "RadioGroupOption" + randomId() },
            ],
        });

        this.updateContent(newPropValue);
    };

    removeItem = (yesOrNoItem: IMultipleRadioGroupsItem) => {
        const index = this.propValue().indexOf(yesOrNoItem);
        const collection = this.propValue();
        collection.splice(index, 1);
        this.updateContent(collection);
    };

    updateLabel = (yesOrNoItem: IMultipleRadioGroupsItem, newLabel: string) => {
        const index = this.propValue().indexOf(yesOrNoItem);
        const collection = this.propValue();
        collection[index].label = newLabel;
        this.updateContent(collection);
    };

    handleOptionLabelChange = (item: IMultipleRadioGroupsItem, option: IRadioGroupOption, value: string) => {
        const index = this.propValue().indexOf(item);
        const collection = this.propValue();
        const optionIndex = collection[index].options.indexOf(option);
        collection[index].options[optionIndex].label = value;
        this.updateContent(collection);
    };

    handleOptionRemoveItem = (item: IMultipleRadioGroupsItem, option: IRadioGroupOption) => {
        const index = this.propValue().indexOf(item);
        const collection = this.propValue();
        const optionIndex = collection[index].options.indexOf(option);
        collection[index].options.splice(optionIndex, 1);
        this.updateContent(collection);
    };

    handleOptionAddItem = (item: IMultipleRadioGroupsItem) => {
        const index = this.propValue().indexOf(item);
        const collection = this.propValue();
        collection[index].options.push({ label: "Nytt svar", id: "RadioGroupOption" + randomId() });
        this.updateContent(collection);
    };

    onChangeCallback = (value: string, name: string, label: string) => {
        this.props.updateCustomField(value, name, "RadioGroup", label);
    };

    render() {
        return this.props.editMode === true ? (
            <div className={styles.wrapper}>
                {this.propValue() && this.propValue().length
                    ? (this.propValue() as IMultipleRadioGroupsItem[]).map((radioGroupItem) => (
                          <div className={styles.optionsWrapper} key={radioGroupItem.id}>
                              <MultipleRadioGroupItem
                                  radioGroupItem={radioGroupItem}
                                  updateLabel={this.updateLabel.bind(this)}
                                  removeItem={this.removeItem.bind(this)}
                                  id={this.props.id + "-" + radioGroupItem.id}
                                  site={this.props.site}
                                  handleOptionLabelChange={this.handleOptionLabelChange.bind(this)}
                                  handleOptionRemoveItem={this.handleOptionRemoveItem.bind(this)}
                                  handleOptionAddItem={this.handleOptionAddItem.bind(this)}
                              />
                          </div>
                      ))
                    : ""}
                <div className={styles.optionsWrapper}>
                    <div className={styles.addNewYesOrNoInactive}>
                        <MultipleRadioGroupItem
                            radioGroupItem={{
                                id: "placeholderAddNew",
                                label: "En fråga att svara på?",
                                options: [
                                    { id: "placeHolderOption1", label: "Ja" },
                                    { id: "placeHolderOption2", label: "Nej¨" },
                                ],
                            }}
                            updateLabel={() => {}}
                            removeItem={() => {}}
                            id={"placeHolderAddNewRadioGroupItem"}
                            site={this.props.site}
                            handleOptionLabelChange={() => {}}
                        />
                    </div>
                    <div className={styles.addNewYesOrNoButton}>
                        <EditButton callback={this.addItem}>
                            <>
                                <IconNewContentModule></IconNewContentModule>
                                Lägg till fråga
                            </>
                        </EditButton>
                    </div>
                </div>
            </div>
        ) : (
            <div>
                <div className={styles.wrapper}>
                    {this.propValue() && this.propValue().length
                        ? (this.propValue() as IMultipleRadioGroupsItem[]).map((radioGroupItem) => (
                              <div className={styles.optionsWrapper} key={radioGroupItem.id}>
                                  <RadioGroup
                                      required={true}
                                      label={radioGroupItem.label}
                                      disabled={false}
                                      options={radioGroupItem.options}
                                      name={radioGroupItem.id}
                                      id={this.props.id + "-" + radioGroupItem.id}
                                      onChangeCallback={(value: string, name: string) => {
                                          this.onChangeCallback(value, name, radioGroupItem.label || "Ingen fråga");
                                      }}
                                      site={this.props.site}
                                  />
                              </div>
                          ))
                        : ""}
                </div>
            </div>
        );
    }
}
