import { RenderElementProps } from "slate-react";

export const Link = ({ attributes, element, children }: RenderElementProps) => {
    if ("url" in element) {
        return (
            <a {...attributes} href={element.url}>
                {children}
            </a>
        );
    } else {
        throw new Error("Url missing in link");
    }
};
