import React, { ChangeEvent } from "react";
import { IconDelete } from "../../../icons/delete";
import { ISite } from "../../../interfaces/Site";
import Confirmation from "../../../modules/confirmation/Confirmation";
import EditButton, { PointerDirection } from "../../EditButton/EditButton";
import styles from "./Radio.module.scss";

interface IProps {
    labelOnChange: any;
    name: string;
    id: string;
    label: string;
    site: ISite;
    handleOptionRemoveItem?: () => void;
}

interface IState {
    showEditBar: boolean;
    showDeleteConfirmation: boolean;
}

export class RadioEdit extends React.Component<IProps, IState> {
    state: IState = {
        showEditBar: false,
        showDeleteConfirmation: false,
    };

    labelOnChange = (e: ChangeEvent) => {
        let targetElement: HTMLElement = e.target as HTMLElement;
        let newLabel = targetElement.innerText;

        this.props.labelOnChange(newLabel);
    };

    refCancelOnClickOutside: any;

    handleClickOutside = (event: Event) => {
        if (this.refCancelOnClickOutside && !this.refCancelOnClickOutside.contains(event.target)) {
            this.setState({
                showEditBar: false,
            });
        }
    };

    componentDidMount() {
        document.addEventListener("mousedown", this.handleClickOutside);
    }

    componentWillUnmount() {
        document.removeEventListener("mousedown", this.handleClickOutside);
    }

    setRefForCancelOnClickOutside = (that: any) => {
        this.refCancelOnClickOutside = that;
    };

    render() {
        return (
            <span className={`${this.props.site ? styles[this.props.site.theme] : styles.wrapper}`}>
                {this.state.showDeleteConfirmation === true && this.props.handleOptionRemoveItem ? (
                    <Confirmation
                        confirmationTitle="Ta bort"
                        confirmButtonText="Ta bort"
                        abortButtonText="Avbryt"
                        confirmationText={'Är du säker på att du vill ta bort svaret "' + this.props.label + '"?'}
                        confirmCallback={this.props.handleOptionRemoveItem.bind(this)}
                        closeCallback={() => this.setState({ showDeleteConfirmation: false })}
                    />
                ) : null}
                <div
                    className={styles.radioAndTextWrapper}
                    ref={this.setRefForCancelOnClickOutside}
                    onClick={() => this.setState({ showEditBar: true })}
                >
                    <div className={styles.radioWrapper}>
                        <input
                            type="radio"
                            className={styles.radio}
                            id={this.props.id}
                            name={this.props.name}
                            disabled={true}
                        />
                    </div>
                    <div className={styles.textWrapper}>
                        <label
                            placeholder="Ingen text"
                            className={styles.labelEdit}
                            htmlFor={this.props.id}
                            contentEditable={true}
                            suppressContentEditableWarning={true}
                            onBlur={this.labelOnChange}
                        >
                            {this.props.label}
                        </label>
                    </div>

                    <div className={styles.editBarWrapper}>
                        {this.state.showEditBar === true && this.props.handleOptionRemoveItem ? (
                            <EditButton
                                callback={() => this.setState({ showDeleteConfirmation: true })}
                                pointer={PointerDirection.UP}
                            >
                                <>
                                    <IconDelete></IconDelete>
                                    Ta bort svar
                                </>
                            </EditButton>
                        ) : null}
                    </div>
                </div>
            </span>
        );
    }
}
