import React, { ChangeEvent } from "react";
import { ISite } from "../interfaces/Site";

export interface IContentProps {
    propertyParent: any;
    property: string;
    updateProperty: any;
    site: ISite;
    className?: any;
    editMode: boolean;
    showError: any;
    style?: any;
    fallbackContent?: any;
}

interface IState {
    showEditCurtain: boolean;
}

export class Content<T extends IContentProps> extends React.Component<T, IState> {
    state: IState = {
        showEditCurtain: false,
    };

    updateContent = (content: any) => {
        this.props.updateProperty(this.props.propertyParent, this.props.property, content);
    };

    onContentChange = (e: ChangeEvent) => {
        let targetElement: HTMLElement = e.target as HTMLElement;
        this.updateContent(targetElement.innerText);
    };

    onContentChangeRTE = (value: any) => {
        this.updateContent(value);
    };

    propValue = (): any => {
        if (!this.props.propertyParent.properties[this.props.property] && this.props.fallbackContent) {
            return this.props.fallbackContent;
        }
        return this.props.propertyParent.properties[this.props.property];
    };

    closeEditCurtain = () => {
        this.setState({
            showEditCurtain: false,
        });
    };

    openEditCurtain = () => {
        this.setState({
            showEditCurtain: true,
        });
    };
}

export default Content;
