import React from "react";
import "./Button.scss";

interface IProps {
    buttonText: string;
    callback?: any;
    type: "primary" | "warning" | "secondary";
    disabled?: boolean;
    submit?: boolean;
    fullWidth?: boolean;
    "data-test-id"?: string;
    "data-umami-event"?: string;
    "data-umami-event-position"?: string;
}

export class Button extends React.Component<IProps> {
    render() {
        return this.props.submit ? (
            <input
                type="submit"
                value={this.props.buttonText}
                disabled={this.props.disabled ? true : false}
                className={`${this.props.type} ${this.props.fullWidth ? "full-width" : ""} component-button`}
                data-test-id={this.props["data-test-id"]}
                data-umami-event={this.props["data-umami-event"]}
                data-umami-event-position={this.props["data-umami-event-position"]}
            />
        ) : (
            <button
                type="button"
                disabled={this.props.disabled ? true : false}
                className={`${this.props.type} ${this.props.fullWidth ? "full-width" : ""} component-button`}
                onClick={this.props.callback}
                data-test-id={this.props["data-test-id"]}
                data-umami-event={this.props["data-umami-event"]}
                data-umami-event-position={this.props["data-umami-event-position"]}
            >
                {this.props.buttonText}
            </button>
        );
    }
}

export default Button;
