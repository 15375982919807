import { randomId } from "../../../../../utils/Random";

// Special customer fix for https://edit.brollopssida.se/redigera/635590baf6931b4f16073636/
// They had two RSVP-forms. Should support (or disable that) in the future.
export const rvspUglyCustomerFix = (rvsps: any) => {
    return rvsps.map((rsvp: any) => {
        let wedding = "";
        if (rsvp.customValues.find((item: any) => item.id === "textField9ed745b7-7740-26e1-5a7e-fc3968d8297b")) {
            wedding = "Rwanda";
        } else if (rsvp.customValues.find((item: any) => item.id === "textField9cf29772-4c8c-6570-b2a7-da149f5a64ae")) {
            wedding = "Sverige";
        }
        rsvp.customValues.unshift({
            _id: "customFix" + randomId(),
            id: "customCustomerFixWedding",
            value: wedding,
            type: "YesOrNo",
            label: "Wedding",
        });
        return rsvp;
    });
};
