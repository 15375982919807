import React from "react";
import styles from "./ThemeItem.module.scss";
import thumbnailTheme1 from "./thumbnails/theme1.jpg";
import thumbnailTheme2 from "./thumbnails/theme2.jpg";

interface IProps {
    theme: string;
    setTheme: any;
    currentTheme: string;
}

export class ThemeItem extends React.Component<IProps> {
    setTheme = () => {
        this.props.setTheme(this.props.theme);
    };

    isActive = () => {
        return this.props.currentTheme === this.props.theme;
    };

    render() {
        let url = "/images/theme-thumbnails/" + process.env.REACT_APP_BRAND + "/" + this.props.theme + ".webp";
        let urlPngFallback =
            "/images/theme-thumbnails/" + process.env.REACT_APP_BRAND + "/" + this.props.theme + ".png";

        return (
            <div
                className={this.isActive() ? styles.wrapperActive : styles.wrapper}
                onClick={this.setTheme}
                data-test-id={"theme-selector-" + this.props.theme}
                data-test-theme={this.props.theme}
            >
                {["theme1", "theme2", "theme3"].indexOf(this.props.theme) > -1 ? (
                    <>
                        {this.props.theme === "theme1" && <img src={thumbnailTheme1} alt="" />}

                        {this.props.theme === "theme3" && <img src={thumbnailTheme1} alt="" />}

                        {this.props.theme === "theme2" && <img src={thumbnailTheme2} alt="" />}
                    </>
                ) : (
                    <>
                        <picture>
                            <source srcSet={url} type="image/webp" />
                            <source srcSet={urlPngFallback} type="image/png" />
                            <img srcSet={urlPngFallback} alt="Designmall" />
                        </picture>
                    </>
                )}
            </div>
        );
    }
}

export default ThemeItem;
