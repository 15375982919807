import styles from "./ContentTypeImageEdit.module.scss";
import ActionCurtain from "../../../../../../../../../../../../../../components/actionCurtain/ActionCurtain";
import { IPrintableContentImage } from "../../../../../../interfaces/IPrintableCard";
import { UploadImage } from "./components/UploadImage/UploadImage";
import { CropImage } from "./components/CropImage/CropImage";
import { useState } from "react";
import Button from "../../../../../../../../../../../../../../components/button/Button";
import { getPrintImageSrc } from "../../utils/getPrintImageSrc";

interface IProps {
    image: IPrintableContentImage["properties"];
    updateImage: (properties: IPrintableContentImage["properties"]) => void;
    close: () => void;
    printId: string | "new";
    aspectRatio: number;
}

export const ContentTypeImageEdit = (props: IProps) => {
    const [imageToCrop, setImageToCrop] = useState<
        IPrintableContentImage["properties"] | Omit<IPrintableContentImage["properties"], "imageBeforeEdit"> | null
    >(null);

    return (
        <ActionCurtain close={props.close}>
            {imageToCrop ? (
                <CropImage
                    aspectRatio={props.aspectRatio}
                    printId={props.printId}
                    updateImage={props.updateImage}
                    image={imageToCrop}
                    close={props.close}
                />
            ) : (
                <div className={styles.wrapper}>
                    <div className={styles.uploadImage}>
                        <UploadImage printId={props.printId} updateImage={setImageToCrop} />
                    </div>
                    <div className={styles.currentImageAndEditImage}>
                        <div className={styles.inner}>
                            <div className={styles.imageWrapper}>
                                <img alt="" src={getPrintImageSrc(props.image)} />
                            </div>
                            <div className={styles.buttonWrapper}>
                                {props.image.imageBeforeEdit ? (
                                    <Button
                                        buttonText="Beskär original"
                                        type="primary"
                                        callback={() => setImageToCrop(props.image.imageBeforeEdit!)}
                                    />
                                ) : null}
                                <Button
                                    buttonText="Beskär"
                                    type="secondary"
                                    callback={() => setImageToCrop(props.image)}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </ActionCurtain>
    );
};
