import { disableBodyScroll, enableBodyScroll } from "body-scroll-lock";
import { useEffect, useRef, useState } from "react";
import ReactDOM from "react-dom";
import Close from "../Close/Close";
import styles from "./Drawer.module.scss";
import useOnClickOutside from "../../utils/useOnClickOutside";

interface IProps {
    children: JSX.Element;
    open: boolean;
    portalNode: any;
    close: () => void;
    title: string;
    actionBar?: JSX.Element;
    resetScrollOnNewKey?: string;
    drawerId: string;
}

const isIOS =
    /iPad|iPhone|iPod/.test(navigator.platform) || (navigator.platform === "MacIntel" && navigator.maxTouchPoints > 1);

export function Drawer(props: IProps) {
    const isDrawerCoveringContent = (): boolean => {
        return !window.matchMedia("(min-width: 1336px)").matches;
    };

    const scrollElement = useRef<null | HTMLDivElement>(null);

    const [drawerCoversContent, setDrawerCoversContent] = useState(isDrawerCoveringContent);
    const [openDelayedBecauseOfEffect, setOpenDelayedBecauseOfEffect] = useState(false);

    useEffect(() => {
        scrollElement.current?.scroll(0, 0);
    }, [props.resetScrollOnNewKey]);

    useEffect(() => {
        const handleResize = () => {
            setDrawerCoversContent(isDrawerCoveringContent());
        };
        if (props.open) {
            handleResize();
            window.addEventListener("resize", handleResize);
        }

        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, [props.open]);

    // Ok this is not the best, but cant redo all this, ship it
    useEffect(() => {
        if (props.portalNode) {
            if (props.open) {
                props.portalNode.setAttribute("data-open-drawer", props.drawerId);
                setOpenDelayedBecauseOfEffect(true);
            } else {
                setOpenDelayedBecauseOfEffect(false);
                if (props.portalNode.getAttribute("data-open-drawer") === props.drawerId) {
                    props.portalNode.removeAttribute("data-open-drawer");
                }
            }
        }

        return () => {
            if (props.portalNode) {
                if (props.portalNode.getAttribute("data-open-drawer") === props.drawerId) {
                    props.portalNode.removeAttribute("data-open-drawer");
                }
            }
        };
    }, [props.drawerId, props.open, props.portalNode]);

    const drawerElement = useOnClickOutside(() => {
        if (props.open) {
            setTimeout(() => {
                props.close();
            });
        }
    }, props.open && drawerCoversContent);

    useEffect(() => {
        if (props.open && drawerCoversContent && scrollElement.current) {
            disableBodyScroll(scrollElement.current);
        } else if (scrollElement.current) {
            enableBodyScroll(scrollElement.current);
        }
    }, [props.open, drawerCoversContent]);

    if (!props.portalNode) {
        return null;
    }

    return ReactDOM.createPortal(
        <div className={`${styles.drawer} ${openDelayedBecauseOfEffect ? styles.open : ""}`} ref={drawerElement}>
            <div className={styles.top}>
                <div className={styles.title}>{props.title}</div>
                <Close testId="drawer-close-button" closeEvent={props.close}></Close>
            </div>
            {props.actionBar ? <div className={styles.actionBar}>{props.actionBar}</div> : null}
            <div className={styles.content} ref={scrollElement}>
                <div className={isIOS ? styles.iOSScrollFix : ""}>{props.children}</div>
            </div>
        </div>,
        props.portalNode
    );
}
