import Axios from "axios";
import { IOrder, IShippingAddress } from "../interfaces/Order";

export interface IShipmentMethod {
    shipmentMethodUid: string;
    price: number;
    minDeliveryDate: string;
    maxDeliveryDate: string;
    minDeliveryDays: number;
    maxDeliveryDays: number;
    name: string;
    isPrivate: boolean;
    isBusiness: boolean;
    type: "pick_up" | "normal" | "express";
}

interface IData {
    print: string;
    quantity: number;
    envelopes: "standard" | "premium" | "none";
    shippingAddress: IShippingAddress;
}

interface ICreateOrderData {
    site: string;
    print: string;
    quantity: number;
    envelopes: "standard" | "premium" | "none";
    shippingAddress: IShippingAddress;
    shipmentMethodName: string;
    shipmentMethodUid: string;
}

export const getShipmentMethods = async (data: IData): Promise<{ shipmentMethods: IShipmentMethod[] }> => {
    const response = await Axios.post(process.env.REACT_APP_API_URL + "/api/orders/shipmentMethods/", data);
    return response.data;
};

export const postCreateOrder = async (data: ICreateOrderData): Promise<string> => {
    const response = await Axios.post(process.env.REACT_APP_API_URL + "/api/orders/", data);
    return response.data.id;
};

export const getOrder = async (orderId: string): Promise<IOrder> => {
    const response = await Axios.get(process.env.REACT_APP_API_URL + "/api/orders/" + orderId);
    return response.data;
};
