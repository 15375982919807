import Axios from "axios";
import { IAccount } from "../interfaces/Account";

export const getAccountRelatedToSite = async (siteId: string): Promise<IAccount> => {
    const response = await Axios.get(process.env.REACT_APP_API_URL + "/api/sites/" + siteId + "/account/");
    const site = response.data;
    return site;
};

export const postLogout = async (): Promise<void> => {
    const response = await Axios.post(process.env.REACT_APP_API_URL + "/api/accounts/logout/");
    return response.data;
};
