import { TSiteGraphic } from "../../../../../../../components/SiteGraphic/SiteGraphic";
import { enumTheme } from "../../../../../../../interfaces/Site";

export const recommendedPrintLayout = (siteTheme: enumTheme, siteGraphic?: TSiteGraphic | null) => {
    if (enumTheme.theme9 === siteTheme) {
        return "TEXT_ON_PHOTO";
    }

    if (enumTheme.theme11 === siteTheme) {
        return "PHOTO_CLEAN";
    }

    if (siteGraphic) {
        return "GENESIS";
    }

    return "PHOTO_CLEAN";
};
