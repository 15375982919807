import React from "react";
import { ISite } from "../../../../interfaces/Site";
import { getSiteThemeGeneration } from "../../../../utils/getSiteThemeGeneration";
import styles from "./SelectImageSize.module.scss";

interface IProps {
    site: ISite;
    image: any;
    updateProperty: (module: any, property: string, content: any) => void;
    module: any;
    property: string;
}

export enum imageSize {
    LARGE = "",
    MEDIUM = "968",
    SMALL = "400",
}
// This values a dependency to media quries in (tooSmallScreenInfo). If you need to change them, inject the media query manually.

export enum imageSizeV2 {
    LARGE = "LARGE",
    MEDIUM = "MEDIUM",
    SMALL = "SMALL",
}

export function SelectImageSize(props: IProps) {
    const changeSize = (size: imageSize | imageSizeV2) => {
        const content = {
            type: "image",
            id: props.image && props.image.id ? props.image.id : null,
            size: size,
        };

        props.updateProperty(props.module, props.property, content);
    };

    const size = props.image && props.image.size ? props.image.size : imageSize.LARGE;

    return (
        <>
            {getSiteThemeGeneration(props.site) === "V1" && (
                <div className={styles.sizeWrapper}>
                    <div
                        className={size === imageSize.LARGE ? styles.sizeActive : styles.size}
                        onClick={() => changeSize(imageSize.LARGE)}
                    >
                        Stor bild (standard)
                    </div>
                    <div
                        className={size === imageSize.MEDIUM ? styles.sizeActive : styles.size}
                        onClick={() => changeSize(imageSize.MEDIUM)}
                    >
                        Mellanstor bild
                        <div className={styles.tooSmallScreenInfoMediumSize}>
                            Du ser bara skillnad på stor och mellanstor bild om du har en större skärm.
                        </div>
                    </div>
                    <div
                        className={size === imageSize.SMALL ? styles.sizeActive : styles.size}
                        onClick={() => changeSize(imageSize.SMALL)}
                    >
                        Liten bild
                        <div className={styles.tooSmallScreenInfo}>
                            Du ser bara skillnad på de olika storlekarna om du har en större skärm (tex. en dator eller
                            padda).
                        </div>
                    </div>
                </div>
            )}
            {getSiteThemeGeneration(props.site) === "V2" && (
                <>
                    <div className={styles.sizeWrapper}>
                        <div
                            className={size === imageSizeV2.LARGE ? styles.sizeActive : styles.size}
                            onClick={() => changeSize(imageSizeV2.LARGE)}
                        >
                            Stor bild
                        </div>
                        <div
                            className={size === imageSizeV2.MEDIUM ? styles.sizeActive : styles.size}
                            onClick={() => changeSize(imageSizeV2.MEDIUM)}
                        >
                            Mellanstor bild
                        </div>
                        <div
                            className={size === imageSizeV2.SMALL ? styles.sizeActive : styles.size}
                            onClick={() => changeSize(imageSizeV2.SMALL)}
                        >
                            Liten bild
                        </div>
                    </div>
                    <div className={styles.smallScreenInfoImageSizesV2}>
                        Beroende på tema syns bara skillnad på de olika storlekarna om du har en större skärm (tex. en
                        dator eller padda).
                    </div>
                </>
            )}
        </>
    );
}
