import React from "react";
import WebFont from "webfontloader";
import { IFonts } from "../../interfaces/Site";
import { getFontExternalLoadName } from "../../utils/LoadSiteFonts";
import styles from "./EditFont.module.scss";
import { FontItem } from "./FontItem/FontItem";
import { TFontHeading, TFontText, fontHeadings, fontTexts } from "../../enums/font";

interface IProps {
    fonts: IFonts;
    setFont: (font: TFontHeading | TFontText, fontType: "heading" | "text") => void;
}

export class EditFont extends React.Component<IProps> {
    componentDidMount() {
        const allFonts = [...this.fontTextCollection, ...this.fontHeadingCollection];

        const fontsToLoad = allFonts
            .map((font) => {
                return getFontExternalLoadName(font);
            })
            .filter((font) => {
                return !!font;
            });

        WebFont.load({
            google: {
                families: fontsToLoad,
            },
        });
    }

    fontHeadingCollection = fontHeadings;

    fontTextCollection = fontTexts;

    setHeadingFont = (font: TFontHeading) => {
        this.props.setFont(font, "heading");
    };

    setTextFont = (font: TFontText) => {
        this.props.setFont(font, "text");
    };

    render() {
        return (
            <div className={styles.colors}>
                <div className={styles.colorsColumn}>
                    {this.fontHeadingCollection.map((font) => (
                        <FontItem
                            fontSize="28px"
                            text="Typsnitt rubrik"
                            currentFont={this.props.fonts.heading}
                            key={font + "heading"}
                            setFont={this.setHeadingFont}
                            font={font}
                        />
                    ))}
                </div>
                <div className={styles.colorsColumn}>
                    {this.fontTextCollection.map((font) => (
                        <FontItem
                            fontSize="16px"
                            text="Typsnitt för brödtext, det vill säga längre texter."
                            currentFont={this.props.fonts.text}
                            key={font + "text"}
                            setFont={this.setTextFont}
                            font={font}
                        />
                    ))}
                </div>
            </div>
        );
    }
}
