import React from "react";
import styles from "./BackgroundGraphics.module.scss";
import GreenAndGoldFlowers from "./GreenAndGoldFlowers.png";
import { TSiteGraphicType } from "../../SiteGraphic";

export const siteGraphicsBackgroundGraphics = ["BACKGROUND_IMAGE_GREEN_AND_GOLD_FLOWERS"] as const;
export type TSiteGraphicsBackgroundGraphic = (typeof siteGraphicsBackgroundGraphics)[number];

interface IProps {
    backgroundGraphic: TSiteGraphicsBackgroundGraphic;
    editAvailable: boolean;
    type: TSiteGraphicType;
}

export function BackgroundGraphic(props: IProps) {
    let url, elements;
    if (props.backgroundGraphic === "BACKGROUND_IMAGE_GREEN_AND_GOLD_FLOWERS") {
        url = GreenAndGoldFlowers;
        elements = (
            <>
                <img src={url} alt="" className={styles.imageTopRight} />
                <img src={url} alt="" className={styles.imageBottomLeft} />
            </>
        );
    } else {
        throw new Error("No background image named " + props.backgroundGraphic);
    }

    return (
        <div className={`${styles.background} ${props.editAvailable ? styles.editAvailable : ""}  ${styles[props.type]} ${styles[props.backgroundGraphic]}`}>
            <div className={styles.innerWrapper}>{elements}</div>
        </div>
    );
}
