import React, { useState, useRef } from "react";
import Axios from "axios";
import ReactCrop, { centerCrop, makeAspectCrop, Crop } from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";
import styles from "./CropImage.module.scss";
import { IPrintableContentImage } from "../../../../../../../../interfaces/IPrintableCard";
import { IError } from "../../../../../../../../../../../../../../../../../App";
import Loading from "../../../../../../../../../../../../../../../../components/Loading/Loading";
import ShowError from "../../../../../../../../../../../../../../../../components/ShowError/ShowError";
import Button from "../../../../../../../../../../../../../../../../components/button/Button";
import { getPrintImageSrc } from "../../../../utils/getPrintImageSrc";

function centerAspectCrop(mediaWidth: number, mediaHeight: number, aspect: number) {
    return centerCrop(
        makeAspectCrop(
            {
                unit: "%",
                width: 90,
            },
            aspect,
            mediaWidth,
            mediaHeight
        ),
        mediaWidth,
        mediaHeight
    );
}

interface IProps {
    image: IPrintableContentImage["properties"] | Omit<IPrintableContentImage["properties"], "imageBeforeEdit">;
    updateImage: (properties: IPrintableContentImage["properties"]) => void;
    close: () => void;
    printId: string | "new";
    aspectRatio: number;
}

export function CropImage(props: IProps) {
    const imgRef = useRef<HTMLImageElement>(null);
    const [crop, setCrop] = useState<Crop>();
    const [completedCrop, setCompletedCrop] = useState<Crop>();
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState<IError | null>(null);

    function onImageLoad(e: React.SyntheticEvent<HTMLImageElement>) {
        const { width, height } = e.currentTarget;
        setCrop(centerAspectCrop(width, height, props.aspectRatio));
    }

    const convertPercentToPixelsOnImage = (percentCrop: Crop) => {
        if (!imgRef.current) {
            throw Error("Could not extract width and height from image");
        }
        const imageWidth = imgRef.current.naturalWidth;
        const imageHeight = imgRef.current.naturalHeight;
        const pixels = {
            height: Math.round((percentCrop.height / 100) * imageHeight),
            width: Math.round((percentCrop.width / 100) * imageWidth),
            x: Math.round((percentCrop.x / 100) * imageWidth),
            y: Math.round((percentCrop.y / 100) * imageHeight),
        };

        if (pixels.width + pixels.x > imageWidth) {
            pixels.width = imageWidth - pixels.x;
        }

        if (pixels.height + pixels.y > imageHeight) {
            pixels.height = imageHeight - pixels.y;
        }

        return pixels;
    };
    
    const handleSave = async () => {
        if (!completedCrop) {
            throw new Error("No crop data available when saving");
        }
        setIsLoading(true);
        try {
            const currentImageObject = props.image;
            const percentCrop = convertPercentToPixelsOnImage(completedCrop);
            const response = await Axios.put(
                process.env.REACT_APP_API_URL + "/api/prints/" + props.printId + "/image-crop",
                {
                    originalName: currentImageObject.originalName,
                    sourceImageUrl: getPrintImageSrc(props.image),
                    ...percentCrop,
                }
            );

            const newImageObject: IPrintableContentImage["properties"] = {
                isDemoImage: false,
                imageBeforeEdit: "imageBeforeEdit" in currentImageObject
                    ? currentImageObject.imageBeforeEdit
                    : currentImageObject,
                ...response.data,
            };
            props.updateImage(newImageObject);
            props.close();
        } catch (err) {
            setError({ text: "Misslyckades att beskära bilden" });
            setIsLoading(false);
        }
    };

    const showSaveButton = completedCrop && completedCrop.height && completedCrop.width;

    return (
        <div className={styles.wrapper}>
            {isLoading ? <Loading /> : null}
            <ShowError error={error} />
            <div className={styles.cropWrapper}>
                <ReactCrop
                    crop={crop}
                    onChange={(_, percentCrop) => setCrop(percentCrop)}
                    onComplete={(_, percentCrop) => setCompletedCrop(percentCrop)}
                    aspect={props.aspectRatio}
                >
                    <img ref={imgRef} alt="" className={styles.image} src={getPrintImageSrc(props.image)} onLoad={onImageLoad} />
                </ReactCrop>
            </div>
            <div className={styles.buttons}>
                <Button buttonText="Avbryt" type="secondary" callback={props.close} />
                {showSaveButton ? <Button buttonText="Spara" type="primary" callback={handleSave} /> : null}
            </div>
        </div>
    );
}
