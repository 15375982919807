import React from "react";
import ActionCurtain from "../../components/actionCurtain/ActionCurtain";
import Content, { IContentProps } from "../Content";
import { EditVideo } from "./Edit";
import styles from "./Video.module.scss";
import { Video } from "../components/Video/Video";
import { VideoThumbnail } from "../components/VideoThumbnail/VideoThumbnail";
import { getYoutubeIdFromUrl } from "../../utils/getYouTubeIdFromUrl";

interface IProps extends IContentProps {}

export class ContentVideo extends Content<IProps> {
    render() {
        const videoIdentifier = getYoutubeIdFromUrl(this.propValue()?.videoUrl);

        return this.props.editMode === true ? (
            <>
                {this.state.showEditCurtain === true && (
                    <ActionCurtain close={this.closeEditCurtain.bind(this)}>
                        <EditVideo
                            site={this.props.site}
                            propertyParent={this.props.propertyParent}
                            updateProperty={this.props.updateProperty}
                            updatePropertySuccess={this.closeEditCurtain.bind(this)}
                            property={this.props.property}
                            value={this.propValue()}
                        />
                    </ActionCurtain>
                )}
                <div className={styles.wrapper}>
                    {videoIdentifier ? (
                        <div
                            className={`${styles.clickable} ${styles.thumbnailWrapper}`}
                            onClick={this.openEditCurtain.bind(this)}
                        >
                            <VideoThumbnail videoIdentifier={videoIdentifier} />
                        </div>
                    ) : (
                        <div
                            className={styles.placeholder + " image-placeholder"}
                            onClick={this.openEditCurtain.bind(this)}
                        >
                            Ingen video uppladdad
                        </div>
                    )}
                </div>
            </>
        ) : videoIdentifier ? (
            <div className={styles.wrapper}>
                <Video autoStart={this.propValue().autoStart} videoIdentifier={videoIdentifier} />
            </div>
        ) : (
            ""
        );
    }
}
