import React, { ChangeEvent } from "react";
import { ISite } from "../../../interfaces/Site";
import styles from "./Checkbox.module.scss";

interface IProps {
    value?: boolean;
    labelOnChange: any;
    name: string;
    id: string;
    label: string;
    site: ISite;
}

export class CheckboxEdit extends React.Component<IProps> {
    refLabel: any;

    setRefForLabel = (that: any) => {
        this.refLabel = that;
    };

    labelOnChange = (e: ChangeEvent) => {
        let targetElement: HTMLElement = e.target as HTMLElement;
        let newLabel = targetElement.innerText;

        this.props.labelOnChange(newLabel);
    };

    render() {
        return (
            <span className={`${this.props.site ? styles[this.props.site.theme] : styles.wrapper}`}>
                <div className={styles.checkboxAndTextWrapper}>
                    <div className={styles.checkboxWrapper}>
                        <input
                            type="checkbox"
                            className={styles.checkbox}
                            id={this.props.id}
                            name={this.props.name}
                            checked={this.props.value}
                            disabled={true}
                        />
                    </div>
                    <div className={styles.textWrapper}>
                        <label
                            placeholder="Ingen text"
                            className={styles.labelEdit}
                            htmlFor={this.props.id}
                            ref={this.setRefForLabel}
                            contentEditable={true}
                            suppressContentEditableWarning={true}
                            onBlur={this.labelOnChange}
                        >
                            {this.props.label}
                        </label>
                    </div>
                </div>
            </span>
        );
    }
}

export default CheckboxEdit;
