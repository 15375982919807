import React from "react";
import EditButton from "../../components/EditButton/EditButton";
import { IconNewContentModule } from "../../icons/newContentModule";
import { IGiftRegistryReservations, IImage } from "../../interfaces/Site";
import { randomId } from "../../utils/Random";
import Content, { IContentProps } from "../Content";
import styles from "./GiftRegistry.module.scss";
import { GiftRegistryItem } from "./GiftRegistryItem/GiftRegistryItem";
import textFieldStyles from "./GiftRegistryItem/GiftRegistryItem.module.scss";

export interface IGiftRegistryItem {
    id: string;
    title: string;
    reservable: boolean;
    description?: string;
    quantity?: number;
    link?: string;
    image?: {
        type: string;
        id: string;
    };
}

interface IProps extends IContentProps {
    addOrUpdateImageToSite: (image: IImage) => void;
    updateGiftRegistryReservations: (giftRegistryReservations: IGiftRegistryReservations[]) => void;
    editAvailable: boolean;
}

export class ContentGiftRegistry extends Content<IProps> {
    randomId = Math.random().toString(36).substring(7);

    addItem = () => {
        let newPropValue = this.propValue() && this.propValue().length ? this.propValue() : [];

        newPropValue.push({
            id: "giftRegistryItem" + randomId(),
        });

        this.updateContent(newPropValue);
    };

    updateGiftRegistryItem = (item: IGiftRegistryItem, newItem: IGiftRegistryItem) => {
        const index = this.propValue().indexOf(item);
        const collection = this.propValue();
        collection[index] = newItem;
        this.updateContent(collection);
    };

    removeItem = (item: IGiftRegistryItem) => {
        const index = this.propValue().indexOf(item);
        const collection = this.propValue();
        collection.splice(index, 1);
        this.updateContent(collection);
    };

    render() {
        return (
            <div className={`${styles[this.props.site.theme]}`}>
                {this.propValue() && this.propValue().length
                    ? (this.propValue() as IGiftRegistryItem[]).map((giftRegistryItem) => (
                          <GiftRegistryItem
                              giftRegistryItem={giftRegistryItem}
                              className={textFieldStyles[this.props.site.theme]}
                              id={giftRegistryItem.id}
                              updateGiftRegistryItem={this.updateGiftRegistryItem.bind(this)}
                              removeItem={this.removeItem.bind(this)}
                              key={giftRegistryItem.id}
                              editMode={this.props.editMode}
                              addOrUpdateImageToSite={this.props.addOrUpdateImageToSite}
                              site={this.props.site}
                              moduleId={this.props.propertyParent._id}
                              updateGiftRegistryReservations={this.props.updateGiftRegistryReservations}
                              editAvailable={this.props.editAvailable}
                          />
                      ))
                    : ""}
                {this.props.editMode === true && (
                    <div className={styles.addNewRowWrapper}>
                        <EditButton callback={this.addItem}>
                            <>
                                <IconNewContentModule></IconNewContentModule>
                                Lägg till ny rad i önskelista
                            </>
                        </EditButton>
                    </div>
                )}
            </div>
        );
    }
}
