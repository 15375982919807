import React from "react";
import ActionCurtain from "../../components/actionCurtain/ActionCurtain";
import Button from "../../components/button/Button";
import EditButton from "../../components/EditButton/EditButton";
import FormWrapper from "../../components/form/FormWrapper/FormWrapper";
import TextField from "../../components/form/TextField/TextField";
import { IconDelete } from "../../icons/delete";
import { IconMail } from "../../icons/mail";
import { ISite } from "../../interfaces/Site";
import styles from "./SetReceiverMail.module.scss";

interface IProps {
    site: ISite;
    receiverCategory: "rsvp" | "speech";
    updateReceiverMail: any;
}

interface IState {
    open: boolean;
    newMail: string[];
}

export class SetReceiverMail extends React.Component<IProps, IState> {
    currentMail = (): string[] => {
        // Old sites may have only one mail as a string and not an array
        const fromSite = this.props.site!.mailReceivers![this.props.receiverCategory];
        if (typeof fromSite === "string") {
            return [fromSite];
        } else if (!fromSite) {
            return [""];
        } else if (Array.isArray(fromSite)) {
            return [...fromSite];
        } else {
            return [""];
        }
    };

    state: IState = {
        open: false,
        newMail: this.currentMail(),
    };

    open = () => {
        this.setState({
            open: true,
        });
    };

    close = () => {
        this.setState({
            open: false,
            newMail: this.currentMail(),
        });
    };

    updateReceiver = () => {
        let newMailArray = this.state.newMail.reduce(function (filtered: string[], mail) {
            if (mail) {
                mail = mail.trim();
                if (mail) {
                    filtered.push(mail);
                }
            }
            return filtered;
        }, []);
        this.props.updateReceiverMail(this.props.receiverCategory, newMailArray);
        this.setState({
            newMail: newMailArray,
        });
        this.close();
    };

    updateField = (value: string, name: string) => {
        const position = parseInt(name);
        const mailArray = this.state.newMail;
        mailArray[position] = value;
        this.setState({
            newMail: mailArray,
        });
    };

    addNew = () => {
        const mailArray = this.state.newMail;
        mailArray.push("");
        this.setState({
            newMail: mailArray,
        });
    };

    remove = (index: number) => {
        const mailArray = this.state.newMail;
        mailArray.splice(index, 1);
        this.setState({
            newMail: mailArray,
        });
    };

    getSendToSummary = () => {
        if (this.currentMail().length === 1) {
            return this.currentMail()[0];
        } else {
            return this.currentMail()[0] + " +" + (this.currentMail().length - 1);
        }
    };

    render() {
        return (
            <div className={styles.wrapper}>
                <EditButton callback={this.open}>
                    <>
                        {IconMail()}
                        Skickas till {this.getSendToSummary()}
                    </>
                </EditButton>
                {this.state.open ? (
                    <ActionCurtain title="Ändra mottagare" close={this.close}>
                        <>
                            <div className={styles.wrapper}>
                                <p>
                                    Välj vilken e-postadress du vill skicka formuläret till. <br />
                                    <b>
                                        Testa alltid formuläret när du har ändrat e-postadress så du vet att dina
                                        gästers information kommer fram!
                                    </b>
                                </p>

                                <FormWrapper
                                    submit={this.updateReceiver.bind(this)}
                                    data-test-id="form-set-receiver-mail"
                                >
                                    <div className={styles.formWrapper}>
                                        <div className={styles.rowsWrappers}>
                                            {this.state.newMail.map((mail, index) => (
                                                <div className={styles.rowWrapper} key={index}>
                                                    <div className={styles.textFieldWrapper}>
                                                        <TextField
                                                            email={true}
                                                            required={index === 0}
                                                            label={"E-post " + (index + 1)}
                                                            name={index.toString()}
                                                            id={
                                                                "emailReceiverMail" +
                                                                index +
                                                                this.props.receiverCategory
                                                            }
                                                            onChangeCallback={this.updateField}
                                                            value={mail}
                                                        ></TextField>
                                                    </div>
                                                    {index !== 0 && (
                                                        <button
                                                            className={styles.removeButton}
                                                            onClick={() => {
                                                                this.remove(index);
                                                            }}
                                                            type="button"
                                                        >
                                                            <IconDelete></IconDelete>
                                                        </button>
                                                    )}
                                                </div>
                                            ))}
                                        </div>
                                        <div className={styles.buttonsWrapper}>
                                            {this.state.newMail.length < 6 && (
                                                <div className={styles.addMoreButtonWrapper}>
                                                    <Button
                                                        callback={this.addNew}
                                                        buttonText="Lägg till fler"
                                                        type="secondary"
                                                        submit={false}
                                                    />
                                                </div>
                                            )}
                                            {this.state.newMail ? (
                                                <Button buttonText="Klar" type="primary" submit={true} />
                                            ) : (
                                                ""
                                            )}
                                        </div>
                                    </div>
                                </FormWrapper>
                            </div>
                        </>
                    </ActionCurtain>
                ) : (
                    ""
                )}
            </div>
        );
    }
}

export default SetReceiverMail;
