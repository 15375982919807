import { IModule } from "../interfaces/Module";
import { randomId } from "./Random";
import { findUniqueSlug, slugify } from "./Slug";

export const addModule = (moduleCollection: IModule[], moduleType: string, moduleToPutNewModelBefore?: IModule) => {
    const standardMenuName = "Ej namngivet";

    let slug = slugify(standardMenuName);
    slug = findUniqueSlug(slug, moduleCollection);

    const newModule: IModule = {
        module: moduleType,
        id: "CLIENTID" + randomId(),
        properties: {},
        hideInMenu: false,
        menuTitle: standardMenuName,
        slug: slug,
    };
    if (moduleToPutNewModelBefore) {
        const index = moduleCollection.indexOf(moduleToPutNewModelBefore);
        moduleCollection.splice(index, 0, newModule);
    } else {
        moduleCollection.push(newModule);
    }
    return { modules: moduleCollection, newModule };
};

export const deleteModule = (moduleCollection: IModule[], module: IModule) => {
    const index = moduleCollection.indexOf(module);
    moduleCollection.splice(index, 1);
    return moduleCollection;
};

export const moduleIsLast = (moduleCollection: IModule[], module: IModule) => {
    const index = moduleCollection.indexOf(module);
    return index === moduleCollection.length - 1;
};

export const moduleIsFirst = (moduleCollection: IModule[], module: IModule) => {
    const index = moduleCollection.indexOf(module);
    return index === 0;
};

export const moveModule = (moduleCollection: IModule[], module: IModule, indexAdjustment: number) => {
    const old_index = moduleCollection.indexOf(module);
    let new_index = old_index + indexAdjustment;

    if (new_index < 0) {
        new_index = 0;
    }

    if (new_index >= moduleCollection.length) {
        new_index = moduleCollection.length;
    }
    moduleCollection.splice(new_index, 0, moduleCollection.splice(old_index, 1)[0]);
    return moduleCollection;
};
