import React, { useState } from "react";
import Button from "../../components/button/Button";
import FormWrapper from "../../components/form/FormWrapper/FormWrapper";
import TextField from "../../components/form/TextField/TextField";
import { ISite } from "../../interfaces/Site";
import styles from "./Edit.module.scss";
import { Video } from "../components/Video/Video";
import { Switch } from "../../components/form/Switch/Switch";
import { getYoutubeIdFromUrl } from "../../utils/getYouTubeIdFromUrl";

export interface IContentVideoValue {
    videoUrl: string;
    type: "YouTube";
    autoStart: boolean;
}

interface IProps {
    updateProperty: (module: any, property: string, content: IContentVideoValue) => void;
    property: string;
    propertyParent: any;
    updatePropertySuccess: () => void;
    value: IContentVideoValue;
    site: ISite;
}

export function EditVideo(props: IProps) {
    const [newVideoValues, setNewVideoValues] = useState(Object.assign({ type: "YouTube" }, props.value));

    const videoIdentifier = getYoutubeIdFromUrl(newVideoValues.videoUrl);

    const handleValueChange = (value: string, name: keyof IContentVideoValue) => {
        setNewVideoValues({
            ...newVideoValues,
            [name]: value,
        });
    };

    const handleDone = () => {
        props.updateProperty(props.propertyParent, props.property, newVideoValues);
        props.updatePropertySuccess();
    };

    return (
        <FormWrapper submit={handleDone}>
            <div className={styles.wrapper}>
                <div className={styles.formWrapper}>
                    <div className={styles.formFieldWrapper}>
                        <TextField
                            label="Url till Youtube-klipp"
                            name="videoUrl"
                            id="videoUrl"
                            value={newVideoValues.videoUrl}
                            onChangeCallback={handleValueChange}
                            placeholder="https://www.youtube.com/watch?v=OOgjmSqd2KE"
                        />
                    </div>
                    <div className={styles.formFieldWrapper}>
                        <Switch
                            label="Autostart av klipp"
                            name="autoStart"
                            handleChange={handleValueChange}
                            value={newVideoValues.autoStart}
                            id="autoStartSwitch"
                            dark={true}
                        />
                    </div>
                </div>
                {videoIdentifier ? (
                    <div className={styles.videoWrapper}>
                        <Video videoIdentifier={videoIdentifier} autoStart={false} />
                    </div>
                ) : null}
            </div>
            <div className={styles.bottomButtons}>
                <Button buttonText="Klar" type="primary" submit={true} fullWidth={true} />
            </div>
        </FormWrapper>
    );
}
