import React from "react";
import styles from "./Eucalyptus.module.scss";
import imageUrl from "./Eucalyptus.webp";
import imageUrlPngFallback from "./Eucalyptus.png";
import { TSiteGraphicType } from "../../SiteGraphic";

export interface IProps {
    editAvailable: boolean;
    type: TSiteGraphicType;
}

export function Eucalyptus(props: IProps) {
    return (
        <div
            className={`${props.editAvailable ? styles.editAvailable : ""} ${styles.outerWrapper} ${
                styles[props.type]
            }`}
        >
            <div className={`${styles.wrapper}`}>
                <div className={styles.graphic1}>
                    <picture>
                        <source srcSet={imageUrl} type="image/webp" />
                        <source srcSet={imageUrlPngFallback} type="image/png" />
                        <img srcSet={imageUrlPngFallback} alt="" />
                    </picture>
                </div>
                <div className={styles.graphic2}>
                    <picture>
                        <source srcSet={imageUrl} type="image/webp" />
                        <source srcSet={imageUrlPngFallback} type="image/png" />
                        <img srcSet={imageUrlPngFallback} alt="" />
                    </picture>
                </div>
            </div>
        </div>
    );
}
