import { HSLColor } from "react-color";
import { IColor } from "../interfaces/Site";

export const colorToCssValue = (color: HSLColor | IColor, component?: "h" | "s" | "l" | "a"): string => {
    const a = color.a === undefined ? 1 : color.a;
    if (component === "h") {
        return color.h.toString();
    } else if (component === "s") {
        return color.s * 100 + "%";
    } else if (component === "l") {
        return color.l * 100 + "%";
    } else if (component === "a") {
        return a.toString();
    } else {
        return "hsla(" + color.h + ", " + color.s * 100 + "%, " + color.l * 100 + "%, " + a + ")";
    }
};
