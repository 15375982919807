import React from "react";
import styles from "./ActionBar.module.scss";

interface IProps {
    children: any;
    primary?: boolean;
    multipleRows?: boolean;
}

export function ActionBar(props: IProps) {
    if (props.primary) {
        return (
            <>
                <div className={styles.spacer}></div>
                <div className={styles.wrapperPrimary}>
                    <div className={`${styles.items} ${props.multipleRows ? styles.multipleRows : ""}`}>
                        {props.children}
                    </div>
                </div>
            </>
        );
    } else {
        return (
            <div className={`${styles.wrapper}`}>
                <div className={`${styles.items} ${props.multipleRows ? styles.multipleRows : ""}`}>
                    {props.children}
                </div>
            </div>
        );
    }
}
