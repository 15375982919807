import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js/pure"; // Note /pure on the end, otherwise stripe loads on all pages everywhere.
import Axios from "axios";
import { useEffect, useState } from "react";
import { Product } from "../../../../../utils/products";
import { logError } from "../../../../utils/LogError";
import StripeForm from "./components/StripeForm/StripeForm";
import styles from "./Stripe.module.scss";

interface IProps {
    setLoading: (loading: boolean, message?: string) => void;
    showError: (errorMessage: string, errorObject: any) => void;
    paymentDone: (paymentReference: string) => void;
}

interface IPropsSite extends IProps {
    siteId: string;
    paymentProduct: Product;
}

interface IPropsOrder extends IProps {
    orderId: string;
}

export function Stripe(props: IPropsSite | IPropsOrder) {
    const [clientSecret, setClientSecret] = useState<string | null>(null);
    const [stripe, setStripe] = useState<Promise<any> | null>(null);
    const [stripeId, setStripeId] = useState<string | null>(null);

    let pollCount = 0;
    let pollingTimeout: any = null;

    useEffect(() => {
        props.setLoading(true);

        let endpoint, postData;
        if ("orderId" in props) {
            endpoint = "/orders/" + props.orderId + "/payment/stripe";
        } else {
            endpoint = "/sites/" + props.siteId + "/payment/stripe";
            postData = {
                paymentProduct: props.paymentProduct,
            };
        }

        Axios.post(process.env.REACT_APP_API_URL + "/api" + endpoint, postData)
            .then((response) => {
                props.setLoading(false);
                setClientSecret(response.data.client_secret);
                setStripeId(response.data.reference);
                setStripe(
                    loadStripe(response.data.stripe_public_key, {
                        locale: "sv",
                    })
                );
            })
            .catch((err) => {
                props.setLoading(false);
                props.showError("Misslyckades att hämta betalningsnyckel", err.response);
            });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const paymentDone = () => {
        props.setLoading(true, "Inväntar bekräftelse från Stripe");
        if (!stripeId) {
            throw new Error("No stripe id when payment done");
        }
        pollStatus(stripeId, 3000);
    };

    useEffect(() => {
        return () => {
            pollingTimeout && clearTimeout(pollingTimeout);
        };
    }, [pollingTimeout]);

    const pollStatus = (id: string, wait: number) => {
        pollCount = pollCount + wait;
        pollingTimeout = setTimeout(() => {
            if (pollCount > 150000) {
                // 150000 = 2,5 minuter
                props.showError(
                    "Vi fick aldrig någon bekräftelse på betalningen av Stripe. Om pengar har dragits från ditt konto, kontakta oss.",
                    {}
                );
                logError("payment.stripe", {}, 3, "Timed out (localy)");
            } else {
                let endpoint;
                if ("orderId" in props) {
                    endpoint = "/orders/" + props.orderId + "/payment/stripe/";
                } else {
                    endpoint = "/sites/" + props.siteId + "/payment/stripe/";
                }
                Axios.get(process.env.REACT_APP_API_URL + "/api" + endpoint + stripeId)
                    .then((response: any) => {
                        const status = response.data.paymentConfirmed;
                        if (!stripeId) {
                            throw new Error("No stripe id when payment done");
                        }
                        if (status === false) {
                            pollStatus(stripeId, 3000);
                        } else if (status === true) {
                            props.paymentDone(stripeId);
                        }
                    })
                    .catch((err) => {
                        props.showError("Misslyckades att kontrollera betalning från stripe", err.response);
                        logError("payment.stripe", err, 3, "Misslyckades att kontrollera betalning från stripe");
                    });
            }
        }, wait);
    };

    return stripe && clientSecret ? (
        <div className={styles.wrapper}>
            <Elements stripe={stripe}>
                <StripeForm showError={props.showError} paymentDone={paymentDone} clientSecret={clientSecret} />
            </Elements>

            <p className={styles.paymentInfo}>
                <strong>Hög säkerhet.</strong> Betalningen sker tryggt och säkert med betalningsleverantören Stripe.
                Dina kortuppgifter skyddas av SSL-kryptering och skickas direkt från din dator till Stripe utan oss som
                mellanhand.
            </p>
        </div>
    ) : (
        <div></div>
    );
}
