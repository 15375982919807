import Axios from "axios";
import React from "react";
import FormWrapper from "../../../generator/components/form/FormWrapper/FormWrapper";
import TextField from "../../../generator/components/form/TextField/TextField";
import styles from "./RequestResetPassword.module.scss";

interface IProps {
    showError: any;
}

interface IState {
    email: String;
    done: boolean;
}

export class RequestResetPassword extends React.Component<IProps> {
    state: IState = {
        email: "",
        done: false,
    };

    onChangeEmail = (email: String) => {
        this.setState({ email });
    };

    submitResetPassword = () => {
        Axios.post(process.env.REACT_APP_API_URL + "/api/accounts/reset-password/", {
            mail: this.state.email,
        })
            .then((response) => {
                this.setState({
                    done: true,
                });
            })
            .catch((error) => {
                this.props.showError("Misslyckades att återställa lösenord", error.response);
            });
    };

    render() {
        if (this.state.done === true) {
            return (
                <p>
                    Om vi har ett konto med e-postadressen {this.state.email} har vi skickat en återställningslänk till
                    den e-postadressen. Länken är giltig i 24 timmar.
                </p>
            );
        }
        return (
            <div className={styles.wrapper}>
                <FormWrapper submit={this.submitResetPassword.bind(this)}>
                    <TextField
                        required={true}
                        label="E-post"
                        name="email"
                        id="email"
                        onChangeCallback={this.onChangeEmail.bind(this)}
                    ></TextField>
                    <input className={styles.button} type="submit" value="Återställ lösenord" />
                </FormWrapper>
            </div>
        );
    }
}

export default RequestResetPassword;
