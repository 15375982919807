import React from "react";
import { convertLineBreaksToHtmlBreaks } from "../../utils/convertLineBreaksToHtmlBreaks";
import Content, { IContentProps } from "../Content";
import styles from "./H2.module.scss";

export class ContentH2 extends Content<IContentProps> {
    render() {
        return this.props.editMode === true ? (
            <h2
                contentEditable
                className={styles.editable}
                placeholder="Ingen rubrik"
                suppressContentEditableWarning={true}
                onBlur={this.onContentChange}
                dangerouslySetInnerHTML={convertLineBreaksToHtmlBreaks(this.propValue())}
            />
        ) : (
            <h2 dangerouslySetInnerHTML={convertLineBreaksToHtmlBreaks(this.propValue())} />
        );
    }
}

export default ContentH2;
