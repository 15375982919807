import React from "react";
import { MovingDotsBackground } from "./components/MovingDotsBackground/MovingDotsBackground";
import { Eucalyptus } from "./components/Eucalyptus/Eucalyptus";
import {
    CornerGraphics,
    TSiteGraphicsCornerGraphics,
    siteGraphicsCornerGraphics,
} from "./components/CornerGraphics/CornerGraphics";
import {
    BackgroundImage,
    TSiteGraphicsBackgroundImage,
    siteGraphicsBackgroundImages,
} from "./components/BackgroundImage/BackgroundImage";
import { LeafsMix } from "./components/LeafsMix/LeafsMix";
import {
    BackgroundGraphic,
    TSiteGraphicsBackgroundGraphic,
    siteGraphicsBackgroundGraphics,
} from "./components/BackgroundGraphics/BackgroundGraphics";

export const siteGraphics = [
    "MOVING_DOTS_BACKGROUND",
    "EUCALYPTUS",
    "LEAFS_MIX",
    ...siteGraphicsCornerGraphics,
    ...siteGraphicsBackgroundImages,
    ...siteGraphicsBackgroundGraphics,
] as const;
export type TSiteGraphic = (typeof siteGraphics)[number];

export type TSiteGraphicType = "SITE" | "PRINT_PAGE_1" | "PRINT_PAGE_2" | "THUMBNAIL";

interface IProps {
    siteGraphic: TSiteGraphic;
    editAvailable: boolean;
    type: TSiteGraphicType;
}

function isCornerGraphic(siteGraphic: TSiteGraphic): siteGraphic is TSiteGraphicsCornerGraphics {
    return siteGraphic && !!siteGraphicsCornerGraphics.find((x) => x === siteGraphic);
}

function isBackgroundImage(siteGraphic: TSiteGraphic): siteGraphic is TSiteGraphicsBackgroundImage {
    return siteGraphic && !!siteGraphicsBackgroundImages.find((x) => x === siteGraphic);
}

function isBackgroundGraphic(siteGraphic: TSiteGraphic): siteGraphic is TSiteGraphicsBackgroundGraphic {
    return siteGraphic && !!siteGraphicsBackgroundGraphics.find((x) => x === siteGraphic);
}

export function SiteGraphic(props: IProps) {
    if (!props.siteGraphic) {
        return null;
    }
    return (
        <>
            {props.siteGraphic === "MOVING_DOTS_BACKGROUND" ? <MovingDotsBackground type={props.type} /> : null}
            {props.siteGraphic === "EUCALYPTUS" ? (
                <Eucalyptus editAvailable={props.editAvailable} type={props.type} />
            ) : null}
            {props.siteGraphic === "LEAFS_MIX" ? (
                <LeafsMix editAvailable={props.editAvailable} type={props.type} />
            ) : null}
            {isCornerGraphic(props.siteGraphic) ? (
                <CornerGraphics
                    editAvailable={props.editAvailable}
                    type={props.type}
                    cornerGraphics={props.siteGraphic}
                />
            ) : null}
            {isBackgroundImage(props.siteGraphic) ? (
                <BackgroundImage
                    editAvailable={props.editAvailable}
                    type={props.type}
                    backgroundImage={props.siteGraphic}
                />
            ) : null}
            {isBackgroundGraphic(props.siteGraphic) ? (
                <BackgroundGraphic
                    editAvailable={props.editAvailable}
                    type={props.type}
                    backgroundGraphic={props.siteGraphic}
                />
            ) : null}
        </>
    );
}
