import React from "react";
import Helmet from "react-helmet";
import { RouteComponentProps } from "react-router";
import { getBrand } from "../../../generator/utils/getBrand";
import RequestResetPassword from "../../modules/RequestResetPassword/RequestResetPassword";
import ResetPassword from "../../modules/ResetPassword/ResetPassword";
import styles from "./ViewResetPassword.module.scss";

interface IProps extends RouteComponentProps<any> {
    showError: any;
}

interface IState {}

export class ViewResetPassword extends React.Component<IProps, IState> {
    state: IState = {};

    render() {
        return (
            <div className={styles.wrapper}>
                <Helmet>
                    <title>Återställ lösenord - {getBrand()}</title>
                </Helmet>
                <h1>Återställ lösenord</h1>
                {this.props.match.params.token ? (
                    <ResetPassword
                        showError={this.props.showError}
                        token={this.props.match.params.token}
                    ></ResetPassword>
                ) : (
                    <RequestResetPassword showError={this.props.showError}></RequestResetPassword>
                )}
            </div>
        );
    }
}
