import styles from "./EditMore.module.scss";
import { Drawer } from "../../../../components/Drawer/Drawer";
import { ISite } from "../../../../interfaces/Site";
import { EditHost } from "../../../../modules/EditHost/EditHost";
import { EditPassword } from "../../../../modules/EditPassword/EditPassword";
import { SiteQrCode } from "../../../../modules/SiteQrCode/SiteQrCode";
import { EditMetaNoindex } from "../../../../modules/EditMetaNoindex/EditMetaNoindex";

interface IProps {
    site: ISite;
    close: () => void;
    open: boolean;
    portalNode?: JSX.Element;
    showError: () => void;
    quickStart: boolean;
}

export function EditMore(props: IProps) {
    return (
        <Drawer title="Mer" drawerId="editMore" close={props.close} open={props.open} portalNode={props.portalNode}>
            <>
                <div className={styles.block}>
                    <h3 className={styles.title}>Adress</h3>
                    <EditHost site={props.quickStart ? undefined : props.site} />
                </div>
                <div className={styles.block}>
                    <h3 className={styles.title}>Lösenord för gästerna</h3>
                    <EditPassword showError={props.showError} quickStart={props.quickStart} site={props.site} />
                </div>
                <div className={styles.block}>
                    <h3 className={styles.title}>QR-kod</h3>
                    <SiteQrCode showError={props.showError} site={props.quickStart ? undefined : props.site} />
                </div>
                <div className={styles.block}>
                    <h3 className={styles.title}>Synlighet i sökmotorer</h3>
                    <EditMetaNoindex showError={props.showError} quickStart={props.quickStart} site={props.site} />
                </div>
            </>
        </Drawer>
    );
}
