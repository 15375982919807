import { useEffect, useState } from "react";
import styles from "./SelectShipmentMethod.module.scss";
import { IError } from "../../../../../../../App";
import Loading from "../../../../../../components/Loading/Loading";
import ShowError from "../../../../../../components/ShowError/ShowError";
import { IShipmentMethod, getShipmentMethods } from "../../../../../../services/OrderService";
import { IShippingAddress } from "../../../../../../interfaces/Order";
import { logError } from "../../../../../../utils/LogError";

interface IProps {
    setShipmentMethod: (shipmentMethod: IShipmentMethod) => void;
    shipmentMethod: IShipmentMethod | null;
    printId: string;
    quantity: number;
    envelopes: "none" | "standard" | "premium";
    shippingAddress: IShippingAddress;
}

export const getBetterShipmentMethodName = (name: string) => {
    if (name === "Parcelshop domestic B2C") {
        return "Schenker";
    } else if (name === "PostenSE Private Parcel") {
        return "PostNord";
    } else if (name === "DHL Express Domestic") {
        return "DHL Express";
    } else if (name === "My Pack Collect") {
        return "PostNord";
    } else {
        logError("printcheckout.selectshipmentMethod.1.missingshopmentmethodname", { name }, 3);
        return name;
    }
};

const getDaysText = (shipmentMethod: IShipmentMethod) => {
    if (shipmentMethod.minDeliveryDays === shipmentMethod.maxDeliveryDays) {
        return shipmentMethod.minDeliveryDays;
    } else {
        return shipmentMethod.minDeliveryDays + "-" + shipmentMethod.maxDeliveryDays;
    }
};

const getDeliveryLocationText = (shipmentMethod: IShipmentMethod) => {
    if (shipmentMethod.type === "express") {
        return "ditt hem";
    } else if (shipmentMethod.type === "normal") {
        return "ditt hem";
    } else if (shipmentMethod.type === "pick_up") {
        return "ditt närmaste ombud";
    } else {
        logError("printcheckout.selectshipmentMethod.2.missingdeliverylocationtext", { shipmentMethod }, 3);
        return shipmentMethod.type;
    }
};

export function SelectShipmentMethod({
    setShipmentMethod,
    shipmentMethod,
    printId,
    quantity,
    envelopes,
    shippingAddress,
}: IProps) {
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState<IError | null>(null);

    useEffect(() => {
        const getShipmentMethodsForSelect = async () => {
            setLoading(true);
            try {
                const data = await getShipmentMethods({
                    shippingAddress,
                    print: printId,
                    quantity: quantity,
                    envelopes: envelopes,
                });

                const selectedShipmentMethod = data.shipmentMethods.sort((a, b) => a.price - b.price)[0];

                setShipmentMethod(selectedShipmentMethod);
            } catch (error) {
                setError({ text: "Misslyckades att hämta leveransalternativ", response: (error as any).response });
            }
            setLoading(false);
        };
        getShipmentMethodsForSelect();
    }, [envelopes, printId, quantity, setShipmentMethod, shippingAddress]);

    if (loading) {
        return <Loading />;
    }

    if (error) {
        return <ShowError error={error} />;
    }
    if (!shipmentMethod) {
        throw new Error("Shipmentmethods not available but not loading state");
    }

    return (
        <>
            <div className={`${styles.wrapper}`}>
                Beräknad leverans inom <strong>{getDaysText(shipmentMethod)} dagar</strong> med{" "}
                {getBetterShipmentMethodName(shipmentMethod.name)} till {getDeliveryLocationText(shipmentMethod)}.{" "}
                <br />
                <i>Observera att leveransdatum är en uppskattning och aldrig kan garanteras.</i>
            </div>
        </>
    );
}
