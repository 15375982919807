import { useState } from "react";
import { Descendant } from "slate";
import { SlateEditor } from "./components/SlateEditor/SlateEditor";
import { deserializeHtmlToSlate } from "./utils/deserializeHtmlToSlate";
import { serializeSlateToHtml } from "./utils/serializeSlateToHtml";

interface IProps {
    onContentChange: (value: string) => void;
    initialValue: string;
}

const emptyEditorValue: Descendant[] = [
    {
        type: "paragraph",
        children: [{ text: "" }],
    },
];

export const RichTextEditor = (props: IProps) => {
    const [slateValue, setSlateValue] = useState<Descendant[] | null>();
    if (!slateValue) {
        if (props.initialValue) {
            const document = new DOMParser().parseFromString(props.initialValue, "text/html");
            setSlateValue(deserializeHtmlToSlate(document.body));
        } else {
            setSlateValue(emptyEditorValue);
        }
    }

    const handleChange = (value: Descendant[]) => {
        const singleParent = { children: value };
        const serialized = serializeSlateToHtml(singleParent);
        props.onContentChange(serialized);
    };

    if (slateValue) {
        return (
            <div>
                <SlateEditor handleChange={handleChange} value={slateValue} />
            </div>
        );
    } else {
        return null;
    }
};
