import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { useState } from "react";
import { IRvspPerson } from "../../../../../../interfaces/Rvsp";
import { deprecatedRsvpValues, ICustomValue, IDeprecatedValueObject } from "../../ViewRvsp";
import { RsvpQuestionText } from "./components/RsvpQuestionText/RsvpQuestionText";
import { RsvpSelectYesOrNoQuestionFilter } from "./components/RsvpSelectYesOrNoQuestionFilter/RsvpSelectYesOrNoQuestionFilter";
import styles from "./RsvpQuestions.module.scss";

interface IProps {
    rsvps: IRvspPerson[];
    getCustomValueWithLabel: (rsvp: IRvspPerson, label: string) => string;
    customValues: ICustomValue[];
    deprecatedValues: IDeprecatedValueObject[];
}

export function RsvpQuestions(props: IProps) {
    const [expanded, setExpanded] = useState<boolean | null | string>(null);
    const [questionFilter, setQuestionFilter] = useState<{ question: string; answer: string } | null>(null);

    const rsvpsFiltered = props.rsvps.filter((rvsp) => {
        if (questionFilter) {
            if (questionFilter.question === "deprecatedWeddingYesOrNo") {
                if (questionFilter.answer === "Ja") {
                    return rvsp.weddingYesOrNo === true;
                } else {
                    return rvsp.weddingYesOrNo === false;
                }
            }
            return props.getCustomValueWithLabel(rvsp, questionFilter.question) === questionFilter.answer;
        } else {
            return true;
        }
    });

    const handleChange = (panel: string) => (event: any, isExpanded: boolean) => {
        setExpanded(isExpanded ? panel : false);
    };

    const getChoiceQuestionsResult = (customValue: ICustomValue) => {
        const values = rsvpsFiltered.map((rvsp): string => {
            return props.getCustomValueWithLabel(rvsp, customValue.label);
        });

        if (values && values.length) {
            let resultSet = values.reduce(function (acc: any, curr: any) {
                acc[curr] ? acc[curr]++ : (acc[curr] = 1);
                return acc;
            }, {});

            return Object.entries(resultSet).map((result) => {
                return (
                    <div key={result[0]}>
                        <>
                            {result[0] || "Inget svar"}: {result[1]} st
                        </>
                    </div>
                );
            });
        } else {
            return null;
        }
    };

    const getUniqueDeprecatedEmail = () => {
        const mails = rsvpsFiltered.map((rvsp): string => {
            return rvsp.mail!;
        });

        const uniqueMails = Array.from(new Set(mails));

        if (uniqueMails && uniqueMails.length) {
            return uniqueMails.join(", ");
        } else {
            return "Inga e-postadresser";
        }
    };

    const deprecatedMail = () => (
        <ExpansionPanel
            key="emailDeprecated"
            expanded={expanded === "emailDeprecated"}
            onChange={handleChange("emailDeprecated")}
        >
            <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                <div>E-post</div>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>
                <div className={styles.questionValueWrapper}>
                    {questionFilter ? (
                        <div className={styles.label}>
                            Svar av personer som svarat "{questionFilter?.answer}" på frågan "
                            {questionFilter?.question.replace("deprecatedWeddingYesOrNo", "Kommer")}
                            ":
                        </div>
                    ) : null}
                    <div>{getUniqueDeprecatedEmail()}</div>
                </div>
            </ExpansionPanelDetails>
        </ExpansionPanel>
    );

    return (
        <div>
            <RsvpSelectYesOrNoQuestionFilter
                rsvps={props.rsvps}
                customValues={props.customValues}
                getCustomValueWithLabel={props.getCustomValueWithLabel}
                setQuestionFilter={setQuestionFilter}
            />
            {props.deprecatedValues.filter((value) => value.key === deprecatedRsvpValues.MAIL).length
                ? deprecatedMail()
                : null}
            {props.customValues.map((customValue: ICustomValue) => (
                <ExpansionPanel
                    key={customValue.id}
                    expanded={expanded === customValue.id}
                    onChange={handleChange(customValue.id)}
                >
                    <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                        <div>{customValue.label}</div>
                    </ExpansionPanelSummary>
                    <ExpansionPanelDetails>
                        <div className={styles.questionValueWrapper}>
                            {customValue.type === "YesOrNo" || customValue.type === "RadioGroup" ? (
                                <>
                                    {questionFilter ? (
                                        <div className={styles.label}>
                                            Svar av personer som svarat "{questionFilter?.answer}" på frågan "
                                            {questionFilter?.question.replace("deprecatedWeddingYesOrNo", "Kommer")}":
                                        </div>
                                    ) : null}
                                    <div>{getChoiceQuestionsResult(customValue)}</div>
                                </>
                            ) : null}
                            {customValue.type === "TextField" && (
                                <RsvpQuestionText
                                    rsvps={rsvpsFiltered}
                                    getCustomValueWithLabel={props.getCustomValueWithLabel}
                                    customValue={customValue}
                                    questionFilter={questionFilter}
                                />
                            )}
                        </div>
                    </ExpansionPanelDetails>
                </ExpansionPanel>
            ))}
        </div>
    );
}
