import React from "react";
import { getBrand, getBrandLogotype } from "../../../generator/utils/getBrand";
import styles from "./Header.module.scss";

interface IProps {}

interface IState {
    toggled: boolean;
}

export class Header extends React.Component<IProps, IState> {
    render() {
        return (
            <>
                <div className={styles.spacer}></div>
                <div className={styles.wrapper}>
                    <div className={styles.innerWrapper}>
                        <a href={process.env.REACT_APP_LANDING_URL} className={styles.logotypeWrapper}>
                            {process.env.REACT_APP_BRAND === "wedding" ? (
                                <>
                                    <div>{getBrandLogotype()}</div>
                                    <div className={styles.logotypeText}>{getBrand()}</div>
                                </>
                            ) : (
                                getBrandLogotype()
                            )}
                        </a>
                        <div className={styles.menu}>
                            <a href={process.env.REACT_APP_LANDING_URL}>
                                <span className={styles.inner}>Till startsida</span>
                            </a>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

export default Header;
