import React from "react";
import { convertLineBreaksToHtmlBreaks } from "../../utils/convertLineBreaksToHtmlBreaks";
import Content, { IContentProps } from "../Content";
import styles from "./H3.module.scss";

export class ContentH3 extends Content<IContentProps> {
    render() {
        return this.props.editMode === true ? (
            <h3
                contentEditable
                className={styles.editable}
                placeholder="Ingen rubrik"
                suppressContentEditableWarning={true}
                onBlur={this.onContentChange}
                dangerouslySetInnerHTML={convertLineBreaksToHtmlBreaks(this.propValue())}
            />
        ) : (
            <h3 className={styles.notEditable} dangerouslySetInnerHTML={convertLineBreaksToHtmlBreaks(this.propValue())} />
        );
    }
}

export default ContentH3;
