import { useEffect, useRef, useState } from "react";
import { IPeopleListItem } from "../../contentModules/PeopleList/PeopleList";
import { IListItem } from "../../contentModules/TextAndImageList/TextAndImageList";
import { IconDelete } from "../../icons/delete";
import { IconMoveDownContentModule } from "../../icons/moveDownContentModule";
import { IconMoveUpContentModule } from "../../icons/moveUpContentModule";
import { IconNewContentModule } from "../../icons/newContentModule";
import { IconToggleContentModuleActionBar } from "../../icons/toggleContentModuleActionBar";
import Confirmation from "../confirmation/Confirmation";
import styles from "./ItemActionBar.module.scss";

type AllowedItemTypes = IListItem | IPeopleListItem;

type Props<Item> = {
    itemName: string; // eg. Person, Ruta
    item: Item;
    items: Item[];
    updateList: (listItems: Item[]) => void;
    getNewItem: () => Item;
    setHighligtItem: (highlight: boolean) => void;
};

export function ItemActionBar<Item extends AllowedItemTypes>(props: Props<Item>) {
    const [isOpen, setIsOpen] = useState(false);
    const [deleteConfirmationIsOpen, setDeleteConfirmationIsOpen] = useState(false);
    const pickerWrapper = useRef<HTMLDivElement>(null);

    const setHighligtItem = props.setHighligtItem;
    useEffect(() => setHighligtItem(isOpen), [setHighligtItem, isOpen]);

    const handleClick = (e: any) => {
        if (pickerWrapper.current && !pickerWrapper.current.contains(e.target)) {
            setIsOpen(false);
        }
    };

    const itemIsLast = (items: Item[], item: Item) => {
        const index = items.indexOf(item);
        return index === items.length - 1;
    };

    const itemIsFirst = (items: Item[], item: Item) => {
        const index = items.indexOf(item);
        return index === 0;
    };

    useEffect(() => {
        if (isOpen === true) {
            document.addEventListener("click", handleClick);
        } else {
            document.removeEventListener("click", handleClick);
        }

        return () => {
            document.removeEventListener("click", handleClick);
        };
    }, [isOpen]);

    const deleteItem = () => {
        const index = props.items.indexOf(props.item);
        const collection = props.items;
        collection.splice(index, 1);
        props.updateList(collection);
    };

    const addItem = () => {
        const index = props.items.indexOf(props.item);
        const collection = props.items;
        collection.splice(index, 0, props.getNewItem());
        props.updateList(collection);
        setIsOpen(false);
    };

    const moveItem = (indexAdjustment: number) => {
        const collection = props.items;
        const old_index = collection.indexOf(props.item);
        let new_index = old_index + indexAdjustment;

        if (new_index < 0) new_index = 0;
        if (new_index >= collection.length) new_index = collection.length;

        collection.splice(new_index, 0, collection.splice(old_index, 1)[0]);
        props.updateList(collection);
    };

    return (
        <div className={`${styles.wrapper} ${isOpen ? styles.open : ""}`}>
            {deleteConfirmationIsOpen ? (
                <Confirmation
                    confirmationTitle="Ta bort"
                    confirmButtonText="Ta bort"
                    abortButtonText="Avbryt"
                    confirmationText={"Är du säker på att du vill ta bort denna " + props.itemName.toLowerCase() + "?"}
                    confirmCallback={deleteItem}
                    closeCallback={() => setDeleteConfirmationIsOpen(false)}
                />
            ) : null}
            <button className={styles.toggle} onClick={() => setIsOpen(true)}>
                {IconToggleContentModuleActionBar()}
                <span>{props.itemName}</span>
            </button>
            <div className={`${styles.actions} ${isOpen ? styles.open : ""}`} ref={pickerWrapper}>
                {!itemIsFirst(props.items, props.item) ? (
                    <button onClick={() => moveItem(-1)}>
                        {IconMoveUpContentModule()}
                        <span>Upp</span>
                    </button>
                ) : null}
                {!itemIsLast(props.items, props.item) ? (
                    <button onClick={() => moveItem(1)}>
                        {IconMoveDownContentModule()}
                        <span>Ner</span>
                    </button>
                ) : null}
                <button onClick={() => setDeleteConfirmationIsOpen(true)}>
                    {IconDelete()}
                    <span>Ta bort</span>
                </button>
                <button onClick={addItem}>
                    {IconNewContentModule()}
                    <span>Ny</span>
                </button>
            </div>
        </div>
    );
}
