import { Editor, Transforms, Element, Range } from "slate";
import { CustomEditor, LinkElement } from "../types/SlateTypes";

export const isLinkActive = (editor: CustomEditor) => {
    const [link] = Array.from(
        Editor.nodes(editor, {
            match: (n) => !Editor.isEditor(n) && Element.isElement(n) && n.type === "link",
        })
    );
    return !!link;
};

export const wrapLink = (editor: CustomEditor, url: string, text?: string) => {
    if (isLinkActive(editor)) {
        unwrapLink(editor);
    }

    const { selection } = editor;
    const isCollapsed = selection && Range.isCollapsed(selection);
    const link: LinkElement = {
        type: "link",
        url,
        children: isCollapsed || text ? [{ text: url }] : [],
    };

    if (isCollapsed || text) {
        Transforms.insertNodes(editor, link);
    } else {
        Transforms.wrapNodes(editor, link, { split: true });
        Transforms.collapse(editor, { edge: "end" });
    }
};

export const unwrapLink = (editor: CustomEditor) => {
    Transforms.unwrapNodes(editor, {
        match: (n) => !Editor.isEditor(n) && Element.isElement(n) && n.type === "link",
    });
};
