import React from "react";
import styles from "./BackgroundImage.module.scss";
import FlowersCleanBackground from "./FlowersCleanBackground.png";
import WoodAndFlowersBackground from "./WoodAndFlowersBackground.jpg";
import BlurryDecorations from "./BlurryDecorations.jpg";
import BokehDark from "./BokehDark.jpg";
import BokehFireworks from "./BokehFireworks.jpg";
import BokehLight from "./BokehLight.jpg";
import DancingParty from "./DancingParty.jpg";
import Noise from "./Noise.png";
import { TSiteGraphicType } from "../../SiteGraphic";

export const siteGraphicsBackgroundImages = [
    "BACKGROUND_IMAGE_FLOWERS_CLEAN",
    "BACKGROUND_IMAGE_WOOD_AND_FLOWERS",
    "BACKGROUND_IMAGE_BLURRY_DECORATIONS",
    "BACKGROUND_IMAGE_BOKEH_DARK",
    "BACKGROUND_IMAGE_BOKEH_FIREWORKS",
    "BACKGROUND_IMAGE_BOKEH_LIGHT",
    "BACKGROUND_IMAGE_DANCING_PARTY",
    "BACKGROUND_IMAGE_NOISE",
] as const;
export type TSiteGraphicsBackgroundImage = (typeof siteGraphicsBackgroundImages)[number];

interface IProps {
    backgroundImage: TSiteGraphicsBackgroundImage;
    editAvailable: boolean;
    type: TSiteGraphicType;
}

export function BackgroundImage(props: IProps) {
    let url;
    if (props.backgroundImage === "BACKGROUND_IMAGE_FLOWERS_CLEAN") {
        url = FlowersCleanBackground;
    } else if (props.backgroundImage === "BACKGROUND_IMAGE_WOOD_AND_FLOWERS") {
        url = WoodAndFlowersBackground;
    } else if (props.backgroundImage === "BACKGROUND_IMAGE_BLURRY_DECORATIONS") {
        url = BlurryDecorations;
    } else if (props.backgroundImage === "BACKGROUND_IMAGE_BOKEH_DARK") {
        url = BokehDark;
    } else if (props.backgroundImage === "BACKGROUND_IMAGE_BOKEH_FIREWORKS") {
        url = BokehFireworks;
    } else if (props.backgroundImage === "BACKGROUND_IMAGE_BOKEH_LIGHT") {
        url = BokehLight;
    } else if (props.backgroundImage === "BACKGROUND_IMAGE_DANCING_PARTY") {
        url = DancingParty;
    } else if (props.backgroundImage === "BACKGROUND_IMAGE_NOISE") {
        url = Noise;
    } else {
        throw new Error("No background image named " + props.backgroundImage);
    }
    url = "url(" + url + ")";
    return (
        <div
            className={`${styles.background}  ${styles[props.type]} ${styles[props.backgroundImage]}`}
        >
            <div className={styles.image} style={{ backgroundImage: url }}></div>
        </div>
    );
}
