import Axios from "axios";
import React from "react";
import { Redirect } from "react-router";
import FormWrapper from "../../components/form/FormWrapper/FormWrapper";
import Select from "../../components/form/Select/Select";
import ContentGeneratorButton from "../../content/GeneratorButton/GeneratorButton";
import ContentRTE from "../../content/RTE/RTE";
import { IRvsp, IRvspPerson } from "../../interfaces/Rvsp";
import SetReceiverMail from "../../modules/SetReceiverMail/SetReceiverMail";
import ModuleHeading from "../components/ModuleHeading/ModuleHeading";
import GeneratorModule from "../GeneratorModule";
import Person from "./Person/Person";
import styles from "./RVSP.module.scss";

interface IState {
    personsQuantity: number;
    persons: IRvspPerson[];
    toDonePage: boolean;
    postKey?: string;
    toDoneSpamPage: boolean;
}

export class RVSP extends GeneratorModule {
    getInitPersons = () => {
        let initPersons = [];
        if (this.props.editMode === true) {
            initPersons.push({
                weddingYesOrNo: true,
                firstName: "",
                lastName: "",
                mail: "",
                phone: "",
            });
        }
        return initPersons;
    };

    state: IState = {
        personsQuantity: this.props.editMode === true ? 1 : 0,
        persons: this.getInitPersons(),
        toDonePage: false,
        toDoneSpamPage: false,
    };

    personPlaceholder = "Välj antal personer";

    personsOptions = [
        {
            title: "1 person",
            value: "1",
        },
        {
            title: "2 personer",
            value: "2",
        },
        {
            title: "3 personer",
            value: "3",
        },
        {
            title: "4 personer",
            value: "4",
        },
        {
            title: "5 personer",
            value: "5",
        },
        {
            title: "6 personer",
            value: "6",
        },
        {
            title: "7 personer",
            value: "7",
        },
        {
            title: "8 personer",
            value: "8",
        },
        {
            title: "9 personer",
            value: "9",
        },
    ];

    changeNumberOfPersons = (value: string) => {
        const personsQuantity = parseInt(value);
        let persons = [] as IRvspPerson[];

        // eslint-disable-next-line array-callback-return
        [...Array(personsQuantity)].map((e, index) => {
            persons[index] = this.state.persons[index] || {
                weddingYesOrNo: true,
                firstName: "",
                lastName: "",
                phone: "",
                mail: "",
            };
        });

        Axios.get(process.env.REACT_APP_API_URL + "/api/rsvps/post-key")
            .then((res) => {
                this.setState({
                    postKey: res.data.key,
                    personsQuantity,
                    persons,
                });
            })
            .catch((err) => {
                this.props.showError("Misslyckades att ladda OSA-formuläret", err.response);
            });
    };

    updateValue = (index: number, name: string, value: string, customValueType: string, label: string) => {
        let persons = this.state.persons;
        let person = persons[index];

        if (customValueType) {
            if (!person.customValues) {
                person.customValues = [];
            }

            let index = person.customValues.findIndex((customValue) => {
                return customValue.id === name;
            });

            if (index < 0) {
                person.customValues.push({
                    id: name,
                    value,
                    type: customValueType,
                    label,
                });
            } else {
                person.customValues[index].value = value;
            }
        } else {
            (person as any)[name] = value;
        }

        this.setState({
            persons,
        });
    };

    submit = () => {
        const RSVP: IRvsp = {
            persons: this.state.persons,
            postKey: this.state.postKey,
        };
        Axios.post(process.env.REACT_APP_API_URL + "/api/rsvps/" + this.props.site._id + "/", RSVP)
            .then((res) => {
                if (res.data.invertedSuccess && res.data.invertedSuccess === true) {
                    this.setState({
                        toDoneSpamPage: true,
                    });
                } else {
                    this.setState({
                        toDonePage: true,
                    });
                }
            })
            .catch((err) => {
                this.props.showError(
                    "Tyvärr misslyckades vi att spara din OSA. Något gick fel. Försök gärna igen.",
                    err.response
                );
            });
    };

    render() {
        if (this.state.toDonePage === true) {
            return (
                <Redirect
                    to={{
                        pathname: "/OSA-bekraftelse/",
                    }}
                />
            );
        }
        if (this.state.toDoneSpamPage === true) {
            return (
                <Redirect
                    to={{
                        pathname: "/OSA-snabbfel/",
                    }}
                />
            );
        }
        return (
            <section className={styles[this.props.site.theme]}>
                <ModuleHeading
                    site={this.props.site}
                    propertyParent={this.props.moduleData}
                    showError={this.props.showError}
                    updateProperty={this.props.updateProperty}
                    editMode={this.props.editMode}
                ></ModuleHeading>
                <div className={styles.textAndFormWrapper}>
                    <div className={styles.rteWrapper}>
                        <ContentRTE
                            site={this.props.site}
                            propertyParent={this.props.moduleData}
                            showError={this.props.showError}
                            property="text"
                            updateProperty={this.props.updateProperty}
                            editMode={this.props.editMode}
                        ></ContentRTE>
                    </div>
                    <div className={styles.selectNumberOfPersonsWrapper}>
                        <span className={styles.selectNumberOfPersonsText}>Jag vill OSA för: </span>
                        <Select
                            id="RVSPSelectNumberOfPersonsSelect"
                            disabled={this.props.editMode}
                            options={this.personsOptions}
                            onChangeCallback={this.changeNumberOfPersons}
                            placeholder={this.personPlaceholder}
                        ></Select>
                    </div>
                    <FormWrapper submit={this.submit.bind(this)} disabled={this.props.editMode === true}>
                        <>
                            {[...Array(this.state.personsQuantity)].map((e, index) => (
                                <Person
                                    showError={this.props.showError}
                                    data={this.state.persons[index]}
                                    key={index}
                                    index={index}
                                    updateValue={this.updateValue}
                                    module={this.props.moduleData}
                                    editMode={this.props.editMode}
                                    updateProperty={this.props.updateProperty}
                                    site={this.props.site}
                                    propertyParent={this.props.moduleData}
                                ></Person>
                            ))}
                        </>
                        <div className={styles.buttonAndReceiverWrapper}>
                            <div>
                                {this.state.personsQuantity && this.state.personsQuantity > 0 ? (
                                    <div className={styles.buttonWrapper}>
                                        <ContentGeneratorButton
                                            buttonDisabled={this.props.editAvailable}
                                            buttonText="Skicka"
                                            buttonType="primary"
                                            buttonSubmit={true}
                                            site={this.props.site}
                                            propertyParent={this.props.moduleData}
                                            property="sendButton"
                                            showError={this.props.showError}
                                            updateProperty={this.props.updateProperty}
                                            editMode={this.props.editMode}
                                        />
                                    </div>
                                ) : (
                                    ""
                                )}
                            </div>
                            {this.props.editMode === true && (
                                <div className={styles.receiverWrapper}>
                                    <SetReceiverMail
                                        site={this.props.site}
                                        updateReceiverMail={this.props.updateReceiverMail}
                                        receiverCategory="rsvp"
                                    />
                                </div>
                            )}
                        </div>
                    </FormWrapper>
                </div>
            </section>
        );
    }
}

export default RVSP;
