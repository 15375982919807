import React, { useState } from "react";
import { useDebouncedCallback } from "use-debounce";
import Button from "../../components/button/Button";
import ExpandableArea from "../../components/ExpandableArea/ExpandableArea";
import { ColorPicker } from "../../components/form/ColorPicker/ColorPicker";
import FormWrapper from "../../components/form/FormWrapper/FormWrapper";
import { Slider } from "../../components/form/Slider/Slider";
import TextField from "../../components/form/TextField/TextField";
import { GeneratorSelectList } from "../../components/GeneratorSelectList/GeneratorSelectList";
import { Map, MapTheme } from "../../components/map/Map";
import { ISite } from "../../interfaces/Site";
import styles from "./Edit.module.scss";

export interface IContentMapValue {
    location: string;
    mapTheme: MapTheme;
    markerColor: string;
    zoom: number;
}

interface IProps {
    updateProperty: (module: any, property: string, content: IContentMapValue) => void;
    property: string;
    propertyParent: any;
    updatePropertySuccess: () => void;
    value: IContentMapValue;
    site: ISite;
}

export function EditMap(props: IProps) {
    const [newMapValues, setNewMapValues] = useState(Object.assign({}, props.value));

    const handleValueChange = (value: string, name: keyof IContentMapValue) => {
        setNewMapValues({
            ...newMapValues,
            [name]: value,
        });
    };

    const handleDone = () => {
        props.updateProperty(props.propertyParent, props.property, newMapValues);
        props.updatePropertySuccess();
    };

    const mapThemeOptions = [
        {
            title: "Standard",
            value: MapTheme.NORMAL,
        },
        {
            title: "Silver",
            value: MapTheme.SILVER,
        },
        {
            title: "Retro",
            value: MapTheme.RETRO,
        },
        {
            title: "Mörk",
            value: MapTheme.DARK,
        },
        {
            title: "Natt",
            value: MapTheme.NIGHT,
        },
    ];

    const debouncedLocationChange = useDebouncedCallback(handleValueChange, 800);

    return (
        <FormWrapper submit={handleDone}>
            <div className={styles.wrapper}>
                <div className={styles.formWrapper}>
                    <div className={styles.formFieldWrapper}>
                        <TextField
                            label="Plats/Adress/koordinater"
                            name="location"
                            id="editMapLocation"
                            onChangeCallback={debouncedLocationChange}
                            value={newMapValues.location}
                            placeholder="Kyrkvägen 2, 111 22, Jönköping"
                        />
                    </div>
                    <div className={styles.formFieldWrapper}>
                        <GeneratorSelectList
                            defaultValue={MapTheme.NORMAL}
                            cols={3}
                            name="mapTheme"
                            options={mapThemeOptions}
                            value={newMapValues.mapTheme}
                            onChangeCallback={handleValueChange}
                            dark={true}
                        />
                    </div>
                    <ExpandableArea title="Avancerade inställningar">
                        <>
                            <div className={styles.formFieldWrapper}>
                                <Slider
                                    min={6}
                                    max={18}
                                    value={newMapValues.zoom || 12}
                                    onChangeCallback={handleValueChange}
                                    label="Zoom"
                                    name="zoom"
                                    id="editMapZoom"
                                />
                            </div>
                            <div className={styles.formFieldWrapper}>
                                <ColorPicker
                                    value={newMapValues.markerColor || "#ea4335"}
                                    onChangeCallback={handleValueChange}
                                    label="Markörfärg"
                                    name="markerColor"
                                    id="editMapMarkerColor"
                                    onlyHex={true}
                                />
                            </div>
                        </>
                    </ExpandableArea>
                </div>
                {newMapValues.location ? (
                    <div className={styles.mapWrapper}>
                        <Map
                            site={props.site}
                            editMode={true}
                            zoom={newMapValues.zoom}
                            markerColor={newMapValues.markerColor}
                            mapTheme={newMapValues.mapTheme}
                            location={newMapValues.location}
                            preventCache={true}
                        />
                    </div>
                ) : null}
            </div>
            <div className={styles.bottomButtons}>
                <Button buttonText="Klar" type="primary" submit={true} fullWidth={true} />
            </div>
        </FormWrapper>
    );
}

export default EditMap;
