import { useEffect, useRef, useState } from "react";

export default function useOnClickOutside(callback: EventListener, activated: boolean) {
    const container = useRef<HTMLDivElement>(null);
    const [isTouchEvent, setTouchEvent] = useState(false);
    const eventType = isTouchEvent ? "touchend" : "click";

    function handleEvent(e: Event) {
        if (e.type === "click" && isTouchEvent) {
            return;
        }

        if (container.current && e.target !== null) {
            if (!container.current.contains(e.target as Node)) {
                callback(e);
            }
        }
    }

    useEffect(() => {
        if (activated) {
            document.addEventListener(eventType, handleEvent, true);
        } else {
            document.removeEventListener(eventType, handleEvent, true);
        }
        return () => {
            document.removeEventListener(eventType, handleEvent, true);
        };
    });

    useEffect(() => {
        setTouchEvent("ontouchstart" in document.documentElement);
    }, []);

    return container;
}
