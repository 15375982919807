import React from "react";
import TextField from "../../../components/form/TextField/TextField";
import TextFieldEdit from "../../../components/form/TextField/TextFieldEdit";
import YesOrNo from "../../../components/form/YesOrNo/YesOrNo";
import ContentMultipleTextFields from "../../../content/MultipleTextFields/MultipleTextFields";
import textFieldStyles from "../../../content/MultipleTextFields/TextFieldItem/TextFieldItem.module.scss";
import ContentMultipleYesOrNo from "../../../content/MultipleYesOrNo/MultipleYesOrNo";
import { IModule } from "../../../interfaces/Module";
import { IRvspPerson } from "../../../interfaces/Rvsp";
import { ISite } from "../../../interfaces/Site";
import styles from "./Person.module.scss";

interface IProps {
    index: number;
    updateValue: any;
    data: IRvspPerson;
    module: IModule;
    editMode: boolean;
    site: ISite;
    updateProperty: any;
    showError: any;
    propertyParent: any;
}

interface IState {
    personsQuantity: number;
}

export class Person extends React.Component<IProps, IState> {
    updateField = (value: string, name: string) => {
        this.props.updateValue(this.props.index, name, value);
    };

    updateCustomField = (value: string, name: string, customValueType: string, label: string) => {
        this.props.updateValue(this.props.index, name, value, customValueType, label);
    };

    toggleMandatoryPhone = () => {
        this.props.updateProperty(
            this.props.propertyParent,
            "phoneIsOptional",
            !this.props.module.properties.phoneIsOptional
        );
    };

    toggleMandatoryMail = () => {
        this.props.updateProperty(
            this.props.propertyParent,
            "mailIsOptional",
            !this.props.module.properties.mailIsOptional
        );
    };

    render() {
        return (
            <div className={styles.personWrapper}>
                <h3 className={styles.personHeading}>
                    Person {this.props.index + 1}
                    {this.props.data.firstName ? "- " + this.props.data.firstName : ""}
                </h3>
                <div className={styles.textFieldGroup}>
                    <div className={styles.textFieldWrapper}>
                        <TextField
                            className={textFieldStyles[this.props.site.theme]}
                            required={true}
                            disabled={this.props.editMode}
                            label="Förnamn"
                            name="firstName"
                            id={"RVSP-FirstName-" + this.props.index}
                            onChangeCallback={this.updateField}
                        ></TextField>
                    </div>
                    <div className={styles.textFieldWrapper}>
                        <TextField
                            className={textFieldStyles[this.props.site.theme]}
                            required={true}
                            disabled={this.props.editMode}
                            label="Efternamn"
                            name="lastName"
                            id={"RVSP-LastName-" + this.props.index}
                            onChangeCallback={this.updateField}
                        ></TextField>
                    </div>
                    <div className={styles.textFieldWrapper}>
                        {this.props.editMode === true ? (
                            <TextFieldEdit
                                label={"Telefonnummer"}
                                id={"RVSP-Phone-" + this.props.index}
                                toggleMandatory={this.toggleMandatoryPhone}
                                mandatory={!this.props.module.properties.phoneIsOptional}
                                className={textFieldStyles[this.props.site.theme]}
                            />
                        ) : (
                            <TextField
                                className={textFieldStyles[this.props.site.theme]}
                                required={!this.props.module.properties.phoneIsOptional}
                                label={"Telefonnummer"}
                                name={"phone"}
                                id={"RVSP-Phone-" + this.props.index}
                                onChangeCallback={this.updateField}
                            />
                        )}
                    </div>
                    <div className={styles.textFieldWrapper}>
                        {this.props.editMode === true ? (
                            <TextFieldEdit
                                label={"E-post"}
                                id={"RVSP-Mail-" + this.props.index}
                                toggleMandatory={this.toggleMandatoryMail}
                                mandatory={!this.props.module.properties.mailIsOptional}
                                className={textFieldStyles[this.props.site.theme]}
                            />
                        ) : (
                            <TextField
                                className={textFieldStyles[this.props.site.theme]}
                                required={!this.props.module.properties.mailIsOptional}
                                label={"E-post"}
                                name="mail"
                                id={"RVSP-Mail-" + this.props.index}
                                onChangeCallback={this.updateField}
                            />
                        )}
                    </div>
                </div>
                <YesOrNo
                    label="Kommer du på bröllopet?"
                    labelYes="Ja, självklart!"
                    labelNo="Nej, jag kan tyvärr inte komma"
                    name="weddingYesOrNo"
                    id={"RVSP-WeddingYesOrNo-" + this.props.index}
                    onChangeCallback={this.updateField}
                    value={this.props.data.weddingYesOrNo}
                    disabled={this.props.editMode}
                    site={this.props.site}
                />

                <ContentMultipleYesOrNo
                    showError={this.props.showError}
                    propertyParent={this.props.module}
                    editMode={this.props.editMode}
                    property="customYesOrNoItems"
                    site={this.props.site}
                    updateProperty={this.props.updateProperty}
                    updateCustomField={this.updateCustomField}
                    id={"RVSP-MultipleYesOrNo-" + this.props.index}
                />

                {this.props.data.weddingYesOrNo === true && (
                    <>
                        <ContentMultipleTextFields
                            showError={this.props.showError}
                            propertyParent={this.props.module}
                            editMode={this.props.editMode}
                            property="customTextFields"
                            site={this.props.site}
                            updateProperty={this.props.updateProperty}
                            updateCustomField={this.updateCustomField}
                            id={"RVSP-MultipleTextFields-" + this.props.index}
                        />
                    </>
                )}
            </div>
        );
    }
}

export default Person;
