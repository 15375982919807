import React from "react";
import ActionCurtain from "../../components/actionCurtain/ActionCurtain";
import { IModule } from "../../interfaces/Module";
import { ISite } from "../../interfaces/Site";
import { isBrand } from "../../utils/getBrand";
import { getSiteThemeGeneration } from "../../utils/getSiteThemeGeneration";
import "./AddModule.scss";

interface IProps {
    closeAddModule: any;
    addNewModule: any;
    addModuleBeforeThisModule?: IModule | null;
    site: ISite;
}

export class AddModule extends React.Component<IProps> {
    selectModule = (type: string) => {
        this.props.addNewModule(type, this.props.addModuleBeforeThisModule);
        this.props.closeAddModule();
    };

    render() {
        return (
            <ActionCurtain title="Välj modul" close={this.props.closeAddModule}>
                <ul className="add-module-wrapper">
                    <li
                        onClick={() => {
                            this.selectModule("location");
                        }}
                    >
                        <div className="title">Plats/tidpunkt</div>
                        <div className="description">Visar tex. karta, vägbeskrivning och tid till en plats.</div>
                    </li>
                    <li
                        onClick={() => {
                            this.selectModule("TextAndImageList");
                        }}
                    >
                        <div className="title">Text och bild i lista</div>
                        <div className="description">
                            Presentera flera saker i en lista med text och bild till varje punkt. Används ofta som "Bra
                            att veta"-modul.
                        </div>
                    </li>
                    <li
                        onClick={() => {
                            this.selectModule("ImageAndText");
                        }}
                    >
                        <div className="title">Bild och text</div>
                        <div className="description">Rubrik, bild och text</div>
                    </li>
                    <li
                        onClick={() => {
                            this.selectModule("Text");
                        }}
                    >
                        <div className="title">Text</div>
                        <div className="description">Text och rubrik</div>
                    </li>
                    <li
                        onClick={() => {
                            this.selectModule(getSiteThemeGeneration(this.props.site) === "V2" ? "RSVP3" : "RSVP2");
                        }}
                    >
                        <div className="title">OSA</div>
                        <div className="description">Formulär för OSA</div>
                    </li>
                    {!isBrand("event") ? (
                        <li
                            onClick={() => {
                                this.selectModule("Speech2");
                            }}
                        >
                            <div className="title">Anmälan av tal</div>
                            <div className="description">Formulär för anmälan av tal</div>
                        </li>
                    ) : null}
                    <li
                        onClick={() => {
                            this.selectModule("countDown");
                        }}
                    >
                        <div className="title">Nedräkning</div>
                        <div className="description">Nedräkning i dagar, timmar, minuter och sekunder.</div>
                    </li>
                    <li
                        onClick={() => {
                            this.selectModule("header");
                        }}
                    >
                        <div className="title">Sidhuvud</div>
                        <div className="description">Sidhuvud med bild, namn och datum</div>
                    </li>
                    {!isBrand("event") ? (
                        <li
                            onClick={() => {
                                this.selectModule("GiftRegistry");
                            }}
                        >
                            <div className="title">Önskelista</div>
                            <div className="description">
                                Önskelista där dina gäster kan boka upp sina inköp så ni inte får dubbletter.
                            </div>
                        </li>
                    ) : null}
                    {getSiteThemeGeneration(this.props.site) === "V2" ? (
                        <>
                            <li
                                onClick={() => {
                                    this.selectModule("PeopleList");
                                }}
                            >
                                <div className="title">Personer</div>
                                <div className="description">Lista med personer</div>
                            </li>
                            <li
                                onClick={() => {
                                    this.selectModule("Video");
                                }}
                            >
                                <div className="title">YouTube-klipp</div>
                                <div className="description">YouTube-klipp med rubrik</div>
                            </li>
                        </>
                    ) : null}
                </ul>
            </ActionCurtain>
        );
    }
}

export default AddModule;
