import { useEffect, useState } from "react";
import { IError } from "../../../../../App";
import { trackEvent } from "../../../../../utils/UmamiHelpers";
import Loading from "../../../../components/Loading/Loading";
import ShowError from "../../../../components/ShowError/ShowError";
import { IGiftRegistryReservations, ISite } from "../../../../interfaces/Site";
import { InitialHelp } from "../../../../modules/InitialHelp/InitialHelp";
import QuickStartSave from "../../../../modules/QuickStartSave/QuickStartSave";
import { QuickStartSaveConfirmation } from "../../../../modules/QuickStartSaveConfirmation/QuickStartSaveConfirmation";
import { getEditQuickStartSite, getEditSite, postCreateSite, putEditSite } from "../../../../services/SiteService";
import { loadSiteFonts } from "../../../../utils/LoadSiteFonts";
import {
    setGeneratorColorStylesVariables,
    setGeneratorFontsStylesVariables,
} from "../../../../utils/SetGeneratorStylesVariables";
import { Home } from "../../../Home/Home";

interface IProps {
    editAvailable: boolean;
    showError: any;
    quickStart: boolean;
    showHelpFn?: any;
    showHelp?: boolean;
    routerHistory: any;
    drawerSlotPortalNode?: JSX.Element;
    siteId?: string;
    host?: string;
}

const isRegisterShortcut = () => {
    const params = new URLSearchParams(window.location.search);
    return params.get("registerAccount") === "true";
};

export function EditHome(props: IProps) {
    const [editMode, setEditMode] = useState(true);
    const [isDirty, setIsDirty] = useState(false);

    const [loading, setLoading] = useState(true);
    const [loadingOverlay, setLoadingOverlay] = useState(false);
    const [site, setSite] = useState<ISite | null>(null);
    const [error, setError] = useState<IError | null>(null);

    const [showQuickStartInitialHelp, setShowQuickStartInitialHelp] = useState(
        props.quickStart && !isDirty && !isRegisterShortcut()
    );
    const [showQuickStartSave, setShowQuickStartSave] = useState(isRegisterShortcut() && props.quickStart);
    const [showQuickStartSaveConfirmation, setShowQuickStartSaveConfirmation] = useState(false);

    useEffect(() => {
        const getSite = async () => {
            setLoading(true);
            try {
                let site;
                if (props.quickStart !== true) {
                    if (!props.siteId) {
                        throw new Error("No siteId but not quickstart");
                    }
                    site = await getEditSite(props.siteId);
                } else {
                    site = await getEditQuickStartSite(props.host);
                }
                setSite(site);
                loadSiteFonts(site.fonts);
                setGeneratorFontsStylesVariables(site.fonts);
                setGeneratorColorStylesVariables(site.colorScheme ? site.colorScheme : site.colors!);
            } catch (error) {
                setError({ text: "Misslyckades att hämta sidan", response: (error as any).response });
            }
            setLoading(false);
        };
        getSite();
    }, [props.siteId, props.host, props.quickStart]);

    if (loading) {
        return <Loading fixed={true} inverted={true} />;
    }

    if (error) {
        return <ShowError error={error} />;
    }

    if (!site) {
        throw Error("Site not loaded but not error");
    }

    const updateSite = (site: ISite, dontTriggerDirty?: boolean) => {
        setSite({ ...site });
        if (!dontTriggerDirty) {
            setIsDirty(true);
        }
    };

    const saveOrCreateSite = () => {
        if (props.quickStart === true) {
            trackEvent("save-button-in-quickstart");
            setShowQuickStartSave(true);
        } else {
            saveSite();
        }
    };

    const saveSite = async () => {
        setLoadingOverlay(true);
        try {
            const updatedSite = await putEditSite(site);
            setSite(updatedSite);
            loadSiteFonts(updatedSite.fonts);
            setGeneratorFontsStylesVariables(updatedSite.fonts);
            setGeneratorColorStylesVariables(updatedSite.colorScheme ? updatedSite.colorScheme : updatedSite.colors!);
            setIsDirty(false);
        } catch (error) {
            setError({ text: "Misslyckades att spara sidan", response: (error as any).response });
        }
        setLoadingOverlay(false);
    };

    const createSite = async () => {
        setLoadingOverlay(true);
        try {
            const newSiteId = await postCreateSite(site);
            setIsDirty(false);
            trackEvent("account-created", {"position": "site"});

            props.routerHistory.push("/redigera/" + newSiteId + "/");
            setShowQuickStartSave(false);
            setShowQuickStartSaveConfirmation(true);
        } catch (error) {
            setError({ text: "Misslyckades att spara sidan", response: (error as any).response });
        }
        setLoadingOverlay(false);
    };

    const updateGiftRegistryReservations = (giftRegistryReservations: IGiftRegistryReservations[]) => {
        throw new Error("updateGiftRegistryReservations should not be called in edit mode");
    };

    return (
        <>
            {showQuickStartInitialHelp === true ? (
                <InitialHelp close={() => setShowQuickStartInitialHelp(false)} />
            ) : null}
            {showQuickStartSave === true ? (
                <QuickStartSave
                    createSite={createSite}
                    close={() => setShowQuickStartSave(false)}
                    site={site}
                />
            ) : null}
            {showQuickStartSaveConfirmation === true ? (
                <QuickStartSaveConfirmation close={() => setShowQuickStartSaveConfirmation(false)} site={site} />
            ) : null}
            {loadingOverlay ? <Loading fixed={true} /> : null}
            <Home
                routerHistory={props.routerHistory}
                setIsDirty={setIsDirty}
                isDirty={isDirty}
                showHelp={props.showHelp}
                showHelpFn={props.showHelpFn}
                toggleEditMode={() => setEditMode(!editMode)}
                quickStart={props.quickStart}
                showError={props.showError}
                editAvailable={props.editAvailable}
                editMode={editMode}
                site={site}
                updateSite={updateSite}
                drawerSlotPortalNode={props.drawerSlotPortalNode}
                updateGiftRegistryReservations={updateGiftRegistryReservations}
                saveOrCreateSite={saveOrCreateSite}
            />
        </>
    );
}
