import React from "react";
import ReactDOM from "react-dom";
import { IError } from "../../../App";
import Close from "../../components/Close/Close";
import Button from "../button/Button";
import styles from "./ShowError.module.scss";

interface IProps {
    error: IError | null;
}

interface IState {
    showError: boolean;
}

export class ShowError extends React.Component<IProps> {
    state: IState = {
        showError: false,
    };

    componentDidMount() {
        if (this.props.error) {
            this.setState({
                showError: true,
            });
        }
    }

    componentDidUpdate(prevProps: IProps) {
        if (this.props.error !== prevProps.error) {
            if (this.props.error) {
                this.setState({
                    showError: true,
                });
            }
        }
    }

    close = () => {
        this.setState({
            showError: false,
        });
    };

    goToLogin = () => {
        window.location.href = "/logga-in";
    };

    errorMessage = () => {
        if (
            this.props.error &&
            this.props.error.response &&
            this.props.error.response.data &&
            this.props.error.response.data.message
        ) {
            return this.props.error.response.data.message;
        } else if (this.props.error && this.props.error.text) {
            return this.props.error.text;
        } else {
            return "Ett okänt fel har uppstått.";
        }
    };

    showLoginButton = () => this.props.error?.response?.status === 403;

    render() {
        if (this.state.showError === true) {
            return (
                <>
                    {ReactDOM.createPortal(
                        <>
                            <div className={styles.background}></div>
                            <div className={styles.wrapper}>
                                <div className={styles.innerWrapper}>
                                    <div className={styles.top}>
                                        <div className={styles.title}>Fel</div>
                                        <Close closeEvent={this.close}></Close>
                                    </div>
                                    <div className={styles.message}>{this.errorMessage()}</div>
                                    <div>
                                        {this.showLoginButton() ? (
                                            <Button buttonText="Logga in" type="primary" callback={this.goToLogin} />
                                        ) : (
                                            <Button
                                                buttonText="Stäng"
                                                type="secondary"
                                                callback={this.close}
                                                data-test-id="close-show-error-button"
                                            />
                                        )}
                                    </div>
                                </div>
                            </div>
                        </>,
                        document.querySelector("#showErrorSlot")!
                    )}
                </>
            );
        } else {
            return null;
        }
    }
}

export default ShowError;
