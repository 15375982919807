import Axios from "axios";
import React, { useEffect, useState } from "react";
import { Product } from "../../../../../utils/products";
import Button from "../../../../components/button/Button";
import TextField from "../../../../components/form/TextField/TextField";
import Loading from "../../../../components/Loading/Loading";
import { logError } from "../../../../utils/LogError";
import styles from "./Swish.module.scss";
import { parseSwishNumber } from "./utils/parseSwishNumber";

enum View {
    initiate,
    polling,
    fatal,
}

interface IProps {
    setLoading: (loading: boolean) => void;
    showError: (errorMessage: string, errorObject: any) => void;
    paymentDone: (paymentReference: string) => void;
    setShowOnlySwish: (hide: boolean) => void;
}

interface IPropsSite extends IProps {
    paymentProduct: Product;
    siteId: string;
}

interface IPropsOrder extends IProps {
    orderId: string;
}

export function Swish(props: IPropsSite | IPropsOrder) {
    const [payerAlias, setPayerAlias] = useState<string | undefined>(undefined);
    const [validationMessage, setValidationMessage] = useState<string | null>(null);
    const [errorMessage, setErrorMessage] = useState<string | null>(null);
    const [view, setView] = useState(View.initiate);
    let pollCount = 0;
    let pollingTimeout: any = null;

    const reset = () => {
        setValidationMessage(null);
        setErrorMessage(null);
        setView(View.initiate);
        pollingTimeout && clearTimeout(pollingTimeout);
    };

    useEffect(() => {
        if (view === View.polling || view === View.fatal) {
            props.setShowOnlySwish && props.setShowOnlySwish(true);
        } else {
            props.setShowOnlySwish && props.setShowOnlySwish(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [view]);

    useEffect(() => {
        return () => {
            pollingTimeout && clearTimeout(pollingTimeout);
        };
    }, [pollingTimeout]);

    const initPayment = () => {
        if (!payerAlias) {
            setValidationMessage("Du måste uppge ditt swish-nummer");
            return;
        }
        const formatedPayerAlias = parseSwishNumber(payerAlias);

        props.setLoading(true);
        let endpoint, postData;
        if ("orderId" in props) {
            endpoint = "/orders/" + props.orderId + "/payment/swish"
        } else {
            endpoint = "/sites/" + props.siteId + "/payment/swish";
            postData = {
                paymentProduct: props.paymentProduct
            }
        }

        Axios.post(process.env.REACT_APP_API_URL + "/api" + endpoint, {
            payerAlias: formatedPayerAlias,
            ...postData
        })
            .then((response: any) => {
                props.setLoading(false);
                setView(View.polling);
                pollStatus(response.data.swishId, 5000);
                setValidationMessage(null);
            })
            .catch((err) => {
                props.setLoading(false);
                if (err.response.status === 422) {
                    setValidationMessage(err.response.data.validationMessage);
                } else {
                    props.showError("Misslyckades att initiera swish-betalning", err.response);
                    logError("publish.swish.postSwishPayment", err, 3, "Misslyckades att initiera swish-betalning");
                }
            });
    };

    const pollStatus = (swishId: string, wait: number) => {
        pollCount = pollCount + wait;
        pollingTimeout = setTimeout(() => {
            if (pollCount > 300000) {
                // 300000 = 5 minuter
                setView(View.fatal);
                setErrorMessage("Ett okänt fel uppstod. Om pengar har dragits från ditt konto, kontakta oss.");
                logError("publish.swish", {}, 3, "Timed out (localy)");
            } else {
                Axios.get(process.env.REACT_APP_API_URL + "/api/swishPayments/" + swishId)
                    .then((response: any) => {
                        const status = response.data.status;
                        if (status === "NOT_SET") {
                            pollStatus(swishId, 5000);
                        } else if (status === "PAID") {
                            props.paymentDone(swishId);
                        } else if (status === "DECLINED") {
                            // Is this in use? Cant simulate it but according to swish documentation maybe?
                            setErrorMessage("Ditt köp nekades.");
                            setView(View.fatal);
                        } else if (status === "ERROR") {
                            setErrorMessage(response.data.errorMessage);
                            setView(View.fatal);
                        } else if (status === "CANCELLED") {
                            // Is this in use? Cant simulate it but according to swish documentation maybe?
                            setErrorMessage("Köp avbrutet");
                            setView(View.fatal);
                        } else {
                            setView(View.fatal);
                            setErrorMessage("Ett okänt fel uppstod");
                            logError(
                                "publish.swish",
                                response.data,
                                3,
                                "Swish status does not exists in if: " + status
                            );
                        }
                    })
                    .catch((err) => {
                        setView(View.fatal);
                        props.showError("Misslyckades att initiera swish-betalning", err.response);
                        logError("publish.swish.initClient", err, 3, "Misslyckades att initiera swish-betalning");
                    });
            }
        }, wait);
    };

    const onChangepayerAlias = (value: string, name: string) => {
        setPayerAlias(value);
    };

    return (
        <div className={styles.wrapper}>
            {view === View.initiate && (
                <>
                    {validationMessage && <div className={styles.validationMessage}>{validationMessage}</div>}
                    <TextField
                        value={payerAlias}
                        placeholder="46700000000"
                        label="Swish-nummer (börja med landskoden, tex. 46)"
                        onChangeCallback={onChangepayerAlias}
                        id="payerAlias"
                        name="payerAlias"
                    ></TextField>
                    <div className={styles.buttonWrapper}>
                        <Button
                            callback={() => {
                                initPayment();
                            }}
                            buttonText="Påbörja betalning"
                            type="primary"
                            data-test-id="button-swish-pay"
                        ></Button>
                    </div>
                    <p className={styles.paymentInfo}>
                        <strong>Hög säkerhet.</strong> Betalningen sker tryggt och säkert med Swish. När du fyllt i ditt
                        Swish-nummer kommer du att behöva öppna din Swish-app och godkänna beloppet och betalningen med
                        BankID.
                    </p>
                </>
            )}
            {view === View.polling && (
                <div className={styles.pollingWrapper}>
                    <div className={styles.openAppText}>Öppna din swish-app och genomför betalningen.</div>
                    <Loading block={true} />
                    <i>
                        Det kan dröja upp till 15 sekunder efter att du har betalat tills att köpet har meddelats oss.
                        Vänligen vänta.
                    </i>
                    <Button
                        buttonText="Avbryt"
                        type="secondary"
                        callback={() => {
                            reset();
                        }}
                    />
                </div>
            )}
            {view === View.fatal && (
                <>
                    <div className={styles.validationMessage}>{errorMessage}</div>
                    <Button
                        buttonText="Försök igen"
                        type="secondary"
                        callback={() => {
                            reset();
                        }}
                    />
                </>
            )}
        </div>
    );
}
