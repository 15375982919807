import { useEffect, useState } from "react";
import styles from "./SitePrintsList.module.scss";
import { IPrintableCard } from "../../../EditPrint/components/PrintEditor/interfaces/IPrintableCard";
import { IError } from "../../../../../../../../../App";
import { getSitePrints } from "../../../../../../../../services/PrintService";
import Loading from "../../../../../../../../components/Loading/Loading";
import ShowError from "../../../../../../../../components/ShowError/ShowError";
import { PrintableCard } from "../../../EditPrint/components/PrintEditor/components/PrintableCard/PrintableCard";
import { Link } from "react-router-dom";

interface IProps {
    siteId: string;
}

export function SitePrintsList(props: IProps) {
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState<IError | null>(null);

    const [prints, setPrints] = useState<IPrintableCard[] | null>(null);

    useEffect(() => {
        const getPrints = async () => {
            setLoading(true);
            try {
                const data = await getSitePrints(props.siteId);
                setPrints(data.prints);
            } catch (error) {
                setError({ text: "Misslyckades att hämta tryck tillhörande sida", response: (error as any).response });
            }
            setLoading(false);
        };
        getPrints();
    }, [props.siteId]);

    if (loading) {
        return <Loading />;
    }

    if (error) {
        return <ShowError error={error} />;
    }
    if (!prints || !prints.length) {
        return null;
    }

    return (
        <>
            <div className={`${styles.wrapper}`}>
                <h3 className={styles.sectionTitle}>Dina inbjudningar</h3>
                <div className={styles.cardWrappers}>
                {prints.map((print) => (
                    <Link key={print._id} className={styles.cardWrapper} to={`/redigera/${props.siteId}/inbjudningar/${print._id}`}>
                        <PrintableCard thumbnail={true} forPrint={false} page={1} shadow={true} card={print} editMode={false} />
                    </Link>
                ))}
                </div>
            </div>
        </>
    );
}
