import React from "react";
import styles from "./CornerGraphics.module.scss";
import FlowersBlueUrlPng from "./FlowersBlue.png";
import FlowersMultiColorUrlPng from "./FlowersMultiColor.png";
import FlowersRoseUrlPng from "./FlowersRose.png";
import FlowersWaterColorUrlPng from "./FlowersWaterColor.png";
import BallonsPng from "./Ballons.png";
import FlowersBlueUrl from "./FlowersBlue.webp";
import FlowersMultiColorUrl from "./FlowersMultiColor.webp";
import FlowersRoseUrl from "./FlowersRose.webp";
import FlowersWaterColorUrl from "./FlowersWaterColor.webp";
import Ballons from "./Ballons.webp";
import WaterColorVintageFlowersUrl from "./WaterColorVintageFlowers.webp";
import WaterColorVintageFlowersUrlPng from "./WaterColorVintageFlowers.png";

import PurpleGreenFlowersBottomRightUrl from "./PurpleGreenFlowersBottomRight.webp";
import PurpleGreenFlowersBottomRightUrlPng from "./PurpleGreenFlowersBottomRight.png";
import PurpleGreenFlowersTopLeftUrl from "./PurpleGreenFlowersTopLeft.webp";
import PurpleGreenFlowersTopLeftUrlPng from "./PurpleGreenFlowersTopLeft.png";

import GreenLeafsArtificialBottomRightUrl from "./GreenLeafsArtificialBottomRight.webp";
import GreenLeafsArtificialBottomRightUrlPng from "./GreenLeafsArtificialBottomRight.png";
import GreenLeafsArtificialBottomLeftUrl from "./GreenLeafsArtificialBottomLeft.webp";
import GreenLeafsArtificialBottomLeftUrlPng from "./GreenLeafsArtificialBottomLeft.png";
import GreenLeafsArtificialTopRightUrl from "./GreenLeafsArtificialTopRight.webp";
import GreenLeafsArtificialTopRightUrlPng from "./GreenLeafsArtificialTopRight.png";

import ArtificialFlowersRedAndPinkBottomLeftUrl from "./ArtificialFlowersRedAndPinkBottomLeft.webp";
import ArtificialFlowersRedAndPinkBottomLeftUrlPng from "./ArtificialFlowersRedAndPinkBottomLeft.png";
import ArtificialFlowersRedAndPinkTopRightUrl from "./ArtificialFlowersRedAndPinkTopRight.webp";
import ArtificialFlowersRedAndPinkTopRightUrlPng from "./ArtificialFlowersRedAndPinkTopRight.png";
import { TSiteGraphicType } from "../../SiteGraphic";

export const siteGraphicsCornerGraphics = [
    "CORNER_GRAPHICS_BLUE",
    "CORNER_GRAPHICS_MULTI_COLOR",
    "CORNER_GRAPHICS_ROSE",
    "CORNER_GRAPHICS_WATER_COLOR",
    "CORNER_GRAPHICS_BALLOONS",
    "CORNER_GRAPHICS_PURPLE_GREEN_FLOWERS",
    "CORNER_GRAPHICS_WATER_COLOR_VINTAGE_FLOWERS",
    "CORNER_GRAPHICS_GREEN_LEAFS_ARTIFICIAL",
    "CORNER_GRAPHICS_ARTIFICIAL_FLOWERS_RED_AND_PINK",
] as const;
export type TSiteGraphicsCornerGraphics = (typeof siteGraphicsCornerGraphics)[number];

interface IProps {
    cornerGraphics: TSiteGraphicsCornerGraphics;
    editAvailable: boolean;
    type: TSiteGraphicType;
}


export function CornerGraphics(props: IProps) {
    let imageUrl;
    let imageUrlPngFallback;
    let imageUrlTopRight;
    let imageUrlTopRightPngFallback;
    let imageUrlBottomRight;
    let imageUrlBottomRightPngFallback;
    let imageUrlBottomLeft;
    let imageUrlBottomLeftPngFallback;

    if (props.cornerGraphics === "CORNER_GRAPHICS_BLUE") {
        imageUrl = FlowersBlueUrl;
        imageUrlPngFallback = FlowersBlueUrlPng;
    } else if (props.cornerGraphics === "CORNER_GRAPHICS_MULTI_COLOR") {
        imageUrl = FlowersMultiColorUrl;
        imageUrlPngFallback = FlowersMultiColorUrlPng;
    } else if (props.cornerGraphics === "CORNER_GRAPHICS_ROSE") {
        imageUrl = FlowersRoseUrl;
        imageUrlPngFallback = FlowersRoseUrlPng;
    } else if (props.cornerGraphics === "CORNER_GRAPHICS_WATER_COLOR") {
        imageUrl = FlowersWaterColorUrl;
        imageUrlPngFallback = FlowersWaterColorUrlPng;
    } else if (props.cornerGraphics === "CORNER_GRAPHICS_BALLOONS") {
        imageUrl = Ballons;
        imageUrlPngFallback = BallonsPng;
    } else if (props.cornerGraphics === "CORNER_GRAPHICS_WATER_COLOR_VINTAGE_FLOWERS") {
        imageUrl = WaterColorVintageFlowersUrl;
        imageUrlPngFallback = WaterColorVintageFlowersUrlPng;
    } else if (props.cornerGraphics === "CORNER_GRAPHICS_PURPLE_GREEN_FLOWERS") {
        imageUrlTopRight = PurpleGreenFlowersTopLeftUrl;
        imageUrlTopRightPngFallback = PurpleGreenFlowersTopLeftUrlPng;
        imageUrlBottomRight = PurpleGreenFlowersBottomRightUrl;
        imageUrlBottomRightPngFallback = PurpleGreenFlowersBottomRightUrlPng;
        imageUrlBottomLeft = PurpleGreenFlowersTopLeftUrl;
        imageUrlBottomLeftPngFallback = PurpleGreenFlowersTopLeftUrlPng;
    } else if (props.cornerGraphics === "CORNER_GRAPHICS_GREEN_LEAFS_ARTIFICIAL") {
        imageUrlTopRight = GreenLeafsArtificialTopRightUrl;
        imageUrlTopRightPngFallback = GreenLeafsArtificialTopRightUrlPng;
        imageUrlBottomRight = GreenLeafsArtificialBottomRightUrl;
        imageUrlBottomRightPngFallback = GreenLeafsArtificialBottomRightUrlPng;
        imageUrlBottomLeft = GreenLeafsArtificialBottomLeftUrl;
        imageUrlBottomLeftPngFallback = GreenLeafsArtificialBottomLeftUrlPng;
    } else if (props.cornerGraphics === "CORNER_GRAPHICS_ARTIFICIAL_FLOWERS_RED_AND_PINK") {
        imageUrlTopRight = ArtificialFlowersRedAndPinkTopRightUrl;
        imageUrlTopRightPngFallback = ArtificialFlowersRedAndPinkTopRightUrlPng;
        imageUrlBottomRight = ArtificialFlowersRedAndPinkTopRightUrl;
        imageUrlBottomRightPngFallback = ArtificialFlowersRedAndPinkTopRightUrlPng;
        imageUrlBottomLeft = ArtificialFlowersRedAndPinkBottomLeftUrl;
        imageUrlBottomLeftPngFallback = ArtificialFlowersRedAndPinkBottomLeftUrlPng;
    } else {
        throw new Error("No corner graphic named " + props.cornerGraphics);
    }
    return (
        <div className={`${styles.outerWrapper} ${props.editAvailable ? styles.editAvailable : ""} ${styles[props.type]}`}>
            <div
                className={`${styles.wrapper} ${styles[props.cornerGraphics]}`}
            >
                <div className={`${styles.topRight} ${styles[props.cornerGraphics]}`}>
                    <picture>
                        <source srcSet={imageUrlTopRight || imageUrl} type="image/webp" />
                        <source srcSet={imageUrlTopRightPngFallback || imageUrlPngFallback} type="image/png" />
                        <img srcSet={imageUrlTopRightPngFallback} alt="" />
                    </picture>
                </div>
                <div className={`${styles.bottomRight} ${styles[props.cornerGraphics]}`}>
                    <picture>
                        <source srcSet={imageUrlBottomRight || imageUrl} type="image/webp" />
                        <source srcSet={imageUrlBottomRightPngFallback || imageUrlPngFallback} type="image/png" />
                        <img srcSet={imageUrlBottomRightPngFallback || imageUrlPngFallback} alt="" />
                    </picture>
                </div>
                <div className={`${styles.bottomLeft} ${styles[props.cornerGraphics]}`}>
                    <picture>
                        <source srcSet={imageUrlBottomLeft || imageUrl} type="image/webp" />
                        <source srcSet={imageUrlBottomLeftPngFallback || imageUrlPngFallback} type="image/png" />
                        <img srcSet={imageUrlBottomLeftPngFallback || imageUrlPngFallback} alt="" />
                    </picture>
                </div>
            </div>
        </div>
    );
}
