import Axios from "axios";
import { IPaymentReceipt } from "../interfaces/PaymentReceipt";

export const getPaymentReceipt = async (siteId: string, paymentReference: string): Promise<IPaymentReceipt> => {
    const response = await Axios.get(
        process.env.REACT_APP_API_URL + "/api/sites/" + siteId + "/payment/receipt/" + paymentReference
    );
    const site = response.data;
    return site;
};
