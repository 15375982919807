import Axios from "axios";
import { IPrintableCard } from "../views/GeneratorEdit/views/ViewCommunication/components/EditPrint/components/PrintEditor/interfaces/IPrintableCard";

export const postPrint = async (print: IPrintableCard): Promise<string> => {
    const response = await Axios.post(process.env.REACT_APP_API_URL + "/api/prints/", print);
    return response.data.id;
};

export const putPrint = async (id: string, print: IPrintableCard): Promise<void> => {
    await Axios.put(process.env.REACT_APP_API_URL + "/api/prints/" + id, print);
    return;
};

export const getPrint = async (printId: string): Promise<IPrintableCard> => {
    const response = await Axios.get(process.env.REACT_APP_API_URL + "/api/prints/" + printId);
    return response.data;
};

export const getPrintWithServerAccess = async (printId: string, accessKey: string): Promise<IPrintableCard> => {
    const response = await Axios.get(process.env.REACT_APP_API_URL + "/api/prints/" + printId + "/serverAccess/" + accessKey);
    return response.data;
};

export const getSitePrints = async (siteId: string): Promise<{ prints: IPrintableCard[] }> => {
    const response = await Axios.get(process.env.REACT_APP_API_URL + "/api/sites/" + siteId + "/prints/");
    return response.data;
};
