import React from "react";
import ModuleHeading from "../components/ModuleHeading/ModuleHeading";
import GeneratorModule from "../GeneratorModule";
import styles from "./Video.module.scss";
import { ContentVideo } from "../../content/Video/Video";

export class Video extends GeneratorModule {
    render() {
        return (
            <section className={styles[this.props.site.theme]}>
                <ModuleHeading
                    site={this.props.site}
                    propertyParent={this.props.moduleData}
                    showError={this.props.showError}
                    updateProperty={this.props.updateProperty}
                    editMode={this.props.editMode}
                ></ModuleHeading>
                <div className={styles.videoWrapper}>
                    <ContentVideo
                        site={this.props.site}
                        propertyParent={this.props.moduleData}
                        property="video"
                        showError={this.props.showError}
                        updateProperty={this.props.updateProperty}
                        editMode={this.props.editMode}
                    />
                </div>
            </section>
        );
    }
}

export default Video;
