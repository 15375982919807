import { Logotype } from "../../landing/icons/logotype";
import { LogotypeEvent } from "../../landing/icons/logotypeEvent";
import { LogotypeParty } from "../../landing/icons/logotypeParty";
import { Origin } from "../../types/origin";
import { IconLoading } from "../icons/loading";
import { IconLoadingEvent } from "../icons/loadingEvent";
import { IconLoadingParty } from "../icons/loadingParty";

export const isBrand = (brand: Origin) => process.env.REACT_APP_BRAND === brand;

export const getBrand = () => {
    if (process.env.REACT_APP_BRAND === "party") {
        return "Festhemsida.se";
    } else if (process.env.REACT_APP_BRAND === "event") {
        return "Eventsida.se";
    } else {
        return "Bröllopssida.se";
    }
};

export const getBrandedLoadingIcon = () => {
    if (process.env.REACT_APP_BRAND === "party") {
        return <IconLoadingParty />;
    } else if (process.env.REACT_APP_BRAND === "event") {
        return <IconLoadingEvent />;
    } else {
        return <IconLoading />;
    }
};

export const getBrandLogotype = () => {
    if (process.env.REACT_APP_BRAND === "party") {
        return <LogotypeParty />;
    } else if (process.env.REACT_APP_BRAND === "event") {
        return <LogotypeEvent />;
    } else {
        return <Logotype />;
    }
};

export const getBrandedSingleCustomerPageName = () => {
    if (process.env.REACT_APP_BRAND === "party") {
        return "Festhemsida";
    } else if (process.env.REACT_APP_BRAND === "event") {
        return "Eventsida";
    } else {
        return "Bröllopssida";
    }
};

export const getBrandedDomainName = () => {
    if (process.env.REACT_APP_BRAND === "party") {
        return "festhemsida.se";
    } else if (process.env.REACT_APP_BRAND === "event") {
        return "eventsida.se";
    } else {
        return "brollopssida.se";
    }
};

export const getBrandedContactMail = () => {
    if (process.env.REACT_APP_BRAND === "party") {
        return "info@festhemsida.se";
    } else if (process.env.REACT_APP_BRAND === "event") {
        return "info@eventsida.se";
    } else {
        return "info@brollopssida.se";
    }
};

export const getBrandedQuickstartHost = () => {
    if (process.env.REACT_APP_BRAND === "party") {
        return "demo.festhemsida.se";
    } else if (process.env.REACT_APP_BRAND === "event") {
        return "demo.eventsida.se";
    } else {
        return "demo.brollopssida.se";
    }
};