import styles from "./ExamplePrintsList.module.scss";
import PrintExample1Webp from "./print_example_1.webp";
import PrintExample1Png from "./print_example_1.png";
import PrintExample2Webp from "./print_example_2.webp";
import PrintExample2Png from "./print_example_2.png";
import PrintExample3Webp from "./print_example_3.webp";
import PrintExample3Png from "./print_example_3.png";
import PrintExample4Webp from "./print_example_4.webp";
import PrintExample4Png from "./print_example_4.png";

export function ExamplePrintsList() {
    return (
        <div className={`${styles.wrapper}`}>
            <h3 className={styles.sectionTitle}>Exempel på inbjudningskort</h3>
            <div className={styles.cardWrappers}>
                <div>
                    <picture className={styles.exampleImage}>
                        <source srcSet={PrintExample1Webp} type="image/webp" />
                        <source srcSet={PrintExample1Png} type="image/png" />
                        <img srcSet={PrintExample1Png} alt="" />
                    </picture>
                </div>
                <div>
                    <picture className={styles.exampleImage}>
                        <source srcSet={PrintExample2Webp} type="image/webp" />
                        <source srcSet={PrintExample2Png} type="image/png" />
                        <img srcSet={PrintExample2Png} alt="" />
                    </picture>
                </div>
                <div>
                    <picture className={styles.exampleImage}>
                        <source srcSet={PrintExample3Webp} type="image/webp" />
                        <source srcSet={PrintExample3Png} type="image/png" />
                        <img srcSet={PrintExample3Png} alt="" />
                    </picture>
                </div>
                <div>
                    <picture className={styles.exampleImage}>
                        <source srcSet={PrintExample4Webp} type="image/webp" />
                        <source srcSet={PrintExample4Png} type="image/png" />
                        <img srcSet={PrintExample4Png} alt="" />
                    </picture>
                </div>
            </div>
        </div>
    );
}
