import { useState } from "react";
import { IPrintableCard, IPrintableContentImage } from "../../../../interfaces/IPrintableCard";
import styles from "./ContentTypeImage.module.scss";
import { ContentTypeImageEdit } from "./components/ContentTypeImageEdit/ContentTypeImageEdit";
import { getPrintImageSrc } from "./utils/getPrintImageSrc";

interface IProps {
    card: IPrintableCard;
    content: IPrintableContentImage;
    editMode: boolean;
    updateImage: (image: IPrintableContentImage["properties"]) => void;
    aspectRatio: number;
    thumbnail?: boolean;
}

export const ContentTypeImage = (props: IProps) => {

    const [edit, setEdit] = useState(false);

    const handleEdit = () => {
        setEdit(true);
    };

    return (
        <>
            {edit ? (
                <ContentTypeImageEdit
                    image={props.content.properties}
                    updateImage={props.updateImage}
                    close={() => setEdit(false)}
                    printId={props.card._id || "new"}
                    aspectRatio={props.aspectRatio}
                />
            ) : null }
            <img
                alt=""
                onClick={props.editMode ? handleEdit : undefined}
                className={props.editMode ? styles.editMode : ""}
                src={getPrintImageSrc(props.content.properties, props.thumbnail)}
            />
        </>
    );
};
