import React, { useEffect, useRef } from "react";
import styles from "./DrawerSlot.module.scss";

interface IProps {
    children: any;
    setPortalNode: any;
}

export function DrawerSlot(props: IProps) {
    const portalRef = useRef(null);

    useEffect(function () {
        props.setPortalNode(portalRef.current);
        // eslint-disable-next-line
    }, []);

    return (
        <div className={styles.wrapper}>
            <div className={styles.drawerWrapper} ref={portalRef} id="drawerSlot"></div>
            <div className={styles.content}>{props.children}</div>
        </div>
    );
}
