import { withStyles } from "@material-ui/core/styles";
import MaterialSwitch from "@material-ui/core/Switch";
import React from "react";
import styles from "./Switch.module.scss";

interface IProps {
    handleChange: any;
    value: boolean;
    name: string;
    label?: string;
    id: string;
    dark?: boolean;
}

const CustomSwitchLight = withStyles({
    switchBase: {
        color: "#ccc",
        "&$checked": {
            color: "#73c991;",
        },
        "&$checked + $track": {
            backgroundColor: "#73c991;",
        },
    },
    checked: {},
    track: {},
})(MaterialSwitch);

const CustomSwitchDark = withStyles({
    switchBase: {
        color: "#ccc",
        "&$checked": {
            color: "#73c991;",
        },
        "&$checked + $track": {
            backgroundColor: "#73c991;",
        },
    },
    checked: {},
    track: {
        backgroundColor: "rgba(255,255,255,0.5)",
    },
})(MaterialSwitch);

export class Switch extends React.Component<IProps> {
    handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        this.props.handleChange(event.target.checked, this.props.name);
    };

    render() {
        return (
            <div className={styles.wrapper}>
                <div className={styles.switchAndTextWrapper}>
                    <div className={styles.switchWrapper}>
                        {this.props.dark ? (
                            <CustomSwitchDark
                                checked={!!this.props.value}
                                onChange={this.handleChange}
                                name={this.props.name}
                                id={this.props.id}
                            />
                        ) : (
                            <CustomSwitchLight
                                checked={!!this.props.value}
                                onChange={this.handleChange}
                                name={this.props.name}
                                id={this.props.id}
                            />
                        )}
                    </div>
                    <div className={styles.textWrapper}>
                        <label className={styles.label} htmlFor={this.props.id}>
                            {this.props.label}
                        </label>
                    </div>
                </div>
            </div>
        );
    }
}
