import React from "react";

export const Inity = () => {
    return (
        <svg
            id="Layer_1"
            xmlns="http://www.w3.org/2000/svg"
            width="136.371"
            height="62.719"
            viewBox="0 0 136.371 62.719"
        >
            <g fill="#333">
                <path d="M10.266,47.355H0V9.316h10.266V47.355z" />
                <path d="M28.09,9.316l0.457,5.449c1.266-1.945,2.824-3.457,4.676-4.535c1.851-1.078,3.925-1.617,6.223-1.617 c3.844,0,6.843,1.208,9,3.621c2.156,2.414,3.234,6.2,3.234,11.355v23.766H41.379V23.571c0-2.561-0.516-4.375-1.547-5.444 c-1.032-1.069-2.59-1.604-4.676-1.604c-1.359,0-2.579,0.276-3.656,0.828c-1.078,0.552-1.981,1.333-2.707,2.343v27.661H18.527V9.316 H28.09z" />
                <path d="M70.137,47.355H59.871V9.316h10.266V47.355z" />
                <path d="M90.773,0v9.316h6.398v7.207h-6.398v19.371c0,1.477,0.304,2.531,0.914,3.164 c0.609,0.633,1.429,0.949,2.461,0.949c0.539,0,1.002-0.029,1.389-0.088c0.387-0.058,0.838-0.158,1.354-0.299l0.879,7.418 c-1.032,0.352-2.039,0.615-3.023,0.791s-2.051,0.264-3.199,0.264c-3.539,0-6.264-0.972-8.174-2.918 c-1.911-1.945-2.865-5.027-2.865-9.246V16.523h-5.555V9.316h5.555V0H90.773z" />
                <path d="M117.422,31.395l0.422,2.109h0.211l7.277-24.188h11.039l-15.434,43.734 c-1.078,2.719-2.543,5.01-4.395,6.873c-1.852,1.863-4.629,2.795-8.332,2.795c-0.867,0-1.67-0.07-2.408-0.211 s-1.635-0.34-2.689-0.598l1.195-7.488c0.304,0.047,0.633,0.093,0.984,0.141c0.352,0.047,0.668,0.07,0.949,0.07 c1.688,0,2.988-0.417,3.902-1.248c0.914-0.832,1.617-1.869,2.109-3.111l1.195-2.953l-13.5-38.004h11.039L117.422,31.395z" />
            </g>
        </svg>
    );
};
