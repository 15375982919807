import dayjs from "dayjs";
import { IRvspPerson } from "../../../../../interfaces/Rvsp";
import { IDeprecatedValueObject } from "../ViewRvsp";

const hasAnyDeprecatedWeddingYesOrNo = (rsvps: IRvspPerson[]) => {
    return !!rsvps.filter((rsvp) => rsvp.weddingYesOrNo === false || rsvp.weddingYesOrNo === true).length;
};

export const getRsvpsForExport = (
    rsvps: IRvspPerson[],
    customValues: any,
    deprecatedValues: IDeprecatedValueObject[],
    getCustomValueWithLabel: (rsvp: IRvspPerson, label: string) => string
) => {
    const useDeprecatedWeddingYesOrNo = hasAnyDeprecatedWeddingYesOrNo(rsvps);
    let headerRow = [];

    if (useDeprecatedWeddingYesOrNo) {
        headerRow.push({ value: "Kommer" });
    }
    const deprecatedValuesHeaders = deprecatedValues.map((value: IDeprecatedValueObject) => ({ value: value.label }));
    const customValuesHeaders = customValues.map((customValue: any) => ({ value: customValue.label }));
    headerRow = [...headerRow, ...deprecatedValuesHeaders, ...customValuesHeaders, { value: "Datum" }];

    const dataRows = rsvps.map((rsvp) => {
        let columns = [];
        if (useDeprecatedWeddingYesOrNo) {
            let value = null;
            if (rsvp.weddingYesOrNo === true) value = "Ja";
            if (rsvp.weddingYesOrNo === false) value = "Nej";
            columns.push({ type: String, value });
        }

        const deprecatedValuesData = deprecatedValues.map((value: IDeprecatedValueObject) => ({
            type: String,
            value: rsvp[value.key],
        }));

        const date = { type: String, value: dayjs(rsvp.registered!.date).format("YYYY-MM-DD") };

        const customValuesData = customValues.map((customValue: any) => ({
            type: String,
            value: getCustomValueWithLabel(rsvp, customValue.label),
        }));

        columns = [...columns, ...deprecatedValuesData, ...customValuesData, date];

        return columns;
    });

    return [headerRow, ...dataRows];
};
