import React from "react";
import ActionCurtain from "../../components/actionCurtain/ActionCurtain";
import { ISite } from "../../interfaces/Site";
import SignUp from "../SignUp/SignUp";
import styles from "./QuickStartSave.module.scss";

interface IProps {
    close: () => void;
    createSite: any;
    site: ISite;
}

export class QuickStartSave extends React.Component<IProps> {
    render() {
        return (
            <ActionCurtain title={"Registrera dig"} close={this.props.close} preventClosingOnClickOutside={true}>
                <div className={styles.wrapper}>
                    <>
                        <p>
                            För att spara dina ändringar och komma igång med din egen sida behöver du registrera dig.
                            Fyll i din e-postadress och önskat lösenord nedan. Vi delar inte med oss av din e-postadress
                            till tredje part.
                        </p>
                        <SignUp callback={this.props.createSite}></SignUp>
                    </>
                </div>
            </ActionCurtain>
        );
    }
}

export default QuickStartSave;
