import React from "react";
import styles from "./ViewError.module.scss";

export class ViewError extends React.Component {
    render() {
        return (
            <div className={styles.wrapper}>
                <div>
                    <h1>Fel inträffat</h1>
                    <p>
                        Tyvärr inträffade ett fel som inte ska kunna hända. Prova att ladda om sidan. Om det inte
                        fungerar, kontakta oss så hjälper vi dig.
                    </p>
                </div>
            </div>
        );
    }
}
