import { useCallback, useMemo, useState } from "react";
import { Editable, withReact, Slate, RenderElementProps, RenderLeafProps, useFocused } from "slate-react";
import { createEditor, Descendant, Editor } from "slate";
import { withHistory } from "slate-history";
import { Toolbar } from "./components/Toolbar/Toolbar";
import { Link } from "./components/Link/Link";
import styles from "./SlateEditor.module.scss";
import { withLinks } from "./utils/withLinks";
import { CustomEditor } from "./types/SlateTypes";

interface IProps {
    label?: string | JSX.Element;
    value: Descendant[];
    handleChange: (value: Descendant[]) => void;
}

export const SlateEditor = (props: IProps) => {
    const editor = useMemo(() => withReact(withLinks(withHistory(createEditor()))), []);
    const [isDirty, setIsDirty] = useState(false);

    const slateValue = useMemo(() => {
        // Slate throws an error if the value on the initial render is invalid
        // so we directly set the value on the editor in order
        // to be able to trigger normalization on the initial value before rendering
        editor.children = props.value;
        Editor.normalize(editor, { force: true });
        editor.history = {
            redos: [],
            undos: [],
        };
        // We return the normalized internal value so that the rendering can take over from here
        return editor.children;
    }, [editor, props.value]);

    const handleChange = (value: Descendant[]) => {
        // Not the best way, but it should work. Making sure the first serializing isnt triggering an update.
        if (isDirty) {
            props.handleChange(value);
        } else {
            if (editor.history.redos.length || editor.history.undos.length) {
                setIsDirty(true);
                props.handleChange(value);
            }
        }
    };

    return (
        <div className={styles.wrapper}>
            {slateValue ? (
                <Slate editor={editor} value={slateValue} onChange={handleChange}>
                    <SlateEditorContent editor={editor} />
                </Slate>
            ) : (
                ""
            )}
        </div>
    );
};

// The default scroll is jumpy, disable it instead
const disableScrollIntoView = () => {};

const SlateEditorContent = ({ editor }: { editor: CustomEditor }) => {
    const renderElement = useCallback((props: any) => <RenderElement {...props} />, []);
    const renderLeaf = useCallback((props: any) => <RenderLeaf {...props} />, []);
    const focused = useFocused();

    return (
        <>
            <Toolbar editor={editor} editorHasFocus={focused} />
            <div className={`${styles.editable} ${focused ? styles.focused : ""}`}>
                <Editable
                    renderElement={renderElement}
                    scrollSelectionIntoView={disableScrollIntoView}
                    placeholder={"Ingen text"}
                    renderLeaf={renderLeaf}
                    spellCheck
                />
            </div>
        </>
    );
};

const RenderElement = ({ attributes, children, element }: RenderElementProps) => {
    switch (element.type) {
        case "link":
            return (
                <Link element={element} attributes={attributes}>
                    {children}
                </Link>
            );
        case "heading-three":
            return <h3 {...attributes}>{children}</h3>;
        default:
            return <p {...attributes}>{children}</p>;
    }
};

const RenderLeaf = ({ attributes, children, leaf }: RenderLeafProps) => {
    if ("bold" in leaf) {
        children = <strong>{children}</strong>;
    }

    if ("italic" in leaf) {
        children = <em>{children}</em>;
    }

    if ("underlined" in leaf) {
        children = <u>{children}</u>;
    }

    return <span {...attributes}>{children}</span>;
};
