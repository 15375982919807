import React from "react";
import Content, { IContentProps } from "../Content";
import styles from "./OneLine.module.scss";

interface IProps extends IContentProps {
    placeholder: string;
}

export class ContentOneLine extends Content<IProps> {
    render() {
        return this.props.editMode === true ? (
            <div
                contentEditable
                placeholder={this.props.placeholder}
                style={this.props.style}
                className={`${this.props.className} ${styles.editable}`}
                suppressContentEditableWarning={true}
                onBlur={this.onContentChange}
            >
                {this.propValue()}
            </div>
        ) : (
            <div className={this.props.className} style={this.props.style}>
                {this.propValue()}
            </div>
        );
    }
}

export default ContentOneLine;
