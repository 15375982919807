import React from "react";
import ContentH3 from "../../content/h3/H3";
import ContentImage from "../../content/image/Image";
import ContentMap from "../../content/Map/Map";
import ContentRTE from "../../content/RTE/RTE";
import ModuleHeading from "../components/ModuleHeading/ModuleHeading";
import GeneratorModule from "../GeneratorModule";
import styles from "./Location.module.scss";

export class Location extends GeneratorModule {
    render() {
        return (
            <section className={styles[this.props.site.theme]}>
                <ModuleHeading
                    site={this.props.site}
                    propertyParent={this.props.moduleData}
                    showError={this.props.showError}
                    updateProperty={this.props.updateProperty}
                    editMode={this.props.editMode}
                ></ModuleHeading>
                <div className={styles.imageMapTextWrapper}>
                    <div className={styles.imageWrapper}>
                        <ContentImage
                            site={this.props.site}
                            propertyParent={this.props.moduleData}
                            property="image"
                            showError={this.props.showError}
                            updateProperty={this.props.updateProperty}
                            editMode={this.props.editMode}
                            addOrUpdateImageToSite={this.props.addOrUpdateImageToSite}
                            sizes="(min-width: 750px) 650px, 100vw"
                        ></ContentImage>
                    </div>
                    <div className={styles.textContentWrapper}>
                        <div className={styles.innerTextContentWrapper}>
                            <ContentH3
                                site={this.props.site}
                                propertyParent={this.props.moduleData}
                                showError={this.props.showError}
                                property="secondTitle"
                                updateProperty={this.props.updateProperty}
                                editMode={this.props.editMode}
                            ></ContentH3>
                            <div className={styles.textWrapper}>
                                <ContentRTE
                                    site={this.props.site}
                                    propertyParent={this.props.moduleData}
                                    showError={this.props.showError}
                                    property="text"
                                    updateProperty={this.props.updateProperty}
                                    editMode={this.props.editMode}
                                ></ContentRTE>
                            </div>
                        </div>
                    </div>
                    <div className={styles.mapWrapper}>
                        <ContentMap
                            site={this.props.site}
                            propertyParent={this.props.moduleData}
                            showError={this.props.showError}
                            property="map"
                            updateProperty={this.props.updateProperty}
                            editMode={this.props.editMode}
                        ></ContentMap>
                    </div>
                </div>
            </section>
        );
    }
}

export default Location;
