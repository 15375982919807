import React from "react";
import IconChevronDown from "../../../landing/icons/chevronDown";
import styles from "./ExpandableArea.module.scss";

interface IProps {
    title: string;
    initiallyExpanded?: boolean;
    children?: JSX.Element;
}

interface IState {
    expanded: boolean;
}

export class ExpandableArea extends React.Component<IProps, IState> {
    state: IState = {
        expanded: this.props.initiallyExpanded || false,
    };

    toggleExpanded = () => {
        this.setState({
            expanded: !this.state.expanded,
        });
    };

    render() {
        return (
            <div className={this.state.expanded ? styles.wrapperExpanded : styles.wrapper}>
                <div className={styles.top} onClick={this.toggleExpanded}>
                    <div className={styles.title}>{this.props.title}</div>
                    <div className={styles.icon}>
                        <IconChevronDown />
                    </div>
                </div>
                <div className={styles.content}>{this.props.children}</div>
            </div>
        );
    }
}

export default ExpandableArea;
