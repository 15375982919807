import MaterialSlider from "@material-ui/core/Slider/Slider";
import withStyles from "@material-ui/core/styles/withStyles";
import React from "react";
import styles from "./Slider.module.scss";

interface IProps {
    onChangeCallback: any;
    value?: number;
    name: string;
    id: string;
    label?: string;
    max: number;
    min: number;
}

const CustomMaterialSlider = withStyles({
    root: {
        color: "#CCC",
        height: 8,
    },
    thumb: {
        height: 24,
        width: 24,
        backgroundColor: "#fff",
        border: "2px solid currentColor",
        marginTop: -8,
        marginLeft: -12,
        "&:focus, &:hover, &$active": {
            boxShadow: "inherit",
        },
    },
    active: {},
    valueLabel: {
        left: "calc(-50% + 4px)",
    },
    track: {
        height: 8,
        borderRadius: 4,
    },
    rail: {
        height: 8,
        borderRadius: 4,
    },
})(MaterialSlider);

export function Slider(props: IProps) {
    const handleChange = (e: any, value: any) => {
        props.onChangeCallback(value, props.name);
    };

    return (
        <div className={styles.wrapper}>
            <div className={styles.labelWrapper}>
                <label className={styles.label} htmlFor={props.id}>
                    {props.label}
                </label>
            </div>
            <div className={styles.sliderWrapper}>
                <CustomMaterialSlider
                    id={props.id}
                    valueLabelDisplay="off"
                    value={props.value}
                    min={props.min}
                    max={props.max}
                    onChange={handleChange}
                />
            </div>
        </div>
    );
}
