import React from "react";
import { ISite } from "../../interfaces/Site";
import styles from "./SiteQrCode.module.scss";
import qrExample from "./example-qr.png";
import { getBrandedDomainName } from "../../utils/getBrand";

interface IProps {
    site?: ISite;
    showError: any;
}

export const SiteQrCode = (props: IProps) => {
    const qrSrc = props.site?.host
        ? `${process.env.REACT_APP_API_URL}/api/sites/${props.site._id}/qr/${props.site.host}/png`
        : qrExample;

    return (
        <div className={styles.wrapper}>
            <div className={styles.qrCodeWrapper}>
                <a className={styles.qrCode} download href={qrSrc}>
                    <img alt="QR-kod" src={qrSrc} />
                </a>
            </div>
            <div className={styles.text}>
                När du bjuder in kan du förutom att skicka adressen lägga till den här QR-koden som går till{" "}
                {props.site?.host || "exempel." + getBrandedDomainName()}.{" "}
                {props.site?.host ? (
                    <>
                        Tryck på QR-koden så laddas den ner som en bild. Tänk på att prova QR-koden innan du skickar den
                        till dina gäster.
                    </>
                ) : (
                    <strong>Du måste välja en adress ovan innan du får din riktiga QR-kod</strong>
                )}
            </div>
        </div>
    );
};
