import React from "react";
import { enumTheme, ISite } from "../../interfaces/Site";
import { getSiteThemeGeneration } from "../../utils/getSiteThemeGeneration";
import styles from "./EditTheme.module.scss";
import { ThemeItem } from "./ThemeItem/ThemeItem";

interface IProps {
    site: ISite;
    setTheme: (theme: enumTheme) => void;
}

export class EditTheme extends React.Component<IProps> {
    themesCollectionV1 = ["theme2", "theme3"];
    themesCollectionV2 = ["theme4", "theme5", "theme7", "theme8", "theme9", "theme10", "theme11"];
    themesCollection =
        getSiteThemeGeneration(this.props.site) === "V2" ? this.themesCollectionV2 : this.themesCollectionV1;

    render() {
        return (
            <div className={styles.wrapper} data-test-id="edit-theme-list">
                {this.themesCollection.map((theme) => (
                    <ThemeItem
                        currentTheme={this.props.site.theme}
                        key={theme}
                        setTheme={this.props.setTheme}
                        theme={theme}
                    />
                ))}
            </div>
        );
    }
}
