import React from "react";
import { RichTextEditor } from "../../components/RichTextEditor/RichTextEditor";
import Content, { IContentProps } from "../Content";
import "./RTE.scss";
import { rteContentIsEmpty } from "../../utils/rteContentIsEmpty";

export class ContentRTE extends Content<IContentProps> {
    createMarkup = (placeholder: boolean) => {
        let markup = "<span></span>";
        if (placeholder === true) {
            markup =
                '<span class="rte-placeholder">Inget innehåll, tryck för att börja skriva (den här texten visas inte för besökare).</span>';
        }
        const data = this.propValue();
        if (!rteContentIsEmpty(data)) {
            markup = data;
        }

        return { __html: markup };
    };

    render() {
        return this.props.editMode === true ? (
            <div className="rte-wrapper">
                <RichTextEditor initialValue={this.propValue()} onContentChange={this.onContentChangeRTE} />
            </div>
        ) : (
            <div className="rte-wrapper">
                <div dangerouslySetInnerHTML={this.createMarkup(false)}></div>
            </div>
        );
    }
}

export default ContentRTE;
