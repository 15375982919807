import dayjs from "dayjs";
import React, { useEffect, useState } from "react";
import { IError } from "../../../../../App";
import Button from "../../../../components/button/Button";
import Loading from "../../../../components/Loading/Loading";
import ShowError from "../../../../components/ShowError/ShowError";
import { IAccount } from "../../../../interfaces/Account";
import { getAccountRelatedToSite, postLogout } from "../../../../services/AccountService";
import { getBrandedContactMail, getBrandedDomainName } from "../../../../utils/getBrand";
import styles from "./ViewAccount.module.scss";
import { LinkButton } from "../../../../components/button/LinkButton";

interface IProps {
    siteId: string;
}

export function ViewAccount(props: IProps) {
    const [accountData, setAccountData] = useState<IAccount | null>(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState<IError | null>(null);

    useEffect(() => {
        const fetchAccount = async () => {
            setLoading(true);
            try {
                const data = await getAccountRelatedToSite(props.siteId);
                setAccountData(data);
            } catch (error) {
                setError({ text: "Misslyckades att hämta användare", response: (error as any).response });
            }
            setLoading(false);
        };
        fetchAccount();
    }, [props.siteId]);

    const logout = async () => {
        setLoading(true);
        try {
            await postLogout();
            window.location.href = "https://" + getBrandedDomainName();
        } catch (error) {
            setLoading(false);
            setError({ text: "Misslyckades att logga ut", response: (error as any).response });
        }
    };

    return (
        <div className={styles.wrapper}>
            <ShowError error={error}></ShowError>
            {loading === true || !accountData ? (
                <Loading inverted={true} block={true}></Loading>
            ) : (
                <div className={styles.grid}>
                    <div className={styles.section}>
                        <h3 className={styles.sectionTitle}>Konto</h3>
                        <div>E-post: {accountData!.mail}</div>
                        <div>Registrerad: {dayjs(accountData!.registered?.date).format("YYYY-MM-DD")}</div>
                        <div className={styles.accountButtonsWrapper}>
                            <div className={styles.logoutButtonWrapper}>
                                <Button
                                    type="secondary"
                                    buttonText="Logga ut"
                                    callback={() => {
                                        logout();
                                    }}
                                />
                            </div>
                            <div className={styles.resetPassword}>
                                <LinkButton to="/aterstall-losenord" type={"secondary"}>
                                    Ändra lösenord
                                </LinkButton>
                            </div>
                        </div>
                    </div>
                    <div className={styles.section}>
                        <h3 className={styles.sectionTitle}>Besöksräknare</h3>
                        <div className={styles.visitorCountWrapper}>
                            <span className={styles.count}>{accountData!.site?.visitorCount || 0}</span>
                            <span className={styles.text}>besökare</span>
                        </div>
                        <div className={styles.descriptionText}>
                            Antalet besökare är ungefärligt. Om till exempel en gäst går till din sida först på datorn
                            och sedan på mobilen kommer det att registreras som två besökare.
                        </div>
                    </div>
                    {accountData!.site?.active ? (
                        <div className={styles.section}>
                            <h3 className={styles.sectionTitle}>Din sida</h3>
                            {accountData!.site?.activeDate ? (
                                <div>Publicerad: {dayjs(accountData!.site?.activeDate).format("YYYY-MM-DD")}</div>
                            ) : (
                                ""
                            )}
                            <div>
                                Raderas:{" "}
                                {dayjs(accountData!.site?.activeExpireDate || "2022-11-01").format("YYYY-MM-DD")}
                            </div>
                            {accountData!.site?.customDomains?.length ? (
                                <div>
                                    Domän: {accountData.site.customDomains[0].domain}, utgår{" "}
                                    {dayjs(accountData.site.customDomains[0].expires).format("YYYY-MM-DD")}
                                </div>
                            ) : (
                                ""
                            )}
                            <div className={styles.descriptionText}>
                                Behöver du förlänga? Mejla oss:{" "}
                                <a className={styles.mailLink} href={"mailto:" + getBrandedContactMail()}>
                                    {getBrandedContactMail()}
                                </a>
                            </div>
                        </div>
                    ) : (
                        ""
                    )}
                </div>
            )}
        </div>
    );
}
