import React from "react";
import { getBrandedDomainName } from "../../generator/utils/getBrand";
import styles from "./View404.module.scss";

export class View404 extends React.Component {
    render() {
        return (
            <div className={styles.wrapper}>
                <div>
                    <h1>Fel adress</h1>
                    <p>
                        Tyvärr finns det ingenting på den här adressen. Antagligen har sidan tagits bort eller bytt
                        namn.
                    </p>

                    <a className={styles.backLink} href={"https://" + getBrandedDomainName()}>
                        Till startsidan
                    </a>
                </div>
            </div>
        );
    }
}
