import React from "react";
import { withRouter } from "react-router-dom";
import { logError } from "../../generator/utils/LogError";

class RouterScrollToTop extends React.Component<any> {
    componentDidUpdate(prevProps: any) {
        try {
            if (this.props.location.pathname !== prevProps.location.pathname) {
                window.scrollTo(0, 0);
            }
        } catch (e) {
            logError("RouterScrollToTop.componentDidUpdate", e, 3);
        }
    }

    render() {
        return null;
    }
}

export default withRouter(RouterScrollToTop);
