import React from "react";
import EditButton from "../../components/EditButton/EditButton";
import YesOrNo from "../../components/form/YesOrNo/YesOrNo";
import { IconNewContentModule } from "../../icons/newContentModule";
import { randomId } from "../../utils/Random";
import Content, { IContentProps } from "../Content";
import styles from "./MultipleYesOrNo.module.scss";
import { YesOrNoItem } from "./YesOrNoItem/YesOrNoItem";

export interface IMultipleYesOrNoItem {
    id: string;
    label: string;
    labelYes: string;
    labelNo: string;
}

interface IProps extends IContentProps {
    updateCustomField: any;
    id: string;
}

export class ContentMultipleYesOrNo extends Content<IProps> {
    contentType: string = "MultipleYesOrNo";

    addItem = () => {
        let newPropValue = this.propValue() && this.propValue().length ? this.propValue() : [];

        newPropValue.push({
            id: "yesOrNo" + randomId(),
            label: "En fråga att svara på?",
            labelYes: "Ja",
            labelNo: "Nej",
        });

        this.updateContent(newPropValue);
    };

    removeItem = (yesOrNoItem: IMultipleYesOrNoItem) => {
        const index = this.propValue().indexOf(yesOrNoItem);
        const collection = this.propValue();
        collection.splice(index, 1);
        this.updateContent(collection);
    };

    updateLabel = (yesOrNoItem: IMultipleYesOrNoItem, newLabel: string) => {
        const index = this.propValue().indexOf(yesOrNoItem);
        const collection = this.propValue();
        collection[index].label = newLabel;
        this.updateContent(collection);
    };

    updateYesLabel = (yesOrNoItem: IMultipleYesOrNoItem, newLabel: string) => {
        const index = this.propValue().indexOf(yesOrNoItem);
        const collection = this.propValue();
        collection[index].labelYes = newLabel;
        this.updateContent(collection);
    };

    updateNoLabel = (yesOrNoItem: IMultipleYesOrNoItem, newLabel: string) => {
        const index = this.propValue().indexOf(yesOrNoItem);
        const collection = this.propValue();
        collection[index].labelNo = newLabel;
        this.updateContent(collection);
    };

    onChangeCallback = (value: string, name: string, label: string) => {
        this.props.updateCustomField(value, name, "YesOrNo", label);
    };

    render() {
        return this.props.editMode === true ? (
            <div className={styles.yesOrNoGroup}>
                {this.propValue() && this.propValue().length
                    ? (this.propValue() as IMultipleYesOrNoItem[]).map((yesOrNoItem) => (
                          <div className={styles.yesOrNoWrapper} key={yesOrNoItem.id}>
                              <YesOrNoItem
                                  yesOrNoItem={yesOrNoItem}
                                  updateLabel={this.updateLabel.bind(this)}
                                  updateYesLabel={this.updateYesLabel.bind(this)}
                                  updateNoLabel={this.updateNoLabel.bind(this)}
                                  removeItem={this.removeItem.bind(this)}
                                  id={this.props.id + "-" + yesOrNoItem.id}
                                  site={this.props.site}
                              />
                          </div>
                      ))
                    : ""}
                <div className={styles.yesOrNoWrapper}>
                    <div className={styles.addNewYesOrNoInactive}>
                        <YesOrNo
                            label={"En fråga att svara på?"}
                            disabled={true}
                            labelNo={"Nej"}
                            labelYes={"Ja"}
                            name={"newYesOrNo"}
                            id={this.props.id + "-newYesOrNo"}
                            onChangeCallback={() => {}}
                            site={this.props.site}
                        />
                    </div>
                    <div className={styles.addNewYesOrNoButton}>
                        <EditButton callback={this.addItem}>
                            <>
                                <IconNewContentModule></IconNewContentModule>
                                Lägg till ja/nej-fråga
                            </>
                        </EditButton>
                    </div>
                </div>
            </div>
        ) : (
            <div>
                <div className={styles.yesOrNoGroup}>
                    {this.propValue() && this.propValue().length
                        ? (this.propValue() as IMultipleYesOrNoItem[]).map((yesOrNoItem) => (
                              <div className={styles.yesOrNoWrapper} key={yesOrNoItem.id}>
                                  <YesOrNo
                                      label={yesOrNoItem.label}
                                      disabled={false}
                                      labelNo={yesOrNoItem.labelNo}
                                      labelYes={yesOrNoItem.labelYes}
                                      name={yesOrNoItem.id}
                                      id={this.props.id + "-" + yesOrNoItem.id}
                                      onChangeCallback={(value: string, name: string, valueLabel: string) => {
                                          this.onChangeCallback(valueLabel, name, yesOrNoItem.label);
                                      }}
                                      site={this.props.site}
                                  />
                              </div>
                          ))
                        : ""}
                </div>
            </div>
        );
    }
}

export default ContentMultipleYesOrNo;
