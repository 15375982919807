import React from "react";
import ContentRTE from "../../content/RTE/RTE";
import ModuleHeading from "../components/ModuleHeading/ModuleHeading";
import GeneratorModule from "../GeneratorModule";
import styles from "./Text.module.scss";

export class Text extends GeneratorModule {
    render() {
        return (
            <section className={styles[this.props.site.theme]}>
                <ModuleHeading
                    site={this.props.site}
                    propertyParent={this.props.moduleData}
                    showError={this.props.showError}
                    updateProperty={this.props.updateProperty}
                    editMode={this.props.editMode}
                ></ModuleHeading>
                <div className={styles.textContentWrapper}>
                    <ContentRTE
                        site={this.props.site}
                        propertyParent={this.props.moduleData}
                        showError={this.props.showError}
                        property="text"
                        updateProperty={this.props.updateProperty}
                        editMode={this.props.editMode}
                    ></ContentRTE>
                </div>
            </section>
        );
    }
}

export default Text;
