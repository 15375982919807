import dayjs from "dayjs";
import React from "react";
import styles from "./CountDown.module.scss";

interface IProps {
    date: Date;
    textDays: any;
    textHours: any;
    textMinutes: any;
    textSeconds: any;
    editDateCallback: any;
}

interface IState {
    days: number;
    hours: number;
    min: number;
    sec: number;
}

class Countdown extends React.Component<IProps, IState> {
    state: IState = {
        days: 0,
        hours: 0,
        min: 0,
        sec: 0,
    };

    interval: any;

    componentDidMount() {
        const updateCountDown = () => {
            const date = this.calculateCountdown(this.props.date);
            date ? this.setState(date) : this.stop();
        };
        updateCountDown();
        this.interval = setInterval(updateCountDown, 1000);
    }

    componentWillUnmount() {
        this.stop();
    }

    calculateCountdown(endDate: any) {
        //let diff = (endDateAsDate.getTime() - new Date().getTime());
        const endDateTransformed = dayjs(endDate);
        const now = dayjs();
        let diff = dayjs(endDateTransformed).diff(now);

        const timeLeft = {
            days: 0,
            hours: 0,
            min: 0,
            sec: 0,
        };

        // clear countdown when date is reached
        if (diff <= 0) return timeLeft;

        diff = Math.abs(diff / 1000);

        // calculate time difference between now and expected date

        if (diff >= 86400) {
            // 24 * 60 * 60
            timeLeft.days = Math.floor(diff / 86400);
            diff -= timeLeft.days * 86400;
        }
        if (diff >= 3600) {
            // 60 * 60
            timeLeft.hours = Math.floor(diff / 3600);
            diff -= timeLeft.hours * 3600;
        }
        if (diff >= 60) {
            timeLeft.min = Math.floor(diff / 60);
            diff -= timeLeft.min * 60;
        }
        timeLeft.sec = Math.floor(diff);

        return timeLeft;
    }

    stop() {
        clearInterval(this.interval);
    }

    addLeadingZeros(value: number) {
        let newValue = String(value);
        /*
    while (newValue.length < 2) {
        newValue = '0' + newValue;
    }
    */
        return newValue;
    }

    render() {
        return (
            <div className="count-down-wrapper">
                <span className="count-down-col">
                    <strong
                        className={this.props.editDateCallback ? styles.markAsEditable : ""}
                        onClick={this.props.editDateCallback}
                    >
                        {this.addLeadingZeros(this.state.days)}
                    </strong>
                    <span>{this.props.textDays}</span>
                </span>

                <span className="count-down-col">
                    <strong
                        className={this.props.editDateCallback ? styles.markAsEditable : ""}
                        onClick={this.props.editDateCallback}
                    >
                        {this.addLeadingZeros(this.state.hours)}
                    </strong>
                    <span>
                        <span>{this.props.textHours}</span>
                    </span>
                </span>

                <span className="count-down-col">
                    <strong
                        className={this.props.editDateCallback ? styles.markAsEditable : ""}
                        onClick={this.props.editDateCallback}
                    >
                        {this.addLeadingZeros(this.state.min)}
                    </strong>
                    <span>
                        <span>{this.props.textMinutes}</span>
                    </span>
                </span>

                <span className="count-down-col">
                    <strong
                        className={this.props.editDateCallback ? styles.markAsEditable : ""}
                        onClick={this.props.editDateCallback}
                    >
                        {this.addLeadingZeros(this.state.sec)}
                    </strong>
                    <span>
                        <span>{this.props.textSeconds}</span>
                    </span>
                </span>
            </div>
        );
    }
}

export default Countdown;
