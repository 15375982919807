import React from "react";
import ContentH2 from "../../../content/h2/H2";
import { ISite } from "../../../interfaces/Site";
import styles from "./ModuleHeading.module.scss";

interface IProps {
    site: ISite;
    propertyParent: any;
    showError: any;
    updateProperty: any;
    editMode: boolean;
}

export class ModuleHeading extends React.Component<IProps> {
    render() {
        return (
            <div className={styles[this.props.site.theme]}>
                <hr className={styles.hr} />
                <ContentH2
                    site={this.props.site}
                    propertyParent={this.props.propertyParent}
                    property="title"
                    showError={this.props.showError}
                    updateProperty={this.props.updateProperty}
                    editMode={this.props.editMode}
                ></ContentH2>
            </div>
        );
    }
}

export default ModuleHeading;
