import React from "react";
import { Link } from "react-router-dom";
import styles from "./RvspPostConfirmation.module.scss";

interface IProps {
    returnUrl: string;
    quickError: boolean;
}

export class RvspPostConfirmation extends React.Component<IProps> {
    render() {
        return (
            <div className={styles.wrapper}>
                {this.props.quickError === true ? (
                    <div>
                        <h1>Tyvärr gick det fel!</h1>
                        <p>
                            Tyvärr lyckades vi inte spara din OSA då vi identifierade dig som en robot. Vänligen fyll i
                            en gång till och vänta några sekunder innan du trycker på skicka. Vi ber om ursäkt för
                            besväret!
                        </p>

                        <Link className={styles.backLink} to={this.props.returnUrl}>
                            Tillbaka
                        </Link>
                    </div>
                ) : (
                    <div>
                        <h1>Tack!</h1>
                        <p>Tack för att du OSA, vi har tagit emot dina uppgifter.</p>

                        <Link className={styles.backLink} to={this.props.returnUrl}>
                            Tillbaka
                        </Link>
                    </div>
                )}
            </div>
        );
    }
}
