import Axios from "axios";
import { ISite } from "../interfaces/Site";
import { transformSiteIn, transformSiteOut } from "../utils/TransformSite";

export const getPublicSite = async (host: string): Promise<ISite> => {
    const response = await Axios.get(process.env.REACT_APP_API_URL + "/api/sites/public/" + host);
    const siteData = response.data;
    const site = transformSiteIn(siteData);
    return site;
};

export const getEditSite = async (siteId: string): Promise<ISite> => {
    const response = await Axios.get(process.env.REACT_APP_API_URL + "/api/sites/" + siteId);
    const site = transformSiteIn(response.data);
    return site;
};

export const putEditSite = async (site: ISite): Promise<ISite> => {
    const response = await Axios.put(process.env.REACT_APP_API_URL + "/api/sites/" + site._id, transformSiteOut(site));
    return transformSiteIn(response.data.site);
};

export const postCreateSite = async (site: ISite): Promise<string> => {
    const response = await Axios.post(
        process.env.REACT_APP_API_URL + "/api/sites/",
        transformSiteOut(site, { stripId: true })
    );
    return response.data.site._id;
};

export const getEditQuickStartSite = async (host: string = ""): Promise<ISite> => {
    const response = await Axios.get(process.env.REACT_APP_API_URL + "/api/sites/quick-start/" + host);
    const site = transformSiteIn(response.data);
    return site;
};

export const postPublicSiteLogin = async (host: string, password: string): Promise<ISite> => {
    const response = await Axios.post(process.env.REACT_APP_API_URL + "/api/sites/public/" + host + "/login", {
        password,
    });
    const site = transformSiteIn(response.data);
    return site;
};
