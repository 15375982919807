import React from "react";
import { NavLink } from "react-router-dom";
import { IconHelp } from "../../../../icons/help";
import { IconHome } from "../../../../icons/home";
import { IconRvsp } from "../../../../icons/rvsp";
import IconUser from "../../../../icons/user";
import styles from "./GeneratorEditMenu.module.scss";
import { IconMail } from "../../../../icons/mail";

interface IProps {
    siteId?: string;
    showHelp: boolean;
    setShowHelp: (showHelp: boolean) => void;
    quickStart: boolean;
}

export function GeneratorEditMenu(props: IProps) {
    const toggleShowHelp = () => {
        props.setShowHelp(!props.showHelp);
    };
    return (
        <>
            <div className={styles.wrapper}>
                <div className={styles.menuItems}>
                    <>
                        <NavLink
                            exact
                            activeClassName={styles.active}
                            to={props.quickStart ? "/snabbstart" : "/redigera/" + props.siteId + "/"}
                        >
                            <span className={styles.icon}>{IconHome()}</span>
                            <span className={styles.text}>Min sida</span>
                        </NavLink>
                        <NavLink
                            exact
                            activeClassName={styles.active}
                            to={props.quickStart ? "/snabbstart/visa-OSA/" : "/redigera/" + props.siteId + "/visa-OSA/"}
                        >
                            <span className={styles.icon}>{IconRvsp()}</span>
                            <span className={styles.text}>OSA</span>
                        </NavLink>
                        {process.env.REACT_APP_BRAND === "wedding" ? (
                            <NavLink
                                exact
                                activeClassName={styles.active}
                                to={
                                    props.quickStart
                                        ? "/snabbstart/inbjudningar/"
                                        : "/redigera/" + props.siteId + "/inbjudningar/"
                                }
                                data-test-id="generator-edit-menu-settings"
                            >
                                <span className={styles.icon}>{IconMail()}</span>
                                <span className={styles.text}>Inbjudningar</span>
                            </NavLink>
                        ) : null}
                    </>
                </div>
                <div className={styles.additionalOptions}>
                    <button onClick={toggleShowHelp} className={props.showHelp ? styles.active : ""}>
                        <span className={styles.icon}>{IconHelp()}</span>
                        <span className={styles.text}>Hjälp</span>
                    </button>
                    {!props.quickStart && (
                        <NavLink exact activeClassName={styles.active} to={"/redigera/" + props.siteId + "/konto/"}>
                            <span className={styles.icon}>{IconUser()}</span>
                            <span className={styles.text}>Konto</span>
                        </NavLink>
                    )}
                </div>
            </div>
        </>
    );
}
