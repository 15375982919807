import React from "react";
import ActionCurtain from "../../components/actionCurtain/ActionCurtain";
import Button from "../../components/button/Button";
import styles from "./InitialHelp.module.scss";

interface IProps {
    close: any;
}

export class InitialHelp extends React.Component<IProps> {
    render() {
        return (
            <ActionCurtain title="Kul att du provar vår tjänst!" close={this.props.close}>
                <>
                    <div className={styles.wrapper}>
                        <p>
                            Tryck på det du vill ändra, oavsett om
                            det är en bild, en text eller en karta. Sidan är uppbyggd i moduler, överst i mitten av varje
                            modul finner du en svart knapp där det står Modul där du kan flytta moduler upp och ner och
                            lägga till nya.
                        </p>
                        <p>
                            Vi har fyllt din sida med några moduler och exempelinformation i dem för att du snabbt ska
                            komma igång. Du kan självklart ändra, ta bort och lägga till hur mycket du vill.
                        </p>
                        <Button
                            data-test-id="button-get-going"
                            type="primary"
                            callback={this.props.close}
                            buttonText="Kom igång!"
                        />
                    </div>
                </>
            </ActionCurtain>
        );
    }
}
