import styles from "./ShippingAddress.module.scss";
import { IShippingAddress } from "../../../../../../interfaces/Order";
import TextField from "../../../../../../components/form/TextField/TextField";
import FormWrapper from "../../../../../../components/form/FormWrapper/FormWrapper";
import Button from "../../../../../../components/button/Button";
import { useState } from "react";

interface IProps {
    setShippingAddress: (shippingAddress: IShippingAddress | null) => void;
    shippingAddress?: IShippingAddress | null;
}

export function ShippingAddress(props: IProps) {
    const [shippingAddress, setShippingAddress] = useState<Partial<IShippingAddress>>({});
    const handleChange = (value: string, name: keyof IShippingAddress) => {
        setShippingAddress({ ...shippingAddress, [name]: value });
    };

    const submit = () => {
        props.setShippingAddress(shippingAddress as IShippingAddress);
    };

    return (
        <div>
            <FormWrapper
                submit={submit}
                className={`${styles.wrapper} ${props.shippingAddress ? styles.disabled : ""}`}
            >
                <div className={styles.row}>
                    <div>
                        <TextField
                            value={shippingAddress?.firstName}
                            required={true}
                            label="Förnamn"
                            onChangeCallback={handleChange}
                            id="firstName"
                            name="firstName"
                        />
                    </div>
                    <div>
                        <TextField
                            value={shippingAddress?.lastName}
                            required={true}
                            label="Efternamn"
                            onChangeCallback={handleChange}
                            id="lastName"
                            name="lastName"
                        />
                    </div>
                </div>
                <div className={styles.row}>
                    <div>
                        <TextField
                            value={shippingAddress?.addressLine1}
                            required={true}
                            label="Gatuadress"
                            onChangeCallback={handleChange}
                            id="addressLine1"
                            name="addressLine1"
                        />
                    </div>
                </div>
                <div className={styles.row}>
                    <div>
                        <TextField
                            value={shippingAddress?.postCode}
                            required={true}
                            label="Postnummer"
                            onChangeCallback={handleChange}
                            id="postCode"
                            name="postCode"
                        />
                    </div>
                    <div>
                        <TextField
                            value={shippingAddress?.city}
                            required={true}
                            label="Postort"
                            onChangeCallback={handleChange}
                            id="city"
                            name="city"
                        />
                    </div>
                </div>
                <div className={styles.row}>
                    <div>
                        <TextField
                            value={shippingAddress?.email}
                            required={true}
                            label="E-postadress"
                            onChangeCallback={handleChange}
                            id="email"
                            name="email"
                            email={true}
                        />
                    </div>
                    <div>
                        <TextField
                            value={shippingAddress?.phone}
                            label="Telefonnummer (för leveransavisering, börja med +46)"
                            onChangeCallback={handleChange}
                            id="phone"
                            name="phone"
                            placeholder="+46700000000"
                            required={true}
                        />
                    </div>
                </div>
                {props.shippingAddress ? (
                    <></>
                ) : (
                    <div className={styles.row}>
                        <div>
                            <Button submit={true} buttonText="Nästa" type={"primary"} data-umami-event="next-step-print-checkout-button" />
                        </div>
                    </div>
                )}
            </FormWrapper>
            {props.shippingAddress ? (
                <div className={styles.changeButton}>
                    <Button
                        callback={() => props.setShippingAddress(null)}
                        buttonText="Ändra adress"
                        type="secondary"
                    />
                </div>
            ) : null}
        </div>
    );
}
