import { useState } from "react";
import { Switch } from "../../../../../../../../components/form/Switch/Switch";
import { IRvspPerson } from "../../../../../../../../interfaces/Rvsp";
import { ICustomValue } from "../../../../ViewRvsp";
import styles from "./RsvpQuestionText.module.scss";

interface IProps {
    rsvps: IRvspPerson[];
    getCustomValueWithLabel: (rsvp: IRvspPerson, label: string) => string;
    customValue: ICustomValue;
    questionFilter: { question: string; answer: string } | null;
}

export function RsvpQuestionText(props: IProps) {
    const [uniqueValuesFilter, setUniqueValuesFilter] = useState<boolean>(false);

    const handleUniqueChange = (value: boolean) => {
        setUniqueValuesFilter(value);
    };

    const getCustomValues = (customValue: ICustomValue, unique: boolean) => {
        let values = props.rsvps
            .filter((rvsp) => props.getCustomValueWithLabel(rvsp, customValue.label))
            .map((rvsp): string => {
                return props.getCustomValueWithLabel(rvsp, customValue.label);
            });

        if (unique) {
            values = Array.from(new Set(values));
        }

        if (values && values.length) {
            return values.join(", ");
        } else {
            return null;
        }
    };

    return (
        <>
            {props.questionFilter ? (
                <div className={styles.label}>
                    {uniqueValuesFilter ? "Unika svar" : "Svar"} av personer som svarat "{props.questionFilter?.answer}
                    ": på frågan "{props.questionFilter?.question.replace("deprecatedWeddingYesOrNo", "Kommer")}"
                </div>
            ) : uniqueValuesFilter ? (
                <div className={styles.label}>Endast unika svar:</div>
            ) : null}
            <div>{getCustomValues(props.customValue, uniqueValuesFilter)}</div>
            <div className={styles.switchWrapper}>
                <Switch
                    handleChange={handleUniqueChange}
                    value={uniqueValuesFilter}
                    name="uniqueValuesFilter"
                    id="uniqueValuesFilter"
                    label="Visa endast unika svar (slå ihop dubbletter)"
                />
            </div>
        </>
    );
}
