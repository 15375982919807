import React, { useState } from "react";
import ActionCurtain from "../../../../../../../../components/actionCurtain/ActionCurtain";
import { ISite } from "../../../../../../../../interfaces/Site";
import { Payment } from "../../../../../../../../modules/Payment/Payment";
import { getProductFromDomainOption } from "../../../../utils/getProductFromDomainOption";
import { domainOption } from "../../../SelectProduct/SelectProduct";

interface IProps {
    site: ISite;
    handlePaymentDone: (paymentReference: string) => void;
    close: () => void;
    fullDomain: string;
    alreadyOwned: boolean;
}

export function DomainPayment(props: IProps) {
    const [hideEverythingButPaymentMethod, setHideEverythingButPaymentMethod] = useState(false);
    const product = getProductFromDomainOption(
        props.alreadyOwned ? domainOption.CUSTOM_DOMAIN_OTHER_REGISTRAR : domainOption.CUSTOM_DOMAIN,
        props.site
    );

    if (product.product === null) {
        throw new Error("Missing product for custom domain");
    }
    return (
        <ActionCurtain title="Köp domänpaket" close={props.close} preventClosingOnClickOutside={true}>
            <>
                {!hideEverythingButPaymentMethod && (
                    <>
                        {props.alreadyOwned === true && (
                            <p>
                                Du är på väg att köpa vårt domänpaket för {product.price}:-. Vi kommer att koppla din
                                sida till {props.fullDomain} <strong>som du redan äger någon annanstans</strong>. Efter
                                avslutad betalning kommer du att få instruktioner på vilka inställningar du behöver göra
                                på webbhotellet där du har domänen.
                            </p>
                        )}

                        {props.alreadyOwned === false && (
                            <>
                                <p>
                                    När du köper vårt domänpaket för {product.price}:- ingår{" "}
                                    <strong>{props.fullDomain}</strong> på köpet! Efter att du köpt adressen kan du
                                    självklart fortsätta att redigera din sida precis som tidigare.
                                </p>
                                <p>
                                    Domänen gäller i 12 månader om du inte förlänger den och du kan inte ångra hela ditt
                                    köp.
                                </p>
                            </>
                        )}
                    </>
                )}
                <Payment
                    paymentProduct={product.product}
                    setHideEverythingButPaymentMethod={setHideEverythingButPaymentMethod}
                    hideEverythingButPaymentMethod={hideEverythingButPaymentMethod}
                    site={props.site}
                    handlePaymentDone={props.handlePaymentDone}
                />
            </>
        </ActionCurtain>
    );
}
