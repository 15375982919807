import React from "react";
import Button from "../../components/button/Button";
import Edit, { IEditProps, IEditState } from "../Edit";
import styles from "./Edit.module.scss";

interface IProps extends IEditProps {}

export class EditCountDown extends Edit<IProps, IEditState> {
    getDateFromFullDate = (fullDate: string) => {
        if (fullDate && fullDate.length) {
            return fullDate.split(" ")[0];
        } else {
            return "";
        }
    };

    getTimeFromFullDate = (fullDate: string) => {
        if (fullDate && fullDate.length) {
            return fullDate.split(" ")[1];
        } else {
            return "";
        }
    };

    state = {
        inputValueDate: this.getDateFromFullDate(this.props.module.properties[this.props.property]),
        inputValueTime: this.getTimeFromFullDate(this.props.module.properties[this.props.property]),
    };

    validDate = (dateValue: string) => {
        return dateValue.match(/^(\d{4})-(\d{2})-(\d{2}) (\d{2}):(\d{2})$/);
    };

    saveDate = () => {
        const newValue = this.state.inputValueDate + " " + this.state.inputValueTime;
        if (newValue && newValue.length && this.validDate(newValue)) {
            this.updateContent(newValue);
        } else {
            alert("Datum måste anges i formatet åååå-mm-dd och tid i formatet hh:mm, tex. 2020-01-01 och 15:00");
        }
    };

    updateInputValueDate(e: React.ChangeEvent<HTMLInputElement>) {
        this.setState({
            inputValueDate: e.target.value.trim(),
        });
    }

    updateInputValueTime(e: React.ChangeEvent<HTMLInputElement>) {
        this.setState({
            inputValueTime: e.target.value.trim(),
        });
    }

    render() {
        return (
            <div className={styles.wrapper}>
                <p>Skriv in datum och tid du vill räkna ner till.</p>
                <label htmlFor="date">Datum (tex. 2020-06-01)</label>
                <input
                    value={this.state.inputValueDate}
                    onChange={(e) => this.updateInputValueDate(e)}
                    type="text"
                    id="date"
                    placeholder="åååå-mm-dd"
                />
                <label htmlFor="time">Tid (tex. 15:00)</label>
                <input
                    value={this.state.inputValueTime}
                    onChange={(e) => this.updateInputValueTime(e)}
                    type="text"
                    id="time"
                    placeholder="mm:ss"
                />
                <Button type="primary" buttonText="Spara" callback={this.saveDate} />
            </div>
        );
    }
}

export default EditCountDown;
