import { useState } from "react";
import ActionCurtain from "../../../../../../components/actionCurtain/ActionCurtain";
import Button from "../../../../../../components/button/Button";
import EditButton from "../../../../../../components/EditButton/EditButton";
import Select from "../../../../../../components/form/Select/Select";
import { Switch } from "../../../../../../components/form/Switch/Switch";
import styles from "./ShowFormContentCondition.module.scss";

interface IProps {
    children: JSX.Element;
    updateProperty: (module: any, property: string, content: any) => void;
    propertyParent: any;
    sourceProperty: string;
    editMode: boolean;
    currentFormData: any;
}

export function ShowFormContentCondition(props: IProps) {
    const conditionQuestionId = props.propertyParent.properties.conditionShowTextFieldsBelow?.question;
    const conditionAnswerId = props.propertyParent.properties.conditionShowTextFieldsBelow?.answer;

    const [modalOpen, setModalOpen] = useState(false);
    const [selectedQuestion, setSelectedQuestion] = useState(conditionQuestionId || "");
    const [selectedAnswer, setSelectedAnswer] = useState(conditionAnswerId || "");
    const [conditionActive, setConditionActive] = useState(!!conditionAnswerId);

    const radioGroups = props.propertyParent.properties[props.sourceProperty] || [];

    const options = radioGroups.map((radioGroup: any) => ({
        title: radioGroup.label,
        value: radioGroup.id,
    }));

    const conditionQuestionObject = radioGroups.find((radioGroup: any) => radioGroup.id === conditionQuestionId);
    const conditionAnswerObject = conditionQuestionObject?.options?.find(
        (option: any) => option.id === conditionAnswerId
    );

    const getCurrentStatus = () => {
        if (!conditionAnswerId) {
            return "Nu visas alltid frågorna längst ner";
        }
        if (!conditionQuestionObject || !conditionAnswerObject) {
            return "Frågan eller svaret som tidigare var ett villkor för att visa frågorna längst ner har tagits bort. Frågorna visas därför alltid.";
        }
        return (
            'Nu visas bara frågorna längst ner om gästen svarat "' +
            conditionAnswerObject?.label +
            '" på frågan "' +
            conditionQuestionObject?.label +
            '"'
        );
    };

    const answerOptions = radioGroups
        .find((radioGroup: any) => radioGroup.id === selectedQuestion)
        ?.options.map((option: any) => ({
            title: option.label,
            value: option.id,
        }));

    const save = () => {
        if (conditionActive && selectedAnswer && selectedAnswer) {
            props.updateProperty(props.propertyParent, "conditionShowTextFieldsBelow", {
                question: selectedQuestion,
                answer: selectedAnswer,
            });
        } else {
            props.updateProperty(props.propertyParent, "conditionShowTextFieldsBelow", null);
            setSelectedAnswer("");
            setSelectedQuestion("");
            setConditionActive(false);
        }
    };

    const showFields = () => {
        if (!conditionAnswerId) {
            return true;
        }
        if (!conditionQuestionObject || !conditionAnswerObject) {
            return true;
        }
        const currentValue = props.currentFormData?.customValues?.find(
            (customValue: any) => customValue.id === conditionQuestionId
        )?.value;
        return currentValue === conditionAnswerObject?.label;
    };

    const notSelected = { title: "Ingen fråga vald", value: "" };

    options.unshift(notSelected);
    const notSelectedAnswer = { title: "Välj svar", value: "" };

    answerOptions?.unshift(notSelectedAnswer);

    if (props.editMode) {
        return (
            <div className={styles.wrapper}>
                {modalOpen ? (
                    <ActionCurtain close={() => setModalOpen(false)}>
                        <div className={styles.modal}>
                            <p>
                                Här kan du ställa in om frågorna sist i formuläret alltid ska visas eller bara under
                                förutsättning att gästen svarat med ett visst svarsalternativ på en fråga. Det kan till
                                exempel vara användbart att gästen inte behöver uppge specialkost om hen inte ska komma
                                på festen.
                            </p>
                            <p>
                                <strong>{getCurrentStatus()}</strong>
                            </p>
                            <div>
                                <Switch
                                    label="Visa frågorna längst ner baserat på gästens svar"
                                    name="conditionActive"
                                    handleChange={setConditionActive}
                                    value={conditionActive}
                                    id="conditionActiveToggleSwitch"
                                    dark={true}
                                />
                            </div>
                            {conditionActive ? (
                                <div className={styles.selectWrapper}>
                                    <div>
                                        <Select
                                            id="selectConditionQuestion"
                                            label="Välj fråga"
                                            options={options}
                                            defaultValue={selectedQuestion}
                                            onChangeCallback={(val: any) => {
                                                setSelectedQuestion(val);
                                                setSelectedAnswer("");
                                            }}
                                        />
                                    </div>
                                    <div>
                                        {selectedQuestion && answerOptions?.length ? (
                                            <Select
                                                id="selectConditionAnswer"
                                                label="Välj svar"
                                                options={answerOptions}
                                                defaultValue={selectedAnswer}
                                                onChangeCallback={(val: any) => {
                                                    setSelectedAnswer(val);
                                                }}
                                            />
                                        ) : null}
                                    </div>
                                </div>
                            ) : null}
                            <Button
                                type="primary"
                                buttonText="Spara"
                                callback={() => {
                                    save();
                                    setModalOpen(false);
                                }}
                            />
                        </div>
                    </ActionCurtain>
                ) : null}
                <div className={styles.editButton}>
                    <EditButton callback={() => setModalOpen(true)}>
                        <>{conditionAnswerId ? "Visar bara frågorna nedan om..." : "Visa bara frågorna nedan om..."}</>
                    </EditButton>
                </div>
                {props.children}
            </div>
        );
    } else {
        return showFields() ? props.children : null;
    }
}
