import { TSiteGraphic } from "../components/SiteGraphic/SiteGraphic";
import { TFontHeading, TFontText } from "../enums/font";
import { IModule, IModuleRest } from "./Module";

export enum enumTheme {
    theme1 = "theme1",
    theme2 = "theme2",
    theme3 = "theme3",
    theme4 = "theme4",
    theme5 = "theme5",
    theme7 = "theme7",
    theme8 = "theme8",
    theme9 = "theme9",
    theme10 = "theme10",
    theme11 = "theme11",
}

export interface ISiteRest {
    metaNoindex?: boolean;
    term?: string;
    host?: string;
    _id?: string;
    modules: IModuleRest[];
    images: IImageRest[];
    user?: string;
    colors?: IColors | null; // Deprecated
    colorScheme?: IColorScheme;
    mailReceivers?: IMailReceivers;
    active?: boolean;
    password?: string;
    fonts: IFonts;
    theme: enumTheme;
    giftRegistryReservations?: IGiftRegistryReservations[];
    activeDate?: string;
    customDomainActive?: boolean;
    customDomainActiveDate?: string;
    customDomainRegistrationsAvailable?: number;
    siteGraphic?: TSiteGraphic | null;
    mostRecentSelectedDesignTemplate?: string;
}

export interface IGiftRegistryReservations {
    giftRegistryId: string;
    quantityReserved: number;
}

export interface IMailReceivers {
    rsvp?: string[] | string;
    speech?: string[] | string;
}

export interface ISite {
    metaNoindex: boolean;
    host: string;
    _id: string;
    modules: IModule[];
    images: IImage[];
    colors?: IColors | null; // Deprecated
    colorScheme?: IColorScheme;
    mailReceivers?: IMailReceivers;
    active?: boolean;
    password?: string;
    fonts: IFonts;
    theme: enumTheme;
    giftRegistryReservations?: IGiftRegistryReservations[];
    activeDate?: string;
    customDomainActive?: boolean;
    customDomainActiveDate?: string;
    customDomainRegistrationsAvailable?: number;
    siteGraphic?: TSiteGraphic | null;
    mostRecentSelectedDesignTemplate?: string;
}

export interface IFonts {
    heading: TFontHeading;
    text: TFontText;
}

export interface IColors {
    primary: string;
    secondary: string;
    background: string;
    text: string;
    backgroundOverlay: string;
    button?: string; // Deprecated
}

export interface IColor {
    h: number;
    s: number;
    l: number;
    a: number;
}

export interface IColorScheme {
    primary: IColor;
    background: IColor;
    surface: IColor;
    onPrimary: IColor;
    onBackground: IColor;
    onSurface: IColor;
}

export interface IImageRest {
    _id?: string;
    id: string;
    path: string;
    fileName: string;
    date?: Date;
    temporary?: boolean; // Deprecated
    cdn?: boolean;
    sizes?: IImageSizeRest[];
    originalName?: string;
    imageBeforeEdit?: {
        path: string;
        fileName: string;
        sizes?: IImageSizeRest[];
    };
}

export interface IImage {
    _id?: string;
    id: string;
    path: string;
    fileName: string;
    date?: Date;
    temporary?: boolean; // Deprecated
    cdn?: boolean;
    sizes?: IImageSize[];
    originalName?: string;
    imageBeforeEdit?: {
        path: string;
        fileName: string;
        sizes?: IImageSize[];
    };
}

export interface IImageSize {
    size: number;
    fileName: string;
    _id?: string;
}

export interface IImageSizeRest {
    size: number;
    fileName: string;
    _id?: string;
}
