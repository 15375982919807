import React, { ChangeEvent } from "react";
import { IconDelete } from "../../../icons/delete";
import { ISite } from "../../../interfaces/Site";
import Confirmation from "../../../modules/confirmation/Confirmation";
import EditButton, { PointerDirection } from "../../EditButton/EditButton";
import CheckboxEdit from "../Checkbox/CheckboxEdit";
import styles from "./YesOrNo.module.scss";

interface IProps {
    id: string;
    label: string;
    labelOnChange: any;
    labelYesOnChange: any;
    labelNoOnChange: any;
    removeItem?: any;
    value?: boolean;
    labelYes: string;
    labelNo: string;
    site: ISite;
}

interface IState {
    showEditBar: boolean;
    showDeleteConfirmation: boolean;
    valueYes: boolean;
    valueNo: boolean;
    value: boolean | undefined;
}

export class YesOrNoEdit extends React.Component<IProps, IState> {
    state: IState = {
        showEditBar: false,
        showDeleteConfirmation: false,
        valueYes: this.props.value === true ? true : false,
        valueNo: this.props.value === false ? true : false,
        value: this.props.value,
    };

    showEditBar = () => {
        this.setState({
            showEditBar: true,
        });
    };

    refCancelOnClickOutside: any;
    refLabel: any;

    componentDidMount() {
        document.addEventListener("mousedown", this.handleClickOutside);
    }

    componentWillUnmount() {
        document.removeEventListener("mousedown", this.handleClickOutside);
    }

    handleClickOutside = (event: Event) => {
        if (this.refCancelOnClickOutside && !this.refCancelOnClickOutside.contains(event.target)) {
            this.setState({
                showEditBar: false,
            });
        }
    };

    closeConfirmationCallback = () => {
        this.setState({
            showDeleteConfirmation: false,
        });
    };

    deleteWithConfirmation = () => {
        this.setState({
            showDeleteConfirmation: true,
        });
    };

    setRefForCancelOnClickOutside = (that: any) => {
        this.refCancelOnClickOutside = that;
    };

    setRefForLabel = (that: any) => {
        this.refLabel = that;
    };

    labelOnChange = (e: ChangeEvent) => {
        let targetElement: HTMLElement = e.target as HTMLElement;
        let newLabel = targetElement.innerText;

        this.props.labelOnChange(newLabel);
    };

    render() {
        return (
            <div className={styles.yesOrNoWrapper}>
                {this.state.showDeleteConfirmation === true && (
                    <Confirmation
                        confirmationTitle="Ta bort"
                        confirmButtonText="Ta bort"
                        abortButtonText="Avbryt"
                        confirmationText={'Är du säker på att du vill ta bort frågan "' + this.props.label + '"?'}
                        confirmCallback={this.props.removeItem.bind(this)}
                        closeCallback={this.closeConfirmationCallback.bind(this)}
                    ></Confirmation>
                )}
                <div ref={this.setRefForCancelOnClickOutside}>
                    <div onClick={this.showEditBar.bind(this)} className={styles.markAsEditable}>
                        <div className={`${styles[this.props.site.theme]}`}>
                            <div
                                placeholder={this.props.labelOnChange ? "Ingen fråga" : undefined}
                                className={styles.label}
                                ref={this.setRefForLabel}
                                contentEditable={this.props.labelOnChange ? true : false}
                                suppressContentEditableWarning={true}
                                onBlur={this.props.labelOnChange && this.labelOnChange}
                            >
                                {this.props.label}
                            </div>
                            <div className={styles.options}>
                                <div className={styles.optionYes}>
                                    <CheckboxEdit
                                        site={this.props.site}
                                        labelOnChange={this.props.labelYesOnChange}
                                        label={this.props.labelYes}
                                        value={this.state.valueYes}
                                        id={this.props.id + "yes"}
                                        name={this.props.id + "yes"}
                                    ></CheckboxEdit>
                                </div>
                                <div className={styles.option}>
                                    <CheckboxEdit
                                        site={this.props.site}
                                        labelOnChange={this.props.labelNoOnChange}
                                        label={this.props.labelNo}
                                        value={this.state.valueNo}
                                        id={this.props.id + "no"}
                                        name={this.props.id + "no"}
                                    ></CheckboxEdit>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={styles.editBarWrapper}>
                        {this.state.showEditBar === true ? (
                            <>
                                {this.props.removeItem && (
                                    <EditButton
                                        callback={this.deleteWithConfirmation.bind(this)}
                                        pointer={PointerDirection.UP}
                                    >
                                        <>
                                            <IconDelete></IconDelete>
                                            Ta bort fråga
                                        </>
                                    </EditButton>
                                )}
                            </>
                        ) : (
                            ""
                        )}
                    </div>
                </div>
            </div>
        );
    }
}

export default YesOrNoEdit;
