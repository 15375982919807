import React from "react";
import styles from "./ColorItem.module.scss";
import isEqual from "lodash.isequal";
import { colorToCssValue } from "../../../utils/colorToCssValue";
import { IColorScheme } from "../../../interfaces/Site";

interface IProps {
    colors: IColorScheme;
    setColors: (colors: IColorScheme) => void;
    currentColors: IColorScheme;
}

export class ColorItem extends React.Component<IProps> {
    colorStyles = {
        background: {
            backgroundColor: colorToCssValue(this.props.colors.background),
        },
        primary: {
            backgroundColor: colorToCssValue(this.props.colors.primary),
        },
        onPrimary: {
            backgroundColor: colorToCssValue(this.props.colors.onPrimary),
        },
    };

    setColors = () => {
        this.props.setColors(this.props.colors);
    };

    isActive = () => isEqual(this.props.currentColors, this.props.colors);

    render() {
        return (
            <div className={this.isActive() ? styles.wrapperActive : styles.wrapper} onClick={this.setColors}>
                <div style={this.colorStyles.primary}></div>
                <div style={this.colorStyles.onPrimary}></div>
                <div style={this.colorStyles.background}></div>
            </div>
        );
    }
}

export default ColorItem;
