import React, { useState } from "react";
import { EditSelectVertical } from "../../components/EditSelectVertical/EditSelectVertical";
import ContentGeneratorButton from "../../content/GeneratorButton/GeneratorButton";
import { ContentGiftRegistry } from "../../content/GiftRegistry/GiftRegistry";
import ContentRTE from "../../content/RTE/RTE";
import { IModule } from "../../interfaces/Module";
import { IGiftRegistryReservations, IImage, ISite } from "../../interfaces/Site";
import ModuleHeading from "../components/ModuleHeading/ModuleHeading";
import styles from "./GiftRegistry.module.scss";

interface IProps {
    site: ISite;
    moduleData: IModule;
    updateProperty: any;
    editMode: boolean;
    editAvailable: boolean;
    addOrUpdateImageToSite: (image: IImage) => void;
    showError: any;
    updateGiftRegistryReservations: (giftRegistryReservations: IGiftRegistryReservations[]) => void;
}

enum View {
    INITIATE,
    LIST,
}

const editViewsOptions = [
    {
        title: "Startknapp",
        value: View.INITIATE,
    },
    {
        title: "Önskelista",
        value: View.LIST,
    },
];

export const GiftRegistry = (props: IProps) => {
    const [view, setView] = useState(View.INITIATE);
    const [editView, setEditView] = useState(View.LIST);

    const viewIs = (viewToCompare: View): boolean => {
        if (dontUseExpansion) {
            return viewToCompare === View.LIST;
        } else {
            return props.editMode ? editView === viewToCompare : view === viewToCompare;
        }
    };

    const changeEditView = (newEditView: View): void => setEditView(newEditView);

    const dontUseExpansion = !(
        props.moduleData.properties?.giftRegistry?.length && props.moduleData.properties?.giftRegistry?.length > 1
    );

    return (
        <section className={styles[props.site.theme]}>
            <ModuleHeading
                site={props.site}
                propertyParent={props.moduleData}
                showError={props.showError}
                updateProperty={props.updateProperty}
                editMode={props.editMode}
            />
            <div className={styles.contentWrapper}>
                <div className={styles.rteWrapper}>
                    <ContentRTE
                        site={props.site}
                        propertyParent={props.moduleData}
                        showError={props.showError}
                        property="text"
                        updateProperty={props.updateProperty}
                        editMode={props.editMode}
                    />
                </div>
                {!dontUseExpansion && (
                    <div className={styles.selectViewWrapper}>
                        {props.editMode === true && (
                            <EditSelectVertical
                                value={editView}
                                onChangeCallback={changeEditView}
                                options={editViewsOptions}
                            />
                        )}
                    </div>
                )}
                {viewIs(View.INITIATE) && (
                    <div className={styles.showGiftRegistryButtonWrapper}>
                        <ContentGeneratorButton
                            buttonText="Visa önskelista"
                            buttonType="primary"
                            buttonCallback={() => setView(View.LIST)}
                            site={props.site}
                            propertyParent={props.moduleData}
                            property="showGiftRegistryButton"
                            showError={props.showError}
                            updateProperty={props.updateProperty}
                            editMode={props.editMode}
                        ></ContentGeneratorButton>
                    </div>
                )}
                {viewIs(View.LIST) ? (
                    <div className={styles.giftRegistryWrapper}>
                        <ContentGiftRegistry
                            site={props.site}
                            propertyParent={props.moduleData}
                            showError={props.showError}
                            property="giftRegistry"
                            updateProperty={props.updateProperty}
                            editMode={props.editMode}
                            addOrUpdateImageToSite={props.addOrUpdateImageToSite}
                            updateGiftRegistryReservations={props.updateGiftRegistryReservations}
                            editAvailable={props.editAvailable}
                        />
                    </div>
                ) : null}
            </div>
        </section>
    );
};
