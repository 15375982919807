import React from "react";
import styles from "./SiteGraphicItem.module.scss";
import { SiteGraphic, TSiteGraphic } from "../../../components/SiteGraphic/SiteGraphic";

interface IProps {
    siteGraphic: TSiteGraphic | null;
    setSiteGraphic: (siteGraphic: TSiteGraphic | null) => void;
    currentSiteGraphic?: TSiteGraphic | null;
}

export const SiteGraphicItem = (props: IProps) => {
    return (
        <div
            className={props.siteGraphic === props.currentSiteGraphic ? styles.wrapperActive : styles.wrapper}
            onClick={() => props.setSiteGraphic(props.siteGraphic)}
            data-test-id={"site-graphic-selector-" + props.siteGraphic}
        >
            {props.siteGraphic ? (
                <SiteGraphic siteGraphic={props.siteGraphic} type="THUMBNAIL" editAvailable={true} />
            ) : (
                <div className={styles.text}><span>Ingen grafik</span></div>
            )}
        </div>
    );
};
