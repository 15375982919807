import React from "react";

export function IconLoadingEvent() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="81.17 81.16 489.74 489.74">
      <g xmlns="http://www.w3.org/2000/svg" fill="#fc5c9c">
        <path
          fill="none"
          d="M7.846 35.717c.402-.176 1.207-.415 1.848-.604a6.812 6.812 0 01-.692-3.004v-.579c-.025-1.408-.05-3.017 1.22-4.173.993-.868 2.942-1.27 4.01-1.27 1.081 0 3.043.402 4.01 1.282 1.258 1.144 1.233 2.74 1.22 4.149l-.012.59a6.776 6.776 0 01-.792 3.22c.465.137.905.288 1.194.401.415.189.918.49 1.345.805.616.44 1.006.842 1.232 1.27.754-.214 1.534-.428 2.012-.553a9.303 9.303 0 01-1.496-5.092c0-.302 0-.603-.013-.905-.025-2.175-.063-4.425 1.798-5.972 1.445-1.22 4.488-1.785 6.084-1.785 1.61 0 4.665.566 6.098 1.785 1.848 1.56 1.81 3.784 1.772 5.947 0 .301-.012.616-.012.93 0 1.986-.604 3.872-1.685 5.393.302.076.616.164.93.264.667-1.194 2.578-2.074 2.615-2.1.403-.175 1.207-.414 1.849-.603a6.812 6.812 0 01-.692-3.004v-.579c-.025-1.408-.05-3.017 1.22-4.173.993-.868 2.941-1.27 4.01-1.27 1.081 0 3.042.402 4.01 1.282 1.258 1.144 1.232 2.74 1.22 4.149l-.013.59a6.776 6.776 0 01-.792 3.22c.465.137.905.288 1.195.401.414.189.917.49 1.345.805.679.49 1.106.943 1.32 1.42.075.164.113.34.113.516v2.628a1.26 1.26 0 01-1.257 1.257H43.965v3.771c0 .692-.554 1.257-1.258 1.257H17.602a1.26 1.26 0 01-1.258-1.257v-3.771H6.287a1.26 1.26 0 01-1.257-1.257V38.47c0-.189.05-.377.125-.553.616-1.245 2.653-2.175 2.69-2.2z"
          transform="translate(81.157 81.157) scale(8.11567)"
        ></path>
        <path
          d="M7.544 5.017v5.795a6.784 6.784 0 006.776 6.776h.277a6.784 6.784 0 006.776-6.776V5.017h2.514v5.795a6.784 6.784 0 006.777 6.776h.276a6.784 6.784 0 006.776-6.776V5.017h1.258v5.795a6.784 6.784 0 006.776 6.776h.276a6.784 6.784 0 006.777-6.776V5.017h7.543v55.328H0V5.017h7.543zM5.03 41.097a1.26 1.26 0 001.257 1.258h10.057v3.771a1.26 1.26 0 001.258 1.257h25.105c.704 0 1.258-.565 1.258-1.257v-3.771H54.06a1.26 1.26 0 001.257-1.257V38.47a1.23 1.23 0 00-.113-.515c-.214-.478-.641-.93-1.32-1.421a8.082 8.082 0 00-1.345-.805c-.29-.113-.73-.264-1.195-.402a6.776 6.776 0 00.792-3.218l.013-.591c.012-1.408.038-3.005-1.22-4.149-.968-.88-2.929-1.282-4.01-1.282-1.069 0-3.017.402-4.01 1.27-1.27 1.156-1.245 2.765-1.22 4.173v.579c0 1.08.252 2.112.692 3.004-.642.189-1.446.428-1.849.604-.037.025-1.948.905-2.614 2.1a15.02 15.02 0 00-.93-.265c1.08-1.52 1.684-3.407 1.684-5.393 0-.314.012-.629.012-.93.038-2.163.076-4.388-1.772-5.947-1.433-1.22-4.488-1.785-6.098-1.785-1.596 0-4.639.566-6.084 1.785-1.86 1.547-1.823 3.797-1.798 5.972.013.302.013.603.013.905a9.303 9.303 0 001.496 5.092c-.478.125-1.258.339-2.012.553-.226-.428-.616-.83-1.232-1.27a8.082 8.082 0 00-1.345-.805c-.29-.113-.73-.264-1.194-.402a6.776 6.776 0 00.792-3.218l.012-.591c.013-1.408.038-3.005-1.22-4.149-.967-.88-2.929-1.282-4.01-1.282-1.068 0-3.017.402-4.01 1.27-1.27 1.156-1.245 2.765-1.22 4.173v.579c0 1.08.252 2.112.692 3.004-.641.189-1.446.428-1.848.604-.038.025-2.075.955-2.69 2.2a1.42 1.42 0 00-.126.553v2.628z"
          transform="translate(81.157 81.157) scale(8.11567)"
        ></path>
        <path
          d="M14.597 15.074h-.277a4.265 4.265 0 01-4.262-4.262V4.25A4.262 4.262 0 0114.32 0h.277a4.262 4.262 0 014.262 4.25v6.562c0 2.351-1.911 4.262-4.262 4.262zm16.343 0h-.276a4.265 4.265 0 01-4.262-4.262V4.25A4.262 4.262 0 0130.664 0h.276a4.262 4.262 0 014.262 4.25v6.562c0 2.351-1.91 4.262-4.262 4.262zm15.086 0h-.276a4.265 4.265 0 01-4.262-4.262V4.25A4.262 4.262 0 0145.75 0h.276a4.262 4.262 0 014.262 4.25v6.562c0 2.351-1.91 4.262-4.262 4.262z"
          transform="translate(81.157 81.157) scale(8.11567)"
        ></path>
      </g>
    </svg>
  );
}
