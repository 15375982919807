import React from "react";
import ActionCurtain from "../../components/actionCurtain/ActionCurtain";
import { Map } from "../../components/map/Map";
import { IconMap } from "../../icons/map";
import Content, { IContentProps } from "../Content";
import EditMap from "./Edit";
import styles from "./Map.module.scss";
import { transformOldMapValue } from "./Utils/TransformOldMapValue";

export class ContentMap extends Content<IContentProps> {
    mapValue = () => transformOldMapValue(this.propValue());

    render() {
        return this.props.editMode === true ? (
            <>
                {this.state.showEditCurtain === true && (
                    <ActionCurtain close={this.closeEditCurtain.bind(this)}>
                        <EditMap
                            updateProperty={this.props.updateProperty}
                            updatePropertySuccess={this.closeEditCurtain.bind(this)}
                            value={this.mapValue()}
                            propertyParent={this.props.propertyParent}
                            property={this.props.property}
                            site={this.props.site}
                        />
                    </ActionCurtain>
                )}
                <div className={styles.editableWrapper} onClick={this.openEditCurtain.bind(this)}>
                    {this.mapValue().location ? (
                        <Map
                            site={this.props.site}
                            editMode={this.props.editMode}
                            zoom={this.mapValue().zoom}
                            markerColor={this.mapValue().markerColor}
                            mapTheme={this.mapValue().mapTheme}
                            location={this.mapValue().location}
                            preventCache={false}
                        />
                    ) : (
                        <div className={styles.placeholder}>
                            <IconMap />
                            <div className={styles.helpText}>Ingen kartplats angiven</div>
                        </div>
                    )}
                </div>
            </>
        ) : this.mapValue().location ? (
            <div className={styles.mapWrapper}>
                <Map
                    site={this.props.site}
                    editMode={this.props.editMode}
                    zoom={this.mapValue().zoom}
                    markerColor={this.mapValue().markerColor}
                    mapTheme={this.mapValue().mapTheme}
                    location={this.mapValue().location}
                    preventCache={false}
                />
            </div>
        ) : (
            ""
        );
    }
}

export default ContentMap;
