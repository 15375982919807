import React from "react";
import styles from "./ButtonRow.module.scss";

interface IProps {
    primary?: any;
    secondary?: any;
}

export function ButtonRow(props: IProps) {
    return (
        <div className={styles.wrapper}>
            <div className={styles.primary}>{props.primary}</div>
            <div className={styles.secondary}>{props.secondary}</div>
        </div>
    );
}
