import styles from "./CardLayoutGenesis.module.scss";
import { IPrintableCard, TPrintableContent, TPrintablePositions } from "../../../../interfaces/IPrintableCard";
import { PrintableContent } from "../../../PrintableContent/PrintableContent";
import { Updater } from "use-immer";

interface IProps {
    card: IPrintableCard;
    editMode: boolean;
    page: 1 | 2;
    updateCard?: Updater<IPrintableCard>;
    thumbnail?: boolean;
}

const findContentWithPosition = (
    content: TPrintableContent[],
    position: TPrintablePositions
) => {
    return content.find((item) => item.position === position);
};

const ContentType = (props: {
    content: TPrintableContent[];
    position: TPrintablePositions;
    updateCard?: Updater<IPrintableCard>;
    card: IPrintableCard;
    editMode: boolean;
}) => {
    const content = findContentWithPosition(props.content, props.position);
    if (!content) {
        throw new Error("No content with position " + props.position);
    }
    return (
        <div className={styles[props.position]}>
            <PrintableContent
                card={props.card}
                editMode={props.editMode}
                updateCard={props.updateCard}
                content={content}
            />
        </div>
    );
};

export const CardLayoutGenesis = (props: IProps) => {
    const card = props.card;

    if (props.page === 1) {
        return (
            <div className={`${styles.wrapper}`}>
                <div className={styles.innerWrapper}>
                    <ContentType
                        content={card.content}
                        editMode={props.editMode}
                        card={card}
                        updateCard={props.updateCard}
                        position="welcomeText"
                    />
                    <ContentType
                        content={card.content}
                        card={card}
                        editMode={props.editMode}
                        updateCard={props.updateCard}
                        position="names"
                    />
                    <ContentType
                        content={card.content}
                        card={card}
                        editMode={props.editMode}
                        updateCard={props.updateCard}
                        position="dateAndLocationLong"
                    />
                    <ContentType
                        content={card.content}
                        card={card}
                        editMode={props.editMode}
                        updateCard={props.updateCard}
                        position="websiteInfo"
                    />
                </div>
            </div>
        );
    } else {
        return (
            <div className={`${styles.wrapper}`}>
                <div className={styles.innerWrapper}>
                    <ContentType
                        card={card}
                        editMode={props.editMode}
                        updateCard={props.updateCard}
                        content={card.content}
                        position="textAtQR"
                    />
                    <ContentType
                        card={card}
                        editMode={props.editMode}
                        updateCard={props.updateCard}
                        content={card.content}
                        position="qr"
                    />
                </div>
            </div>
        );
    }
};
