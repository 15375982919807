import React from "react";
import TextFieldEdit from "../../../components/form/TextField/TextFieldEdit";
import { ITextFieldItem } from "../MultipleTextFields";

interface IProps {
    textFieldItem: ITextFieldItem;
    updateTextFieldLabel: any;
    removeItem: any;
    updateMandatory: any;
    className: any;
    id: string;
}

export class TextFieldItem extends React.Component<IProps> {
    labelOnChange = (newLabel: string) => {
        this.props.updateTextFieldLabel(this.props.textFieldItem, newLabel);
    };

    removeItem = () => {
        this.props.removeItem(this.props.textFieldItem);
    };

    toggleMandatory = () => {
        this.props.updateMandatory(this.props.textFieldItem, !this.props.textFieldItem.mandatory);
    };

    render() {
        return (
            <div>
                <TextFieldEdit
                    label={this.props.textFieldItem.label}
                    id={this.props.id}
                    labelOnChange={this.labelOnChange.bind(this)}
                    removeItem={this.removeItem.bind(this)}
                    toggleMandatory={this.toggleMandatory.bind(this)}
                    mandatory={this.props.textFieldItem.mandatory}
                    className={this.props.className}
                />
            </div>
        );
    }
}

export default TextFieldItem;
