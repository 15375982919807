import React from "react";
import ActionCurtain from "../../components/actionCurtain/ActionCurtain";
import Button from "../../components/button/Button";
import "./Confirmation.scss";

interface IProps {
    confirmCallback: any;
    cancelCallback?: any;
    closeCallback: any;
    confirmationText: string;
    confirmButtonText: string;
    abortButtonText: string;
    confirmationTitle: string;
}

export class Confirmation extends React.Component<IProps> {
    cancel = () => {
        if (this.props.cancelCallback) {
            this.props.cancelCallback();
        }
        this.props.closeCallback();
    };

    confirm = () => {
        if (this.props.confirmCallback) {
            this.props.confirmCallback();
        }
        this.props.closeCallback();
    };

    render() {
        return (
            <ActionCurtain title={this.props.confirmationTitle} close={this.cancel}>
                <div className="module-confirmation">
                    {this.props.confirmationText}
                    <div className="button-wrapper">
                        <Button type="warning" buttonText={this.props.confirmButtonText} callback={this.confirm} />
                        <Button type="secondary" buttonText={this.props.abortButtonText} callback={this.cancel} />
                    </div>
                </div>
            </ActionCurtain>
        );
    }
}

export default Confirmation;
