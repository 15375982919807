import React from "react";
import { ISite } from "../../../interfaces/Site";
import Checkbox from "../Checkbox/Checkbox";
import styles from "./YesOrNo.module.scss";

interface IProps {
    onChangeCallback: any;
    value?: boolean;
    name: string;
    id: string;
    labelYes: string;
    labelNo: string;
    label: string;
    disabled: boolean;
    site: ISite;
}

interface IState {
    valueYes: boolean;
    valueNo: boolean;
    value: boolean | undefined;
}

export class YesOrNo extends React.Component<IProps, IState> {
    state: IState = {
        valueYes: this.props.value === true ? true : false,
        valueNo: this.props.value === false ? true : false,
        value: this.props.value,
    };

    setValue(yesOrNo: boolean) {
        if (this.props.disabled) {
            return;
        }
        if (yesOrNo === true) {
            this.setState({
                valueYes: true,
                valueNo: false,
                value: true,
            });
        } else if (yesOrNo === false) {
            this.setState({
                valueYes: false,
                valueNo: true,
                value: false,
            });
        }

        const valueLabel = yesOrNo ? this.props.labelYes : this.props.labelNo;

        this.props.onChangeCallback(yesOrNo, this.props.name, valueLabel);
    }

    handleChangeYes(value: boolean) {
        value ? this.setValue(true) : this.setValue(false);
    }

    handleChangeNo(value: boolean) {
        value ? this.setValue(false) : this.setValue(true);
    }

    render() {
        return (
            <div className={`${styles[this.props.site.theme]}`}>
                <div className={styles.label}>{this.props.label}</div>
                <div className={styles.options}>
                    <div className={styles.optionYes}>
                        <Checkbox
                            disabled={this.props.disabled}
                            label={this.props.labelYes}
                            onChangeCallback={this.handleChangeYes.bind(this)}
                            value={this.state.valueYes}
                            id={this.props.id + "yes"}
                            name={this.props.id + "yes"}
                            site={this.props.site}
                        ></Checkbox>
                    </div>
                    <div className={styles.option}>
                        <Checkbox
                            disabled={this.props.disabled}
                            label={this.props.labelNo}
                            onChangeCallback={this.handleChangeNo.bind(this)}
                            value={this.state.valueNo}
                            id={this.props.id + "no"}
                            name={this.props.id + "no"}
                            site={this.props.site}
                        ></Checkbox>
                    </div>
                </div>
            </div>
        );
    }
}

export default YesOrNo;
