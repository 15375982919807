export const transformOldMapValue = (propValue: any) => {
    // Before we only had a location as propvalue, not an object.
    if (propValue && typeof propValue === "object") {
        return propValue;
    } else {
        return {
            location: propValue,
        };
    }
};
