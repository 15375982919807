import Axios from "axios";

export const logError = (originId: any, errorObject: any, severity: any, info?: any, siteData?: any) => {
    const log: any = {
        url: window.location.href,
        severity: severity,
        originId: originId,
        userAgent: window.navigator.userAgent,
    };

    if (errorObject) {
        log.errorObject = JSON.stringify({ message: errorObject.message, stack: errorObject.stack });
    }

    if (siteData) {
        log.siteData = JSON.stringify(siteData);
    }

    if (info) {
        log.info = JSON.stringify(info);
    }

    Axios.post(process.env.REACT_APP_API_URL + "/api/logs/", log)
        .then((res) => {
            console.log("Wrote to log.");
        })
        .catch((err) => {
            console.log("Failed to write to log.");
        });
};
