import { useEffect, useState } from "react";
import { IError } from "../../../../../App";
import Loading from "../../../../components/Loading/Loading";
import ShowError from "../../../../components/ShowError/ShowError";
import { PrintableCard } from "../ViewCommunication/components/EditPrint/components/PrintEditor/components/PrintableCard/PrintableCard";
import { IPrintableCard } from "../ViewCommunication/components/EditPrint/components/PrintEditor/interfaces/IPrintableCard";
import { getPrintWithServerAccess } from "../../../../services/PrintService";

interface IProps {
    printId: string;
    serverAccessKey: string;
}

export function ViewPdfPrint(props: IProps) {
    const [loading, setLoading] = useState(true);
    const [print, setPrint] = useState<IPrintableCard | null>(null);
    const [error, setError] = useState<IError | null>(null);

    useEffect(() => {
        const loadPrint = async () => {
            setLoading(true);
            try {
                const data = await getPrintWithServerAccess(props.printId, props.serverAccessKey);
                setPrint(data);
            } catch (error) {
                setError({ text: "Misslyckades att hämta tryck vid utskrift", response: (error as any).response });
            }
            setLoading(false);
        };
        loadPrint();
    }, [props.serverAccessKey, props.printId]);

    if (loading) {
        return <Loading fixed={true} inverted={true} />;
    }

    if (error) {
        return <ShowError error={error} />;
    }

    if (!print) {
        throw Error("Print not loaded but not error");
    }

    return (
        <>
            {loading ? <Loading fixed={true} /> : null}
            <PrintableCard forPrint={true} card={print} page={1} editMode={false} />
            <PrintableCard forPrint={true} card={print} page={2} editMode={false} />
        </>
    );
}
