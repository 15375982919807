import { useEffect, useState } from "react";

import styles from "./ContentTypeQREdit.module.scss";
import ActionCurtain from "../../../../../../../../../../../../../../components/actionCurtain/ActionCurtain";
import FormWrapper from "../../../../../../../../../../../../../../components/form/FormWrapper/FormWrapper";
import TextField from "../../../../../../../../../../../../../../components/form/TextField/TextField";
import Button from "../../../../../../../../../../../../../../components/button/Button";
import Loading from "../../../../../../../../../../../../../../components/Loading/Loading";
import ShowError from "../../../../../../../../../../../../../../components/ShowError/ShowError";
import { IError } from "../../../../../../../../../../../../../../../App";
import { getEditSite } from "../../../../../../../../../../../../../../services/SiteService";

interface IProps {
    url: string;
    updateQRUrl: (string: string) => void;
    siteId?: string;
    close: () => void;
}

export const ContentTypeQREdit = (props: IProps) => {
    const [loading, setLoading] = useState(true);
    const [siteHost, setSiteHost] = useState<string | null>(null);
    const [error, setError] = useState<IError | null>(null);
    const [url, setUrl] = useState(props.url || "");

    useEffect(() => {
        const getSite = async () => {
            setLoading(true);
            try {
                if (props.siteId) {
                    const site = await getEditSite(props.siteId);
                    setSiteHost(site.host);
                } else {
                    setLoading(false);
                }
            } catch (error) {
                setError({ text: "Misslyckades att hämta sidan", response: (error as any).response });
            }
            setLoading(false);
        };
        getSite();
    }, [props.siteId]);

    if (error) {
        return <ShowError error={error} />;
    }

    const handleDone = () => {
        props.updateQRUrl(url);
        props.close();
    };

    return (
        <ActionCurtain close={props.close}>
            <>
                {loading ? (
                    <Loading />
                ) : (
                    <FormWrapper submit={handleDone}>
                        <div className={styles.wrapper}>
                            {!siteHost ? null : siteHost === props.url ? (
                                <div>QR-koden går till din sidas adress {siteHost}</div>
                            ) : (
                                <>
                                    <div>
                                        Din sidas adress är {siteHost} men qr-koden går{" "}
                                        {props.url ? "till " + props.url : "ingenstans"}.
                                    </div>
                                    <div>
                                        <Button
                                            buttonText={"Autoifyll " + siteHost}
                                            type="primary"
                                            submit={false}
                                            callback={() => setUrl(siteHost + "")}
                                        />
                                    </div>
                                </>
                            )}
                            <div>
                                <TextField
                                    label="Url (tex: demo.brollopssida.se)"
                                    name="url"
                                    id="url"
                                    required={true}
                                    onChangeCallback={setUrl}
                                    value={url}
                                    placeholder="demo.brollopssida.se"
                                />
                            </div>
                            <div className={styles.bottomButtons}>
                                <Button buttonText="Klar" type="primary" submit={true} fullWidth={true} />
                            </div>
                        </div>
                    </FormWrapper>
                )}
            </>
        </ActionCurtain>
    );
};
