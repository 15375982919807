import Axios from "axios";
import React, { useState } from "react";
import Button from "../../components/button/Button";
import { Switch } from "../../components/form/Switch/Switch";
import TextField from "../../components/form/TextField/TextField";
import Loading from "../../components/Loading/Loading";
import { ISite } from "../../interfaces/Site";
import styles from "./EditPassword.module.scss";

interface IProps {
    site?: ISite;
    showError: any;
    quickStart: boolean;
}

export const EditPassword = (props: IProps) => {
    const [isLoading, setIsLoading] = useState(false);
    const [savedPassword, setSavedPassword] = useState(props.site?.password || "");
    const [usePassword, setUsePassword] = useState(!!props.site?.password);
    const [password, setPassword] = useState(props.site?.password || "");

    const handlePasswordChange = (value: string) => {
        setPassword(value.trim());
    };

    const passwordSavedDiffs = () => {
        if (savedPassword !== password) return true;
        if (usePassword === false && password) return true;
        return false;
    };

    const savePassword = () => {
        if (!props.site) {
            throw new Error("No site when saving password");
        }
        setIsLoading(true);
        Axios.put(process.env.REACT_APP_API_URL + "/api/sites/" + props.site._id + "/password", {
            password: usePassword ? password : ""
        })
            .then((response) => {
                setIsLoading(false);
                setSavedPassword(usePassword ? password : "");
                setPassword(usePassword ? password : "")
            })
            .catch((err) => {
                setIsLoading(false);
                props.showError("Misslyckades att uppdatera lösenordet", err.response);
            });
    };

    return (
        <>
            {isLoading === true && <Loading></Loading>}
            <div className={styles.text}>
                Om du vill att bara dina gäster ska kunna se din sida kan du välja ett lösenord. Lösenordet får du sedan
                skicka med i inbjudningarna. För att göra det enklare för dina gäster skiljer vi inte på små eller stora
                bokstäver. Ett lösenord utan användarnamn och med ett fåtal tecken är aldrig någon garanti för att någon
                obehörig inte kan ta sig in men risken minskar avsevärt.
            </div>
            <div className={styles.checkboxWrapper}>
                <Switch
                    label={"Använd lösenord"}
                    name="usePassword"
                    handleChange={setUsePassword}
                    value={usePassword}
                    id="usePassword"
                ></Switch>
            </div>
            <div className={styles.fieldAndButtonWrapper}>
                {usePassword ? (
                    <div className={styles.textFieldWrapper}>
                        <TextField
                            required={true}
                            label={"Lösenord"}
                            name="password"
                            id={"password"}
                            onChangeCallback={handlePasswordChange}
                            value={password}
                        ></TextField>
                    </div>
                ) : (
                    ""
                )}

                {passwordSavedDiffs() && props.quickStart !== true && props.site?.active === true ? (
                    <div>
                        <Button buttonText="Spara lösenord" type="primary" callback={savePassword} />
                    </div>
                ) : (
                    ""
                )}
            </div>
            {passwordSavedDiffs() ? (
                props.quickStart !== true && props.site?.active === true ? (
                    ""
                ) : (
                    <div className={styles.publishFirst}>Du måste välja adress innan du kan spara ett lösenord</div>
                )
            ) : (
                ""
            )}
        </>
    );
};
