import { IModule, IModuleRest } from "../interfaces/Module";
import { enumTheme, IImage, IImageRest, IImageSize, IImageSizeRest, ISite, ISiteRest } from "../interfaces/Site";

const fixOldBrokenDemoPath = (path: string) => {
    let newPath;
    // Accidently removed demo site but customers sites still have image path to removed images...
    newPath = path.replace("1c40a7bcf036f62ac95c2ed1", "66ffc59802e87354c2e3b807");
    newPath = fixOldBrokenFilename(newPath);
    return newPath;
};
const fixOldBrokenFilename = (filename: string) => {
    let newfilename = filename;
    // Accidently removed demo site but customers sites still have image path to removed images...
    newfilename = newfilename.replace("2021-08-20T11:26:25.260Z_macl21", "2024-11-05T11:54:40.642Z_sfufox");
    newfilename = newfilename.replace("2021-08-20T11:26:47.349Z_p2ifzy", "2024-11-05T11:54:48.048Z_9u96xw");
    newfilename = newfilename.replace("2021-08-20T11:27:04.408Z_jzrckt", "2024-11-05T11:55:01.816Z_h66kqk");
    newfilename = newfilename.replace("2021-08-20T11:27:15.496Z_fv4zgz", "2024-11-05T11:55:08.081Z_r72b0z");
    newfilename = newfilename.replace("2021-08-20T11:27:28.167Z_pvelvv", "2024-11-05T11:55:18.827Z_bw02zy");
    newfilename = newfilename.replace("2021-08-20T11:27:40.580Z_nu7005", "2024-11-05T11:55:23.577Z_jyfqjh");
    newfilename = newfilename.replace("2021-08-20T11:27:48.989Z_geel3v", "2024-11-05T11:55:27.656Z_ditpxv");
    newfilename = newfilename.replace("2023-02-09T10:43:35.531Z_pyatcz", "2024-11-05T11:55:33.388Z_qqahbu");
    newfilename = newfilename.replace("2023-02-09T10:43:41.462Z_rcmjv2", "2024-11-05T11:55:38.461Z_zwrdnq");
    newfilename = newfilename.replace("2023-02-09T10:43:46.035Z_yxcyem", "2024-11-05T11:55:42.082Z_5nyzxz");
    newfilename = newfilename.replace("2021-08-20T11:27:57.379Z_ntmbz7", "2024-11-05T11:55:56.794Z_wn1kd3");
    newfilename = newfilename.replace("2021-08-20T11:28:07.243Z_mwpjtb", "2024-11-05T11:56:06.979Z_24zbal");
    newfilename = newfilename.replace("2021-08-20T11:28:16.834Z_ix95mc", "2024-11-05T11:56:15.301Z_k95oy9");
    newfilename = newfilename.replace("2021-08-20T11:28:24.422Z_wfohs3", "2024-11-05T11:56:22.734Z_dsq6ua");
    newfilename = newfilename.replace("2021-08-20T11:28:32.592Z_hyq7kb", "2024-11-05T11:56:30.986Z_oloxzx");
    newfilename = newfilename.replace("2021-08-20T11:28:39.658Z_ohnwai", "2024-11-05T11:56:37.727Z_vxggos");

    return newfilename;
};

export const transformSiteIn = (data: ISiteRest) => {

    let site: ISite = {
        host: data.host!,
        _id: data._id!,
        modules: [],
        images: data.images,
        colors: data.colors,
        colorScheme: data.colorScheme,
        active: data.active,
        password: data.password,
        fonts: data.fonts,
        theme: data.theme,
        giftRegistryReservations: data.giftRegistryReservations,
        siteGraphic: data.siteGraphic,
        mostRecentSelectedDesignTemplate: data.mostRecentSelectedDesignTemplate,
        metaNoindex: data.metaNoindex || false,
    };

    if (site.images && site.images.length) {
        site.images.forEach((image) => {
            image.fileName = fixOldBrokenFilename(image.fileName);
            image.path = fixOldBrokenDemoPath(image.path);
            image.sizes?.forEach((size) => {
                size.fileName = fixOldBrokenFilename(size.fileName);
            });
        });
    }

    if (data.activeDate) {
        site.activeDate = data.activeDate;
    }

    if (data.customDomainActive) {
        site.customDomainActive = data.customDomainActive;
    }

    if (data.customDomainActiveDate) {
        site.customDomainActiveDate = data.customDomainActiveDate;
    }

    if (data.customDomainRegistrationsAvailable) {
        site.customDomainRegistrationsAvailable = data.customDomainRegistrationsAvailable;
    }

    if (!site.giftRegistryReservations) {
        site.giftRegistryReservations = [];
    }

    // If old sites without font set
    if (!site.fonts) {
        site.fonts = {
            heading: "Playfair Display",
            text: "Arial",
        };
    }

    // If old sites without theme
    if (!site.theme) {
        site.theme = enumTheme.theme1;
    }

    if (data.mailReceivers) {
        site.mailReceivers = {
            rsvp: data.mailReceivers.rsvp,
            speech: data.mailReceivers.speech,
        };
    } else {
        site.mailReceivers = {};
    }

    if (data.modules && data.modules.length) {
        site.modules = data.modules.map(transformModuleIn);
    }

    return site;
};

const transformModuleIn = (data: IModuleRest) => {
    let module: IModule = {
        module: data.module,
        _id: data._id, // Used in db
        id: data._id!, // Used in client
        menuTitle: data.menuTitle,
        slug: data.slug,
        hideInMenu: data.hideInMenu,
        hide: data.hide,
    };

    module.properties = {};
    if (data.properties && data.properties.length) {
        data.properties.forEach((property) => {
            module.properties[property.key] = property.value;
        });
    }
    return module;
};

interface ITransformOutOptions {
    stripId?: boolean;
}

export const transformSiteOut = (site: ISite, options: ITransformOutOptions = {}) => {
    let data: ISiteRest = {
        modules: [],
        images: [],
        mailReceivers: {
            rsvp: site.mailReceivers!.rsvp,
            speech: site.mailReceivers!.speech,
        },
        fonts: site.fonts,
        theme: site.theme,
        siteGraphic: site.siteGraphic,
        mostRecentSelectedDesignTemplate: site.mostRecentSelectedDesignTemplate,
    };

    if (site.colorScheme) {
        data.colorScheme = site.colorScheme;
        data.colors = null;
    } else {
        data.colors = site.colors;
    }

    if (site.images && site.images.length) {
        data.images = site.images.map((image) => {
            return transformImageOut(image, options);
        });
    }
    if (site.modules && site.modules.length) {
        data.modules = site.modules.map((module) => {
            return transformModuleOut(module, options);
        });
    }
    return data;
};

const transformSizesOut = (size: IImageSizeRest, options: ITransformOutOptions): IImageSize => {
    let data: IImageSize = {
        size: size.size,
        fileName: size.fileName,
    };
    if (!(options.stripId && options.stripId === true)) {
        data._id = size._id;
    }
    return data;
};

const transformImageOut = (image: IImage, options: ITransformOutOptions): IImageRest => {
    let data: IImageRest = {
        fileName: image.fileName,
        id: image.id,
        temporary: image.temporary,
        path: image.path,
        originalName: image.originalName,
        cdn: image.cdn,
    };
    if (!(options.stripId && options.stripId === true)) {
        data._id = image._id;
    }
    if (image.sizes && image.sizes.length) {
        data.sizes = image.sizes.map((size) => {
            return transformSizesOut(size, options);
        });
    }
    if (image.imageBeforeEdit?.path) {
        data.imageBeforeEdit = {
            path: image.imageBeforeEdit.path,
            fileName: image.imageBeforeEdit.fileName,
            sizes: image.imageBeforeEdit.sizes?.map((size) => {
                return transformSizesOut(size, options);
            }),
        };
    }
    return data;
};

const transformModuleOut = (module: IModule, options: ITransformOutOptions): IModuleRest => {
    let data: IModuleRest = {
        module: module.module,
        menuTitle: module.menuTitle,
        slug: module.slug,
        hideInMenu: module.hideInMenu,
        hide: module.hide,
    };

    if (!(options.stripId && options.stripId === true)) {
        data._id = module._id;
    }

    data.properties = convertPropertiesToArray(module.properties);
    return data;
};

const convertPropertiesToArray = (properties: any) => {
    let propertiesAsArray: any = [];
    Object.keys(properties).forEach((key) => {
        propertiesAsArray.push({
            key: key,
            value: properties[key],
        });
    });

    return propertiesAsArray;
};
