import React from "react";
import { ContentMultipleRadioGroups } from "../../../../content/MultipleRadioGroups/MultipleRadioGroups";
import ContentMultipleTextFields from "../../../../content/MultipleTextFields/MultipleTextFields";
import ContentOneLine from "../../../../content/oneLine/OneLine";
import { IModule } from "../../../../interfaces/Module";
import { IRvspPersonV2 } from "../../../../interfaces/Rvsp";
import { ISite } from "../../../../interfaces/Site";
import { ShowFormContentCondition } from "./components/ShowFormContentCondition/ShowFormContentCondition";
import styles from "./Person.module.scss";

interface IProps {
    index: number;
    updateValue: any;
    data: IRvspPersonV2 | null;
    module: IModule;
    editMode: boolean;
    site: ISite;
    updateProperty: any;
    showError: any;
    propertyParent: any;
    maxNumberOfPersons: number;
}

export class Person extends React.Component<IProps> {
    updateCustomField = (value: string, name: string, customValueType: string, label: string) => {
        this.props.updateValue(this.props.index, name, value, customValueType, label);
    };

    render() {
        return (
            <div className={styles[this.props.site.theme]}>
                {this.props.maxNumberOfPersons > 1 ? (
                    <h3 className={styles.personHeading}>
                        <ContentOneLine
                            className={styles.personHeadingEditable}
                            site={this.props.site}
                            showError={this.props.showError}
                            propertyParent={this.props.module}
                            property="personTitle"
                            placeholder="Ingen text"
                            updateProperty={this.props.updateProperty}
                            editMode={this.props.editMode}
                        />{" "}
                        {this.props.index + 1}
                    </h3>
                ) : null}
                <div className={styles.customTextFieldsAboveWrapper}>
                    <ContentMultipleTextFields
                        showError={this.props.showError}
                        propertyParent={this.props.module}
                        editMode={this.props.editMode}
                        property="customTextFieldsAbove"
                        site={this.props.site}
                        updateProperty={this.props.updateProperty}
                        updateCustomField={this.updateCustomField}
                        id={"RVSP-MultipleTextFieldsAbove-" + this.props.index}
                    />
                </div>
                <ContentMultipleRadioGroups
                    showError={this.props.showError}
                    propertyParent={this.props.module}
                    editMode={this.props.editMode}
                    property="customMultipleRadioGroups"
                    site={this.props.site}
                    updateProperty={this.props.updateProperty}
                    updateCustomField={this.updateCustomField}
                    id={"RVSP-MultipleRadioGroups-" + this.props.index}
                />
                <ShowFormContentCondition
                    updateProperty={this.props.updateProperty}
                    propertyParent={this.props.module}
                    sourceProperty="customMultipleRadioGroups"
                    editMode={this.props.editMode}
                    currentFormData={this.props.data}
                >
                    <>
                        <ContentMultipleRadioGroups
                            showError={this.props.showError}
                            propertyParent={this.props.module}
                            editMode={this.props.editMode}
                            property="customMultipleRadioGroupsBelow"
                            site={this.props.site}
                            updateProperty={this.props.updateProperty}
                            updateCustomField={this.updateCustomField}
                            id={"RVSP-MultipleRadioGroupsBelow-" + this.props.index}
                        />
                        <div className={styles.customTextFieldsBelowWrapper}>
                            <ContentMultipleTextFields
                                showError={this.props.showError}
                                propertyParent={this.props.module}
                                editMode={this.props.editMode}
                                property="customTextFieldsBelow"
                                site={this.props.site}
                                updateProperty={this.props.updateProperty}
                                updateCustomField={this.updateCustomField}
                                id={"RVSP-MultipleTextFieldsBelow-" + this.props.index}
                            />
                        </div>
                    </>
                </ShowFormContentCondition>
            </div>
        );
    }
}

export default Person;
