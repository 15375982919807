import React from "react";
import { getBrandedLoadingIcon } from "../../utils/getBrand";
import styles from "./Loading.module.scss";

interface IProps {
    fixed?: boolean;
    inverted?: boolean;
    block?: boolean;
    text?: string;
}

export class Loading extends React.Component<IProps> {
    render() {
        return (
            <div
                className={`${this.props.inverted === true && styles.inverted} ${
                    this.props.block === true && styles.block
                } ${this.props.fixed === true ? styles.loadingWrapperFixed : styles.loadingWrapper}`}
            >
                <div className={styles.icon}>{getBrandedLoadingIcon()}</div>
                {this.props.text && <div className={styles.text}>{this.props.text}</div>}
            </div>
        );
    }
}

export default Loading;
