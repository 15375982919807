import React from "react";
import { ISite } from "../../interfaces/Site";
import styles from "./EditDesignTemplate.module.scss";
import { EditDesignTemplateItem } from "./EditDesignTemplateItem/EditDesignTemplateItem";
import { IDesignTemplate, selectableDesignTemplates } from "./utils/designTemplates";

interface IProps {
    site: ISite;
    setDesignTemplate: (designTemplate: IDesignTemplate) => void;
}

export const EditDesignTemplate = (props: IProps) => {
    const getCurrentCreatedDesignTemplate = () => {
        const designTemplate: IDesignTemplate = {
            name: "CHANGE_NAME_AND_REPLACE_COLOR_SCHEME",
            colorScheme: props.site.colorScheme!,
            fonts: props.site.fonts,
            theme: props.site.theme,
            siteGraphic: props.site.siteGraphic || null,
        };
        console.info(designTemplate);
    };
    return (
        <div className={styles.wrapper}>
            <div className={styles.text}>
                Våra mallar är förslag på designval. Välj en mall du tycker om och sedan kan du ändra layout, typsnitt,
                färg eller grafik i flikarna ovan.
            </div>
            <div className={styles.items} data-test-id="design-template-list">
                {selectableDesignTemplates.map((designTemplate) => (
                    <EditDesignTemplateItem
                        site={props.site}
                        key={designTemplate.name}
                        setDesignTemplate={props.setDesignTemplate}
                        designTemplate={designTemplate}
                    />
                ))}
            </div>
            <button className={styles.displayNoneShowInDevToolsIfNeeded} onClick={getCurrentCreatedDesignTemplate}>
                Design template as object in console
            </button>
        </div>
    );
};
