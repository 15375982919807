import React from "react";
import ActionCurtain from "../../components/actionCurtain/ActionCurtain";
import { IImage } from "../../interfaces/Site";
import { Image } from "../components/Image/Image";
import Content, { IContentProps } from "../Content";
import EditImage from "./Edit";
import styles from "./Image.module.scss";

interface IProps extends IContentProps {
    addOrUpdateImageToSite: (image: IImage) => void;
    userCanChooseWidth?: boolean;
    sizes: string;
}

export class ContentImage extends Content<IProps> {
    maxWidth = (): string => {
        if (
            this.props.userCanChooseWidth &&
            this.propValue() &&
            this.propValue().size &&
            /^\d+$/.test(this.propValue().size) // only contain numbers
        ) {
            return this.propValue().size + "px";
        } else {
            return "none";
        }
    };

    render() {
        return this.props.editMode === true ? (
            <>
                {this.state.showEditCurtain === true && (
                    <ActionCurtain close={this.closeEditCurtain.bind(this)}>
                        <EditImage
                            showError={this.props.showError}
                            site={this.props.site}
                            module={this.props.propertyParent}
                            updateProperty={this.props.updateProperty}
                            updatePropertySuccess={this.closeEditCurtain.bind(this)}
                            property={this.props.property}
                            addOrUpdateImageToSite={this.props.addOrUpdateImageToSite}
                            userCanChooseWidth={this.props.userCanChooseWidth}
                        />
                    </ActionCurtain>
                )}
                <div className={`${styles[this.props.site.theme]}`} style={{ maxWidth: this.maxWidth() }}>
                    {this.propValue() ? (
                        <Image
                            image={this.propValue()}
                            onClick={this.openEditCurtain.bind(this)}
                            site={this.props.site}
                            sizes={this.props.sizes}
                        />
                    ) : (
                        <div
                            className={styles.placeholder + " image-placeholder"}
                            onClick={this.openEditCurtain.bind(this)}
                        >
                            Ingen bild uppladdad
                        </div>
                    )}
                </div>
            </>
        ) : this.propValue() ? (
            <div>
                <div className={`${styles[this.props.site.theme]}`} style={{ maxWidth: this.maxWidth() }}>
                    <Image image={this.propValue()} site={this.props.site} sizes={this.props.sizes} />
                </div>
            </div>
        ) : (
            ""
        );
    }
}

export default ContentImage;
