import React from "react";
import { IColors } from "../../../interfaces/Site";
import styles from "./ColorItem.module.scss";

interface IProps {
    colors: IColors;
    setColors: any;
    currentColors: IColors;
}

export class ColorItem extends React.Component<IProps> {
    colorStyles = {
        background: {
            backgroundColor: this.props.colors.background,
        },
        primary: {
            backgroundColor: this.props.colors.primary,
        },
        secondary: {
            backgroundColor: this.props.colors.secondary,
        },
        text: {
            backgroundColor: this.props.colors.text,
        },
    };

    setColors = () => {
        this.props.setColors(this.props.colors);
    };

    isActive = () => {
        return (
            this.props.currentColors.background === this.props.colors.background &&
            this.props.currentColors.backgroundOverlay === this.props.colors.backgroundOverlay &&
            this.props.currentColors.primary === this.props.colors.primary &&
            this.props.currentColors.secondary === this.props.colors.secondary &&
            this.props.currentColors.text === this.props.colors.text
        );
    };

    render() {
        return (
            <div className={this.isActive() ? styles.wrapperActive : styles.wrapper} onClick={this.setColors}>
                <div style={this.colorStyles.primary} className={styles.colorPrimary}></div>
                <div style={this.colorStyles.secondary} className={styles.colorSecondary}></div>
                <div style={this.colorStyles.background} className={styles.colorBackground}></div>
                {/*
                <div style={this.colorStyles.text} className={styles.color}></div>
                */}
            </div>
        );
    }
}

export default ColorItem;
