import React from "react";
import { ChromePicker, ColorResult } from "react-color";
import { IColors } from "../../../interfaces/Site";
import { ColorPropNames } from "../EditColor";
import styles from "./AdvancedColorItem.module.scss";

interface IProps {
    colorPropName: ColorPropNames;
    currentColors: IColors;
    title: string;
    setColors: (colors: IColors) => void;
}

interface IState {
    pickerOpen: boolean;
}

export class AdvancedColorItem extends React.Component<IProps, IState> {
    refCancelOnClickOutside: any;

    state: IState = {
        pickerOpen: false,
    };

    componentDidMount() {
        document.addEventListener("mousedown", this.handleClickOutside);
    }

    componentWillUnmount() {
        document.removeEventListener("mousedown", this.handleClickOutside);
    }

    handleClickOutside = (event: Event) => {
        if (this.refCancelOnClickOutside && !this.refCancelOnClickOutside.contains(event.target)) {
            this.setState({
                pickerOpen: false,
            });
        }
    };

    setRefForCancelOnClickOutside = (that: any) => {
        this.refCancelOnClickOutside = that;
    };

    showPicker = (): void => {
        if (!this.state.pickerOpen) {
            this.setState({
                pickerOpen: true,
            });
        }
    };

    setColors = (color: ColorResult) => {
        const colors = { ...this.props.currentColors };
        colors[this.props.colorPropName] =
            "rgba(" + color.rgb.r + "," + color.rgb.g + "," + color.rgb.b + "," + color.rgb.a + ")";
        this.props.setColors(colors);
    };

    render() {
        return (
            <div
                className={styles.wrapper}
                style={{
                    backgroundColor: this.props.currentColors[this.props.colorPropName],
                }}
                onClick={this.showPicker}
            >
                <span className={styles.titleWrapper}>{this.props.title}</span>
                <div className={styles.picker} ref={this.setRefForCancelOnClickOutside}>
                    {this.state.pickerOpen && (
                        <ChromePicker
                            onChange={this.setColors}
                            color={this.props.currentColors[this.props.colorPropName]}
                        />
                    )}
                </div>
            </div>
        );
    }
}

export default AdvancedColorItem;
