import React, { useState } from "react";
import { convertLineBreaksToHtmlBreaks } from "../../utils/convertLineBreaksToHtmlBreaks";
import { IContentProps } from "../Content";
import styles from "./H1.module.scss";
import { getPropValue } from "../utils/getPropValue";
import EditButton, { PointerDirection } from "../../components/EditButton/EditButton";
import useOnClickOutside from "../../utils/useOnClickOutside";
import { IconMoveDownContentModule } from "../../icons/moveDownContentModule";
import { IconMoveUpContentModule } from "../../icons/moveUpContentModule";

interface IProps extends IContentProps {
    movableByUser?: boolean;
}

const ContentH1 = (props: IProps) => {
    const [hasFocus, setHasFocus] = useState(false);
    const propValue = getPropValue(props.propertyParent, props.property, props.fallbackContent);
    const wrapper = useOnClickOutside(() => setHasFocus(false), hasFocus);

    const updateContent = (content: any) => {
        props.updateProperty(props.propertyParent, props.property, content);
    };

    const handleBlur = (e: React.FocusEvent<HTMLHeadingElement>) => {
        let targetElement = e.target;
        updateContent(targetElement.innerText);
    };

    const moveText = (value: number) => {
        const alignmentPropertyName = props.property + "VerticalAlignment";
        const currentValue = props.propertyParent?.properties[alignmentPropertyName] || 0;
        let newValue = currentValue + value;
        if (newValue > 1 || newValue < -1) {
            newValue = value;
        }
        props.updateProperty(props.propertyParent, props.property + "VerticalAlignment", newValue)
    };

    if (props.editMode === true) {
        return (
            <div className={styles.editWrapper} ref={wrapper} onClick={() => setHasFocus(true)}>
                {hasFocus && props.movableByUser ? (
                    <>
                        <div className={styles.moveButtonUp}>
                            <EditButton callback={() => moveText(-1)} pointer={PointerDirection.RIGHT}>
                                <>
                                    <IconMoveUpContentModule />
                                    <span className={styles.text}>Upp</span>
                                </>
                            </EditButton>
                        </div>
                        <div className={styles.moveButtonDown}>
                            <EditButton callback={() => moveText(1)} pointer={PointerDirection.RIGHT}>
                                <>
                                    <IconMoveDownContentModule />
                                    <span className={styles.text}>Ner</span>
                                </>
                            </EditButton>
                        </div>
                    </>
                ) : null}
                <h1
                    className={styles.editable}
                    placeholder="Ingen rubrik"
                    contentEditable
                    suppressContentEditableWarning={true}
                    onBlur={handleBlur}
                    dangerouslySetInnerHTML={convertLineBreaksToHtmlBreaks(propValue)}
                />
            </div>
        );
    } else {
        return <h1 dangerouslySetInnerHTML={convertLineBreaksToHtmlBreaks(propValue)} />;
    }
};

export default ContentH1;
