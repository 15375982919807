import React from "react";
import { IconClose } from "../../icons/close";
import "./Close.scss";

interface IProps {
    closeEvent: any;
    testId?: string;
}

export class Close extends React.Component<IProps> {
    render() {
        return (
            <div className="component-close" data-test-id={this.props.testId} onClick={this.props.closeEvent}>
                <div>Stäng</div>
                <div>
                    <IconClose></IconClose>
                </div>
            </div>
        );
    }
}

export default Close;
