import React from "react";
import ExpandableArea from "../../components/ExpandableArea/ExpandableArea";
import { enumTheme, IColors } from "../../interfaces/Site";
import AdvancedColorItem from "./AdvancedColorItem/AdvancedColorItem";
import ColorItem from "./ColorItem/ColorItem";
import styles from "./EditColor.module.scss";

interface IProps {
    currentColors: IColors;
    setColors: (colors: IColors) => void;
    theme: enumTheme;
}

export enum ColorPropNames {
    primary = "primary",
    secondary = "secondary",
    background = "background",
    text = "text",
    backgroundOverlay = "backgroundOverlay",
}

export class EditColor extends React.Component<IProps> {
    colorsCollections: IColors[] = [
        {
            primary: "#3B3B3B",
            secondary: "#ececec",
            background: "#F5F5F5",
            text: "#353535",
            backgroundOverlay: "rgba(255, 255, 255, 0.8)",
        },
        {
            primary: "#e0ab65",
            secondary: "#282828",
            background: "#F5F5F5",
            text: "#3c3c3c",
            backgroundOverlay: "rgba(255, 255, 255, 0.8)",
        },
        {
            primary: "#8fa887",
            secondary: "#365135",
            background: "#eef1e7",
            text: "#353535",
            backgroundOverlay: "rgba(255, 255, 255, 0.8)",
        },
        {
            primary: "#62342c",
            secondary: "#f7806e",
            background: "#fff8f9",
            text: "#353535",
            backgroundOverlay: "rgba(255, 255, 255, 0.8)",
        },
        {
            primary: "#957DAD",
            secondary: "#FFDFD3",
            background: "#f1e7e7",
            text: "#353535",
            backgroundOverlay: "rgba(255, 255, 255, 0.8)",
        },
        {
            primary: "#435b4e",
            secondary: "#C0C85F",
            background: "#eef1e7",
            text: "#353535",
            backgroundOverlay: "rgba(255, 255, 255, 0.8)",
        },
        {
            primary: "#009688",
            secondary: "#fbeba5",
            background: "#fffde7",
            text: "#353535",
            backgroundOverlay: "rgba(255, 255, 255, 0.8)",
        },
        {
            primary: "#F7F7F7",
            secondary: "#282828",
            background: "#242427",
            text: "#F7F7F7",
            backgroundOverlay: "rgba(0, 0, 0, 0.4)",
        },
        {
            primary: "rgba(238,238,242,1)",
            secondary: "#222932",
            background: "rgba(30,60,79,1)",
            text: "rgba(255,255,255,1)",
            backgroundOverlay: "rgba(0,0,0,0.25)",
        },
        {
            primary: "rgba(125,79,80,1)",
            secondary: "#FFEBEB",
            background: "rgba(204,139,134,1)",
            text: "rgba(0,0,0,1)",
            backgroundOverlay: "rgba(255,255,255,0.2)",
        },
        {
            primary: "rgba(110,64,38,1)",
            secondary: "rgba(192,149,95,1)",
            background: "rgba(215,180,137,1)",
            text: "rgba(90,56,14,1)",
            backgroundOverlay: "rgba(255,255,255,0.8)",
        },
    ];

    render() {
        return (
            <>
                <div className={styles.colors}>
                    {this.colorsCollections.map((colors, index) => (
                        <ColorItem
                            currentColors={this.props.currentColors}
                            key={index}
                            setColors={this.props.setColors}
                            colors={colors}
                        />
                    ))}
                </div>
                <ExpandableArea title="Avancerade inställningar">
                    <div className={styles.advanced}>
                        <AdvancedColorItem
                            title="Primär"
                            colorPropName={ColorPropNames.primary}
                            currentColors={this.props.currentColors}
                            setColors={this.props.setColors}
                        />

                        <AdvancedColorItem
                            title="Sekundär"
                            colorPropName={ColorPropNames.secondary}
                            currentColors={this.props.currentColors}
                            setColors={this.props.setColors}
                        />

                        {this.props.theme !== enumTheme.theme1 && (
                            <>
                                <AdvancedColorItem
                                    title="Text"
                                    colorPropName={ColorPropNames.text}
                                    currentColors={this.props.currentColors}
                                    setColors={this.props.setColors}
                                />

                                <AdvancedColorItem
                                    title="Bakgrund"
                                    colorPropName={ColorPropNames.background}
                                    currentColors={this.props.currentColors}
                                    setColors={this.props.setColors}
                                />

                                <AdvancedColorItem
                                    title="Sekundär bakgrund"
                                    colorPropName={ColorPropNames.backgroundOverlay}
                                    currentColors={this.props.currentColors}
                                    setColors={this.props.setColors}
                                />
                            </>
                        )}
                    </div>
                </ExpandableArea>
            </>
        );
    }
}
