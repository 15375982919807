import React from "react";
import Helmet from "react-helmet";
import { RouteComponentProps } from "react-router";
import Login from "../../../generator/modules/Login/Login";
import { getBrand } from "../../../generator/utils/getBrand";
import styles from "./ViewLogin.module.scss";

interface IProps extends RouteComponentProps<any> {
    showError: any;
}

interface IState {}

export class ViewLogin extends React.Component<IProps, IState> {
    state: IState = {};

    onLoginSuccess = (account: any) => {
        this.props.history.push("/redigera/" + account.site + "/");
    };

    render() {
        return (
            <div className={styles.wrapper}>
                <Helmet>
                    <title>Logga in - {getBrand()}</title>
                </Helmet>
                <h1>Logga in</h1>

                <Login showError={this.props.showError} onSuccess={this.onLoginSuccess}></Login>
            </div>
        );
    }
}
