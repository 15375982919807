const envelopCosts = { none: 0, standard: 300, premium: 500 };
const printCost = 1500;

const currencyFormatter = (number: number) => {
    return new Intl.NumberFormat("sv-SE", {
        style: "currency",
        currency: "SEK",
        maximumSignificantDigits: 10,
    }).format(number);
};

const getPrintProductCost = (envelopes: "standard" | "premium" | "none", numberOfPrints: number) => {
    return (envelopCosts[envelopes] + printCost) * numberOfPrints;
}

export const getPrintProductCostFormatted = (envelopes: "standard" | "premium" | "none", numberOfPrints: number) => {
    return currencyFormatter(getPrintProductCost(envelopes, numberOfPrints) / 100);
}

export const getFreightCost = () => {
    return 9500;
}

export const getFreightCostFormatted = () => {
    return currencyFormatter(getFreightCost() / 100);
}

export const getTotalCost = (envelopes: "standard" | "premium" | "none", numberOfPrints: number) => {
    return getFreightCost() + getPrintProductCost(envelopes, numberOfPrints);
}

export const getTotalVat = (envelopes: "standard" | "premium" | "none", numberOfPrints: number) => {
    return (getFreightCost() + getPrintProductCost(envelopes, numberOfPrints)) * 0.2;
}

export const getTotalCostFormatted = (envelopes: "standard" | "premium" | "none", numberOfPrints: number) => {
    return currencyFormatter(getTotalCost(envelopes, numberOfPrints) / 100);
}

export const getTotalVatFormatted = (envelopes: "standard" | "premium" | "none", numberOfPrints: number) => {
    return currencyFormatter(getTotalVat(envelopes, numberOfPrints) / 100);
}

