import React from "react";
import { ISite } from "../../../interfaces/Site";
import styles from "./Checkbox.module.scss";

interface IProps {
    onChangeCallback: any;
    value?: boolean;
    name: string;
    id: string;
    label: string;
    disabled?: boolean;
    required?: boolean;
    site?: ISite;
    children?: any;
}

export class Checkbox extends React.Component<IProps> {
    handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        this.props.onChangeCallback(event.target.checked);
    };

    render() {
        return (
            <span className={`${this.props.site ? styles[this.props.site.theme] : styles.wrapper}`}>
                <div className={styles.checkboxAndTextWrapper}>
                    <div className={styles.checkboxWrapper}>
                        <input
                            type="checkbox"
                            required={this.props.required}
                            className={styles.checkbox}
                            id={this.props.id}
                            name={this.props.name}
                            checked={this.props.value}
                            onChange={this.handleCheckboxChange.bind(this)}
                            disabled={this.props.disabled === true ? true : undefined}
                            data-invalid-feedback-id={this.props.id + "_invalid_feedback"}
                        />
                    </div>
                    <div className={styles.textWrapper}>
                        <label className={styles.label} htmlFor={this.props.id}>
                            {this.props.label}
                        </label>
                        {this.props.children ? <span>{this.props.children}</span> : ""}
                    </div>
                </div>
                <div
                    className="invalidFeedback"
                    id={this.props.id + "_invalid_feedback"}
                    data-invalid-feedback="true"
                ></div>
            </span>
        );
    }
}

export default Checkbox;
