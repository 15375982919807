import { IRvspPerson } from "../../../../../interfaces/Rvsp";

const trimAndClean = (value: string) => {
    if (value && typeof value === "string") {
        value = value?.trim();
        // If last two chars is \n, remove. This should be done on save but it is what it is now...
        if (value?.charAt(value.length - 2) === "\n") {
            value = value.substring(0, value.length - 2);
        }
    }
    return value;
};

export const trimValuesAndLabels = (persons: IRvspPerson[]) => {
    persons.forEach((person) => {
        person.customValues?.forEach((customValue) => {
            if (customValue.label) {
                customValue.label = trimAndClean(customValue.label);
            }
            if (customValue.value) {
                customValue.value = trimAndClean(customValue.value);
            }
        });
    });
};
