import { convertLineBreaksToHtmlBreaks } from "../../../../../../../../../../../../utils/convertLineBreaksToHtmlBreaks";
import { IPrintableCard, IPrintableContentText } from "../../../../interfaces/IPrintableCard";
import styles from "./ContentTypeText.module.scss";

interface IProps {
    card: IPrintableCard;
    content: IPrintableContentText;
    editMode: boolean;
    updateText: (text: string) => void;
}

export const ContentTypeText = (props: IProps) => {
    const handleBlur = (event: React.FocusEvent<HTMLDivElement>) => {
        const text = event.target.innerText;
        props.updateText(text);
    };

    return (
        <div
            className={props.editMode ? styles.editMode : ""}
            onBlur={props.editMode ? handleBlur : undefined}
            contentEditable={props.editMode}
            suppressContentEditableWarning={true}
            dangerouslySetInnerHTML={convertLineBreaksToHtmlBreaks(props.content.properties.text)}
            data-placeholder="Ingen text"
        ></div>
    );
};
