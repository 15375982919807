import React from "react";
import styles from "./Percentage.module.scss";

interface IProps {
    value: number;
    totalValue: number;
    title: string;
    type: "Positive" | "Negative";
}

export class Percentage extends React.Component<IProps> {
    percentage = () => {
        if (!this.props.totalValue || 0) {
            return 0;
        }
        return Math.round((this.props.value / this.props.totalValue) * 100);
    };

    render() {
        return (
            <div className={styles.wrapper}>
                <div className={styles.percentageWrapper}>
                    <div className={styles["filler" + this.props.type]} style={{ width: this.percentage() + "%" }} />
                    <div className={styles.percentage}>{this.percentage()}%</div>
                </div>

                <div className={styles.titleAndNumbersWrapper}>
                    <div className={styles.title}>{this.props.title}</div>
                    <div className={styles.numbers}>
                        {this.props.value} av {this.props.totalValue}
                    </div>
                </div>
            </div>
        );
    }
}

export class PercentageWrapper extends React.Component<{ children: any }, {}> {
    render() {
        return <div className={styles.percentageItemsWrapper}>{this.props.children}</div>;
    }
}
