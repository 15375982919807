export function IconUndo() {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" strokeWidth="1.5" viewBox="0 0 24 24">
            <path
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M5 9.5h10c.162 0 4 0 4 4 0 4.5-3.702 4.5-4 4.5H7"
            ></path>
            <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" d="M8.5 13L5 9.5 8.5 6"></path>
        </svg>
    );
}
