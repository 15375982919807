import { LinkButton } from "../../../../../../components/button/LinkButton";
import { IconCheck } from "../../../../../../icons/check";
import { ISite } from "../../../../../../interfaces/Site";
import { getBrandedDomainName } from "../../../../../../utils/getBrand";
import { getProductFromDomainOption } from "../../utils/getProductFromDomainOption";
import styles from "./SelectProduct.module.scss";

interface IProps {
    site: ISite;
}

export enum domainOption {
    SUBDOMAIN = "SUBDOMAIN",
    CUSTOM_DOMAIN = "CUSTOM_DOMAIN",
    CUSTOM_DOMAIN_OTHER_REGISTRAR = "CUSTOM_DOMAIN_OTHER_REGISTRAR",
}

export const SelectProduct = (props: IProps) => {
    const customDomainProduct = getProductFromDomainOption(domainOption.CUSTOM_DOMAIN, props.site);
    const subdomainProduct = getProductFromDomainOption(domainOption.SUBDOMAIN, props.site);

    return (
        <div className={styles.wrapper}>
            <div className={styles.grid}>
                <div className={styles.sectionFullWidth}>
                    <h1>Välj adress</h1>
                    <div className={styles.intro}>
                        För att dina gäster ska kunna besöka din sida måste du välja din adress. Efter att du valt
                        adressen kan du självklart fortsätta att redigera din sida som tidigare. Säkra din adress
                        direkt!
                    </div>
                </div>
                <div className={styles.section}>
                    <div className={styles.push}>
                        <h3 className={styles.sectionTitle}>vad-du-vill.{getBrandedDomainName()}</h3>
                        <div className={styles.price}>{subdomainProduct.price}:-</div>
                        {subdomainProduct.information ? (
                            <div className={styles.extraInfo}>{subdomainProduct.information}</div>
                        ) : null}
                        <ul>
                            <li>
                                <IconCheck /> Sidan finns kvar i 18 månader
                            </li>
                            <li>
                                <IconCheck /> Full ångerrätt i 30 dagar.
                            </li>
                            <li>
                                <IconCheck /> Ändra adress när som helst utan extra kostnad.
                            </li>
                            <li>
                                <IconCheck /> Alla funktioner inkluderade.
                            </li>
                        </ul>
                    </div>
                    <div>
                        <LinkButton
                            fullWidth={true}
                            type="primary"
                            to={"/redigera/" + props.site._id + "/valj-adress/subdoman/"}
                            data-test-id="select-subdomain-product"
                        >
                            {props.site.active ? "Ändra adress" : "Sök ledig adress"}
                        </LinkButton>
                    </div>
                </div>
                <div className={styles.section}>
                    <div className={styles.push}>
                        <h3 className={styles.sectionTitle}>vad-du-vill.se</h3>
                        <div className={styles.price}>{customDomainProduct.price}:-</div>
                        {customDomainProduct.information ? (
                            <div className={styles.extraInfo}>{customDomainProduct.information}</div>
                        ) : null}
                        <ul>
                            <li>
                                <IconCheck /> Egen domän ingår (.se, .nu, .com eller .eu)
                            </li>
                            <li>
                                <IconCheck /> Sidan finns kvar i 12 månader
                            </li>
                            <li>
                                <IconCheck /> Full ångerrätt i 30 dagar
                            </li>
                            <li>
                                <IconCheck /> Alla funktioner inkluderade.
                            </li>
                        </ul>
                    </div>
                    <div>
                        {customDomainProduct.price === null ? null : (
                            <LinkButton
                                fullWidth={true}
                                type="primary"
                                to={"/redigera/" + props.site._id + "/valj-adress/doman/"}
                                data-test-id="select-custom-domain"
                            >
                                Sök ledig domän
                            </LinkButton>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};
