import { CustomEditor } from "../types/SlateTypes";
import isUrl from "is-url";
import { wrapLink } from "./link";

export const withLinks = (editor: CustomEditor) => {
    const { isInline, insertData, insertText } = editor;
    editor.isInline = (element) => (element.type === "link" ? true : isInline(element));

    editor.insertText = (text) => {
        if (text && text.length > 5 && isUrl(text)) {
            wrapLink(editor, text, text);
        } else {
            insertText(text);
        }
    };

    editor.insertData = (data) => {
        const text = data.getData("text/plain");

        if (text && text.length > 5 && isUrl(text)) {
            wrapLink(editor, text, text);
        } else {
            insertData(data);
        }
    };

    return editor;
};
