import React from "react";
import styles from "./FontItem.module.scss";

interface IProps {
    font: string;
    setFont: any;
    currentFont: string;
    text: string;
    fontSize: string;
}

export class FontItem extends React.Component<IProps> {
    setFont = () => {
        this.props.setFont(this.props.font);
    };

    isActive = () => {
        return this.props.currentFont === this.props.font;
    };

    render() {
        return (
            <div
                className={this.isActive() ? styles.wrapperActive : styles.wrapper}
                onClick={this.setFont}
                style={{ fontFamily: this.props.font, fontSize: this.props.fontSize }}
                data-test-id={"font-item-" + this.props.font}
            >
                <div className={styles.text}>{this.props.text}</div>
                <div className={styles.font}>{this.props.font}</div>
            </div>
        );
    }
}

export default FontItem;
