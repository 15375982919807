import React from "react";
import styles from "./SelectPrintLayout.module.scss";
import { SelectPrintLayoutItem } from "./SelectPrintLayoutItem/SelectPrintLayoutItem";
import { IPrintableCard, TPrintableCardLayout } from "../../../../interfaces/IPrintableCard";
import { ISite } from "../../../../../../../../../../../../interfaces/Site";

interface IProps {
    print: IPrintableCard;
    setPrintLayout: (layout: TPrintableCardLayout) => void;
    site: ISite;
}


const cardLayouts: TPrintableCardLayout[] = ["GENESIS", "PHOTO_CLEAN", "TEXT_ON_PHOTO"]

export const SelectPrintLayout = (props: IProps) => {
    return (
        <div className={styles.wrapper}>
            {cardLayouts.map((cardLayout) => (
                <SelectPrintLayoutItem
                    key={cardLayout}
                    printLayout={cardLayout}
                    print={props.print}
                    setPrintLayout={props.setPrintLayout}
                    site={props.site}
                />
            ))}
        </div>
    );
};
