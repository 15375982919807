import styles from "./PrintableCard.module.scss";
import { SiteGraphic } from "../../../../../../../../../../components/SiteGraphic/SiteGraphic";
import { CardLayoutGenesis } from "./cardLayouts/CardLayoutGenesis/CardLayoutGenesis";
import { IPrintableCard } from "../../interfaces/IPrintableCard";
import { Updater } from "use-immer";
import { useEffect } from "react";
import { loadSiteFonts } from "../../../../../../../../../../utils/LoadSiteFonts";
import { CardLayoutPhotoClean } from "./cardLayouts/CardLayoutPhotoClean/CardLayoutPhotoClean";
import { getStyleVariables } from "./utils/getStyleVariables";
import { CardLayoutTextOnPhoto } from "./cardLayouts/CardLayoutTextOnPhoto/CardLayoutTextOnPhoto";

interface IPropsView {
    editMode: false;
    card: IPrintableCard;
    shadow?: boolean;
    page: 1 | 2;
    forPrint: boolean;
    thumbnail?: boolean;
}

interface IPropsEdit {
    card: IPrintableCard;
    editMode: boolean;
    updateCard: Updater<IPrintableCard>;
    shadow?: boolean;
    page: 1 | 2;
    forPrint?: false;
}

export const PrintableCard = (props: IPropsEdit | IPropsView) => {
    useEffect(() => {
        loadSiteFonts(props.card.fonts);
    }, [props.card.fonts]);

    const showGraphic = () => {
        if (props.page === 1) {
            if (props.card.layout === "PHOTO_CLEAN" || props.card.layout === "TEXT_ON_PHOTO") {
                return false;
            }
        }
        return true;
    }

    return (
        <div
            className={`${styles.wrapper} ${props.card.trim === "rounded" ? styles.cutRounded : ""} ${
                props.editMode === true ? styles.edit : styles.preview
            } ${props.editMode === true ? styles.bleedMarker : props.forPrint ? "" : styles.hideBleed} ${
                props.shadow ? styles.shadow : ""
            }`}
            style={getStyleVariables(props.card.fonts, props.card.colorScheme)}
        >
            <div
                className={styles.innerWrapper}
            >
                {showGraphic() && props.card.siteGraphic ? (
                    <SiteGraphic
                        type={props.page === 1 ? "PRINT_PAGE_1" : "PRINT_PAGE_2"}
                        siteGraphic={props.card.siteGraphic}
                        editAvailable={true}
                    />
                ) : null}

                {props.card.layout === "GENESIS" ? (
                    <CardLayoutGenesis
                        updateCard={"updateCard" in props ? props.updateCard : undefined}
                        page={props.page}
                        card={props.card}
                        editMode={props.editMode}
                        thumbnail={"thumbnail" in props && props.thumbnail}
                    />
                ) : null}

                {props.card.layout === "PHOTO_CLEAN" ? (
                    <CardLayoutPhotoClean
                        updateCard={"updateCard" in props ? props.updateCard : undefined}
                        page={props.page}
                        card={props.card}
                        editMode={props.editMode}
                        thumbnail={"thumbnail" in props && props.thumbnail}
                    />
                ) : null}

                {props.card.layout === "TEXT_ON_PHOTO" ? (
                    <CardLayoutTextOnPhoto
                        updateCard={"updateCard" in props ? props.updateCard : undefined}
                        page={props.page}
                        card={props.card}
                        editMode={props.editMode}
                        thumbnail={"thumbnail" in props && props.thumbnail}
                    />
                ) : null}

            </div>
        </div>
    );
};
