

export const parseSwishNumber = (swishNumber: string) => {
    if (!swishNumber) {
        return "";
    }
    swishNumber = swishNumber.trim();
    swishNumber = swishNumber.replace("+", "");
    if (swishNumber.length > 6 && swishNumber.substring(0,2) === "07") {
        return "46" + swishNumber.substring(1, swishNumber.length);
    }
    return swishNumber;
};
