import React from "react";
import styles from "./PasswordField.module.scss";

interface IProps {
    onChangeCallback: any;
    value?: string;
    name: string;
    id: string;
    label?: string;
    disabled?: boolean;
    required?: boolean;
}

interface IState {
    value: string;
}

export class PasswordField extends React.Component<IProps, IState> {
    state: IState = {
        value: this.props.value || "",
    };

    handleChange(event: React.ChangeEvent<HTMLInputElement>) {
        this.setState({ value: event.target.value });

        this.props.onChangeCallback(event.target.value, this.props.name);
    }

    render() {
        return (
            <div className={styles.textFieldWrapper}>
                <label htmlFor={this.props.id}>{this.props.label}</label>
                <input
                    required={this.props.required ? true : false}
                    disabled={this.props.disabled ? true : false}
                    id={this.props.id}
                    minLength={this.props.required ? 6 : undefined}
                    type="password"
                    name={this.props.name}
                    value={this.state.value}
                    onChange={this.handleChange.bind(this)}
                />
                <div className="invalidFeedback" data-invalid-feedback="true"></div>
            </div>
        );
    }
}

export default PasswordField;
