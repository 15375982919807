import React, { useState } from "react";
import { ISite } from "../../../interfaces/Site";
import { getSrc, getSrcSet } from "../../../utils/getSiteImage";
import styles from "./Image.module.scss";

interface IProps {
    image: any;
    site: ISite;
    onClick?: () => void;
    sizes: string | null;
}

export function Image(props: IProps) {
    const [failedToLoadSrc, setFailedToLoadSrc] = useState<null | string>(null);

    const handleError = () => {
        setFailedToLoadSrc(getSrc(props.image, props.site));
    };

    const showError = () => getSrc(props.image, props.site) === failedToLoadSrc;

    return showError() ? (
        <div className={styles.error} onClick={props.onClick}>
            {" "}
            Misslyckades att ladda bild
        </div>
    ) : (
        <img
            className={props.onClick ? styles.clickable : ""}
            onClick={props.onClick}
            onError={handleError}
            src={getSrc(props.image, props.site)}
            srcSet={getSrcSet(props.image, props.site)}
            sizes={props.sizes || undefined}
            alt=""
        />
    );
}
