import { CardElement, useElements, useStripe } from "@stripe/react-stripe-js";
import { StripeCardElement } from "@stripe/stripe-js";
import React, { useState } from "react";
import Button from "../../../../../../components/button/Button";
import Loading from "../../../../../../components/Loading/Loading";
import { IconStripe } from "../../../../../../icons/stripe";
import styles from "./StripeForm.module.scss";

interface IProps {
    showError: any;
    paymentDone: any;
    clientSecret: string;
}

const StripeForm = (props: IProps) => {
    const [loading, setLoading] = useState(false);
    const [cardError, setCardError] = useState("");

    const stripe = useStripe();
    const elements = useElements();

    const handleSubmit = async (event: any) => {
        // Block native form submission.
        event.preventDefault();

        if (!stripe || !elements) {
            // Stripe.js has not loaded yet. Make sure to disable
            // form submission until Stripe.js has loaded.
            console.log("stripe not loaded");
            return;
        }

        setLoading(true);

        const cardElement = elements.getElement(CardElement) as StripeCardElement;

        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const { error } = await stripe.confirmCardPayment(props.clientSecret, {
            payment_method: {
                card: cardElement,
            },
        });

        if (error) {
            setLoading(false);
            setCardError(
                "Betalningen misslyckades. Kontrollera dina kortuppgifter och se till att du öppnat ditt kort för internetköp om din bank kräver det."
            );
        } else {
            setLoading(false);
            props.paymentDone();
        }
    };

    const createOptions = () => {
        return {
            style: {
                base: {
                    fontSize: "16px",
                    color: "#424770",
                    letterSpacing: "0.025em",
                    fontFamily: "Source Code Pro, monospace",
                    "::placeholder": {
                        color: "#aab7c4",
                    },
                },
                invalid: {
                    color: "#9e2146",
                },
            },
        };
    };

    const handleChange = (event: any) => {
        if (event.error) {
            setCardError(event.error.message);
        } else {
            setCardError("");
        }
    };

    return (
        <form onSubmit={handleSubmit} className={styles.wrapper}>
            {loading === true && <Loading></Loading>}
            <label className={styles.label}>
                Dina kortuppgifter
                <div className={styles.cardElementWrapper} data-test-id="stripe-wrapper">
                    <CardElement onChange={handleChange} options={createOptions()} />
                </div>
                {cardError ? <div className={styles.errors}>{cardError}</div> : ""}
            </label>
            <div className={styles.buttonAndLogosWrapper}>
                <div>
                    <Button
                        buttonText="Betala"
                        submit={true}
                        type="primary"
                        data-test-id="button-pay"
                        disabled={!stripe}
                    ></Button>
                </div>
                <div className={styles.logosWrapper}>
                    <div>
                        <a href="https://www.stripe.com" target="_blank" rel="noopener noreferrer">
                            {IconStripe()}
                        </a>
                    </div>
                </div>
            </div>
        </form>
    );
};

export default StripeForm;
