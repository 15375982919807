import Fade from "@material-ui/core/Fade";
import Modal from "@material-ui/core/Modal";
import React from "react";
import { IconClose } from "../../icons/close";
import { ISite } from "../../interfaces/Site";
import styles from "./GeneratorModal.module.scss";

interface IProps {
    children: any;
    close: () => void;
    open: boolean;
    site: ISite;
    large?: boolean;
    "data-test-id"?: string;
}

export default function GeneratorModal(props: IProps) {
    return (
        <div>
            <Modal
                className={`${styles[props.site.theme]} font-setter ${props.large ? styles.large : ""}`}
                open={props.open}
                onClose={props.close}
                data-test-id={props["data-test-id"]}
            >
                <Fade in={props.open}>
                    <div className={styles.paper}>
                        <button className={styles.close} onClick={props.close}>
                            <IconClose />
                        </button>
                        {props.children}
                    </div>
                </Fade>
            </Modal>
        </div>
    );
}
