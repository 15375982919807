import React, { ChangeEvent } from "react";
import { IconDelete } from "../../../icons/delete";
import Confirmation from "../../../modules/confirmation/Confirmation";
import EditButton from "../../EditButton/EditButton";
import styles from "./TextField.module.scss";

interface IProps {
    id: string;
    label: string;
    toggleMandatory: any;
    mandatory: boolean;
    className?: any;
    labelOnChange?: any;
    removeItem?: any;
}

interface IState {
    showEditBar: boolean;
    showDeleteConfirmation: boolean;
}

export class TextFieldEdit extends React.Component<IProps, IState> {
    state: IState = {
        showEditBar: false,
        showDeleteConfirmation: false,
    };

    showEditBar = () => {
        this.setState({
            showEditBar: !this.state.showEditBar,
        });
    };

    refCancelOnClickOutside: any;
    refLabel: any;

    componentDidMount() {
        document.addEventListener("mousedown", this.handleClickOutside);
    }

    componentWillUnmount() {
        document.removeEventListener("mousedown", this.handleClickOutside);
    }

    handleClickOutside = (event: Event) => {
        if (this.refCancelOnClickOutside && !this.refCancelOnClickOutside.contains(event.target)) {
            this.setState({
                showEditBar: false,
            });
        }
    };

    closeConfirmationCallback = () => {
        this.setState({
            showDeleteConfirmation: false,
        });
    };

    deleteWithConfirmation = () => {
        this.setState({
            showDeleteConfirmation: true,
        });
    };

    setRefForCancelOnClickOutside = (that: any) => {
        this.refCancelOnClickOutside = that;
    };

    setRefForLabel = (that: any) => {
        this.refLabel = that;
    };

    labelOnChange = (e: ChangeEvent) => {
        let targetElement: HTMLElement = e.target as HTMLElement;
        let newLabel = targetElement.innerText;

        // This cant be null, so if user entered nothing then take the old value.
        if (!newLabel) {
            newLabel = this.props.label;
            this.refLabel.innerText = newLabel;
        } else {
            this.props.labelOnChange(newLabel);
        }
    };

    render() {
        return (
            <div className={this.props.className || styles.textFieldWrapper}>
                {this.state.showDeleteConfirmation === true && (
                    <Confirmation
                        confirmationTitle="Ta bort"
                        confirmButtonText="Ta bort"
                        abortButtonText="Avbryt"
                        confirmationText="Är du säker på att du vill ta bort det här formulärfältet?"
                        confirmCallback={this.props.removeItem.bind(this)}
                        closeCallback={this.closeConfirmationCallback.bind(this)}
                    ></Confirmation>
                )}
                <span
                    ref={this.setRefForLabel}
                    className="actAsLabel"
                    contentEditable={this.props.labelOnChange ? true : false}
                    suppressContentEditableWarning={true}
                    onBlur={this.props.labelOnChange && this.labelOnChange}
                >
                    {this.props.label}
                </span>
                <div ref={this.setRefForCancelOnClickOutside}>
                    <div onClick={this.showEditBar.bind(this)} className={styles.inputClickArea}>
                        <input type="text" readOnly={true} />
                    </div>
                    <div className={styles.editBarWrapper}>
                        {this.state.showEditBar === true ? (
                            <>
                                {this.props.removeItem && (
                                    <EditButton callback={this.deleteWithConfirmation.bind(this)}>
                                        <>
                                            <IconDelete></IconDelete>
                                            Ta bort fält
                                        </>
                                    </EditButton>
                                )}
                                <EditButton callback={this.props.toggleMandatory}>
                                    <>
                                        {this.props.mandatory === true
                                            ? "Ange som frivilligt"
                                            : "Ange som obligatoriskt"}
                                    </>
                                </EditButton>
                            </>
                        ) : (
                            ""
                        )}
                    </div>
                </div>
            </div>
        );
    }
}

export default TextFieldEdit;
