import { jsx } from "slate-hyperscript";

export const deserializeHtmlToSlate = (el: any, markAttributes = {}): any => {
    if (el.nodeType === Node.TEXT_NODE) {
        return jsx("text", markAttributes, el.textContent);
    } else if (el.nodeType !== Node.ELEMENT_NODE) {
        return null;
    }

    const nodeAttributes: any = { ...markAttributes };

    switch (el.nodeName.toLowerCase()) {
        case "strong":
            nodeAttributes.bold = true;
            break;
        case "em":
            nodeAttributes.italic = true;
            break;
        case "u":
            nodeAttributes.underlined = true;
            break;
    }

    const children = Array.from(el.childNodes)
        .map((node) => deserializeHtmlToSlate(node, nodeAttributes))
        .flat();

    if (children.length === 0) {
        children.push(jsx("text", nodeAttributes, ""));
    }

    switch (el.nodeName.toLowerCase()) {
        case "body":
            return jsx("fragment", {}, children);
        case "br":
            return "\n";
        case "h3":
            return jsx("element", { type: "heading-three" }, children);
        case "p":
            return jsx("element", { type: "paragraph" }, children);
        case "a":
            return jsx("element", { type: "link", url: el.getAttribute("href") }, children);
        default:
            return children;
    }
};
