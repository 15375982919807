import React from "react";
import { ISite } from "../../interfaces/Site";
import styles from "./Map.module.scss";

interface IProps {
    location: string;
    editMode: boolean;
    mapTheme: MapTheme;
    markerColor: string;
    zoom: number;
    site: ISite;
    preventCache: boolean;
}

export enum MapTheme {
    NORMAL = "NORMAL",
    DARK = "DARK",
    NIGHT = "NIGHT",
    RETRO = "RETRO",
    SILVER = "SILVER",
}

export const Map = (props: IProps) => {
    const imageSrc = () => {
        let markerColor = props.markerColor || "#ea4335";
        markerColor = markerColor.replace("#", "0x");
        const location = encodeURIComponent(props.location);

        let url = `${process.env.REACT_APP_API_URL}/api/sites/${props.site._id}/maps/${location}/${props.zoom || 12}/${
            props.mapTheme || MapTheme.NORMAL
        }/${markerColor}/`;

        if (props.preventCache) {
            url = url + "?preventCache=true";
        }
        return url;
    };
    /*
        Would like to do this but kind of hard since i need to know the height and width in the css
        srcSet={`${this.imageSrc("640x640")} 640w, ${this.imageSrc("400x400")} 400w, ${this.imageSrc("360x360")} 360w`}
    */
    const mapAndLabel = () => (
        <>
            <div className={styles.locationLabel}>{props.location}</div>
            <img src={imageSrc()} loading="lazy" alt="karta" />
        </>
    );

    if (!props.location) {
        return null;
    }
    if (props.editMode) {
        return <div className={`${styles.mapWrapper}`}>{mapAndLabel()}</div>;
    } else {
        return (
            <a
                className={styles.mapWrapper}
                target="_blank"
                rel="noreferrer"
                href={"https://www.google.se/maps/search/?api=1&query=" + encodeURIComponent(props.location)}
            >
                {mapAndLabel()}
            </a>
        );
    }
};
