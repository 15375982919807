import Fade from "@material-ui/core/Fade";
import { default as ReactModal } from "@material-ui/core/Modal";
import React from "react";
import { IconClose } from "../../icons/close";
import styles from "./Modal.module.scss";

interface IProps {
    children: any;
    close: () => void;
    open: boolean;
    disableBackdropClick?: boolean;
}

export default function Modal(props: IProps) {
    return (
        <div>
            <ReactModal
                className={styles.modal}
                open={props.open}
                onClose={props.close}
                disableBackdropClick={props.disableBackdropClick}
            >
                <Fade in={props.open}>
                    <div className={styles.closeAndContentWrapper}>
                        <button className={styles.close} onClick={props.close}>
                            <IconClose />
                        </button>
                        <div className={styles.paper}>{props.children}</div>
                    </div>
                </Fade>
            </ReactModal>
        </div>
    );
}
